import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DragulaModule } from 'ng2-dragula';

import { AssessmentDetailComponent } from './detail/edit-training/raw-data/detail/assessment-detail.component';
import { AssessmentRowComponent } from './detail/edit-training/raw-data/list/row/assessment-row.component';
import { AssessmentsComponent } from './detail/edit-training/raw-data/list/assessments.component';
import { AuthGuardService } from '../shared/services/authenticationGuard.service';
import { DetailAssessmentRowComponent } from './detail/edit-training/raw-data/detail/row/detail-assessment-row.component';
import { SharedModule } from '../shared/shared.module';
import { TrainingFormulaComponent } from './detail/edit-training/formula/training-formula.component';
import { TrainingGeneralInformationComponent } from './detail/edit-training/general-information/general-information.component';
import { TrainingParameterComponent } from './detail/edit-training/parameters/training-parameter.component';
import { TrainingPublicationComponent } from './detail/edit-training/publication/training-publication.component';
import { TrainingRootComponent } from './detail/root/training-root.component';
import { TrainingRowComponent } from './list/row/training-row.component';
import { TrainingsComponent } from './list/trainings.component';
import { TrainingUsersComponent } from './detail/edit-training/users/training-users.component';
import { TrainingWorkflowsComponent } from './detail/edit-training/workflows/training-workflows.component';

const appRoutes: Routes = [
    {
        path: 'trainings',
        component: TrainingsComponent,
        canActivate: [AuthGuardService],
        data: { role: 'DNA_SCHEDULE' }
    },
    {
        path: 'trainings/:idTraining/edit',
        component: TrainingRootComponent,
        canActivate: [AuthGuardService],
        data: { role: 'DNA_SCHEDULE' },
        children: [
            { path: '', redirectTo: 'generalInformation', pathMatch: 'full' },
            { path: 'generalInformation', component: TrainingGeneralInformationComponent, canDeactivate: [AuthGuardService] },
            { path: 'workflows', component: TrainingWorkflowsComponent, canDeactivate: [AuthGuardService] },
            { path: 'formulas', component: TrainingFormulaComponent, canDeactivate: [AuthGuardService] },
            { path: 'users', component: TrainingUsersComponent, canDeactivate: [AuthGuardService] },
            { path: 'parameters', component: TrainingParameterComponent, canDeactivate: [AuthGuardService] },
            { path: 'publish', component: TrainingPublicationComponent, canDeactivate: [AuthGuardService] },
            { path: 'rawdata', component: AssessmentsComponent, canDeactivate: [AuthGuardService] },
            { path: 'rawdata/:idAssessment/detail', component: AssessmentDetailComponent, canDeactivate: [AuthGuardService] },
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        DragulaModule.forRoot(),
        RouterModule.forChild(appRoutes),
        SharedModule
    ],
    declarations: [
        AssessmentDetailComponent,
        AssessmentRowComponent,
        AssessmentsComponent,
        DetailAssessmentRowComponent,
        TrainingFormulaComponent,
        TrainingGeneralInformationComponent,
        TrainingParameterComponent,
        TrainingPublicationComponent,
        TrainingRootComponent,
        TrainingRowComponent,
        TrainingsComponent,
        TrainingUsersComponent,
        TrainingWorkflowsComponent
    ]
})
export class TrainingModule { }
