<div class="ibox float-e-margins ibox-position">
  <ngx-loading [show]="showSpinner" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
  <div class="ibox-title">
    <h5 translate>{{ 'BLOCKS' | translate}} - {{ (user.currentWorkspace.name ? user.currentWorkspace.name : 'ALL') | translate }}</h5>
  </div>
  <div id="ibox-Blocks" class="ibox-content text-center">
    <div class="row">
      <div class="col-12 col-md-6">
        <svg viewBox="-1 -1 2 2" height="250px" width="250px" style="transform: rotate(-90deg);">
          <path class="green1" [ngClass]="{'pathhover': hover1}" [attr.d]="utilService.pathProgress(blocksToPrepare.length / blocks.length)"></path>
          <path class="green3" [ngClass]="{'pathhover': hover3}" [attr.d]="utilService.pathProgress((blocksToPrepare.length + blocksInProgress.length) / blocks.length, blocksToPrepare.length / blocks.length)"></path>
          <path class="green5" [ngClass]="{'pathhover': hover5}" [attr.d]="utilService.pathProgress(1, (blocksToPrepare.length + blocksInProgress.length) / blocks.length)"></path>
        </svg>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group">
          <a class="list-group-item green1" (mouseenter)="hover1 = true" (mouseleave)="hover1 = false" [routerLink]="['/blocks', { state: blockStates.Draft }]">
            {{ blocksToPrepare.length }}
            <span translate>DRAFT</span>
          </a>
          <a class="list-group-item green3" (mouseenter)="hover3 = true" (mouseleave)="hover3 = false" [routerLink]="['/blocks', { state: blockStates.Published }]">
            {{ blocksInProgress.length }}
            <span translate>PUBLISHED</span>
          </a>
          <a class="list-group-item green5" (mouseenter)="hover5 = true" (mouseleave)="hover5 = false" [routerLink]="['/blocks', { state: blockStates.Suspended }]">
            {{ blocksSuspended.length }}
            <span translate>SUSPENDED</span>
          </a>
        </ul>
      </div>
    </div>
  </div>
</div>
