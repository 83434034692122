<dna-layout>
  <h1 class="mb-4" id="banner-title" translate>TRAININGS</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>TRAININGS</li>
    </ol>
  </nav>
  <div class="actions" id="banner-actions">
    <a class="btn btn-primary mb-4" [routerLink]="['/trainings', 'new', 'edit']">
      <span translate>CREATE </span>
      <span translate>TRAINING</span>
    </a>
  </div>
  <div class="block">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5 translate>FILTERS</h5>
        <div class="ibox-tools">
          <a href="#ibox-Filters" data-toggle="collapse" aria-expanded="true">
            <i class="fa"></i>
          </a>
        </div>
      </div>
      <div id="ibox-Filters" class="ibox-content collapse show">
        <div class="row">
          <ngx-loading [show]="showSpinner"></ngx-loading>
          <div class="col-md-4 mt-2 mb-2">
            <dna-search-text [data]="filter.name" (onSearch)="updateData($event, 'name', filter)"></dna-search-text>
          </div>
          <div class="offset-md-4 col-md-4 mt-2 mb-2">
            <dna-filter-list [array]="allStates" [filterValue]="filter.states" (updateFilter)="onFilterChanged(filter)">
            </dna-filter-list>
          </div>
          <div class="col-md-6 mt-2 mb-2">
            <dna-button-display-only logo="fa fa-star" textToDisplay="{{'ONLY_DISPLAY_FAVORITE' | translate}}"
              [value]="filter.displayFavoritesOnly"
              (onClick)="updateData(!filter.displayFavoritesOnly, 'displayFavoritesOnly', filter)">
            </dna-button-display-only>
          </div>
        </div>
      </div>
    </div>
    <input id="tourSign" type="hidden" value="trainings">
    <dna-table [data]="filteredTrainings" [headers]="tableHeaders$ | async"
      [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage" orderedBy="DATE_MODIFICATION"
      [pageIndex]="filter.pageIndex" [reverse]="true" [sortFunction]="sort" [title]="'Trainings'"
      (parametersChanged)="onParametersChanged($event)">
      <ng-template #row let-data="data">
        <tr dna-training-row class="cursor-pointer" [currentLanguage]="currentLanguage" [training]="data"
          (onClick)="onClickActionButton($event, data.id)"></tr>
      </ng-template>
    </dna-table>
  </div>
</dna-layout>
