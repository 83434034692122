<div 
    class="dna-table-raw-data--caption"
    *ngIf="this.title" translate> {{ this.title | applyTranslation:this.lang: Languages.Default }} 
</div>
<table class="dna-table-raw-data" #table>
    <thead>
      <tr *ngFor="let head of this.head">
        <th [style.width.px]="'30'"> # </th>
        <th *ngFor="let column of head" [attr.colspan]="column.colspan" translate> {{ column.label | applyTranslation:this.lang: Languages.Default }} </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let body of this.body; let index = index;">
        <td 
          [style.width.px]="'30'"
        > 
          <ng-template [ngIf]="body[0]?.value?.translations?.label">
            {{ body[0]?.value?.translations?.label | applyTranslation : this.lang : Languages.Default }}
          </ng-template>
          <ng-template [ngIf]="!body[0]?.value?.translations?.label">
            {{ index }}
          </ng-template>
        </td>
        <td *ngFor="let cell of body"
          [style.color]="cell.color"
        >
          <ng-template [ngIf]="cell?.value?.translations?.value"> {{ cell.value.translations.value.label | applyTranslation : this.lang : Languages.Default}} </ng-template>
          <ng-template [ngIf]="!cell?.value?.translations?.label && !cell?.value?.translations?.value"> {{ cell.value.translations | applyTranslation : this.lang : Languages.Default}} </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
