<div class="modal-header">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <h4 class="modal-title">
    <span translate>DEMAND </span>{{ synergyDemand.id }}
  </h4>
  <button class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only">Close</span>
  </button>
</div>
<div class="modal-body row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 my-2">
        <div class="search">
          <dna-search-text [data]="filter.name" (onSearch)="updateData($event, 'name', filter)">
          </dna-search-text>
        </div>
      </div>
      <div class="col-12 col-md-4 my-2">
        <dna-filter-list [array]="states" [filterValue]="filter.states" (updateFilter)="onFilterChanged(filter)"></dna-filter-list>
      </div>
      <div class="col-12 col-md-4 my-2">
        <dna-filter-list [array]="typeCampaigns" [filterValue]="filter.studyType" (updateFilter)="onFilterChanged(filter)" fieldToDisplay="name"></dna-filter-list>
      </div>
      <div class="col-12 col-md-4 my-2">
        <dna-filter-list [array]="typeEvaluations" [filterValue]="filter.typeEvaluation" (updateFilter)="onFilterChanged(filter)"
          fieldToDisplay="name"></dna-filter-list>
      </div>
      <div class="col-12 col-md-6 my-2">
        <dna-filter-date [dateFilterStart]="filter.startDate" [dateFilterEnd]="filter.endDate" (updateDateStart)="updateData($event, 'startDate', filter)"
          (updateDateEnd)="updateData($event, 'endDate', filter)">
        </dna-filter-date>
      </div>
    </div>
  </div>
  <div class="ibox-content select-campaign col-12">
    <label for="label-campaign">{{ 'CAMPAIGN_SELECT' | translate }}</label>
    <select id="label-campaign" class="form-control w-50 mx-auto" [disabled]="isEmpty" [(ngModel)]="campaignSelected" name="select-campaign">
      <option *ngFor="let campaign of campaignsFiltered" [ngValue]="campaign" translate>{{ campaign.name }}</option>
    </select>
  </div>
  <div *ngIf="isEmpty" class="col-12 text-center">
    <label class="title mt-3">{{ "BRIDGE_NO_CAMPAIGNS" | translate }}</label>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" [disabled]="isEmpty" type="button" (click)="link(synergyDemand, campaignSelected)" translate>BRIDGE_LINK</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
