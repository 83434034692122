
import {forkJoin as observableForkJoin, 
  Observable
,  Subscription } from 'rxjs';

import {merge, zip, tap, mergeMap, filter, map} from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import {
  Component,
  OnInit
} from '@angular/core';
import { Location } from '@angular/common';

import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import * as moment from 'moment';

import {
  Answer,
  Buttons,
  CampaignRawData,
  Evaluation,
  TableHeader
} from '../../../../../types';
import {
  CampaignService
} from '../../../../campaigns.service';
import {
  DNATranslateService
} from '../../../../../shared/services/translate.service';
import { EvaluationService } from '../evaluations.service';
import { UtilService } from '../../../../../shared/services/util.service';

@Component({
  selector: 'dna-evaluation-detail',
  templateUrl: './evaluation-detail.component.html',
  styleUrls: ['./evaluation-detail.component.less']
})
export class EvaluationDetailComponent implements OnInit {

  containsPhotos: boolean = false;
  containsVideos: boolean = false;
  currentLanguage: string;
  evaluation: Evaluation;
  idCampaign: string;
  listButtons$: Observable<Buttons[]>;
  objects$: Observable<any[]>;
  showSpinner: boolean = true;
  subscribeLang: Subscription;
  tableHeaders$: Observable<TableHeader[]>;

  constructor(
    public campaignService: CampaignService,
    private DNATranslate: DNATranslateService,
    private evaluationService: EvaluationService,
    public location: Location,
    private route: ActivatedRoute,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');
    const idEvaluation = this.route.snapshot.paramMap.get('idEvaluation');

    const evaluation$ = this.campaignService.getEvaluationPerId(this.idCampaign, idEvaluation).pipe(
      map(this.addIndexInAnswers),
      map((e: Evaluation) => this.mapUsers(e)),
      tap((e: Evaluation) => this.tableHeaders$ = this.utilService.createHeadersDetailEvaluation(e)),
      tap((e: Evaluation) => this.evaluation = e)
    )

    const campaign$ = this.campaignService.getCampaignRawData(this.idCampaign).pipe(
      tap((c: CampaignRawData) => this.containsPhotos = c.containsPhotos),
      tap((c: CampaignRawData) => this.containsVideos = c.containsVideos),
      tap(() => this.listButtons$ = this.utilService.createButtonsDetailEvaluation([this.containsPhotos, this.containsVideos]))
    )

    this.objects$ = evaluation$.pipe(zip(campaign$),
      tap(objects => objects[0].workflowName = objects[1].workflows.find((w: any) => w.id === objects[0].questionnaireId).name),
      tap(() => this.showSpinner = false));

    this.subscribeLang = this.DNATranslate.onLangChange().pipe(
      tap((translation: any) => this.currentLanguage = translation.lang),
      tap(() => this.tableHeaders$ = this.utilService.createHeadersDetailEvaluation(this.evaluation)),
      tap(() => this.listButtons$ = this.utilService.createButtonsDetailEvaluation([this.containsPhotos, this.containsVideos])))
      .subscribe();
  }

  goBack() {
    this.showSpinner = true;
    this.location.back();
  }

  ngOnDestroy() {
    this.subscribeLang.unsubscribe();
  }

  addIndexInAnswers(evaluation: Evaluation) {
    evaluation.answers = evaluation.answers.map((answer, index) => {
      answer.index = index;
      return answer;
    });
    return evaluation;
  }

  download(idButton: string, campaignRawData: CampaignRawData, evaluation: Evaluation) {
    switch (idButton) {
      case 'DOWNLOAD_IMAGES':
        this.downloadImg(campaignRawData, evaluation);
        break;
      case 'DOWNLOAD_VIDEOS':
        this.downloadVideo(campaignRawData, evaluation);
        break;
    }
  }

  downloadImg(campaignRawData: CampaignRawData, evaluation: Evaluation) {
    let zip = new JSZip();
    let dateStartCampaign = moment(campaignRawData.startDate).format("YYYYMMDD");
    let firstFolder = zip.folder(dateStartCampaign + "_" + campaignRawData.name);
    let observables: Observable<any>[] = [];
    evaluation.answers.forEach((answer: Answer) => {
      let subFolder = firstFolder.folder(evaluation.formula.name);
      let index = 1;
      if (answer.idComponent == "dna-photo" && answer.records && answer.records.length > 0 && answer.records[0].data && answer.records[0].data.length != 0 && answer.records[0].data[0].value) {
        observables.push(this.evaluationService.getImage(answer, subFolder, index, evaluation, campaignRawData.name, campaignRawData.workflows));
        index += 1;
      }
    });

    observableForkJoin(observables).subscribe(() => {
      zip.generateAsync({
        type: "blob"
      }).then((content: any) => {
        FileSaver.saveAs(content, campaignRawData.name + ".zip");
      });
    })
  }

  downloadVideo(campaignRawData: CampaignRawData, evaluation: Evaluation) {
    let zip = new JSZip();
    let dateStartCampaign = moment(campaignRawData.startDate).format("YYYYMMDD");
    let firstFolder = zip.folder(dateStartCampaign + "_" + campaignRawData.name);
    let observables: Observable<any>[] = [];
    evaluation.answers.forEach((answer: Answer) => {
      let subFolder = firstFolder.folder(evaluation.formula.name);
      let index = 1;
      if (answer.idComponent == "dna-video-capture" && answer.records && answer.records.length > 0 && answer.records[0].data && answer.records[0].data.length != 0 && answer.records[0].data[0].value && answer.records[0].data[0].value !== 'NOT_EXPECTING_ANSWER') {
        observables.push(this.evaluationService.getVideo(campaignRawData.name, campaignRawData.workflows, evaluation, answer, subFolder, index));
        index += 1;
      }
    });

    observableForkJoin(observables).subscribe(() => {
      zip.generateAsync({
        type: "blob"
      }).then((content: any) => {
        FileSaver.saveAs(content, campaignRawData.name + ".zip");
      });
    })
  }

  mapUsers(evaluation: Evaluation): Evaluation {
    evaluation.users = this.utilService.addNamesToUsers(evaluation.users, this.campaignService.users);
    return evaluation;
  }

  sort(answers: Answer[], headerId: string, reverse: boolean) {
    switch (headerId) {
      case 'INDEX':
        return this.utilService.sortListByType(answers, 'index', reverse);
      case 'LABEL':
        return this.utilService.sortListByType(answers, 'label', reverse, true, this.currentLanguage);
    }
  }

}
