declare let require: any;
import * as Highcharts from 'highcharts';
import { Subject } from 'rxjs';
import { Languages } from '../enums';
import { Descriptor } from '../../../../../../../types';
import * as _ from 'lodash';

Highcharts.setOptions({
  colors: ['#0c00ff', '#1ab394', ...Highcharts.getOptions().colors],
});
const highchartsMore = require('highcharts/highcharts-more.js');
highchartsMore(Highcharts);
const boostCanvas = require('highcharts/modules/boost-canvas.js');
boostCanvas(Highcharts);
const annotationModule = require('highcharts/modules/annotations.js');
annotationModule(Highcharts);

const domtoimage = require('dom-to-image-more');

export abstract class Chart {

  public custom: {
    updateRoutines: ( routines: any[] ) => void;
  } = {
      updateRoutines: (_) => {}
    };
  
  constructor(
    protected _parameters?: any,
    protected _data?: any,
    protected _lang?: string,
    protected _filters?: any
  ) {
    const { data, pDescriptors, routines } = this._data;
    this.parameters = this._parameters || {};
    this.descriptors = pDescriptors || [];
    this.routines = routines || {};
    this.data = data || [];
    this.lang = this._lang || Languages.Default;
    this.filters = this._filters || {};
  }

  get parameters(): any {
    return this._parameters;
  }
  get data(): Array<any> {
    return this._data;
  }
  get options(): any {
    return this._options;
  }
  get tableRawData(): any {
    return this._tableRawData;
  }
  get lang(): string {
    return this._lang;
  }
  get filters(): any {
    return this._filters;
  }
  get descriptors(): Array<Descriptor> {
    return this._descriptors;
  }
  get routines(): any {
    return this._routines;
  }

  set filters(_filters: any) {
    this._filters = _filters;
  }
  set descriptors(_descriptors: Array<Descriptor>) {
    if (_descriptors && _descriptors.length) { this._descriptors = _descriptors; } else { this._descriptors = []; }
  }

  set routines(routines: any) {
    if (!routines) { this._routines = routines; } else {
      let { routines: rs, ...rest } = routines;
      if (rs && rs.length) {
        rs = rs.map((formula: any, index: number) => {
          formula.color = Chart.colorScale[index];
          return formula;
        });
      }
      this._routines = {
        routines: rs,
        ...rest,
      };
    }
  }
  set lang(_lang: string) {
    this._lang = _lang;
    if (this.chart) { this.chart.userOptions['language'] = this.lang; }
    this.generateDataAssignment(this.data, this.lang);
  }
  set tableRawData(_tableRawData: any) {
    this._tableRawData = _tableRawData;
  }
  set options(options: any) {
    this._options = options;
  }
  set data(data: Array<any>) {
    this._data = data;
    /*
     * Adding a try/catch to handle Table instance that does not have table for raw data.
     * You might want to refactor this part, using for instance a check such as `if (this instanceof Table)`
     * but in this case, you will be forced to import Table model within this class, and that will cause a circular
     * injection...
     */
    /*  try {
            //this.formatRawData(data, this.lang);
            this.tableRawData = this.oldformatRawData(this.data);
        } catch (e) {
            try {
                // Meaning that this is an instance of Table
                // this.tableRawData = this.formatDataTable(this.data);
                this.tableRawData = this.formatDataTable(this.data);
            } catch (e) { }
        } */
  }
  set parameters(parameters: any) {
    this._parameters = Object.assign(this._baseParameters, parameters);
  }
  public static colorScale = Highcharts.getOptions().colors;
  public static drilledColors = [
    '#f34336',
    '#9c27b0',
    '#607d8b',
    '#673ab7',
    '#2196f2',
    '#00bcd3',
    '#4caf50',
    '#8bc24a',
    '#ccdb39',
    '#feea3b',
    '#fec007',
    '#e81e63',
    '#9e9e9e',
    '#795548',
    '#fe5722',
    '#ff5252',
  ];

  public static dashStyles = [
    'Solid',
    'ShortDash',
    'ShortDot',
    'ShortDashDot',
    'ShortDashDotDot',
    'Dot',
    'Dash',
    'LongDash',
    'DashDot',
    'LongDashDot',
    'LongDashDotDot',
  ];
  public onOptionChangeAfterLoadSubject = new Subject<any>();
  protected _baseParameters: any = {
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      marginTop: 100,
      backgroundColor: '#FAFAFA',
    },
    subtitle: {
      text: null,
    },
    series: [],
  };
  protected chart: Highcharts.Chart;
  protected _options: any;
  protected _tableRawData: any;
  protected _descriptors: Array<Descriptor>;
  protected _routines: any;

  public container: String | HTMLElement;

  public static castValuesToNumber = (
    values: Array<any>,
    key: string,
    isAbs?: boolean
  ) => {
    return values.map(({ value }: any) => {
      if (isNaN(value[key]) || value[key] === '') { return value; }
      value[key] = Number(value[key]);
      if (isAbs) { value[key] = Math.abs(value[key]); }
      return value;
    });
  }

  public static getObjectValueTranslation = (
    object: any,
    lang: string
  ): string => {
    switch (typeof object) {
      case 'object':
        if (!object) { return ''; }
        if (object.hasOwnProperty('custom')) { return object; }
        if (object.hasOwnProperty('user') && object.user.length) {
          return _.get(object, 'user', '').trim();
        }
        if (object.hasOwnProperty(lang) && object[lang].length) {
          return _.get(object, lang, '').trim();
        }
        return _.get(object, Languages.Default, '').trim();
      default:
        return object;
    }
  }

  public setRenderTo = (container: string | HTMLElement): void => {
    this.setContainer(container);
    Object.assign(this.parameters.chart, { renderTo: container });
  }

  /**
   * build
   * Method to build current chart using Hightcharts' building method.
   * It checks if a container is already set. If it's the case, then method will only 'update' the chart parameters and data.
   * If not, method will completly build the chart.
   * @param container? : the current container if exists
   * @return Highchart.Chart
   */
  public build = (container?: string | HTMLElement): Highcharts.Chart => {
    if (container) { this.setRenderTo(container); }
    if (!this.chart || !this.chart.series || !this.chart.series.length) {
      return (this.chart = Highcharts.chart({
        ...this.parameters,
        language: this.lang,
      }));
    } else {
      this.chart.update(this.parameters, true);
      return this.chart;
    }
  }

  /**
   * update
   * Method to update the current chart
   * @return Highcharts.Chart (this current chart);
   */
  public update = (): Highcharts.Chart => {
    this.chart.update(this.parameters, true);
    return this.chart;
  }

  /**
   * exportGraphToPng
   * Method to export charts
   * @param container {HTMLElement}
   * @return { Promise }
   */
  public exportGraphToPng = (container: any) => {
    const tableContainer = container.querySelector(
      '.dna-chart-table-container'
    );
    let init = null;
    if (tableContainer) {
      init = {
        overflow: tableContainer.style.overflow,
        width: container.style.width,
        padding: container.style.padding,
      };
      tableContainer.style.overflow = 'initial';
      // container.style.width = "fit-content";
      // container.style.padding = "3em";
    }
    return new Promise((resolve, reject) => {
      domtoimage
        .toBlob(container)
        .then((response) => {
          const file = new File([response], 'chart', {
            type: 'image/png',
          });
          resolve(file);
        })
        .finally(() => {
          if (tableContainer) {
            tableContainer.style.overflow = init.overflow;
            container.style.width = init.width;
            container.style.padding = init.padding;
          }
        });
    });
  }

  protected formatData = (
    data: Array<any>,
    method: string,
    baseKey: string,
    lang: string,
    parameters: any,
    descriptors: Array<Descriptor>,
    routines: Array<any>
  ) => {}
  protected setParamsToAxis = (params: any, axis: any) => {};
  public buildGraphOptions = (options: any) => {};
  protected generateDataAssignment = (data: any, lang: string) => {};
  protected formatRawData = (data: any, lang: string) => {};

  protected updateTitleTranslations = (
    chart: Highcharts.Chart,
    lang: string,
    parameters: any
  ) => {
    /* Check if user doesn't update current title via option panel.
     * If he did, set that value to the current title.
     * To check, a new key 'user' must appear within the 'translations' object.
     * While this value exists (meaning fiel filled) plot that value.
     * In other case, first try to get current language key, otherwise set up
     * the fallback translation key.
     */
    let translation: string;
    try {
      if (
        parameters.title.translations.hasOwnProperty('user') &&
        parameters.title.translations.user.length
      ) {
        translation = parameters.title.translations.user;
      } else if (
        parameters.title.translations.hasOwnProperty(lang) &&
        parameters.title.translations[lang].length
      ) {
        translation = parameters.title.translations[lang];
           } else if (
        parameters.title.translations.hasOwnProperty(Languages.Default) &&
        parameters.title.translations[Languages.Default].length
      ) {
        translation = parameters.title.translations[Languages.Default];
           } else { translation = parameters.title.text; }
    } catch (e) {
      // None of previous declared/searched properties exist
      translation = null;
    }

    try {
      if(
        chart.userOptions &&
        chart.userOptions.plotOptions &&
        chart.userOptions.plotOptions[ 'default_language' ] &&
        chart.userOptions.plotOptions[ 'default_language' ].enabled &&
        Languages.Default !== lang &&
        parameters.title.translations.hasOwnProperty( Languages.Default ) &&
        parameters.title.translations[ Languages.Default ].length &&
        parameters.title.translations[ Languages.Default] !== translation ) {
        
        translation = `${translation} / ${parameters.title.translations[ Languages.Default ]}`;

      }
    } catch( e ) {}
    /* Set translation value within parameters object;
     * We don't need to update other properties (such as the whole subtitle object)
     * because it was already updated within the class after update (such as 'onOptionsChange' method )
     */
    this.parameters.title.text = translation;

    // Correctly update chart's property using Highchart's method
    chart.title.update({
      text: translation,
    });
  }

  protected updateSubTitleTranslations = (
    chart: Highcharts.Chart,
    lang: string,
    parameters: any
  ) => {
    /* Check if user doesn't update current title via option panel.
     * If he did, set that value to the current title.
     * To check, a new key 'user' must appear within the 'translations' object.
     * While this value exists (meaning fiel filled) plot that value.
     * In other case, first try to get current language key, otherwise set up
     * the fallback translation key.
     */
    let translation: string;
    try {
      if (
        parameters.subtitle.translations.hasOwnProperty('user') &&
        parameters.subtitle.translations.user.length
      ) {
        translation = parameters.subtitle.translations.user;
      } else if (
        parameters.subtitle.translations.hasOwnProperty(lang) &&
        parameters.subtitle.translations[lang].length
      ) {
        translation = parameters.subtitle.translations[lang];
           } else if (
        parameters.subtitle.translations.hasOwnProperty(Languages.Default) &&
        parameters.subtitle.translations[Languages.Default].length
      ) {
        translation = parameters.subtitle.translations[Languages.Default];
           } else { translation = parameters.subtitle.text; }
    } catch (e) {
      // None of previous declared/searched properties exist
      translation = null;
    }

     try {
      if(
        chart.userOptions &&
        chart.userOptions.plotOptions &&
        chart.userOptions.plotOptions[ 'default_language' ] &&
        chart.userOptions.plotOptions[ 'default_language' ].enabled &&
        Languages.Default !== lang &&
        parameters.subtitle.translations.hasOwnProperty( Languages.Default ) &&
        parameters.subtitle.translations[ Languages.Default ].length &&
        parameters.subtitle.translations[Languages.Default] !== translation) {
        
        translation = `${translation} / ${parameters.subtitle.translations[ Languages.Default ]}`;

      }
    } catch( e ) {}

    /* Set translation value within parameters object;
     * We don't need to update other properties (such as the whole subtitle object)
     * because it was already updated within the class after update (such as 'onOptionsChange' method )
     */
    this.parameters.subtitle.text = translation;

    this.setChartXAxisTitleTranslation(chart);
    this.setChartYAxisTitleTranslation(chart);

    // Correctly update chart's property using Highchart's method
    chart.subtitle.update({
      text: translation,
    });
  }

  private setChartXAxisTitleTranslation(chart: Highcharts.Chart) {
    if (
      this.parameters.translations &&
      this.parameters.translations.xAxis &&
      this.parameters.translations.xAxis.title
    ) {
      chart.xAxis[0].setTitle(
        {
          text: this.getObjectValueTranslation(
            this.parameters.translations.xAxis.title,
            this.lang
          ),
        },
        false
      );
    }
  }

  private setChartYAxisTitleTranslation(chart: Highcharts.Chart) {
    if (
      this.parameters.translations &&
      this.parameters.translations.yAxis &&
      this.parameters.translations.yAxis.title
    ) {
      chart.yAxis[0].setTitle(
        {
          text: this.getObjectValueTranslation(
            this.parameters.translations.yAxis.title,
            this.lang
          ),
        },
        false
      );
    }
  }

  protected getObjectValueTranslation = (object: any, lang: string): string => {
    switch (typeof object) {
      case 'object':
        if (null === object || object.hasOwnProperty('custom')) { return object; } else if (
          object.hasOwnProperty('user') &&
          object.user &&
          object.user.length
        ) {
          return object.user;
             } else if (
          object.hasOwnProperty(lang) &&
          object[lang] &&
          object[lang].length
        ) {
          return object[lang];
             } else { return object[Languages.Default]; }
      default:
        return object;
    }
  }

  /**
   * formatDataTable
   * Method to turn raw data into readable format in order to display a two-headers table.
   * This is a special method to handle Table input data formatting
   */
  public formatDataTable = (
    data: any
  ): { header: Array<any>; body: Array<any> } => {
    const payload = {
      header: [],
      body: {},
      temp: {},
    };

    data.reduce((reducer: any, item: any, index: number) => {
      (reducer.header[0] = reducer.header[0] || []).push({
        label: item.attribute.value || item.attribute.label,
        colspan:
          item.hasOwnProperty('values') &&
          item.values.length &&
          item.values[0].hasOwnProperty('attribute')
            ? item.values.length
            : null,
      });

      // Check if multi-headers
      if (
        item.hasOwnProperty('values') &&
        item.values.length &&
        item.values[0].hasOwnProperty('attribute')
      ) {
        item.values.forEach((object: any) => {
          object.values.forEach((o: any) => {
            const key = o.label;
            reducer.temp[key] = reducer.temp[key] || {};
            reducer.temp[key] = o.label;
          });
        });
      }
      return reducer;
    }, payload);

    data.reduce((reducer: any, item: any) => {
      item.values.forEach((object: any, index: number) => {
        // Check if multi simple table or multi-header one
        if (
          item.hasOwnProperty('values') &&
          item.values.length &&
          item.values.some((object) => object.hasOwnProperty('attribute'))
        ) {
          item.values.forEach((object: any) => {
            (reducer.header[1] = reducer.header[1] || []).push({
              label: object.attribute.value || object.attribute.label,
            });
          });
          Object.keys(reducer.temp).map((key: string) => {
            const o = object.values.find((x: any) => {
              return x.label === key;
            });
            reducer.body[key] = reducer.body[key] || { data: [{ value: key }] };
            reducer.body[key].data.push({ value: o ? o.value : null });
          });
        } else {
          const key = `${index}_${object.index}`;
          reducer.body[key] = reducer.body[key] || { data: [] };
          reducer.body[key].data.push({ value: object ? object.value : null });
        }
      });
      return reducer;
    }, payload);

    return {
      header: payload.header,
      body: Object.values(payload.body),
    };
  }

  protected median = (data: any) => {
    const mid = Math.floor(data.length / 2),
      nums = [...data].sort((a, b) => a - b);
    return data.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  }

  public setContainer = (container: any) => {
    this.container = container;
  }

  /**
   * Init title for options
   * @param {any} parameters
   * @param {string} lang current language
   */
  protected loadChartTitles = (parameters: any, lang: string): void => {
    parameters.title.text = this.getObjectValueTranslation(
      parameters.title.translations,
      lang
    );
    parameters.subtitle.text = this.getObjectValueTranslation(
      parameters.subtitle.translations,
      lang
    );
  }
}
