<div class="btn-group btn-group-lg mb-4" role="group">
  <button class="btn" type="button" [ngClass]="{ 'btn-secondary': display !== 'repetitionQuestions', 'btn-primary': display === 'repetitionQuestions' }"
    (click)="changeTab('repetitionQuestions')" translate>LOOP.PARAM</button>
  <button class="btn" type="button" [ngClass]="{ 'btn-secondary': display !== 'delayQuestions', 'btn-primary': display === 'delayQuestions' }"
    (click)="changeTab('delayQuestions')" translate>DATE_PARAM</button>
  <button class="btn" type="button" [ngClass]="{ 'btn-secondary': display !== 'activateQuestions', 'btn-primary': display === 'activateQuestions' }"
    (click)="changeTab('activateQuestions')" translate>ACTIVE_QUESTIONS</button>
</div>

<div *ngIf="display === 'repetitionQuestions'">
  <dna-questions-repetition [isWorkflowEditable]="isWorkflowEditable" [workflowConfiguration]="workflowConfiguration" (onLoopCreated)="onLoopCreated($event)"> </dna-questions-repetition>
</div>
<div *ngIf="display === 'activateQuestions'">
  <dna-questions-activate [isWorkflowEditable]="isWorkflowEditable" [workflowConfiguration]="workflowConfiguration" (change)="onChangeActiveQuestions($event)"> </dna-questions-activate>
</div>
<div *ngIf="display === 'delayQuestions'">
  <dna-questions-delay [isWorkflowEditable]="isWorkflowEditable" [workflowConfiguration]="workflowConfiguration"> </dna-questions-delay>
</div>
