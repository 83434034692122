import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dna-visits-updated-modal',
  templateUrl: './visits-updated-modal.component.html',
  styleUrls: ['./visits-updated-modal.component.less']
})
export class VisitsUpdatedModalComponent implements OnInit {

  @Input() blocksDeleted: {
    visit: string;
    idWorkflow: string;
    workflowName: string;
    blockName: string;
    blockId: string;
  }[]

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

}