<td [routerLink]="['/trainings', training.id, 'edit','rawdata', assessment.id, 'detail']">{{ assessment.id }}</td>
<td [routerLink]="['/trainings', training.id, 'edit','rawdata', assessment.id, 'detail']">
  <div *ngFor="let workflow of training.workflows">
    <span *ngIf="workflow.id === assessment.idWorkflow">{{ workflow.name[currentLanguage] || workflow.name.english || '-' }}</span>
  </div>
</td>
<td [routerLink]="['/trainings', training.id, 'edit','rawdata', assessment.id, 'detail']">
  <span>{{ assessment.formula.name }} </span>
</td>
<td [routerLink]="['/trainings', training.id, 'edit','rawdata', assessment.id, 'detail']">
  <ng-container *ngFor="let user of assessment.users; let isLast = last">
    {{ user.name }}{{ isLast ? "" : ", " }}
  </ng-container>
</td>
<td [routerLink]="['/trainings', training.id, 'edit','rawdata', assessment.id, 'detail']">{{ assessment.participant.name }}</td>
<td [routerLink]="['/trainings', training.id, 'edit','rawdata', assessment.id, 'detail']">{{ assessment.session }}</td>
<td>
  <svg viewBox="-1 -1 2 2" height="16px" width="16px" style="transform: rotate(-90deg);">
    <path [attr.d]="utilService.pathProgress(assessment.progress)" fill="#1ab394"></path>
    <path [attr.d]="utilService.pathProgress(1, assessment.progress)" fill="#d7d7d7"></path>
  </svg>
  <small style="vertical-align: top">{{ assessment.progress | percent:'1.0-2' }}</small>
</td>