<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="!showSpinner" class="container">
  <div class="ibox float-e-margins">
    <div *ngIf="!error" class="ibox-content">
      <h1>{{ "MULTI_CAMPAIGNS" | translate }}: {{ headerValues.projectName }} </h1>
      <h5>({{ "MODIFY_ON" | translate }} {{ dateMulti | date:'dd-MM-yyyy hh:mm' }})</h5>
      <div class="pull-right">
        <ng-container *ngIf="isChanged">
          <button id="save" class="btn btn-primary mb-4 " type="button" (click)="save(managers)" translate>SAVE</button>
          <button id="cancel" class="btn btn-default mb-4" type="button" (click)="onCancel()" translate>CANCEL</button>
        </ng-container>
      </div>
      <div class="pt-3 form-group">
        <h3 translate>ONE_PAGER_IDENTIFICATION</h3>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr>
              <th class="table-secondary identification-th" translate>PROJECT_NAME</th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-project-name"
                  [ngModel]="headerValues.projectName" disabled="true">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th">
                {{ "NUMBER_ELEMENT" | translate: { element: "Actiview" } }}</th>
              <td class="identification-td">
                <input class="form-control" type="text" [ngModel]="headerValues.actiview" disabled="true"
                  name="input-actiview">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th">{{ "NUMBER_ELEMENT" | translate: { element: "Bridge" } }}
              </th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-bridge" [ngModel]="headerValues.synergy"
                  disabled="true">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th">
                {{ "NUMBER_ELEMENT" | translate: { element: "Orchestra" } }}</th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-orchestra" [ngModel]="headerValues.orchestra"
                  disabled="true">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th" translate>MANAGER</th>
              <td class="identification-td">
                <tag-input inputClass="tagInputOnePager" [(ngModel)]="managers" (ngModelChange)="onChangeManager()"
                  displayBy="name" identifyBy="key" name="input-manager" [onlyFromAutocomplete]="true"
                  placeholder="+ {{ 'USER' | translate }}"
                  secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'USER' | translate | lowercase} }}">
                  <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="users" displayBy="name"
                    identifyBy="key" [minimumTextLength]="3" [showDropdownIfEmpty]="false">
                  </tag-input-dropdown>
                </tag-input>
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th" translate>EXPERT</th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-expert" [ngModel]="headerValues.experts"
                  disabled="true">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th" translate>LABORATORY</th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-laboratory" [ngModel]="headerValues.laboratory"
                  disabled="true">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th" translate>HUB</th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-place" [ngModel]="headerValues.hub" disabled="true">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th" translate>FIELDWORK</th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-fieldwork" [ngModel]="headerValues.fieldwork" disabled="true">
              </td>
            </tr>
            <tr>
              <th class="table-secondary identification-th" translate>ONE_PAGER_DATE</th>
              <td class="identification-td">
                <input class="form-control" type="text" name="input-date" [ngModel]="headerValues.studyDate"
                  disabled="true">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <dna-error-page *ngIf="error" (onClickReload)="init()"></dna-error-page>
  </div>
</div>
