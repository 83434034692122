import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ActionBarButton, CampaignStates, DNATypes, Languages, Training } from '../../../types';
import { UtilService } from '../../../shared/services/util.service';

@Component({
  selector: '[dna-training-row]',
  templateUrl: './training-row.component.html',
  styleUrls: ['./training-row.component.less']
})
export class TrainingRowComponent implements OnInit {

  @Input() currentLanguage: Languages;
  @Input() training: Training;

  @Output() onClick: EventEmitter<ActionBarButton> = new EventEmitter();

  actionsButtons: Observable<ActionBarButton[]>;

  constructor(
    public utilService: UtilService,
    private router: Router
  ) { }

  ngOnInit() {
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.Training);
  }

  onClickButton(actionsButton: ActionBarButton) {
    this.onClick.emit(actionsButton);
  }

  routeSelection(training: Training) {
    training.state === CampaignStates.Published ? this.router.navigate(['trainings', training.id, 'edit', 'rawdata']) :
      this.router.navigate(['trainings', training.id, 'edit']);
  }

}
