<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <form novalidate #formulaForm="ngForm">
      <div class="ibox-title">
        <div class="row">
          <div class="col-6 my-auto">
            <h5>{{ "FORMULAS" | translate }}</h5>
          </div>
          <div *ngIf="(campaign.state !== campaignStates.Finished && campaign.state !== campaignStates.Abandoned) && isChangedCampaign(campaign)" class="col-6">
            <div class="float-end d-flex flex-row gap-1">
              <button id="save" class="btn btn-primary" type="button" (click)="save(formulaForm)" translate>SAVE</button>
              <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
            </div>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label required" translate>FORMULAS</label>
            <div [ngClass]="{ 'col-7': !isDisabled, 'col-9': isDisabled }">
              <tag-input [inputClass]="'tagInputCpgFormula'" [ngClass]="{ 'ng-invalid': submitted && campaign.formula.listFormulas.length == 0 }"
                [(ngModel)]="campaign.formula.formulas" [disable]="campaign.state === campaignStates.Finished || campaign.state === campaignStates.Abandoned" displayBy="name"
                identifyBy="name" [onAdding]="onAdding" [onRemoving]="onRemoving( _listFormulaUntouched)"
                (onAdd)="campaign = addFormulaToList(campaign, $event)" (onRemove)="campaign = removeFormulaToList(campaign, $event)"
                name="formulas" placeholder="+ {{ 'FORMULA' | translate }}" secondaryPlaceholder="{{ 'ENTER_FEM' | translate: { element: 'FORMULA' | translate | lowercase } }}">
              </tag-input>
              <div *ngIf="submitted && campaign.formula.listFormulas.length === 0" class="error text-center" translate>
                REQUIRED
              </div>
              <div *ngIf="submitted && campaign.formula.simultaneousComparison.isActive && campaign.formula.formulas.length < 2" class="error text-center"
                translate>
                TWO_FORMULA_SIM_COMPARISON
              </div>
            </div>
            <div *ngIf="!isDisabled" class="col-2 d-flex flex-row gap-1">
              <button class="btn btn-primary" type="button" (click)="openModal(campaign)">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
              <button class="btn btn-danger" type="button" (click)="reset()">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group pt-3" *ngIf="currentWorkspace.preferences.formulas.routine.isActive">
          <div class="row">
            <label class="col-3 control-label" translate>ROUTINE</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign.parameters.isRoutine" (onBooleanChanged)="campaign = onRoutineChange(campaign, $event)"></dna-switch>
            </div>
            <div *ngIf="campaign.parameters.isRoutine" class="col-12">
              <div class="d-flex flex-row pt-3">
                <label class="offset-3 col-form-label" translate>NUMBER_ROUTINE</label>
                <input class="offset-1 col-4 form-control w-25" [(ngModel)]="numberRoutine" (ngModelChange)="campaign = onChangeNumberRoutine(campaign)"
                  [disabled]="isDisabled" #minInput="ngModel" type="number" min="1" name="routineNumber" required>
              </div>
              <div class="d-flex flex-row pt-3">
                <button *ngIf="!isDisabled" class="offset-3 btn btn-primary" type="button" (click)="goToRoutines(campaign)" translate>EDIT_ROUTINES</button>
                <button class="offset-1 btn btn-primary" type="button" (click)="openModalPreviewRoutines(campaign)" translate>PREVIEWING</button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group pt-3">
          <div class="row">
            <label class="col-3 control-label" translate>ANONYMOUS</label>
            <div class="col-9">
              <dna-switch [disabled]="isDisabled" [isSelected]="campaign.formula.anonymousFormula.isAnonymous" (onBooleanChanged)="onChangeAnonymous(campaign.formula, $event)"></dna-switch>
            </div>
          </div>
          <ng-container *ngIf="campaign.formula.anonymousFormula.isAnonymous">
          <div *ngIf="campaign.formula.anonymousFormula.anonymousCode; else isEmpty">
              <dna-anonymous-formula [anonymousCode]="campaign.formula.anonymousFormula.anonymousCode" [campaignPublished]="isDisabled" (onAnonymousCodeChange)="campaign.formula.anonymousFormula.anonymousCode = $event"></dna-anonymous-formula>
          </div>
          <ng-template #isEmpty>
            <div class="text-center">
              <label class="col-form-label" translate>NO_ANONYMOUS_CODE_TO_DISPLAY</label>
            </div>
          </ng-template>
        </ng-container>

        </div>
        <div class="form-group pt-3" *ngIf="currentWorkspace.preferences.formulas.applicationZone.isActive">
          <div class="row">
            <label class="col-3 control-label" translate>PROTOCOL_APPLICATION_AREAS</label>
            <div class="col-4">
              <select id="listApplicationZones" class="form-control" name="listApplicationZones" [(ngModel)]="chosenApplicationZone" [compareWith]="compare" (ngModelChange)="onApplicationZoneChange($event)" [disabled]="isDisabled">
                <option *ngFor="let zone of applicationZones" [ngValue]="zone">{{zone.value | translate }}</option>
              </select>
            </div>
            <label *ngIf="!simpCompActive" class="error text-center" translate>SIM_COMP_ACTIVE_ERROR</label>
          </div>
        </div>
        <div class="form-group pt-3" *ngIf="campaign.formula.simultaneousComparison.isActive && currentWorkspace.preferences.formulas.applicationZone.isActive">
          <div *ngIf="campaign.formula.listFormulas.length > 1; else isEmpty">
            <div class="row">
              <div class="col-3"><label class="col-3 control-label" translate>BENCH</label></div>
              <div class="col-4">
                <select id="listBench" class="form-control" name="listBench" [(ngModel)]="chosenBench" [compareWith]="compareBench" (ngModelChange)="onChosenBenchChange($event)" [disabled]="isDisabled">
                  <option *ngFor="let bench of campaign.formula.listFormulas" [ngValue]="bench">{{bench.name | translate }}</option>
                </select>
              </div>
            </div>
            <dna-simultaneous-comparison [couples]="campaign.formula.simultaneousComparison.couples" [campaignPublished]="isDisabled" (onCoupleChange)="campaign = onSimCompChange(campaign, $event)"></dna-simultaneous-comparison>
          </div>
          <ng-template #isEmpty>
            <div class="text-center">
              <label class="col-form-label" translate>NO_COMPARISON_TO_DISPLAY</label>
            </div>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
