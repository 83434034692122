<dna-layout>
  <ngx-loading [show]="showSpinner">
    <span translate>LOADING</span>
  </ngx-loading>
  <h1 class="mb-4" id="banner-title">
    <span translate>TRAINING</span>
  </h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/trainings" translate>TRAININGS</a>
      </li>
      <li *ngIf="training.name" class="breadcrumb-item active">{{training.name}}</li>
      <li *ngIf="training.name; else notTrainingName" class="breadcrumb-item active" aria-current="page" translate>EDIT</li>
      <ng-template #notTrainingName>
        <li *ngIf="!training.name" class="breadcrumb-item active" aria-current="page" translate>CREATE</li>
      </ng-template>
    </ol>
  </nav>
  <ng-container *ngIf="!showSpinner">
    <div class="block">
      <div class="row">
        <div class="col-sm-3">
          <dna-side-bar-menu [menus]="sideMenu"> </dna-side-bar-menu>
        </div>
        <div class="col-sm-9">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-container>
</dna-layout>
