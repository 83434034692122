import {
  throwError as observableThrowError
} from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  ApplicationSides, CampaignPublication, Evaluation, UserInCampaign, Volunteers
} from '../../../../../types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../../../../campaigns.service';
import { UtilService } from '../../../../../shared/services/util.service';
import * as _ from 'lodash';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';


@Component({
  selector: 'dna-modal-randomisation',
  templateUrl: './modal-randomisation.component.html',
  styleUrls: []
})

export class ModalRandomisationComponent implements OnInit {

  @Input() campaignPublication: CampaignPublication;
  usersName: string;
  evaluationsInitial: any[];
  volunteers: any[] = [];
  volunteersInitial: any[];
  showSpinner = false;
  zoneApplication: string;
  users: UserInCampaign[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private utilService: UtilService,
    private errorManager: ErrorManagerService
  ) {
  }

  ngOnInit() {
    this.users = this.utilService.addNamesToUsers(_.cloneDeep(this.campaignPublication.users.accountables), this.campaignService.users);
    this.usersName = this.users.map(u => u.name).reduce(this.unsplitArray);
    this.zoneApplication = _.get(this.campaignPublication, 'formula.applicationZone.key', 1);
    this.volunteersInitial = _.cloneDeep(this.volunteers);
    this.evaluationsInitial = _.cloneDeep(this.campaignPublication.evaluations);
    this.volunteers = this.createVolunteersObject(this.campaignPublication);
  }

  unsplitArray = (finalString: string, s: string) => {
    return finalString + ', ' + s;
  }

  changeSide = (ev: any) => {
    if (ev.side === 'NONE') {
      ev.sideValue = undefined;
    } else {
      ev.sideValue = ev.side === ApplicationSides.Right ? 0 : 1;
    }
    if (this.campaignPublication.users.isVolunteer) {
      this.campaignPublication.evaluations.map((e, index) => {
        if (e.volunteer.name === ev.userName) {
          e.side = ev.sideValue;
          e.formula.name = this.generateFormulaSide(ev.sideValue, ev.bench, ev.lab, ev.formula);
        }
      });
      this.volunteers.map((volunteer) => {
        if (volunteer.userName === ev.userName) {
          volunteer.formula = this.generateFormulaSide(ev.sideValue, ev.bench, ev.lab, ev.formula);
          volunteer.sideValue = ev.sideValue;
        }
      });
    } else if (this.campaignPublication.users.isCollaborative) {
      this.campaignPublication.evaluations.map((e) => {
        e.side = ev.sideValue;
        e.formula.name = this.generateFormulaSide(ev.sideValue, ev.bench, ev.lab, ev.formula);
      });
      this.volunteers.map((volunteer) => {
        if (volunteer.userName === ev.userName) {
          volunteer.formula = this.generateFormulaSide(ev.sideValue, ev.bench, ev.lab, ev.formula);
          volunteer.sideValue = ev.sideValue;
        }
      });
    } else {
      this.campaignPublication.evaluations.filter(e => e.users.find(user => user.key === ev.userKey)).map((e) => {
        e.side = ev.sideValue;
        e.formula.name = this.generateFormulaSide(ev.sideValue, ev.bench, ev.lab, ev.formula);
      });
      this.volunteers.map((volunteer) => {
        if (volunteer.userName === ev.userName) {
          volunteer.formula = this.generateFormulaSide(ev.sideValue, ev.bench, ev.lab, ev.formula);
          volunteer.sideValue = ev.sideValue;
        }
      });
    }
  }

  createVolunteersObject = (campaign: CampaignPublication): any => {
    const volunteersObject = [];
    if (campaign.users.isVolunteer) {
      _.get(campaign, 'users.volunteers', []).forEach(v => {
        const evStartedOfVolunteer = campaign.evaluations.filter(e => e.volunteer.name === v.name && e.progress > 0);
        const evOfVolunteer = campaign.evaluations.filter(e => e.volunteer.name === v.name);
        if (!_.isEmpty(evOfVolunteer)) {
          volunteersObject.push(this.createObject(evStartedOfVolunteer, evOfVolunteer, v.name));
        }
      });
    } else if (campaign.users.isCollaborative) {
      const evStartedOfUsers = campaign.evaluations.filter(e => e.progress > 0);
      const evOfUsers = campaign.evaluations;
      if (!_.isEmpty(evOfUsers)) {
        volunteersObject.push(this.createObject(evStartedOfUsers, evOfUsers, this.usersName));
      }
    } else {
      this.campaignPublication.users.accountables.forEach(u => {
        const evStartedOfUser = campaign.evaluations.filter(e => e.users.find(user => user.key === u.key) && e.progress > 0);
        const evOfUser = campaign.evaluations.filter(e => e.users.find(user => user.key === u.key));
        if (!_.isEmpty(evOfUser)) {
          volunteersObject.push(this.createObject(evStartedOfUser, evOfUser, this.users.find(user => user.key === u.key).name, u.key));
        }
      });
    }
    return volunteersObject;
  }

  cancel() {
    this.volunteers = _.cloneDeep(this.volunteersInitial);
    this.campaignPublication.evaluations = _.cloneDeep(this.evaluationsInitial);
    this.activeModal.dismiss();
  }

  generateFormulaSide(side, bench, lab, formula): string {
    console.log(side, bench, lab, formula)
    if(bench) {
      const routines = formula.split('-');

      if (routines.length === 3) {
        if (side === 0) {
          return `R3 - ${bench} - ${lab}`
        } else if (side === 1) {
          return `${lab} - ${bench} - R3`
        }
      } else {
        if (side === 0) {
          return `${bench} - ${lab}`
        } else if (side === 1) {
          return `${lab} - ${bench}`
        }
      }
    } else {
      return formula;
    }
  }

  createObject(evStarted, ev, name, key = '') {
    let lab = '';
    let bench = '';
    const evaluation = this.evaluationsInitial[0];

    if (this.campaignPublication.formula.listFormulas.find(f => f.id === _.get(evaluation, 'lab[0]', ''))) {
      lab = this.campaignPublication.formula.listFormulas.find(f => f.id === _.get(evaluation, 'lab[0]', '')).name;
    }

    if (this.campaignPublication.formula.listFormulas.find(f => f.id === _.get(evaluation, 'bench', ''))) {
      bench = this.campaignPublication.formula.listFormulas.find(f => f.id === _.get(evaluation, 'bench', '')).name;
    }
    const hasEvStarted = !_.isEmpty(evStarted);
    const side = hasEvStarted
      ? evStarted[0].side
      : ev[0].side;
    let formulaName = ev[0].formula.name;
    if (formulaName && formulaName.split(' - ')[0] === lab && side !== 1 && side !== 'NONE') {
      formulaName = this.generateFormulaSide(side, bench, lab, formulaName);
    }
    const object = {
      userName: name,
      formula: formulaName,
      lab,
      side: this.utilService.getApplicationSide(side),
      sideValue: side,
      isStarted: hasEvStarted,
      bench: bench
    };
    if (key) {
      object['userKey'] = key;
    }
    return object;
  }

  save() {
    this.showSpinner = true;
    this.campaignService.putCampaignPublicationRando(this.campaignPublication.id, this.campaignPublication).pipe(
      tap((res) => this.activeModal.close(res)),
      catchError(error => {
        this.showSpinner = false;
        this.errorManager.catchError(error);
        return observableThrowError(error);
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

}
