
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { CampaignStates, Training, User } from '../../../../types';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { TrainingService } from '../../../training.service';
import { UtilService } from '../../../../shared/services/util.service';

@Component({
  selector: 'dna-training-users',
  templateUrl: './training-users.component.html',
  styleUrls: ['./training-users.component.less']
})
export class TrainingUsersComponent implements OnInit {

  training: Training = new Training();
  trainingOriginal: Training = new Training();
  showSpinner: boolean = false;
  submitted: boolean = false;
  users: User[];
  isDisabled: boolean = false;
  trainingStates: typeof CampaignStates = CampaignStates;

  constructor(
    private errorManager: ErrorManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private trainingService: TrainingService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.users = this.trainingService.users;
    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.trainingOriginal = this.trainingService.getTrainingFromLocalStorage(params.get("idTraining"));
      this.trainingOriginal.users.accountables = this.utilService.addNamesToUsers(this.trainingOriginal.users.accountables, this.users);
      this.isDisabled = this.trainingOriginal.state === CampaignStates.Finished;
      this.training = _.cloneDeep(this.trainingOriginal);
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.training, this.trainingOriginal);
  }

  canIRemoveThisElement(element: any, type: string, id: string) {
    if (this.training.state == this.trainingStates.Published && this.isInOriginalTraining(element, type, id)) {
      this.utilService.getSubObject(this.training, type).push(element);
    }
  }

  isInOriginalTraining(element: any, type: string, id: string) {
    return _.find(this.utilService.getSubObject(this.trainingOriginal, type), (o: any) => o[id] == element[id]);
  }

  onCancel() {
    this.training = _.cloneDeep(this.trainingOriginal);
    this.trainingService.setTrainingFromLocalStorage(this.training);
  }

  save(form: NgForm) {
    this.submitted = true;
    let isValidForm = this.training.users.accountables.length != 0 && this.training.users.participants.length != 0;

    if (isValidForm) {
      this.showSpinner = true;
      let trainingToSend = _.cloneDeep(this.training);
      this.trainingService
        .putTraining(trainingToSend).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(
        response => {
          this.errorManager.displayMessage("ON_SUCCESS_UPDATE");
          this.trainingService.setTrainingFromLocalStorage(this.training);
          this.training = _.cloneDeep(this.training);
          this.trainingOriginal = _.cloneDeep(this.training);
          this.router.navigate(['trainings', response.id, 'edit', 'parameters']);
        },
        error => this.errorManager.displayMessage(error)
        );
    } else {
      this.errorManager.displayMessage("ON_ERROR_FORM", "danger");
    }
  }

}
