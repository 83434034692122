
import {throwError as observableThrowError } from 'rxjs';

import {catchError, finalize, mergeMap, tap} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import {
  User,
  Workspace,
} from '../../../../types';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { UserService } from '../../../../shared/services/user.service';
import { UtilService } from '../../../../shared/services/util.service';
import { WorkspaceModalComponent } from '../detail/workspaceModal/workspaceModal.component';
import { WorkspaceModalListComponent } from '../detail/workspaceModalList/workspaceModalList.component';
import { WorkspacePreferencesModalComponent } from '../detail/workspacePreferencesModal/workspacePreferencesModal.component';
import { WorkspaceService } from '../workspaces.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-workspaces',
  templateUrl: './workspaces.component.html'
})
export class WorkspacesComponent implements OnInit, AfterViewInit {

  allUsers: any;
  showSpinner = true;
  user: User;
  workspaces: Workspace[];
  initTime = performance.now();

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg',
  };

  constructor(
    private errorManagerService: ErrorManagerService,
    private modalService: NgbModal,
    private userService: UserService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private appInsightsService: ApplicationInsightsService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route.snapshot.routeConfig.path;
    this.appInsightsService.logPageView('MyProfileWorkflows', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.user = this.userService.getUser();
    if (_.includes(this.user.roles, 'DNA_ADMIN')) {
      this.workspaceService.getWorkspaces().pipe(
        tap(() => this.showSpinner = false)
      ).subscribe((success: any) => this.workspaces = success);
    } else {
      this.workspaceService.getWorkspacesByAdminUser(this.user.track).pipe(
        tap(() => this.showSpinner = false)
      ).subscribe(success => this.workspaces = success.list);
    }
    this.userService.getIdentities().pipe(
      tap(users => this.allUsers = users)
    ).subscribe();
  }

  deleteWorkspace(idWorkspace: string) {
    this.showSpinner = true;
    return this.workspaceService.deleteWorkspace(idWorkspace).pipe(
      finalize(() => this.showSpinner = false),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }),)
  }

  openModalList(idWorkspace: string, type: string) {
    this.showSpinner = true;
    const modal = this.modalService.open(WorkspaceModalListComponent, this.modalOption);
    modal.componentInstance.idWorkspace = idWorkspace;
    modal.componentInstance.type = type;
    this.showSpinner = false;
  }

  openModalPreferences(idWorkspace: string) {
    const modal = this.modalService.open(WorkspacePreferencesModalComponent, this.modalOption);
    modal.componentInstance.idWorkspace = idWorkspace;
    modal.result.then((updatedWorkspace: Workspace) => {
      this.showSpinner = true;
      this.workspaceService.putWorkspace(updatedWorkspace)
        .subscribe(() => {
          this.user.currentWorkspace = updatedWorkspace;
          this.userService.setUser(this.user);
          this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
          this.showSpinner = false;
        },
        error => this.showSpinner = false)
    }, (reason) => { });
  }

  openModalWorkspace(idWorkspace?: string, usersToDisplay: any[] = [], adminsToDisplay: any[] = []) {
    const modal = this.modalService.open(WorkspaceModalComponent, this.modalOption);
    modal.componentInstance.users = this.allUsers;
    modal.componentInstance.workspaces = this.workspaces;
    modal.componentInstance.idWorkspace = idWorkspace;
    modal.componentInstance.usersToDisplay = usersToDisplay;
    modal.componentInstance.adminsToDisplay = adminsToDisplay;
    modal.result.then(() => {
      this.showSpinner = false;
    }, (reason) => { });
  }

  remove(object: any) {
    const wk = object.workspaces.find(w => w.id === object.idWorkspace);
    this.utilService.translateMessageModal('CONFIRM_DELETE', wk.name, 'THE_WORKSPACE').pipe(
      mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.deleteWorkspace(object.idWorkspace)))
      .subscribe(() => {
        _.remove(object.workspaces, wk);
        this.errorManagerService.displayMessage('ON_SUCCESS_DELETE');
      });
  }

}
