import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dna-no-workspaces',
  templateUrl: './no-workspaces.component.html',
  styleUrls: ['./no-workspaces.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class NoWorkspacesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
