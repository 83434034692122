
import {of as observableOf, 
  Observable,
  Subscription
} from 'rxjs';

import {merge, finalize, filter, map, mergeMap} from 'rxjs/operators';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  Campaign,
  CampaignStates,
  Priority,
  Priorities,
  SynergyDemand,
  User,
  UserInCampaign
} from '../../../../types';
import {
  CampaignService
} from '../../../../campaigns/campaigns.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  ReferenceTypeService
} from '../../../../shared/services/reference-type.service';
import {
  SynergyDemandService
} from '../../../../shared/services/synergy-demand.service';

@Component({
  selector: 'dna-edit-demand-modal',
  templateUrl: './edit-demand-modal.component.html',
  styleUrls: ['./edit-demand-modal.component.less']
})
export class EditDemandModalComponent implements OnInit, OnDestroy {

  @Input() synergyDemand: SynergyDemand;
  @Input() users: User[];

  campaign: Campaign = new Campaign();
  contributorsKeys: string[] = [];
  priorities: Priority[];
  prioritySynergy: string = Priorities.Priority2;
  showSpinner: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private errorManagerService: ErrorManagerService,
    private referenceTypeService: ReferenceTypeService,
    private synergyDemandService: SynergyDemandService
  ) {}

  ngOnInit() {
    this.priorities = this.referenceTypeService.getPriorities();
    if (this.synergyDemand.studyId && this.synergyDemand.contributors && this.synergyDemand.contributors.length > 0) {
      this.showSpinner = true;
      this.contributorsKeys = this.synergyDemand.contributors.map((contributor: UserInCampaign) => contributor.key);
      this.subscriptions.push(this.campaignService.getHttpCampaign(this.synergyDemand.studyId).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(
          (campaign: Campaign) => this.campaign = campaign,
          error => this.errorManagerService.catchError(error)
        ));
    }

    if (!this.synergyDemand.contributors) {
      this.synergyDemand.contributors = [];
    }

    if (this.synergyDemand.priority) {
      this.prioritySynergy = this.synergyDemand.priority;
    } else {
      this.synergyDemand.priority = Priorities.Priority2;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  addPriorityToSynergyDemand(priority: Priority) {
    this.synergyDemand.priority = priority;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  filterToRemoveContributor(contributor: UserInCampaign): boolean {
    return (this.campaign.state === (CampaignStates.Published || CampaignStates.Archived || CampaignStates.Finished || CampaignStates.Abandoned) &&
      this.synergyDemand.studyId && this.contributorsKeys.includes(contributor.key)) ? false : true;
  }

  filterToRemoveReferent(referent: UserInCampaign): boolean {
    return (this.campaign.state === (CampaignStates.Published || CampaignStates.Archived || CampaignStates.Finished || CampaignStates.Abandoned) &&
      this.synergyDemand.studyId) ? false : true;
  }

  onAddReferent(referent: UserInCampaign) {
    let userIsPresent = this.synergyDemand.contributors
      .filter((contributor) => contributor.key === referent.key).length !== 0 ? true : false;

    if (!userIsPresent) {
      this.synergyDemand.contributors.push(referent);
    }
  }

  onRemovingContributor = (contributor: UserInCampaign): Observable < UserInCampaign > => {
    return observableOf(contributor).pipe(
      filter((c: UserInCampaign) => this.filterToRemoveContributor(c)));
  }

  onRemovingReferent = (referent: UserInCampaign): Observable < UserInCampaign > => {
    return observableOf(referent).pipe(
      filter((c: UserInCampaign) => this.filterToRemoveReferent(referent)));
  }

  pickContributorProperties(user: User): Observable < UserInCampaign > {
    return observableOf(user).pipe(
      map((u: User) => _.pick(u, ['name', 'key', 'formula', 'pzone', 'isActive'])));
  }

  pickReferentProperties(user: User): Observable < UserInCampaign > {
    return observableOf(user).pipe(
      map((u: User) => _.pick(u, ['name', 'key', 'formula', 'pzone', 'isActive'])));
  }

  save(synergyDemand: SynergyDemand) {
    this.showSpinner = true;

    const linkToCampaign$ = observableOf(synergyDemand).pipe(
      filter((s: SynergyDemand) => typeof s.studyId !== "undefined" && s.studyId.length > 0),
      mergeMap(() => this.campaignService.getHttpCampaign(synergyDemand.studyId)),
      mergeMap((campaign: Campaign) => this.updateCampaign(campaign, synergyDemand)),);

    const notLinkToCampaign$ = observableOf(synergyDemand).pipe(
      filter((s: SynergyDemand) => typeof s.studyId === "undefined" || s.studyId.length === 0))

    linkToCampaign$.pipe(merge(notLinkToCampaign$),
      mergeMap(() => this.synergyDemandService.updateSynergyDemandWorkspaces(synergyDemand)),
      finalize(() => this.showSpinner = false),)
      .subscribe(
        (s: SynergyDemand) => this.activeModal.close(s),
        error => this.errorManagerService.catchError(error)
      );
  }

  updateCampaign(campaign: Campaign, synergyDemand: SynergyDemand): Observable < Campaign > {
    campaign.users.accountables = [];
    campaign.users.accountables = _.unionBy(synergyDemand.referent, synergyDemand.contributors, 'key');

    campaign.synergy.priority = synergyDemand.priority;
    campaign.synergy.referent = synergyDemand.referent;
    return this.campaignService.putCampaign(campaign);
  }

}
