import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dna-remove-visits-modal',
  templateUrl: './remove-visits-modal.component.html',
  styleUrls: ['./remove-visits-modal.component.less']
})
export class RemoveVisitsModalComponent{

  @Input() unableDeleteVisitTab: {
    id: string;
    name: string;
  }[];

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  onClose() {
    this.activeModal.dismiss();
  }

}
