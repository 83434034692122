import {
  throwError as observableThrowError
} from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../../../../campaigns.service';
import * as _ from 'lodash';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';


@Component({
  selector: 'dna-confort-modal',
  templateUrl: './confort-modal.component.html',
  styleUrls: []
})

export class ConfortModalComponent implements OnInit {

  @Input() idCampaign: string;
  showSpinner: boolean = true;
  confortTimeDisplay: any;
  confortTimeDisabled: string[];
  confortComponents: any;

  constructor(
    private activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService
  ) {}

  ngOnInit() {
    this.campaignService.getConfortTime(this.idCampaign).pipe(
      tap(result => this.confortComponents = result.confortComponents),
      tap(result => this.confortTimeDisabled = result.confortTimeDisabled ? result.confortTimeDisabled : []),
      tap(() => this.confortTimeDisplay = this.initTime(this.confortComponents, this.confortTimeDisabled)),
      tap(() => this.showSpinner = false)
    ).subscribe()
  }

  initTime(confortComponents, confortTimeDisabled) {
    let confortTimeDisplay = {};
    confortComponents.forEach(c => confortTimeDisplay[c.time] = !confortTimeDisabled.find(ct => c.time === ct));
    return confortTimeDisplay;
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    this.showSpinner = true;
    let elementToSave = Object.keys(this.confortTimeDisplay).filter(c => !this.confortTimeDisplay[c]);
    this.campaignService.putConfortTime(this.idCampaign, elementToSave).pipe(
      tap(() => this.activeModal.close()),
      catchError(error => {
        this.showSpinner = false;
        this.errorManager.catchError(error);
        return observableThrowError(error);
      }),
      finalize(() => {
        this.showSpinner = false;
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
      })
    ).subscribe();
  }
}
