
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { Subject, throwError as observableThrowError, timer, EMPTY } from 'rxjs';
import { CampaignService } from '../../../../../../campaigns.service';
import { Timepoint, TimepointDisplay } from '../../../../../../../types';
import { UtilService } from '../../../../../../../shared/services/util.service';
import { ErrorManagerService } from '../../../../../../../shared/services/errorManager.service';
import { DNATranslateService } from '../../../../../../../shared/services/translate.service';
import { generateTimepointNameByLanguage } from '../../../utils';
@Component({
  selector: 'addPhotoModal',
  templateUrl: './addPhotoModal.component.html',
  styleUrls: ['./addPhotoModal.component.less']
})
export class AddPhotoModalComponent implements OnInit, OnDestroy {

  public TIMER_LIMITE = 10000;
  @Input() title: string;
  @Input() timepointId: string;
  @Input() volunteerId: string;
  @Input() idCampaign: string;

  timepointRefs: TimepointDisplay[] = [];
  volunteersId: string[] = [];
  campaignName: string;
  keepArcs: boolean = false;
  keepSynergy: boolean = false;
  submitted: boolean;
  timepointSelected: TimepointDisplay;
  volunteerSelected: string;
  showSpinner: boolean = false;
  urlPhotoAdded: string;
  fileToUpload: File;
  currentLanguage: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  disabledSelectTimepoint: boolean = false;
  disabledSelectVolunteer: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private utilService: UtilService,
    private errorManagerService: ErrorManagerService,
    private translateService: DNATranslateService,
  ) {
    this.currentLanguage = this.translateService.getLanguage();
    this.translateService.onLangChange().subscribe(({ lang }: any) => {
      this.currentLanguage = lang;
    });
  }

  ngOnInit() {
    const timepointSource = this.campaignService.getTimepointsReferences();
    this.volunteersId = this.campaignService.getVolunteersId();
    this.timepointRefs = timepointSource.map((timepoint: Timepoint) => {
      return {
        id: timepoint.id,
        name: generateTimepointNameByLanguage(timepoint, this.currentLanguage)
      }
    })

    if (this.timepointId) {
      const timepoint = this.timepointRefs.find((tpRef) => tpRef.id === this.timepointId)
      this.timepointSelected = timepoint;
      this.disabledSelectTimepoint = true;
    }
    if (this.volunteerId) {
      const volunteer = this.volunteersId.find((volunteer) => volunteer === this.volunteerId);
      this.volunteerSelected = volunteer;
      this.disabledSelectVolunteer = true;
    }
  }

  asociatePhotoToTimepoint() {
    console.log("this.timepointSelected:", JSON.stringify(this.timepointSelected, null, 2));
  }

  asociatePhotoToVolunteer() {
    console.log("this.volunteerSelected:", this.volunteerSelected);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  onPhotoAdd(event: any) {
    this.fileToUpload = event.target.files[0];
    let url = URL.createObjectURL(this.fileToUpload);
    this.urlPhotoAdded = url

  }
  uploadPhoto() {
    const file = this.fileToUpload;
    this.utilService.uploadFile(file).subscribe(dataToFile => {
      const [file, [type, media]] = dataToFile;
      this.showSpinner = true;
      this.campaignService.associateDataPhotoAndUpload(file, media, type, this.idCampaign, this.volunteerSelected, this.timepointSelected.id)
        .pipe(
          switchMap((response: any) => {
            if (!response.isEvaluationStarted) {
              // evaluation has not started yet, then display message alert 
              this.showSpinner = false;
              this.errorManagerService.displayMessage('ON_MESSAGE_PHOTO_UPLOAD_NOT_AVAILABLE', 'warning');
              this.activeModal.close();
              return EMPTY; // stop the flow of observables
            }
            const mediaUUID = response.mediaUUID;
            const maxTime$ = timer(this.TIMER_LIMITE).pipe(
              switchMap(() => {
                return observableThrowError('MAX_TIME_REACHED'); // emit an error to finalize the process
              })
            );
            const checkAvailability$ = timer(0, 1000).pipe(
              switchMap(() => this.campaignService.isDataPhotoAvailable(mediaUUID)),
              catchError(error => {
                console.log('ERROR_WHILE_CHECK_AVAILABLE_PHOTO', error);
                return observableThrowError(error);
              }),
              filter(response => response.isAvailable === true), // filter if the photo have been created
              take(1), // take the first element si isAvailable is true
              takeUntil(maxTime$), // stop when reached the max time
            );
            return checkAvailability$
          }),
        ).subscribe(
          (result: any) => {
            if (result.isAvailable) {
              this.showSpinner = false;
              this.errorManagerService.displayMessage('PHOTO_SUCCESSFULLY_UPLOADED');
              this.activeModal.close();
            }
          },
          err => {
            if (err === "MAX_TIME_REACHED") {
              this.errorManagerService.displayMessage('ON_MESSAGE_RELOAD_PAGE');
              this.activeModal.close();
            } else {
              this.errorManagerService.catchError(err)
            }
            this.showSpinner = false;
          }
        )
    });
  }
}
