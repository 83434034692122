
import {finalize} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Answer, Assessment } from './../../../../../../types';
import { AssessmentsService } from '../../assessments.service';
import { ErrorManagerService } from '../../../../../../shared/services/errorManager.service';
import { ModalEditAnswerComponent } from '../../../../../../campaigns/detail/edit-campaign/raw-data/detail/edit-answer/modal-edit-answer.component';

@Component({
  selector: '[dna-detail-assessment-row]',
  templateUrl: './detail-assessment-row.component.html',
  styleUrls: ['./detail-assessment-row.component.less']
})
export class DetailAssessmentRowComponent implements OnInit {

  @Input() answer: any;
  @Input() assessment: Assessment;
  @Input() currentLanguage: string;
  @Input() trainingId: string;

  showSpinner: boolean = false;

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  constructor(
    private assessmentService: AssessmentsService,
    private modalService: NgbModal,
    private errorManagerService: ErrorManagerService
  ) { }

  ngOnInit() { }

  openModal(answerIndex: number) {
    const modal = this.modalService.open(ModalEditAnswerComponent, this.modalOption);
    modal.componentInstance.answer = this.assessment.answers[answerIndex];
    modal.componentInstance.currentLanguage = this.currentLanguage;
    modal.result.then(
      (answerUpated) => {
        this.showSpinner = true;
        this.assessment.answers[answerIndex] = answerUpated;
        this.assessmentService.updateAssessmentBlocks(this.trainingId, this.assessment).pipe(
          finalize(() => this.showSpinner = false))
          .subscribe(success => console.log(),
          error => this.errorManagerService.displayMessage(error, "danger"));
      },
      (reason) => { }
    );
  }

}
