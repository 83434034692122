import { Observable, Subject, forkJoin, throwError } from 'rxjs';

import { finalize, takeUntil, tap, take, catchError, mergeMap } from 'rxjs/operators';

import {
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';

import * as _ from 'lodash';
import { LightCampaign, TableHeader, FilterSchedule, User, DNATypes, Hub, Workflow } from '../types';
import { UtilService } from '../shared/services/util.service';
import { CampaignService } from '../campaigns/campaigns.service';
import { WorkflowService } from '../workflows/workflows.service';
import { UserService } from '../shared/services/user.service';
import { FilterService } from '../shared/services/filter.service';
import * as FileSaver from 'file-saver';
import { ErrorManagerService } from '../shared/services/errorManager.service';
import { ReferenceTypeService } from '../shared/services/reference-type.service';

@Component({
  selector: 'dna-export-data-set',
  templateUrl: './export-data-set.component.html',
  styleUrls: ['./export-data-set.component.less']
})

export class ExportDataSetComponent implements OnInit, OnDestroy {

  showSpinner: boolean = false;
  bridgeList = [];
  orchestraList = [];
  campaignsFiltered: LightCampaign[] = [];
  campaignsSelected: LightCampaign[] = [];
  filter: FilterSchedule;
  user: User;
  hubs: Hub[];
  totalItems: Number = 0;
  workflows: Workflow[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  progressValue: number = 0;
  showProgressBar: boolean = false;
  currentFileIndex: number = 0;
  totalFiles: number = 0;
  isError: boolean = false;
  errorMessage: string = '';
  isDownloadComplete: boolean = false;


  tableHeaders$: Observable<TableHeader[]>;
  areAllSelected = false;
  totalExportedStudies: number = 0;
  isExportedStudies: boolean = false;

  constructor(
    private utilService: UtilService,
    private campaignService: CampaignService,
    private workflowService: WorkflowService,
    private userService: UserService,
    private filterService: FilterService,
    private errorManager: ErrorManagerService,
    private referenceType: ReferenceTypeService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.CampaignExport);
    this.user = this.userService.getUser();
    this.hubs = this.referenceType.getHubs();
    this.workflowService.getWorkflows().pipe(
      tap((workflows: any) => {
        this.workflows = workflows.list;
        this.getWorkflowsWithTranslatedName(this.workflows, this.user.language)
      }),
      takeUntil(this.destroy$)
    ).subscribe();
    this.userService.onUserChanged().pipe(
      tap((user: User) => this.init(user)),
      takeUntil(this.destroy$))
      .subscribe();
    this.filter = this.filterService.resetFilterCampaignsExport(this.userService.getUserHub(this.user.hub));
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  init(user:User){
    this.campaignsFiltered= [];
    this.campaignsSelected= [];
    this.filter = this.filterService.resetFilterCampaignsExport(this.userService.getUserHub(this.user.hub));
  }
  isAuthorized(role: string) {
    return this.userService.isAuthorized(role);
  }
  showStudies(filter: FilterSchedule) {
    this.showSpinner = true;
    this.campaignService.getHttpCampaignsWithFilter(filter).pipe(
      tap(result => this.campaignsFiltered = result.list),
      tap(result => this.totalItems = result.totalItems),
      tap(result => this.campaignsSelected = []),
      tap(result => {
        this.filter.page = result.currentPage;
        this.filter.limit = result.itemsPerPage;
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }
  getWorkflowsWithTranslatedName(workflows: Workflow[], language: string = "english"): Workflow[] {
    return workflows.map(
      (w: Workflow) => {
        w.name = w.name[language] ? w.name[language] : w.name.english;
        return w;
      }
    );
  }
  export() {
    this.showSpinner = true;
    this.showProgressBar = true;
    this.isDownloadComplete = false;
    this.progressValue = 0;
    // Si aucune étude n'est sélectionnée pour l'export, il faut alors exporter toutes les études affichées.
    const body = this.campaignsSelected.length === 0 ? this.campaignsFiltered.map(c => _.pick(c, "id")) : this.campaignsSelected.map(c => _.pick(c, "id"));

    const campaignsExported = this.campaignsSelected.length === 0 ? this.campaignsFiltered : this.campaignsSelected;
    const filteredCampaigns = campaignsExported.filter(campaign =>
      campaign && campaign.evaluations && Array.isArray(campaign.evaluations) && campaign.evaluations.length > 0);
    this.isExportedStudies = true;
    this.totalExportedStudies = filteredCampaigns.length;

    const bodySegments = [];
    let pageNbr = 0;

    for (let i = 0; i < body.length; i += 50) {
      const segment = body.slice(i, i + 50);
      if (segment.length > 0) {
        bodySegments.push(segment);
        pageNbr++;
      }
    }

    // Nombre total de fichiers à exporter
    const totalFiles = bodySegments.length;
    this.currentFileIndex = 0;
    this.totalFiles = totalFiles;

    // Fonction récursive pour appeler les requêtes une par une
    const sendRequestsSequentially = async (segments, index = 0) => {
      if (index < segments.length) {
        try {
          await this.fetchDataToExport(segments[index]);
          this.currentFileIndex = index + 1;
          this.progressValue = ((index + 1) / this.totalFiles) * 100;
        } catch (error) {
          this.isError = true;
          this.errorMessage = error.message;
          this.errorManager.displayMessage('UNKNOW_ERROR', 'danger');
          throw error; // Relancer l'erreur pour l'attraper dans le bloc try...catch extérieur
        }

        await sendRequestsSequentially(segments, index + 1);
      }
    };

    (async () => {
      try {
        await sendRequestsSequentially(bodySegments);
      } catch (error) {
        console.error('Erreur dans le processus d\'exportation:', error);
        this.isDownloadComplete = false;
      } finally {
        this.showSpinner = false;
        this.isDownloadComplete = true;
        setTimeout(() => {
          this.showProgressBar = false;
        }, 2000);
        // Après l'exportation réussie
        this.campaignsFiltered.forEach(campaign => {
          if (campaign.isSelected) {
            campaign.isExported = true;
            this.addExportedClassToRow(campaign.id);
            const selectedCampaignIndex = this.campaignsSelected.findIndex(selected => selected.id === campaign.id);
            if (selectedCampaignIndex !== -1) {
              this.campaignsSelected[selectedCampaignIndex].isExported = true;
            }
          } else {
            campaign.isExported = false;
          }
        });

        // Marquer le composant pour la détection des changements Angular
        this.changeDetectorRef.markForCheck();

      }

    })();

  }
  private addExportedClassToRow(campaignId: string) {
    const rowElements = document.querySelectorAll(`[id^='campaignRow-${campaignId}']`);
    rowElements.forEach(element => {
      element.classList.add('exported-campaign');
    });
  }

  async fetchDataToExport(segment) {
    try {
      const blob = await this.campaignService.exportHttpCampaignsWithFilter(this.user.displayName, segment).toPromise();
      FileSaver.saveAs(blob, `export${new Date().getTime()}.xlsx`);
    } catch (err) {
      this.isError = true;
      this.errorManager.catchError(err);
    }
  }

  handleErrorExport(err:any):Observable<any>{
    this.showSpinner = false;
    return throwError(err)
  }

  sortExport = (idHeader: string, reverse: boolean = false) => {
    reverse ? _.set(this.filter, 'order', 'DESC') : _.set(this.filter, 'order', 'ASC');
    switch (idHeader) {
      case 'NAME':
        _.set(this.filter, 'orderBy', `name`);
        break;
      case 'STATE':
        _.set(this.filter, 'orderBy', `state`);
        break;
      case 'BRIDGE':
        _.set(this.filter, 'orderBy', `synergy.requestNumber`);
        break;
      case 'ACTIVIEW':
        _.set(this.filter, 'orderBy', `activityNumber`);
        break;
      case 'DATE_MODIFICATION':
        _.set(this.filter, 'orderBy', `updated_on`);
        break;
      case 'ORCHESTRA':
        _.set(this.filter, 'orderBy', `synergy.orchestra`);
        break;
    }
    this.onFilterChanged();
  }

  updateDate(text: any, type: string) {
    this.filter[type] = text;
  }

  onFilterChanged() {
    this.showSpinner = true;
    this.campaignService.getHttpCampaignsWithFilter(this.filter, true).pipe(
      tap(result =>
        {
        this.campaignsFiltered = result.list;
        // refresh la propriété selected de la campagne pour la mise à jour du bouton de sélection des études
        this.campaignsFiltered.forEach(campaign => campaign.isSelected = this.campaignsSelected.some(selected => selected.id === campaign.id) ? true : false);
      }),
      take(1),
      finalize(() => this.showSpinner = false))
      .subscribe();
  }

  onParametersChanged(event: any) {
    _.set(this.filter, 'page', _.get(event.pager, 'currentPage', 1));
    _.set(this.filter, 'limit', _.get(event.pager, 'pageSize', 10));
    this.onFilterChanged();
  }

  onSwitchChanged(switchState: boolean, campaign: LightCampaign) {
    this.campaignsFiltered = this.updatePropertyIsSelected(_.cloneDeep(this.campaignsFiltered), campaign.id, switchState);
    this.campaignsSelected = this.updateCampaignsSelected(_.cloneDeep(this.campaignsSelected), _.cloneDeep(campaign), switchState);
    this.campaignService.setCampaignsSelectedForExport(this.campaignsSelected);

    this.checkIfAllSelected();
  }

  updateCampaignsSelected(campaigns: LightCampaign[], campaign: LightCampaign, switchState: boolean) {
    const index = campaigns.findIndex((c: LightCampaign) => c.id === campaign.id);

    if (switchState) {
      if (index === -1) {
        campaign.isSelected = switchState;
        campaigns.push(campaign);
      } else {
        campaigns[index].isSelected = switchState;
      }
    } else {
      campaigns.splice(index, 1);
    }

    return campaigns;
  }

  updatePropertyIsSelected(campaigns: LightCampaign[], idCampaign: string, switchState: boolean): LightCampaign[] {
    const index = campaigns.findIndex((campaign: LightCampaign) => campaign.id === idCampaign);
    campaigns[index].isSelected = switchState;
    return campaigns;
  }

  onSelectAllSwitchChanged(isSelected: boolean) {
    this.areAllSelected = isSelected;
    this.campaignsFiltered.forEach(campaign => {
      campaign.isSelected = isSelected;
    });
  }

  checkIfAllSelected() {
    this.areAllSelected = this.campaignsFiltered.every(campaign => campaign.isSelected);
  }

}
