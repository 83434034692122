<div class="ibox float-e-margins">
  <ngx-loading [show]="showSpinner"></ngx-loading>
</div>

<div class="modal-header">
  <h3 class="text-center">{{ (editionMode ? titleEdit : titleCreate) | translate }}</h3>
  <i *ngIf="editionMode" class="fa fa-edit">{{ groupVolunteersOriginal.groupName }}</i>
</div>

<div class="modal-scroll">
  <div class="ibox">
    <div class="ibox-content d-flex flex-column gap-3">
      <div class="form-group row">
        <label class="col-12 col-md-3 my-auto form-control-label required">{{ 'GROUP_NAME_LABEL' | translate }}
        </label>
        <div class="col-12 col-md-4 my-auto">
          <input type="text" class="form-control" [(ngModel)]="groupVolunteers.groupName" name="gvName"
                 [disabled]="editionMode" required>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-12 col-md-3 my-auto form-control-label required">{{ 'HUB' | translate }}
        </label>
        <div class="col-12 col-md-4 my-auto">
          <select class="form-control" [(ngModel)]="hub" name="gvHub" [disabled]="hasHorsArcsSystem">
            <option *ngFor="let hubToSelect of hubs" [value]="hubToSelect" translate>{{ hubToSelect }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-12 col-md-3 my-auto form-control-label required">{{'ONE_PAGER_VOLUNTEERS_TITLE' | translate}}
        </label>
        <div class="col-12 col-md-4 my-auto">
          <input type="number" class="form-control" [(ngModel)]="numberVolunteersModal" min="1"
            [(name)]="gvNumberVolunteers" required>
        </div>
        <button *ngIf="groupVolunteers && numberVolunteersModal !== groupVolunteers.numberOfPanelists"
          id="save" class="col-md-1 btn btn-primary" (click)="onChangeNumberVolunteers()" translate>VALIDATE</button>
      </div>
      <div class="form-group row">
        <label class="col-12 col-md-3 my-auto form-control-label required">{{ 'ACTIONS_LABEL' | translate }}
        </label>
        <div class="col-12 col-md-4 my-auto d-flex flex-row gap-2">
          <button id="save" class="btn btn-primary mb-4" (click)="import()" translate>IMPORT</button>
          <button id="save" class="btn btn-primary mb-4" (click)="export()" translate>EXPORT</button>
          <button id="save" class="btn btn-primary mb-4" (click)="exportRapportAgences()" translate>REPORT</button>
        </div>
      </div>
    </div>
  </div>


  <div class="ibox">
    <div class="ibox-title">
      <h5 translate>VOLUNTEERS_LIST</h5>
    </div>
    <div class="ibox-content">
      <div *ngIf="groupVolunteers.panelists.length > 0" class="ibox">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr>
              <th translate>NUMBER</th>
              <th translate>FIRST_NAME</th>
              <th translate>NAME</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let v of groupVolunteers.panelists; let indexV = index">
              <td><input [disabled]="isPanelistLocked[indexV]"
                         type="number" class="form-control no-arrows" [(ngModel)]="v.panelistShortNumber"
                         (click)="alertPanelistShortNumber(v.panelistShortNumber)" required></td>
              <td><input [disabled]="isPanelistLocked[indexV]"
                         type="text" class="form-control" [(ngModel)]="v.firstName" maxlength="3"></td>
              <td><input [disabled]="isPanelistLocked[indexV]"
                         type="text" class="form-control" [(ngModel)]="v.lastName" maxlength="3"></td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="ibox" *ngIf="isAssociateStudyPossible">
    <div class="ibox-title">
      <h5 translate>STUDIES</h5>
    </div>
    <div class="ibox-content">
      <dna-associateStudies [groupPanelistHorsArcs]="groupVolunteers"
                            (updateGroup)="onUpdateStudies($event)"></dna-associateStudies>

    </div>
  </div>
</div>

<div class="modal-footer">
  <ng-container *ngIf="hasChanged()">
    <button id="save" class="btn btn-primary mb-4" (click)="save()" translate>SAVE</button>
    <button id="save" class="btn btn-primary mb-4" (click)="cancel()" translate>CANCEL</button>
  </ng-container>
  <button id="cancel" class="btn btn-default mb-4" (click)="close()" translate>CLOSE</button>
</div>
