<div class="row text-center" style="padding-bottom: 20px">
  <div class='col'>
    <div class="btn-group btn-group-lg">
      <button class="btn" type="button" [ngClass]="{'btn-secondary': display !== notificationsModes.StartDate, 'btn-primary': display === notificationsModes.StartDate}"
      (click)="onChangeDisplay(notificationsModes.StartDate)">
        <label translate>{{notificationsModes.StartDate}}</label>
      </button>
      <button class="btn" type="button" [ngClass]="{'btn-secondary': display !== notificationsModes.OnUserOpen, 'btn-primary': display === notificationsModes.OnUserOpen}"
        (click)="onChangeDisplay(notificationsModes.OnUserOpen)">
        <label translate>{{notificationsModes.OnUserOpen}}</label>
      </button>
      <button class="btn" type="button" [ngClass]="{'btn-secondary':display !== notificationsModes.OnUserSubmit, 'btn-primary': display === notificationsModes.OnUserSubmit}"
        (click)="onChangeDisplay(notificationsModes.OnUserSubmit)">
        <label translate>{{notificationsModes.OnUserSubmit}}</label>
      </button>
    </div>
  </div>
</div>
<form class="form-inline d-flex flex-row align-items-center" style="padding-bottom: 20px" *ngIf="display === notificationsModes.StartDate">
  <div class="form-group">
    <div class="input-group">
      <label class="control-label align-self-center">{{ 'START_DATE' | translate }} : </label>
      <input class="form-control" required [ngClass]="{'is-invalid' : dateStart.invalid && dateStart.touched}" #dateStart="ngModel"
        [placeholder]="dateFormat" name="date" [ngModel]="startDate" (ngModelChange)="onDateChanged($event)" ngbDatepicker
        #d="ngbDatepicker" [disabled]="!isWorkflowEditable">
      <button class="input-group-addon" (click)="d.toggle()" type="button" [disabled]="!isWorkflowEditable">
          <i class="fa fa-calendar"></i>
      </button>
    </div>
  </div>
  <div class="form-group">
      <ngb-timepicker [ngModel]="startTime" (ngModelChange)="onTimeChanged($event)" name="hour" [disabled]="!isWorkflowEditable"></ngb-timepicker>
      <div class="small form-text text-danger" *ngIf="((dateStart.dirty || dateStart.touched) && dateStart.errors) || isErrorOnTime">
        <label translate>REQUIRED</label>
      </div>
  </div>
</form>
<div class="form-inline row">
  <div class="col-sm-4"></div>
  <div class="form-group col-sm-4">
    <label class="control-label" translate>DELAY_START</label>
  </div>
  <div class="form-group col-sm-4">
    <label class="control-label" for="{{workflowConfiguration.id}}delayEnd" translate>DELAY_END</label>
  </div>
</div>
<div *ngFor='let block of activeBlocks; let index = index' class="form-inline row w-100">
  <label class="col col-sm-4"> <dna-translate [fieldToTranslate]="block.name"></dna-translate>{{block.suffix}}</label>
  <div class="form-group col col-sm-4">
    <input [disabled]="index == 0 || !isWorkflowEditable" id="{{workflowConfiguration.id}}{{block.index}}delayStart" type="number" min="0" class="form-control input-width" [(ngModel)]="block.parameters.delayStart"
      name="{{workflowConfiguration.id}}{{block.index}}delayStart">
  </div>
  <div class="form-group col col-sm-4">
    <input id="{{workflowConfiguration.id}}{{block.index}}delayEnd" type="number" min="0" class="form-control input-width" [(ngModel)]="block.parameters.delayEnd"
      name="{{workflowConfiguration.id}}{{block.index}}delayEnd" (ngModelChange)="updateDelayStartNextBlock(block.index, activeBlocks)" [disabled]="!isWorkflowEditable">
  </div>
</div>
