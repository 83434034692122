import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { ActionBarButton, DNATypes, SynergyDemand, User } from '../../../../types';
import { environment } from '../../../../../environments/environment';
import { UtilService } from '../../../../shared/services/util.service';
import { CampaignService } from '../../../../campaigns/campaigns.service';

@Component({
  selector: '[dna-campaign-view-row]',
  templateUrl: './campaign-view-row.component.html',
  styleUrls: ['./campaign-view-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignViewRowComponent implements OnInit {

  @Input() synergyDemand: SynergyDemand;
  @Input() users: User[];

  @Output() onClick: EventEmitter<ActionBarButton> = new EventEmitter();

  actionsButtons: Observable<ActionBarButton[]>;
  managerName: string;
  referentName: string;

  constructor(
    private utilService: UtilService,
    private campaignService: CampaignService
  ) { }

  ngOnInit() {
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.SynergyDemand, this.synergyDemand.studyId ? true : false);
    this.managerName = this.getUserName(this.synergyDemand.manager);
    this.referentName = "";
    if (this.synergyDemand.referent && this.synergyDemand.referent.length) {
      this.referentName = this.synergyDemand.referent[0].name;
      if (this.synergyDemand.referent.length > 1) {
        this.referentName += ` (+${this.synergyDemand.referent.length - 1})`;
      }
    }
  }

  goToBridgePlatform() {
    window.open(environment.bridge_url() + this.synergyDemand.id);
  }

  onClickButton(actionsButton: ActionBarButton) {
    this.onClick.emit(actionsButton);
  }

  private getUserName(userKey: string) {
    const userDB = this.users.find((user: User) => userKey == user.key);
    return !_.isUndefined(userDB) 
      ? this.campaignService.decodeUserName(userDB.name)
      : "";
  }

}
