
import {of as observableOf, 
  Observable,
  Subscription,
} from 'rxjs';

import {filter, map, tap, finalize} from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router,
} from '@angular/router';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NgForm } from '@angular/forms';

import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
//import { TagModel } from 'ngx-chips/core/accessor';

import {
  CampaignStates,
  Formula,
  GroupedFormula,
  SimComp,
  Tag,
  Training,
} from '../../../../types';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { FormulasInsertionModalComponent } from '../../../../campaigns/detail/edit-campaign/formula/formulas-insertion/formulas-insertion-modal.component';
import { TrainingService } from '../../../training.service';
import { UtilService } from '../../../../shared/services/util.service';

@Component({
  selector: 'dna-training-formulas',
  templateUrl: './training-formula.component.html',
  styleUrls: ['./training-formula.component.less']
})
export class TrainingFormulaComponent implements OnInit, OnDestroy {

  private _trainingUntouched: Training;

  _listFormulaUntouched: Formula[];
  isDisabled: boolean = false;
  showSpinner: boolean = false;
  simpCompActive: boolean = true;
  submitted: boolean = false;
  subscriptions: Subscription[] = [];
  training: Training;
  trainingStates: typeof CampaignStates = CampaignStates;

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    size: 'lg',
  };

  constructor(
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private trainingService: TrainingService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.route.parent.paramMap.pipe(map((params: ParamMap) => this.trainingService.getTrainingFromLocalStorage(params.get('idTraining'))),
        tap((training: Training) => this.isDisabled = training.state === CampaignStates.Published || training.state === CampaignStates.Finished),
        tap((training: Training) => this.initFormula(training)),
        tap((training: Training) => this._listFormulaUntouched = _.cloneDeep(training.formula.formulas)),
        tap((training: Training) => this._trainingUntouched = _.cloneDeep(training)),
        tap((training: Training) => this.training = _.cloneDeep(training)),)
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  checkIsActive(couples: SimComp[], isActive: boolean): boolean {
    if (!isActive || !couples || couples.length === 0) return true;
    for (let s of couples) {
      if (s.isActive) return true;
    }
    return false;
  }

  formulasNotInCampaign(training: Training, formulas: Formula[]): Formula[] {
    const formulasNotInCampaign = formulas.reduce(
      (result: Formula[], formula: Formula) => {
        if (!training.formula.formulas.find((formulaInCampaign: Formula) => formulaInCampaign.name === formula.name)) {
          result.push(formula);
        }
        return result;
      }, []
    );
    return _.uniqBy(formulasNotInCampaign, 'name');
  }

  initFormula(training: Training): Training {
    if (!training.formula.formulas) {
      training.formula.formulas = [];
      training.formula.formulas = _.uniqBy(
        _.flatten(training.formula.listFormulas.map((f: GroupedFormula) => f.formula)), 'id'
      );
    }

    return training;
  }

  onCancel() {
    this.training = _.cloneDeep(this._trainingUntouched);
    this.trainingService.setTrainingFromLocalStorage(this.training);
    this.training.formula.formulas = _.cloneDeep(this._listFormulaUntouched);
  }

  onChangeSimultaneous(training: Training, bool: boolean): Training {
    training.formula.simultaneousComparison.isActive = bool;
    return bool ? this.updateCouplesTable(training, _.cloneDeep(training.formula.simultaneousComparison.couples)) : training;
  }

  onSimCompChange(training: Training, simComp: SimComp[]): Training {
    training.formula.simultaneousComparison.couples = _.cloneDeep(simComp);
    return training;
  }

  openModal(training: Training) {
    const modal = this.modalService.open(FormulasInsertionModalComponent, this.modalOption);
    modal.result.then(
      (formulasArray: Formula[]) => {
        let formulasNotInCampaign = this.formulasNotInCampaign(training, formulasArray);
        training.formula.formulas = training.formula.formulas.concat(formulasNotInCampaign);
        this.training = formulasNotInCampaign.reduce(
          (acc: Training, curr: Formula) => this.addFormulaToList(acc, curr), _.cloneDeep(training)
        );
      },
      (reason) => { }
    );
  }

  reset() {
    this.training.formula.formulas = [];
    this.training.formula.listFormulas = [];
  }

  save(form: NgForm) {
    this.submitted = true;
    const isValidForm = this.training.formula.listFormulas.length != 0 && (!this.training.formula.simultaneousComparison.isActive || this.training.formula.listFormulas.length > 1) && form.valid;
    this.simpCompActive = this.checkIsActive(this.training.formula.simultaneousComparison.couples, this.training.formula.simultaneousComparison.isActive);
    if (isValidForm && this.simpCompActive) {
      this.showSpinner = true;
      this.trainingService.putTraining(this.training).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe((training: Training) => {
          this.trainingService.setTrainingFromLocalStorage(training);
          this.training = training;
          this._trainingUntouched = _.cloneDeep(this.training);
          this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
          this.router.navigate(['trainings', training.id, 'edit', 'users']);
        }, err => this.errorManager.catchError(err));
    } else {
      this.errorManager.displayMessage('ON_ERROR_FORM', 'danger');
    }
  }

  updateCouplesTable(training: Training, couples: SimComp[]): Training {
    couples = this.utilService.calculateCouple(
      training.state,
      training.formula.listFormulas,
      couples,
      _.get(training, 'formula.chosenBench', _.get(training, 'formula.listFormulas[0]')),
      _.get(training, 'formula.applicationZone')
    );
    return this.onSimCompChange(training, couples);
  }


  /**
   * Management of the grouped formulas list
   */

  addFormulaToList(training: Training, formula: Formula): Training {
    training.formula.listFormulas.push(
      new GroupedFormula(this.utilService.generateRandomID(), formula.name, [new Formula(formula.name)])
    );
    return training.formula.simultaneousComparison.isActive ? this.updateCouplesTable(_.cloneDeep(training), _.cloneDeep(training.formula.simultaneousComparison.couples)) :
      _.cloneDeep(training);
  }

  removeFormulaToList(training: Training, formula: Formula): Training {
    training.formula.listFormulas = this.training.formula.listFormulas.filter(
      (groupedFormula: GroupedFormula) => groupedFormula.name !== formula.name
    );
    return training.formula.simultaneousComparison.isActive ? this.updateCouplesTable(_.cloneDeep(training), _.cloneDeep(training.formula.simultaneousComparison.couples)) :
      _.cloneDeep(training);
  }

  /**
   * Management of the Tag Input component
   */

  onAdding(tag) {
    return observableOf(tag.toString().toUpperCase());
  }

  onRemoving(training: Training, published: string, listFormula: Formula[]) {
    return ((tag: Tag) => observableOf(tag).pipe(
      filter((tag: Tag) => {
        if (training.state === published && listFormula.find((f: Formula) => f.name === tag.name.toString().toUpperCase())) {
          return false;
        }
        return true;
      }))
    );
  }

}
