import { Component, OnInit } from '@angular/core';
import { OnePager, User, CampaignMultiHead, CampaignMetadata, CampaignsMulti } from '../../../../types';
import { OnePagerService } from '../../../../shared/services/one-pager.service';
import { MultiCampaignsService } from '../../../../multi-campaigns/multi-campaigns.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { UtilService } from '../../../../shared/services/util.service';
import { tap, catchError, flatMap, take, map } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import * as _ from 'lodash';
import { CampaignService } from '../../../../campaigns/campaigns.service';

@Component({
  selector: 'dna-one-pager-multi-head',
  templateUrl: './one-pager-multi-head.component.html',
  styleUrls: ['./one-pager-multi-head.component.less']
})
export class OnePagerMultiHeadComponent implements OnInit {

  onePager: OnePager;
  initialOnePager: OnePager;
  campaignMulti: CampaignsMulti;
  headerValues: any = {};
  initialManagers: User[];
  booleanIsOnePagerFromCampaign: boolean;
  users: User[];
  isChanged: boolean = false;
  campaignMultiHead: CampaignMultiHead;
  showSpinner: boolean = false;
  managers: User[] = [];
  error: boolean = false;
  dateMulti;

  constructor(
    private campaignService: CampaignService,
    private onePagerService: OnePagerService,
    private campaignMultiService: MultiCampaignsService,
    private errorManagerService: ErrorManagerService,
    private utilService: UtilService,

  ) { }

  ngOnInit() {
    this.init();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.managers, this.initialManagers);
  }

  catchError = (err) => {
    this.errorManagerService.catchError(err);
    return observableOf(this.onePager);
  };

  init() {
    this.showSpinner = true;
    this.error = false;
    this.campaignMultiService.getCampaignMultiOnePagerHead(this.campaignMultiService.getCampaignMultiId()).pipe(
      catchError(err => {
        this.error = true;
        return observableOf([]);
      }),
      tap((campaignMultiHead: CampaignMultiHead) => this.campaignMultiHead = campaignMultiHead),
      flatMap(() => this.campaignService.getCampaignMetadata()),
      take(1),
      map((metadata: CampaignMetadata) => this.users = this.campaignService.displayUsersName(metadata)),
      tap(() => this.headerValues = this.initHeader(this.campaignMultiHead)),
      tap(() => this.campaignMultiHead.campaignsMulti.orchestra = this.headerValues.orchestra),
      tap(() => this.onePager = this.onePagerService.getOnePager()),
      tap(() => this.initialOnePager = _.cloneDeep(this.onePager)),
      tap(() => this.managers = _.get(this.onePager, 'identification.manager', [])),
      tap(() => this.initialManagers = _.cloneDeep(this.managers)),
      tap(() => this.campaignMultiService.hasNameChanged.next(this.campaignMultiHead.campaignsMulti.name)),
      tap(() => this.dateMulti = new Date(_.get(this.campaignMultiHead, 'campaignsMulti.updated_on', 0))),
      tap(() => this.showSpinner = false)
    ).subscribe();
  }

  private initHeader(campaignMultiHead: CampaignMultiHead) {
    _.set(campaignMultiHead, 'campaignsHeadInfo', this.onePagerService.setHeaderInfoExperts(_.get(campaignMultiHead, 'campaignsHeadInfo', []), this.users));
    const headInfo = this.onePagerService.getHeadInfoFromDatabase(_.get(campaignMultiHead, 'campaignsHeadInfo', []));
    this.onePagerService.setOnePagerHeaderInfo(headInfo);
    return {
      projectName: _.get(campaignMultiHead, 'campaignsMulti.name', ""),
      actiview: _.get(campaignMultiHead, 'campaignsMulti.actiview', ""),
      synergy: _.get(campaignMultiHead, 'campaignsMulti.synergy', ""),
      hub: _.get(campaignMultiHead, 'campaignsMulti.hub', ""),
      studyDate: _.get(campaignMultiHead, 'campaignsHeadInfo', []).reduce((acc, curr, index) => {
        let date = _.get(curr, '[0].identification.studyDate', '/')
        return index > 0 ? acc + ", " + date : date;
      }, ""),
      orchestra:  _.get(campaignMultiHead, 'campaignsHeadInfo', "").flatMap(study => study.map(item => item.identification.orchestra)),
      laboratory: headInfo.laboratory,
      experts: headInfo.experts,
      fieldwork: headInfo.fieldwork
    };
  }

  onCancel() {
    this.managers = _.cloneDeep(this.initialManagers);
    this.isChanged = false;
  }

  onChangeManager() {
    this.isChanged = !_.isEqual(this.managers, this.initialManagers);
  }

  save(managers: User[]) {
    _.set(this.onePager, 'identification.manager', managers);
    this.updateOnePager()
      .subscribe((onePager: OnePager) => {
        this.onePager = onePager;
        this.initialOnePager = _.cloneDeep(this.onePager);
        this.onePagerService.setOnePager(this.onePager);
        this.initialManagers = _.cloneDeep(this.managers);
        this.isChanged = false;
      });
  }

  private updateOnePager = (): Observable<OnePager> => {
    this.errorManagerService.displayMessage("ON_SAVE_PROCESSING", "info");
    return this.onePagerService.updateOnePager(this.onePager.id, this.onePagerService.getElementToUpdate(this.initialOnePager, this.onePager)).pipe(
      tap(() => this.errorManagerService.displayMessage("ON_SUCCESS_UPDATE")),
      catchError(this.catchError)
    )
  }

}
