<h1 class="mb-4" id="banner-title">
  <span translate>VOLUNTEERS</span> - {{ panelist?.firstName }} {{ panelist?.lastName }}
</h1>
<nav aria-label="breadcrumb" id="banner-nav">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/dashboard" translate>DASHBOARD</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/volunteers" translate>VOLUNTEERS</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      {{ panelist?.panelistNumber }}
    </li>
  </ol>
</nav>
<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="!showSpinner" class="block">
  <div class="ibox float-e-margins">
    <div class="ibox-title row noMarginSide" (click)="isCollapsedFilter = !isCollapsedFilter"
         aria-controls="volunteerFilter">
      <h5 class="col-md-6" translate>FILTERS</h5>
      <div class="ibox-tools col-md-6">
        <a>
          <i *ngIf="isCollapsedFilter; else isNotCollapsedFilter" class="fa fa-chevron-down"></i>
          <ng-template #isNotCollapsedFilter><i class="fa fa-chevron-up"></i></ng-template>
        </a>
      </div>
    </div>
    <div id="volunteerFilter" class="ibox-content" [ngbCollapse]="isCollapsedFilter">
      <div class="row">
        <div class="col-2" translate>
          DATE_START
        </div>
        <div class="col-4">
          <div class="form-group">
            <div class="row input-group">
              <input class="col-11 margin-date form-control" [(ngModel)]="dateStart" (ngModelChange)="onDateChanged()"
                     ngbDatepicker #d="ngbDatepicker">
              <button class="input-group-addon" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-2" translate>
          DATE_END
        </div>
        <div class="col-4">
          <div class="form-group">
            <div class="row input-group">
              <input class="col-11 margin-date form-control" [(ngModel)]="dateEnd" (ngModelChange)="onDateChanged()"
                     ngbDatepicker #e="ngbDatepicker">
              <button class="input-group-addon" (click)="e.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ibox float-e-margins">
    <div class="ibox-content" *ngIf="panelist">
      <div class="row">
        <div class="col-3">
          <h4 translate>INFORMATION</h4>
          <div class="row">
            <div class="col-4">{{ 'ID' | translate }}:</div>
            <div class="col-8">{{ panelist.panelistNumber }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{ 'FIRST_NAME' | translate }}:</div>
            <div class="col-8">{{ panelist.firstName }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{ 'LAST_NAME' | translate }}:</div>
            <div class="col-8">{{ panelist.lastName }}</div>
          </div>
        </div>
        <div class="col-6" translate>
          <h4 translate>TIMELINE</h4>
          <div class="custom-theme">
            <ngx-timeline
              [events]="timelineEvents"
              [enableAnimation]="false"
              [reverseOrder]="true"
              [changeSideInGroup]="NgxTimelineEventChangeSideInGroup.ALL"
              [innerEventCustomTemplate]="innerEventCustomTemplate"
            ></ngx-timeline>
          </div>
        </div>
        <div class="col-3">
          <h4 translate>ACTIONS</h4>
          <div class="row">
            <a class="btn btn-primary mb-4 text-white" (click)="goToFicheCaracterisation()">
              <span translate>FICHE_CARACTERISATION</span>
            </a>
          </div>
          <div class="row">
            <a class="btn btn-primary mb-4 text-white" (click)="goToFicheRemanence()">
              <span translate>FICHE_REMANENCE</span>
            </a>
          </div>
          <div class="row">
            <a class="btn btn-primary mb-4 text-white" (click)="goToFicheEntretien()">
              <span translate>FICHE_ENTRETIEN</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #innerEventCustomTemplate let-event=event>
  <div class="event d-flex flex-column" (click)="goToTimelineElement(event)">
    <div class="item-date">{{ event?.eventInfo?.timestamp.toLocaleString(undefined, dateOptions) }}</div>
    <h3 translate>{{ event?.eventInfo?.title }}</h3>
    <p>{{ event?.eventInfo?.description }}</p>
  </div>
</ng-template>
