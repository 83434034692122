
import { of as observableOf, Observable, forkJoin } from 'rxjs';

import { zip, tap, finalize, mergeMap, map } from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Actiview,
  Campaign,
  CampaignStates,
  LightCampaign,
  SynergyDemand,
  Type,
} from '../../../../types';
import { CampaignService } from '../../../../campaigns/campaigns.service';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { FilterService } from '../../../../shared/services/filter.service';
import { ReferenceTypeService, } from '../../../../shared/services/reference-type.service';
import { StateService } from '../../../../shared/services/state.service';
import { SynergyDemandService } from '../../../../shared/services/synergy-demand.service';
import { UtilService } from '../../../../shared/services/util.service';
import { HttpRestService } from '../../../../shared/services/httpRest.service';

@Component({
  selector: 'dna-link-to-campaign-modal',
  templateUrl: './link-to-campaign-modal.component.html',
  styleUrls: ['./link-to-campaign-modal.component.less']
})
export class LinkToCampaignModalComponent implements OnInit {

  @Input() campaigns: LightCampaign[];
  @Input() synergyDemand: SynergyDemand;

  campaignSelected: LightCampaign;
  campaignsFiltered: LightCampaign[];
  campaignStates: typeof CampaignStates = CampaignStates;
  filter: any;
  isEmpty: boolean = true;
  language: string;
  showSpinner: boolean = false;
  states: string[];
  typeCampaigns: Type[];
  typeEvaluations: Type[];

  constructor(
    private activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private errorManagerService: ErrorManagerService,
    private filterService: FilterService,
    private referenceType: ReferenceTypeService,
    private stateService: StateService,
    private synergyDemandService: SynergyDemandService,
    private translateService: DNATranslateService,
    private utilService: UtilService,
    private httpService: HttpRestService,
  ) { }

  ngOnInit() {
    this.filter = _.cloneDeep(this.filterService.getFilter().dashboard);
    this.language = this.translateService.getLanguage();
    this.states = this.stateService.getVisibleStates(this.stateService.SYNERGY_DEMANDS);
    this.typeCampaigns = this.referenceType.getTypeCampaign();
    this.typeEvaluations = this.referenceType.getTypeEvaluation();

    this.campaigns = this.campaigns.filter((c: LightCampaign) => !c.synergy && !c.isTemplate);
    this.campaignsFiltered = this.utilService.filterListSynergyDemand(this.campaigns, this.filter);
    this.updateCampaignSelected(this.campaignsFiltered);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  link(synergyDemand: SynergyDemand, campaign: LightCampaign) {
    this.showSpinner = true;
    let observables = [
      this.campaignService.getHttpCampaign(campaign.id),
      this.httpService.getActiviewBySynergy(synergyDemand.id)
    ]

    forkJoin(observables).pipe(
      zip(observableOf(this.synergyDemand)),
      map((objects: [any[], SynergyDemand]) => this.linkToCampaign(objects[0][0], objects[0][1], objects[1])),
      mergeMap((objects: [Campaign, SynergyDemand]) => this.save(objects[0], objects[1])),
      finalize(() => this.showSpinner = false))
      .subscribe(
        (c: Campaign) => this.activeModal.close(_.cloneDeep(c)),
        error => this.errorManagerService.catchError(error)
      );
  }

  linkToCampaign(campaign: Campaign, actiview: Actiview, synergyDemand: SynergyDemand): [Campaign, SynergyDemand] {
    campaign.synergy = _.assign(campaign.synergy, synergyDemand);
    campaign.synergy.requestNumber = synergyDemand.requestNumber;
    synergyDemand.studyId = campaign.id;
    synergyDemand.oldWorkspaces = synergyDemand.workspaces;
    synergyDemand.workspaces = campaign.workspaces;
    campaign.actiview = _.assign(campaign.actiview, actiview);
    campaign.actiview.activityNumber = actiview.code;
    campaign = this.synergyDemandService.updateCampaignOnSynergyChanged(synergyDemand, campaign);
    return [campaign, synergyDemand];
  }

  onFilterChanged(filter: any) {
    this.campaignsFiltered = this.utilService.filterListSynergyDemand(this.campaigns, filter);
    this.updateCampaignSelected(this.campaignsFiltered);
  }

  save(campaign: Campaign, synergyDemand: SynergyDemand): Observable<Campaign> {
    return this.synergyDemandService.patchSynergyDemand(synergyDemand).pipe(
      mergeMap(() => this.campaignService.putCampaign(campaign)),
      tap(campaign => this.campaignService.setCampaignFromLocalStorage(campaign)))
  }

  updateCampaignSelected(campaigns: LightCampaign[]) {
    if (campaigns.length > 0) {
      this.campaignSelected = campaigns[0];
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

  updateData(text: any, type: string, filter: any) {
    filter[type] = text;
    this.onFilterChanged(filter);
  }

}
