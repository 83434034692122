import { Chart } from "../../chart.model";

const doWork = (data: Array<any>, baseKey : string,lang: string, parameters: any, payload: any, drilledMethod: string, routinesObject: any): any => {
    let usedColors = [...Chart.drilledColors];
    let routines = routinesObject && routinesObject.hasOwnProperty('routines') && routinesObject.routines && routinesObject.routines.length ? routinesObject.routines.sort((a, b) => b.isBench - a.isBench) : [];
    const flattenedData = [...new Set(
        data.flatMap((item) => 
            item.values.flatMap((valueItem) => 
                valueItem.values.map((innerValue) => innerValue.label)
            )
        )
    )];
    const sortedVolunteers = flattenedData.sort((a, b) => a.localeCompare(b));
    const output = data.reduce((accumulator: any, item: any) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            item.values.reduce((reducer: { categories: object, series: object }, object: any, fIndex : number) => {
                let keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_')
                accumulator.categories[keyLabel] = item.attribute;
                
                object.values.reduce(( acc : any, drilledObject : any, volIndex : number ) => {
                    const keySerie = Chart.getObjectValueTranslation(drilledObject.label, lang);
                    const volSortedIndex = sortedVolunteers.indexOf(keySerie);
                    acc[keySerie] = (acc[keySerie] || { name: keySerie, data: [], color: usedColors[volSortedIndex] });

                    (acc[keySerie].data = ( acc[keySerie].data || []) ).push({
                        y : routines && routines.find((routine: any) => routine.name === object.attribute.label).isBench && Number(drilledObject.value[baseKey]) > 0 ? Number(-1 * Number(drilledObject.value[baseKey])) : Number(drilledObject.value[baseKey]),
                        x : Object.keys(accumulator.categories).indexOf(keyLabel),
                    });
                    return acc;
                }, accumulator.series);
                
                return reducer;
            }, accumulator);
        }
        return accumulator;
    }, payload);
   
    switch (drilledMethod) {
        case 'median-merged':
            addMedianScatter(output, routines);
            break;
        default:
            break;
    };
    return output;
};

/**
 * Append median Scatter into series
 * @param data data with series
 * @param routines routine object
 */
const addMedianScatter = (data: any, routines: any) => {
    let medianCName = 'median';
    let medianN = 'Median';

    const medians: {
        [key: string] : number[]
    } = {};
    // regroup values by category
    Object.values(data.series).forEach((s: any) => {
        Object.values(s.data).forEach((v: any) => {
            if(!Number.isNaN(v.y)) {
                if(!medians[v.x]) medians[v.x] = [];
                medians[v.x].push(v.y);
            }
        })
    })
    // create median series squelleton
    const medianSeries = {
        name: medianN,
        data: [],
        type: 'scatter',
        zIndex: 4,
        color: Chart.colorScale[0],
        marker: {
            symbol: 'diamond',
            fillColor: 'white',
            lineColor: Chart.colorScale[0],
            lineWidth: 1
        }
    };
    // calculate median for each category
    Object.entries(medians).forEach(([key, values]: [string, number[]]) => {
        const currentMedian = median(values);
        const currentColorIndex = currentMedian <= 0 ? 0 : 1;
        const currentColor = Chart.colorScale[currentColorIndex];
        medianSeries.data.push({
            x: Number(key),
            y: currentMedian,
            color: currentColor,
            marker: {
                fillColor: currentColor,
                lineColor: currentColor,
            }
        })
    });
    // append into series
    data.series[medianCName] = medianSeries;
}

const median = (array: Array<any>) => {
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a - b);
    return array.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};


export {
    doWork
};