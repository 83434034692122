<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <form #generalInfoForm="ngForm" name="campaignForm" novalidate>
      <div class="ibox-title">
        <div class="row">
          <div class="col-12 col-md-6 my-auto">
            <h5 *ngIf="!campaign.id; else campaignId" class="text-center">{{ "GENERAL" | translate }} - {{ "DATE_CREATION" | translate }}</h5>
            <ng-template #campaignId>
              <h5 class="text-center">{{ "GENERAL" | translate }} - {{ "MODIFICATION" | translate }}</h5>
            </ng-template>
          </div>
          <div class="col-12 col-md-6">
            <div class="float-end d-flex flex-row gap-1">
              <ng-container *ngIf="isChangedCampaign()">
                <button id="save" class="btn btn-primary" type="button" (click)="saveAndUpdate(generalInfoForm)" translate>SAVE</button>
                <button id="cancel" class="btn btn-default" type="button" (click)="clickCancel()" translate>CANCEL</button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="ibox-content d-flex flex-column gap-3">
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': name.invalid && (name.touched || submitted) }">
              {{ "NAME" | translate }}
            </label>
            <div class="col-12 col-md-9">
              <input class="form-control" [ngClass]="{ 'is-invalid': name.invalid && (name.touched || submitted) }"
                #name="ngModel" [(ngModel)]="campaign.name" type="text" name="name" [disabled]="false" required>
            </div>
            <div *ngIf="name.dirty || name.touched || submitted" class="offset-1 text-center invalid-feedback">
              <p *ngIf="name.errors && name.errors.required" translate>REQUIRED</p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label">{{ "STUDY_DESCRIPTION" | translate }}</label>
            <div class="col-12 col-md-9">
              <textarea class="form-control" [disabled]="isEditDisabled(campaign)" [(ngModel)]="campaign.description" style="overflow: hidden"
                type="text" name="description"></textarea>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required">{{ "CAMPAIGN_TYPE" | translate }}</label>
            <div class="col-12 col-md-9">
              <select class="form-control" [ngClass]="{ 'is-invalid': (!campaign.studyType || !campaign.studyType.id) && submitted }" [disabled]="publishedTemplate" required [(ngModel)]="campaign.studyType" [compareWith]="byId"
                name="studyType">
                <option *ngFor="let item of typeCampaigns" [ngValue]="item" translate>{{ item.name }}</option>
              </select>
            </div>
            <div *ngIf="(!campaign.studyType || !campaign.studyType.id) && submitted" class="offset-1 text-center invalid-feedback">
              <p translate>REQUIRED</p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required">{{ "EVALUATION_TYPE" | translate }}</label>
            <div class="col-12 col-md-9">
              <select class="form-control" required [ngClass]="{ 'is-invalid': (!campaign.evaluationType || !campaign.evaluationType.id) && submitted }" [(ngModel)]="campaign.evaluationType" [compareWith]="byId"
                name="evaluationType" [disabled]="publishedTemplate">
                <option *ngFor="let item of typeEvaluations" [ngValue]="item" translate>{{ item.name }}</option>
              </select>
            </div>
            <div *ngIf="(!campaign.evaluationType || !campaign.evaluationType.id) && submitted" class="offset-1 text-center invalid-feedback">
              <p translate>REQUIRED</p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required">{{ "FOCUS" | translate }}</label>
            <div class="col-12 col-md-9">
              <select class="form-control" required [ngClass]="{ 'is-invalid': (!campaign.focus || !campaign.focus.id) && submitted }" [(ngModel)]="campaign.focus" [compareWith]="byId"
                name="focus" [disabled]="publishedTemplate">
                <option *ngFor="let focus of opticalCodes" [ngValue]="focus" translate>{{ focus.name }}</option>
              </select>
            </div>
            <div *ngIf="(!campaign.focus || !campaign.focus.id) && submitted" class="offset-1 text-center invalid-feedback">
              <p translate>REQUIRED</p>
            </div>
          </div>
        </div>
        <ng-container>
          <div class="form-group" *ngIf="!campaign.isTemplate">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label required" [ngClass]="{ 'is-invalid': dateStart.invalid && (dateStart.touched || submitted) }">{{ "DATE_START" | translate }}</label>
              <div class="col-12 col-md-9 input-group w-75">
                <input class="form-control" [ngClass]="{ 'is-invalid': dateStart.invalid && (dateStart.touched || submitted) }" [disabled]="isEditDisabled(campaign)"
                  [(ngModel)]="startDate" (ngModelChange)="onDateChanged($event, 'startDate')" #dateStart="ngModel" [placeholder]="dateFormat"
                  name="d" #d="ngbDatepicker" ngbDatepicker required>
                <div class="input-group-append">
                  <button class="input-group-addon calendar-icon" [disabled]="isEditDisabled(campaign)" type="button" (click)="d.toggle()">
                  <i class="fa fa-calendar"></i>
                </button>
                </div>
              </div>
              <div *ngIf="dateStart.dirty || dateStart.touched || submitted" class="offset-3 text-center invalid-feedback">
                <p *ngIf="dateStart.errors && dateStart.errors.required" translate>REQUIRED</p>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="!campaign.isTemplate">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">{{ "DATE_END" | translate }}</label>
              <div class="col-12 col-md-9 input-group w-75">
                <input class="form-control" [disabled]="isEditDisabled(campaign)" [(ngModel)]="endDate" (ngModelChange)="onDateChanged($event, 'endDate')"
                  [placeholder]="dateFormat" name="dend" #dend="ngbDatepicker" ngbDatepicker>
                <div class="input-group-append">
                  <button class="input-group-addon calendar-icon" [disabled]="isEditDisabled(campaign)" type="button" (click)="dend.toggle()">
                  <i class="fa fa-calendar"></i>
                </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="campaign.actiview?.activityNumber && !campaign.isTemplate" class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">{{ "PERFORMANCE_CENTER" | translate }}</label>
              <div class="col-12 col-md-9 input-group">
                <img src="/assets/pc_icon.svg" alt="Pfc icon" width="20" class="cursor-pointer icon-class" (click)="goToPerformanceCenter(campaign.actiview.activityNumber)">
              </div>
            </div>
          </div>
          <div *ngIf="currentWorkspace.preferences.projects.actiview.isActive && !campaign.isTemplate" class="form-group" class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">
              {{ "PROJECTS.PROJECT_NUMBER" | translate }}
            </label>
              <div class="col-12 col-md-9 input-group w-75">
                <input class="form-control" [disabled]="activiewLocked || isEditDisabled(campaign)" [(ngModel)]="activityNumber" type="text"
                  name="actiview">
                <div *ngIf="campaign.actiview?.activityNumber" class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="goToActiview(activityNumber)" title="{{ 'OPEN_ACTIVIEW' | translate }}">
                    <i class="fa fa-external-link"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button *ngIf="!isEditDisabled(campaign)" class="btn btn-outline-secondary" type="button" (click)="updateActiviewInProject(activityNumber)">
                  <i class="fa" [ngClass]="{ 'fa-refresh': campaign.actiview?.activityNumber, 'fa-link': !campaign.actiview || !campaign.actiview.activityNumber }"></i>
                </button>
                <button *ngIf="!campaign.actiview && isEditDisabled(campaign)" title="{{ 'OPEN_ACTIVIEW' | translate }}" class="btn btn-outline-secondary" type="button" (click)="updateActiviewInProjectWithSynergy()">
                  <i class="fa fa-refresh" ></i>
                </button>
                <button *ngIf="!campaign.actiview && isEditDisabled(campaign)" title="{{ 'OPEN_ACTIVIEW' | translate }}" class="btn btn-outline-secondary" type="button" (click)="updateActiviewInProjectWithSynergy()">
                  <i class="fa fa-refresh" ></i>
                </button>
                </div>
                <div *ngIf="activiewLocked" class="input-group-append">
                  <button class="btn btn-danger" [disabled]="isEditDisabledActiviewSynergy(campaign)" type="button" (click)="unlinkActiview()" ngbTooltip="{{ $tooltipUnlink | async }}" placement="top">
                  <i class="fa fa-chain-broken"></i>
                </button>
                </div>
                <ngx-loading [show]="activiewLoading" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
              </div>
              <div *ngIf="activiewIncorrect" class="offset-3 text-center invalid-feedback">
                <p translate>PROJECTS.INCORRECT_PROJECT_NUMBER</p>
              </div>
            </div>
          </div>
          <div *ngIf="currentWorkspace.preferences.projects.bridge.isActive && !campaign.isTemplate" class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">
              {{ "PROJECTS.PROJECT_BRIDGE" | translate }}
            </label>
              <div class="col-12 col-md-9 input-group w-75">
                <input class="form-control" [disabled]="synergyLocked || isEditDisabled(campaign)" [(ngModel)]="requestNumber" type="text"
                  name="synergy">
                <div *ngIf="campaign.synergy?.id" class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="goToSynergy(requestNumber)" title="{{ 'OPEN_SYNERGY' | translate }}">
                    <i class="fa fa-external-link"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button *ngIf="!isEditDisabled(campaign)" class="btn btn-outline-secondary" type="button" (click)="updateSynergyInProject(requestNumber)">
                  <i class="fa" [ngClass]="{ 'fa-refresh': campaign.synergy?.id, 'fa-link': !campaign.synergy || !campaign.synergy.requestNumber }"></i>
                </button>
                  <button *ngIf="synergyLocked" class="btn btn-danger" [disabled]="isEditDisabledActiviewSynergy(campaign)" type="button" (click)="unlinkSynergy()">
                  <i class="fa fa-chain-broken"></i>
                </button>
                </div>
                <ngx-loading [show]="synergyLoading" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
              </div>
              <div *ngIf="synergyIncorrect" class="offset-3 text-center invalid-feedback">
                <p translate>PROJECTS.INCORRECT_PROJECT_BRIDGE</p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">{{ "DO_YOU_MANAGE_PERSONAL_DATA" | translate }}</label>
              <div class="col-12 col-md-9">
                <select [disabled]="isEditDisabled(campaign)" class="form-control" [(ngModel)]="campaign.frame.personalDataManagementAndFrameId" name="personalDataManagementAndFrameId" (ngModelChange)="onPersonalDataFrameChange($event)" >
                  <option *ngFor="let option of framePersonalData" [ngValue]="option.code" translate>{{ option.name[languageMap[currentLanguage]] || option.name['en'] }}</option>
                </select>
              </div>
            </div>
          </div>
          <div *ngIf="campaign.frame?.link && !campaign.isTemplate" class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">{{ "FRAME_LINK" | translate }}</label>
              <div class="col-12 col-md-9">
                <input readonly class="form-control-plaintext" [value]= 'campaign.frame.link' >
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">{{ "PERSONAL_DATA_COMMENT" | translate }}</label>
              <div class="col-12 col-md-9">
                <textarea [disabled]="isEditDisabled(campaign)" class="form-control" [(ngModel)]="campaign.frame.personalDataComment" name="personalDataComment"></textarea>
              </div>
            </div>
          </div>

        <div *ngIf="currentWorkspace.preferences.projects.arcs.isActive && !campaign.isTemplate">
          <div class="form-group" *ngFor="let arc of arcsData; let index = index">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label">
              {{ "PROJECTS.PROJECT_ARCS_STUDY" | translate }} {{ index+1}}
              </label>
              <div class="col-12 col-md-9 input-group w-75">
                <div class="input-group-prepend">
                  <button class="btn btn-outline-secondary dropdown-toggle" [disabled]="arcsLocked[index] || isEditDisabled(campaign)" type="button"
                      data-toggle="dropdown" name="system" aria-haspopup="true" aria-expanded="false">
                    {{ arcsSystem[index] }}
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let country of countries">
                      <a class="dropdown-item" (click)="arcsSystem[index] = country">{{ country }}</a>
                    </ng-container>
                  </div>
                </div>
                <input class="form-control" [(ngModel)]="arcsNumber[index]" [disabled]="arcsLocked[index] || isEditDisabled(campaign)" type="text"
                  [name]="'arcs' + index">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" [disabled]="arcsLocked[index]" type="button" (click)="updateArcsInProject(arcsNumber[index], arcsSystem[index], index)">
                    <i class="fa" [ngClass]="{ 'fa-refresh': campaign.arcs[index], 'fa-link': !campaign.arcs[index] }"></i>
                  </button>
                  <button *ngIf="arcsLocked[index]" class="btn btn-danger" type="button" (click)="unlinkArcs(index)">
                  <i class="fa fa-chain-broken"></i>
                </button>
                </div>
                <ngx-loading [show]="arcsLoading[index]" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
              </div>
              <div *ngIf="arcsIncorrect[index]" class="offset-3 text-center invalid-feedback">
                <p translate>PROJECTS.INCORRECT_PROJECT_ARCS</p>
              </div>
            </div>
          </div>
          <div class="form-group pt-3">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label" translate>PROJECTS.PROJECT_ARCS_STUDY</label>
              <div class="col-12 col-md-9 w-75">
                <div>
                  <button id="addArcs" class="btn btn-primary" type="button" (click)="addArcs()" translate>ADD_ARCS_NUMBER</button>
                </div>
              </div>
            </div>
          </div>

        </div>
          <div class="d-flex flex-column gap-3" *ngIf="!campaign.isTemplate">
            <div class="form-group" *ngFor="let horsarcs of horsArcsData; let index = index">
              <div class="row">
                <label class="col-12 col-md-3 my-auto form-control-label">
                {{ "PROJECT_HORS_ARCS_STUDY" | translate }} {{ index+1}}
                </label>
                <div class="col-12 col-md-9 input-group w-75">
                  <div class="input-group-prepend">
                    <button class="btn btn-outline-secondary dropdown-toggle" [disabled]="horsArcsLocked[index] || isEditDisabled(campaign)" type="button"
                        data-toggle="dropdown" name="system" aria-haspopup="true" aria-expanded="false">
                      {{ horsArcsSystem[index] }}
                    </button>
                    <div class="dropdown-menu">
                      <ng-container *ngFor="let country of arcsCountries">
                        <a class="dropdown-item" (click)="horsArcsSystem[index] = country">{{ country }}</a>
                      </ng-container>
                    </div>
                  </div>
                  <input class="form-control" [(ngModel)]="groupsNameHorsArcs[index]" [disabled]="horsArcsLocked[index] || isEditDisabled(campaign)" type="text"
                    [name]="'horsArcs' + index">
                  <div class="input-group-append">
                    <button *ngIf="horsArcsLocked[index]" class="btn btn-danger" type="button" (click)="unlinkHorsArcs(index)">
                    <i class="fa fa-chain-broken"></i>
                  </button>
                  </div>
                  <ngx-loading [show]="horsArcsLoading[index]" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
                </div>
                <div *ngIf="horsArcsIncorrect[index]" class="offset-3 text-center invalid-feedback">
                  <p translate>INCORRECT_GROUP_HORS_ARCS</p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="currentWorkspace.preferences?.displayNonArcsVolunteers && !campaign.isTemplate">
            <div class="row">
              <label class="col-12 col-md-3 my-auto form-control-label" translate>PROJECT_HORS_ARCS_STUDY</label>
              <div class="col-12 col-md-9 space-between">
                <div>
                  <a class="btn btn-primary text-white" (click)="openModalGroupeVolunteer()">
                    <span translate>CREATE_GROUP_VOLUNTEERS</span>
                  </a>
                </div>
                <div>
                  <button id="associateGroupHorsArcs" class="btn btn-primary" type="button" (click)="associateGroupHorsArcs()" translate>ASSOCIATE_GROUP</button>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label" [ngClass]="{ 'required': !campaign.isTemplate }" for="hub" translate>HUB</label>
            <div class="col-12 col-md-9">
              <select class="form-control" [disabled]="isEditDisabled(campaign)" [(ngModel)]="campaign.hub" name="hub" [required]="!campaign.isTemplate">
                <option *ngFor="let hub of hubs" [ngValue]="hub" translate>{{ hub }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label" [ngClass]="{ 'required': !campaign.isTemplate }" for="fieldWork" translate>FIELDWORK</label>
            <div class="col-12 col-md-9">
              <select class="form-control" [(ngModel)]="campaign.fieldWork" name="fieldWork" [compareWith]="byId" [required]="!campaign.isTemplate"
               [ngClass]="{ 'is-invalid': (!campaign.fieldWork || !campaign.fieldWork.id) && submitted }" [disabled]="publishedTemplate">
                <option *ngFor="let fieldWork of allCountries" [ngValue]="fieldWork" translate>{{ fieldWork.value }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 form-control-label" translate>IS_TEMPLATE</label>
            <div class="col-2">
              <dna-switch [disabled]="isEditDisabled(campaign)" [isSelected]="campaign.isTemplate" (onBooleanChanged)="campaign.isTemplate = !campaign.isTemplate"></dna-switch>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
