
import { tap, flatMap, catchError} from 'rxjs/operators';
import {
  Router,
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  AnalyseService
} from './analyse.service';
import { OptionDataviz, TypeDataviz, WorkflowPBI, CampaignAnalyse } from '../../../../types';
import {
  CampaignService
} from '../../../campaigns.service';
import { of, throwError } from 'rxjs';

@Component({
  selector: 'dna-analyse',
  templateUrl: './analyse.component.html',
  styleUrls: ['./analyse.component.less']
})

export class AnalyseComponent implements OnInit {
  chosenWorkflow: WorkflowPBI;
  listWorkflows: WorkflowPBI[] = [];
  optionDataviz: typeof OptionDataviz = OptionDataviz;
  typeDataviz: typeof TypeDataviz = TypeDataviz;
  error: boolean = false;
  showSpinner: boolean;

  constructor(
    private analyseService: AnalyseService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.error = false;
    this.showSpinner = true;
    this.route.parent.paramMap.pipe(
      flatMap((params: ParamMap) => this.campaignService.getCampaignAnalyse(params.get("idCampaign"))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaign: CampaignAnalyse) => this.campaignService.updateBreadCrumb(campaign.name)),
      tap((campaign: CampaignAnalyse) => this.listWorkflows = this.analyseService.groupByWorkflows(campaign)),
      tap(() => this.setSelectTag()),
      tap(() => this.showSpinner = false),)
    .subscribe();
  }

  compare(option1: any, option2: any): boolean {
    return option2 ? option1.id === option2.id : false;
  }

  goToPageJSCharts(chosenWorkflow: WorkflowPBI) {
    this.campaignService.setReportMetadata(chosenWorkflow);
    this.router.navigate(['campaigns', chosenWorkflow.idCampaign[0], 'edit', 'analyse', 'reports']);
  }

  setSelectTag() {
    this.chosenWorkflow = this.listWorkflows[0];
  }
}
