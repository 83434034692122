import { Chart } from "../../chart.model";


const doWorkMedian = (data: Array<any>, baseKey : string, lang: string, parameters: any, descriptors: Array<any>, drilledMethod: string, payload: any, routines: Array<any>): any => {
    return data.reduce((accumulator: any, item: any, index: number) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            item.values.reduce((reducer: { categories: object, series: object }, object: any, fIndex: number) => {
                const keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_')
                accumulator.categories[keyLabel] = item.attribute;
                
                object.values.reduce((acc: any, drilledObject: any, volIndex : number) => {
                    const keySerie = [Chart.getObjectValueTranslation(drilledObject.label, lang), Chart.getObjectValueTranslation(object.attribute.label, lang)].join('_');
                    const nameSerie = [Chart.getObjectValueTranslation(drilledObject.label, lang), Chart.getObjectValueTranslation(object.attribute.label, lang)].join(' ');
                    acc[keySerie] = (acc[keySerie] || { 
                        name: nameSerie,
                        data: [], 
                        color: Chart.drilledColors[volIndex],
                        dashStyle : Chart.dashStyles[fIndex]
                    });
                    (acc[keySerie].data = (acc[keySerie].data || [])).push({
                        y: Number(drilledObject.value[baseKey]),
                        x: Object.keys(accumulator.categories).indexOf(keyLabel)
                    });
                    return acc;
                }, accumulator.series);

                // Add scatters for medians
                const keyMedian = ['median', Chart.getObjectValueTranslation(object.attribute.label, lang)].join("_");
                const nameMedian = ['median', Chart.getObjectValueTranslation(object.attribute.label, lang)].join(' ');

                reducer.series[keyMedian] = (accumulator.series[keyMedian] || { 
                    name: nameMedian, 
                    data: [],
                    type: 'scatter', 
                    zIndex: 4, 
                    color: Chart.colorScale[fIndex] 
                });
                (reducer.series[keyMedian].data = (reducer.series[keyMedian].data || [])).push({
                    y: median(Chart.castValuesToNumber(object.values, baseKey), baseKey),
                    x: Object.keys(accumulator.categories).indexOf(keyLabel),
                    details: object.values
                });
                return reducer;
            }, accumulator);
        }
        return accumulator;
    }, payload);
};

const doWorkNormalizedMedian = (data: Array<any>, baseKey : string, lang: string, parameters: any, descriptors: Array<any>, drilledMethod: string, payload: any, routines: Array<any>): any => {
    const [baseline, ...rest] = data;
    baseline.values = baseline.values.map((item: any) => {
        item.values = item.values.map((object: any) => {
            object.value[baseKey] =  0;
            return object;
        });
        return item;
    });
    return [baseline, ...rest].reduce((accumulator: any, item: any, index: number) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            item.values.reduce((reducer: { categories: object, series: object }, object: any, fIndex: number) => {
                const keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_')
                accumulator.categories[keyLabel] = item.attribute;
                
                object.values.reduce((acc: any, drilledObject: any, volIndex : number) => {
                    const keySerie = [Chart.getObjectValueTranslation(drilledObject.label, lang), Chart.getObjectValueTranslation(object.attribute.label, lang)].join('_');
                    const nameSerie = [Chart.getObjectValueTranslation(drilledObject.label, lang), Chart.getObjectValueTranslation(object.attribute.label, lang)].join(' ');
                    acc[keySerie] = (acc[keySerie] || { 
                        name: nameSerie,
                        data: [], 
                        color: Chart.drilledColors[volIndex],
                        dashStyle : Chart.dashStyles[fIndex]
                    });
                    (acc[keySerie].data = (acc[keySerie].data || [])).push({
                        y: Number(drilledObject.value[baseKey]),
                        x: Object.keys(accumulator.categories).indexOf(keyLabel)
                    });
                    return acc;
                }, accumulator.series);

                // Add scatters for medians
                const keyMedian = ['median', Chart.getObjectValueTranslation(object.attribute.label, lang)].join("_");
                const nameMedian = ['median', Chart.getObjectValueTranslation(object.attribute.label, lang)].join(' ');

                reducer.series[keyMedian] = (accumulator.series[keyMedian] || { 
                    name: nameMedian, 
                    data: [],
                    type: 'scatter', 
                    zIndex: 4, 
                    color: Chart.colorScale[fIndex] 
                });
                (reducer.series[keyMedian].data = (reducer.series[keyMedian].data || [])).push({
                    y: median(Chart.castValuesToNumber(object.values, baseKey), baseKey),
                    x: Object.keys(accumulator.categories).indexOf(keyLabel),
                    details: object.values
                });
                return reducer;
            }, accumulator);
        }
        return accumulator;
    }, payload);
};

const doWork = (data: Array<any>, baseKey : string, lang: string, parameters: any, descriptors: Array<any>, drilledMethod: string, payload: any, routines: Array<any>): any => {
    switch (drilledMethod) {
        case null:
            break;
        case 'median':
            return doWorkMedian(data, baseKey, lang, parameters, descriptors, drilledMethod, payload, routines);
        case 'normalized-median':
            return doWorkNormalizedMedian(data, baseKey, lang, parameters, descriptors, drilledMethod, payload, routines);
    }
};

const median = (array: Array<any>, key: string) => {
    array = array.filter(x => x.hasOwnProperty(key) && x[key] !== null);
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a[key] - b[key]);
    return array.length % 2 !== 0 ? nums[mid][key] : (nums[mid - 1][key] + nums[mid][key]) / 2;
};

export {
    doWork
}