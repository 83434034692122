import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dna-confirm-publish-modal',
  templateUrl: './confirm-publish-modal.component.html',
  styleUrls: ['./confirm-publish-modal.component.less']
})
export class ConfirmPublishModalComponent {

  @Input() loopAndNoVisits: {
    idWorkflow: string,
    workflowName: string,
    loopAndNoVisite: boolean
  }[];
  @Input() type: string;

  confirmationMessage: string = '';

  constructor(
    private activeModal: NgbActiveModal
    ) { }

  onCancel() {
    this.activeModal.dismiss();
  }

  onValidate() {
    this.activeModal.close(true);
  }

}
