<ngx-loading [show]="showSpinner"></ngx-loading>
<div>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "TARGET" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div *ngIf="targetHead.length > 0" class="table-target">
        <table class="table table-bordered" [ngStyle]="{'width': widthTable + 'px'}">
          <thead>
            <tr>
              <th class="fixed-column"></th>
              <th *ngFor="let head of targetHead; let index = index;" style="width:200px">
                {{ targetTraduction[head] | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="campaignTarget.length > 0">
              <tr *ngFor="let ct of campaignTarget">
                <th id="td_{{ct.id}}" class="fixed-column studies-background" title="{{ ct.name }}">{{ ct.name }}</th>
                <ng-container *ngFor="let head of targetHead; let indexHead = index;">
                  <td id="td_{{ct.id}}_{{indexHead}}">
                    {{ formatValueHead(ct.target[head]) }}
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div *ngIf="!error && targetHead.length == 0">{{ "TARGETS_CONFIG_ERROR" | translate }}</div>
    </div>
  </div>

  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "PROTOCOL" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div *ngIf="!error && campaignTarget.length > 0">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="th-protocol">{{ "CAMPAIGNS" | translate }}</th>
              <th>{{ "PROTOCOL" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let campaign of campaignTarget">
              <td>{{ campaign.name }}</td>
              <td>
                <div *ngIf="targetProtocol[campaign.idOnePager]; else emptyProtocol"
                  [innerHTML]="targetProtocol[campaign.idOnePager].protocol"></div>
                <ng-template #emptyProtocol></ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "VOLUNTEERS" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div *ngIf="!error && campaignTarget.length > 0">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{ "CAMPAIGNS" | translate}}</th>
              <th class="th-volunteer">{{ "ONE_PAGER_VOLUNTEERS_TITLE" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let campaign of campaignTarget">
              <td>{{ campaign.name }}</td>
              <td class="center">
                {{ targetProtocol[campaign.idOnePager] ? targetProtocol[campaign.idOnePager].volunteersNumber : 0 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="!showSpinner && !error && isRoutines" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ROUTINES" | translate }}</h5>
        </div>
      </div>
    </div>
    <div *ngIf="analyseType !== 'CAMPAIGNS_COMPARISON_BARE_SKIN'" class="ibox-content">
      <div class="row">
        <div class="col-12">
          <table class="table-bordered couple-table mx-auto">
            <tr>
              <td class="td-padding" rowspan=2>
                <span translate>CAMPAIGN</span>
              </td>
              <td colspan="2"><span translate>ONE_PAGER_TESTED</span></td>
              <td colspan="3"><span translate>ONE_PAGER_REFERENCE</span></td>
            </tr>
            <tr>
              <td class="td-padding"><span translate>NAME_STUDY</span></td>
              <td class="td-padding"><span translate>NAME_STUDY_MULTI</span></td>
              <td class="td-padding"><span translate>NAME_STUDY</span></td>
              <td class="td-padding"><span translate>NAME_STUDY_MULTI</span></td>
              <td class="td-padding"><span translate>NAME_COMBINED</span></td>
            </tr>
            <tr *ngFor="let couple of tab.couples; let index = index">
              <td class="td-padding">{{ couple.campaignName }}</td>
              <td class="td-padding">{{ couple.tested }}</td>
              <td class="td-padding">{{ onePagerService.getRoutineMultiName(index) }}</td>
              <td class="td-padding">{{ couple.reference }}</td>
              <td class="td-padding">{{ onePagerService.getRoutinePrime(index) }}</td>
              <td *ngIf="index === 0" [attr.rowspan]="tab.couples.length" class="td-padding">RM2</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row pt-2">
        <div class="table-responsive col-12 col-md-6">
          <table class="table table-bordered">
            <tr>
              <th colspan="6" class="table-primary protocol-th">
                <span translate>ONE_PAGER_TESTED</span>
              </th>
            </tr>
            <tr>
              <th class="table-secondary" translate>ROUTINE</th>
              <th class="table-secondary" translate>CAMPAIGN</th>
              <th class="table-secondary" translate>ROUTINE_VISIT</th>
              <th class="table-secondary" translate>FORMULA</th>
              <th class="table-secondary" translate>ROUTINE_BATCH</th>
              <th class="table-secondary" translate>SOCLE</th>
            </tr>
            <tr *ngFor="let tested of tab.testedTab; let i = index">
              <td>{{ onePagerService.getRoutineName(tested.routineName, tested.campaignName, tab) }}
                <ng-container *ngIf="tested.routineLabel"> - {{ tested.routineLabel }}</ng-container>
              </td>
              <td>{{ tested.campaignName }} </td>
              <td>{{ tested.visitName }} </td>
              <td>{{ tested.formulaName | translate }} </td>
              <td>
                <input class="form-control" type="text" name="tested-lot" disabled="true" value="{{tested.lot}}">
              </td>
              <td>
                <input class="form-control" type="text" name="tested-socle" disabled="true" value="{{tested.socle}}">
              </td>
            </tr>
          </table>
        </div>
        <div class="table-responsive col-12 col-md-6">
          <table class="table table-bordered">
            <tr>
              <th colspan="6" class="table-primary protocol-th">
                <span translate>ONE_PAGER_REFERENCE</span>
              </th>
            </tr>
            <tr>
              <th class="table-secondary" translate>ROUTINE</th>
              <th class="table-secondary" translate>CAMPAIGN</th>
              <th class="table-secondary" translate>ROUTINE_VISIT</th>
              <th class="table-secondary" translate>FORMULA</th>
              <th class="table-secondary" translate>ROUTINE_BATCH</th>
              <th class="table-secondary" translate>SOCLE</th>
            </tr>
            <tr *ngFor="let reference of tab.referenceTab; let i = index">
              <td>{{ onePagerService.getRoutineName(reference.routineName, reference.campaignName, tab) }}
                <ng-container *ngIf="reference.routineLabel"> - {{ reference.routineLabel }}</ng-container>
              </td>
              <td>{{ reference.campaignName }} </td>
              <td>{{ reference.visitName }} </td>
              <td>{{ reference.formulaName | translate}}</td>
              <td>
                <input class="form-control" type="text" name="reference-lot" disabled="true" value="{{reference.lot}}">
              </td>
              <td>
                <input class="form-control" type="text" name="reference-socle" disabled="true"
                  value="{{reference.socle}}">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="analyseType === 'CAMPAIGNS_COMPARISON_BARE_SKIN'" class="ibox-content">
      <div class="row pt-2">
        <div class="table-responsive col-12">
          <table class="table table-bordered">
            <tr>
              <th class="table-secondary" translate>ROUTINE</th>
              <th class="table-secondary" translate>CAMPAIGN</th>
              <th class="table-secondary" translate>ROUTINE_VISIT</th>
              <th class="table-secondary" translate>FORMULA</th>
              <th class="table-secondary" translate>ROUTINE_BATCH</th>
              <th class="table-secondary" translate>SOCLE</th>
            </tr>
            <tr *ngFor="let routine of routines; let i = index">
              <td> {{ routine.routineName }}
                <ng-container *ngIf="routine.routineLabel"> - {{ routine.routineLabel }}</ng-container>
              </td>
              <td>{{ routine.campaignName }} </td>
              <td>{{ routine.visitName }} </td>
              <td>{{ routine.formulaName }}</td>
              <td>
                <input class="form-control" type="text" name="routine-lot" disabled="true" value="{{routine.lot}}">
              </td>
              <td>
                <input class="form-control" type="text" name="routine-socle" disabled="true" value="{{routine.socle}}">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!showSpinner && !error && !isRoutines" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "FORMULAS" | translate }}</h5>
        </div>
      </div>
    </div>
    <div *ngIf="analyseType !== 'CAMPAIGNS_COMPARISON_BARE_SKIN'" class="ibox-content">
      <table class="table-bordered couple-table">
        <tr>
          <td class="table-secondary td-padding" colspan="3">
            <span translate>STUDIED_COUPLES</span>
          </td>
        </tr>
        <tr>
          <td class="td-padding">
            <span translate>STUDIES</span>
          </td>
          <td class="td-padding">
            <span translate>ONE_PAGER_TESTED</span>
          </td>
          <td class="td-padding">
            <span translate>ONE_PAGER_REFERENCE</span>
          </td>
        </tr>
        <tr *ngFor="let couple of tab.couples">
          <td class="td-padding">
            <span translate>{{ couple.campaignName }}</span>
          </td>
          <td class="td-padding">{{ couple.tested }}</td>
          <td class="td-padding">{{ couple.reference }}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="analyseType === 'CAMPAIGNS_COMPARISON_BARE_SKIN'" class="ibox-content">
      <table class="table-bordered couple-table">
        <tr>
          <td class="td-padding">
            <span translate>STUDIES</span>
          </td>
          <td class="td-padding">
            <span translate>FORMULAS</span>
          </td>
        </tr>
        <tr *ngFor="let ct of campaignTarget">
          <td class="td-padding">
            <span translate>{{ ct.name }}</span>
          </td>
          <td class="td-padding">
            {{ getFormulaByCampaign(ct.id, ct.formula.listFormulas) }}
          </td>
        </tr>
      </table>
    </div>
  </div>


  <div *ngIf="error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>

</div>
