<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "PROTOCOL" | translate }} - {{ "METIER_SKIN" | translate }}</h5>
      </div>
      <div class="col-12 col-md-8">
        <div class="float-end d-flex flex-row gap-1" *ngIf="isChangedProtocol && !publishedTemplate">
          <button class="btn btn-primary mr-2" type="button" (click)="onSubmit(protocolForm)" translate>SAVE</button>
          <button class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="protocolForm" class="ibox-content">
    <form [formGroup]="protocolForm">
      <div class="row">
        <div class="col-12 mb-3">
          <h4 translate>PROTOCOL_DESCRIPTION</h4>
          <textarea id="label-description" class="form-control" formControlName="description" placeholder="{{ 'ENTER_DESCRIPTION' | translate }}"
            name="textarea-description" rows="3"></textarea>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_PRODUCTS_TYPES</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="studies" [onlyFromAutocomplete]="true" name="tag-input-studies"
            displayBy="value" identifyBy="key" placeholder="+ {{ 'PROTOCOL_PRODUCTS_TYPES' | translate }}"
            [disable]="publishedTemplate" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'PROTOCOL_PRODUCTS_TYPE' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="studies" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_TEST_TYPE</h4>
          <select id="label-test" class="form-control" formControlName="test" name="select-test" [compareWith]="compareKeyValue">
            <option *ngFor="let test of tests" [ngValue]="test">{{ test.value }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate class="required">METHOD</h4>
          <tag-input inputClass="tagInputMethod" formControlName="method" [onlyFromAutocomplete]="true" name="tag-input-methods"
            displayBy="value" identifyBy="key" placeholder="+ {{ 'METHOD' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'METHOD' | translate | lowercase } }}"
            [disable]="publishedTemplate" [ngClass]="{ 'isinvalid': getStatus('method') }" required>
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="methods" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 [ngClass]="{'required': this.campaign.actiview ? !!this.campaign.actiview.activityNumber : false}" translate>PROTOCOL_STUDY_TYPE</h4>
          <select id="label-study_type" class="form-control" formControlName="studyType" name="select-study_type" [compareWith]="compareCode"
          [ngClass]="{ 'isinvalid': getStatus('studyType') }"  required>
            <option *ngFor="let studyType of studyTypes" [ngValue]="studyType">{{ studyType.name[languageMap[currentLanguage]] || studyType.name['en'] }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 class="required" translate>ESTIMATED_CONTRIBUTION</h4>
          <input required class="form-control" type="number" min="0" step="0.5" formControlName="estimatedContribution" name="estimatedContribution"
          placeholder="{{ 'ENTER_ESTIMATED_CONTRIBUTION' | translate }}" [ngClass]="{ 'is-invalid': protocolForm.controls.estimatedContribution.errors?.required }">
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>RESEARCH_AXES</h4>
          <select id="label-research-axes" class="form-control" formControlName="researchAxes" name="select-research-axes" [compareWith]="compareCode">
            <option *ngFor="let axe of researchAxes" [ngValue]="axe">{{ axe.name[languageMap[currentLanguage]] || axe.name['en'] }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_METHODS</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="protocol_methods" [onlyFromAutocomplete]="true" name="tag-input-protocol_methods"
            displayBy="value" identifyBy="key" placeholder="+ {{ 'PROTOCOL_METHOD' | translate }}"
            [disable]="publishedTemplate" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'PROTOCOL_METHOD' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="protocol_methods" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_APPLICATION_AREAS</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="applicationAreas" [onlyFromAutocomplete]="true"
            name="tag-input-application-areas" displayBy="value" identifyBy="key" placeholder="+ {{ 'PROTOCOL_APPLICATION_AREA' | translate }}"
            [disable]="publishedTemplate" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_APPLICATION_AREA' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="applicationAreas" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>APPLICATION_MODES</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="applicators" displayBy="value" identifyBy="key" [onlyFromAutocomplete]="true"
            name="tag-input-application-modes" placeholder="+ {{ 'APPLICATION_MODE' | translate }}"
            [disable]="publishedTemplate" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'APPLICATION_MODE' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="applicationModes" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
      </div>
    </form>
  </div>
</div>
