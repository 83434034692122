<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <ng-container *ngIf="currentTraining">
    <dna-table [buttons]="listButtons$ | async" [data]="currentTraining.assessments" [headers]="tableHeaders$ | async" orderedBy="NAME"
      [reverse]="true" [sortFunction]="sort" [title]="'ASSESSMENTS'" (buttonAction)="buttonAction($event, currentTraining)">
      <ng-template #row let-data="data">
        <tr dna-assessment-row class="cursor-pointer" [assessment]="data" [training]="currentTraining"></tr>
      </ng-template>
    </dna-table>
  </ng-container>
</div>
