import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, AfterViewInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Languages } from '../../enums';

@Component({
  selector: '[dna-table-raw-data]',
  templateUrl: './table-raw-data.component.html',
  styleUrls: ['./table-raw-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableRawDataComponent implements AfterViewInit {
  @ViewChild('table', { read: ElementRef }) table: ElementRef;

  @Input() data: { thead: Array<any>, tbody: Array<any> };
  @Input() lang: string;
  @Input() title: string;

  public Languages = Languages;

  public body : Array<any>;
  public head : Array<any>;

  constructor(private ref: ChangeDetectorRef) { };

  ngOnChanges(changes: SimpleChanges) {
    this.ref.detectChanges();
    this.body = this.duplicateRowspansRows(JSON.parse(JSON.stringify(this.data.tbody))) ;
    this.head = this.duplicateRowspansRows(JSON.parse(JSON.stringify(this.data.thead))) ;
  };

  ngAfterViewInit() { this.ref.detectChanges(); };

  /**
   * duplicateRowspansRows
   * Method to handle rawspans > 1 for body (rows).
   * It duplicates cells neatly that have rowspan > 1 into next n rows
   * @param rows { Array<any> }
   * @return {Array<any>}
   */
  private duplicateRowspansRows = (rows: Array<any>): Array<any> => {
    return rows.reduce((accumulator: any, row: Array<any>, index: number) => {
      for (const [cellIndex, cell] of Array.from(row).entries()) {
        if (cell.rowspan > 1) {
          for (let [rowIndex, c] of new Array(cell.rowspan - 1).fill(cell).entries()) {
            let copiedCell = JSON.parse(JSON.stringify(c));
            copiedCell.rowspan = 1;
              accumulator[index + rowIndex + 1].splice(cellIndex, 0, copiedCell);
          }
        }
      }
      return accumulator;
    }, rows);
  };

  /**
   * isNumber
   * Check if value is a number or not.
   * It's useful within DOM/UI to center the current value.
   * @param value : any;
   * @return {boolean} // True if value is a number;
  */
  public isNumber = (value: any): boolean => !isNaN(parseInt(value));

  /**
   * _has
   * Check if object has declare property
   * @param object {object}
   * @param property { string }
   * @return {boolean};
   */
  public _has = (object: object, property: string): boolean => object && object.hasOwnProperty(property);

};
