
import {of as observableOf, empty as observableEmpty, 
  Observable
} from 'rxjs';

import {finalize, catchError, map, filter, tap, merge, mergeMap} from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';

import * as _ from 'lodash';
import {
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';

import {
  CampaignStates,
  Type,
  User,
  Training
} from '../../../../types';
import {
  TrainingService
} from '../../../training.service';
import {
  DNATranslateService
} from '../../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  UserService
} from '../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../shared/services/util.service';

@Component({
  selector: 'dna-training-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.less']
})
export class TrainingGeneralInformationComponent implements OnInit {

  training: Training = new Training();
  trainingOriginal: Training = new Training();
  trainingStates: typeof CampaignStates = CampaignStates;
  dateFormat: string;

  isCreation: boolean = false;
  showSpinner: boolean = false;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  submitted: boolean = false;
  typeCampaigns: Type[];
  typeEvaluations: Type[];

  constructor(
    private trainingService: TrainingService,
    private errorManager: ErrorManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionUser: UserService,
    private translationService: DNATranslateService,
    private utilService: UtilService
  ) { }

  getParam = (params: ParamMap): string => params.get('idTraining');

  isEgal = (param: any) => (data: any) => param === data;

  isNotEgal = (param: any) => (data: any) => param != data;

  initVariableTraining = (training: Training) => {
    this.trainingOriginal = training;
    this.training = _.cloneDeep(training);
  }

  ngOnInit() {
    this.dateFormat = this.translationService.getDateFormat();
    this.isCreation = false;
    let idTraining = this.route.parent.paramMap.pipe(map(this.getParam));
    let newTraining = idTraining.pipe(filter(this.isEgal('new')),
      tap(() => this.isCreation = true),
      map(() => this.training),);

    let existingTraining = idTraining.pipe(filter(this.isNotEgal('new')),
      map(this.trainingService.getTrainingFromLocalStorage),);

    existingTraining.pipe(merge(newTraining),
      tap(this.initDate),
      tap(this.initVariableTraining),).subscribe();
  }

  isEditDisabled(training: Training) {
    return training.state !== CampaignStates.Draft && training.state !== CampaignStates.Suspended;
  }

  byId(a: Type, b: Type): boolean {
    if (!b) return false;
    return a.id === b.id;
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.training, this.trainingOriginal);
  }

  clickCancel() {
    this.training = _.cloneDeep(this.trainingOriginal);
    if (this.isCreation)
      this.router.navigate(['trainings']);

    this.trainingService.setTrainingFromLocalStorage(this.training);
  }

  initDate = (training: Training) => {
    if (training.startDate) {
      let date = new Date(training.startDate)
      this.startDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
  }

  onCreateOrUpdateSuccess = (training: Training) => {
    this.trainingService.setTrainingFromLocalStorage(training);
    this.errorManager.displayMessage(this.isCreation ? 'ON_SUCCESS_CREATE' : 'ON_SUCCESS_UPDATE');
    this.router.navigate(['trainings', training.id, 'edit', 'workflows']);
    this.training = this.trainingOriginal;
    this.utilService.updateMenuTabs();
  }

  onDateChanged(date: NgbDateStruct, input: string) {
    this.training[input] = new Date(Date.UTC(date.year, date.month - 1, date.day)).getTime();
  }

  onErrorRequest = (err: Response) => {
    this.showSpinner = false;
    this.errorManager.catchError(err);
    return observableEmpty();
  }

  saveAndUpdate(form: NgForm) {
    this.submitted = true;
    if (form.valid) {
      this.showSpinner = true;
      let user = this.sessionUser.getUser();
      this.training.createdBy = {
        user: user.track
      };
      // this.training.timeZone = new Date().getTimezoneOffset();
      let onCreate = observableOf(this.isCreation).pipe(
        filter(this.isEgal(true)),
        map(() => this.training),
        mergeMap(this.trainingService.createTraining),)

      let onUpdate = observableOf(this.isCreation).pipe(
        filter(this.isEgal(false)),
        map(() => this.training),
        mergeMap(this.trainingService.putTraining),)

      onCreate.pipe(merge(onUpdate),
        catchError(this.onErrorRequest),
        finalize(() => this.showSpinner = false),
        tap(this.onCreateOrUpdateSuccess),)
        .subscribe()
    } else {
      this.errorManager.displayMessage('ON_ERROR_FORM', "danger");
    }
  }

}
