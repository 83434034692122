import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ChartType } from '../../../../types';
import { ChartTypeService } from '../../../../shared/services/chartType.service';
import { DNATranslateService } from '../../../../shared/services/translate.service';

@Component({
  selector: 'dna-modal-extend-edit-blocks',
  templateUrl: './modal-extend-edit-blocks.component.html',
  styleUrls: ['./modal-extend-edit-blocks.component.css']
})
export class ModalExtendEditBlocksComponent implements OnInit {
  @Input() block: any;
  chartTypes: ChartType[];
  editBlockForm: FormGroup;
  languages: string[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private DNATranslate: DNATranslateService,
    private formBuilder: FormBuilder,
    private myChartType: ChartTypeService
  ) { }

  ngOnInit() {
    if (!this.block) {
      this.activeModal.dismiss();
    }
    else {
      this.DNATranslate.getLanguages().then(languages => this.languages = languages);
      this.chartTypes = this.myChartType.getChartType();
      this.initialiseBlock(this.block);
    }
  }

  cancel() {
    this.activeModal.dismiss();
  };

  initialiseBlock(block: any) {
    this.editBlockForm = this.formBuilder.group({
      id: block.id,
      name: block.name,
      chartType: block.chartType,
      chartName: this.formBuilder.group(block.chartName)
    });
  }

  onSubmit(form: FormGroup) {
    this.activeModal.close(form.value);
  }

}
