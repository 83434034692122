
import {finalize} from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { Answer, Assessment, TableHeader, Training, Workflow } from '../../../../../types';
import { AssessmentsService } from '../assessments.service';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { ModalEditAnswerComponent } from '../../../../../campaigns/detail/edit-campaign/raw-data/detail/edit-answer/modal-edit-answer.component';
import { TrainingService } from '../../../../training.service';
import { UtilService } from '../../../../../shared/services/util.service';

@Component({
  selector: 'dna-assessment-detail',
  templateUrl: './assessment-detail.component.html',
  styleUrls: ['./assessment-detail.component.less']
})
export class AssessmentDetailComponent implements OnInit {

  assessment: Assessment;
  assessmentId: string;
  containsPhotos: boolean = false;
  currentLanguage: string;
  showSpinner: boolean = true;
  training: Training = new Training();
  trainingId: string;
  workflow: Workflow = new Workflow();

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  tableHeaders$: Observable<TableHeader[]>;

  constructor(
    private assessmentService: AssessmentsService,
    private DNATranslate: DNATranslateService,
    private modalService: NgbModal,
    public trainingService: TrainingService,
    private route: ActivatedRoute,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
      this.tableHeaders$ = this.utilService.createHeadersDetailAssessment();
    });
    this.route.parent.paramMap.subscribe((parentParams: ParamMap) => {
      this.trainingId = parentParams.get("idTraining");
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.assessmentId = params.get("idAssessment");
        this.trainingService.getAssessmentPerId(this.trainingId, this.assessmentId).pipe(
          finalize(() => this.showSpinner = false))
          .subscribe(success => {
            this.assessment = success;
            this.assessment.answers = this.addIndexInAnswers(this.assessment.answers);
            this.training = this.trainingService.getTrainingFromLocalStorage(this.trainingId);
            this.tableHeaders$ = this.utilService.createHeadersDetailAssessment();
            this.workflow = this.training.workflows.find((workflow: Workflow) => workflow.id === this.assessment.idWorkflow);
            this.containsPhotos = this.trainingContainsPhotos(this.training);
            this.mapUsers(this.training);
          },
          error => this.showSpinner = false
          )
      });
    });
  }

  addIndexInAnswers(answers: any[]) {
    return answers.map((answer, index) => {
      answer.index = index;
      return answer;
    });
  }

  mapUsers(training: Training) {
    this.assessment.users = training.assessments.find((assessment: Assessment) => assessment.id === this.assessmentId).users;
    this.assessment.users = this.utilService.addNamesToUsers(this.assessment.users, this.trainingService.users);
  }

  sort(answers: Answer[], headerId: string, reverse: boolean) {
    switch (headerId) {
      case "INDEX":
        return this.utilService.sortListByType(answers, "index", reverse);
      case "LABEL":
        return this.utilService.sortListByType(answers, "label", reverse, true, this.currentLanguage);
    }
  }

  openModal(answerIndex: number) {
    const modal = this.modalService.open(ModalEditAnswerComponent, this.modalOption);
    modal.componentInstance.answer = this.assessment.answers[answerIndex];
    modal.componentInstance.currentLanguage = this.currentLanguage;
    modal.result.then(
      (answerUpated: Answer) => {
        this.showSpinner = true;
        this.assessment.answers[answerIndex] = answerUpated;

        this.assessmentService.updateAssessmentBlocks(this.assessmentId, this.assessment)
          .subscribe(success => this.showSpinner = false);
      },
      (reason) => { }
    );
  }

  trainingContainsPhotos(training: Training) {
    for (let wk of training.workflows) {
      for (let block of wk.blocks) {
        for (let component of block.components) {
          if (component.type === "dna-photo") return true;
        }
      }
    }
    return false;
  }

}
