import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Campaign } from '../../../../../../types';

@Component({
  selector: 'dna-upload-capture-modal',
  templateUrl: './upload-capture-modal.component.html',
  styleUrls: ['./upload-capture-modal.component.less']
})
export class UploadCaptureModalComponent implements OnInit {
  @Input() capturePowerBI: string;
  showSpinner: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  compareID(c1: Campaign, c2: Campaign): boolean {
    return (c1 && c2) ? c1.id === c2.id : false;
  }

  onSubmit(capture: string) {
    this.activeModal.close(capture);
  }

}
