<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "FORMULAS_ROUTINES" | translate }}</h5>
      </div>
    </div>
    <div class="float-end">
      <ng-container *ngIf="isChange">
        <button id="save" class="btn btn-primary" type="button"
          (click)="save(chosenBench, chosenRoutines, chosenFormulas, nbRoutines)" translate>SAVE</button>
        <button id="cancel" class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
      </ng-container>
    </div>
  </div>
  <div class="ibox-content">
    <div *ngIf="!error && !showSpinner">
      <div class="form-group">
        <div class="row">
          <label class="col-12 col-md-3 my-auto form-control-label">{{ 'ANALYSE_TYPE' | translate }}</label>
          <div class="col-12 col-md-9">
            <select class="form-control" [(ngModel)]="analyseType" name="analyseTypeSelection"
              (ngModelChange)="onChangeAnalyseType()">
              <option *ngFor="let analyseType of listAnalyseTypes" [ngValue]="analyseType">{{ analyseType | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="analyseType === 'CAMPAIGNS_COMPARISON_BARE_SKIN'">
          <div *ngIf="!isRoutines && chosenFormulas && chosenFormulas.length > 0" class="table-responsive">
            <table class="table table-hover table-borderless-th dataTable">
              <thead>
                <tr>
                  <th translate>FORMULAS</th>
                  <th translate>CAMPAIGN</th>
                  <th translate>IS_ACTIVE</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let campaign of chosenFormulas; let indexCampaign = index">
                  <tr *ngFor="let formula of campaign.formulas; let indexFormula = index">
                    <td>{{ getFormulaName(campaign.idCampaign, formula.id)}}</td>
                    <td>{{ getCampaignName(campaign.idCampaign)}}</td>
                    <td>
                      <dna-switch [isSelected]="formula.isActive" (onBooleanChanged)="changeFormula(chosenFormulas, indexCampaign, indexFormula)"></dna-switch>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      <div class="form-group" *ngIf="analyseType === 'CAMPAIGNS_COMPARISON'">
        <div>
          <span>
            {{ "BENCH_MULTI_COMPARISON" | translate }}
          </span>
        </div>
        <div class="row">
          <span class="col-md-3 offset-3">{{ "BENCH" | translate }} {{ isRoutines ? "R'2": "" }}</span>
        </div>
        <ng-container *ngFor="let cf of campaignFormulas; let indexRoutine = index">
          <div class="row">
            <label class="col-12 col-md-3 bold">{{ cf.name |  slice:0:30 }}</label>
            <div class="col-12 col-md-5">
              <select id="listBench" class="form-control" name="listBench_{{cf.id}}"
                [(ngModel)]="chosenBench[cf.id]['bench']"
                (ngModelChange)="onChangeValue(cf, chosenBench[cf.id]['bench'], indexRoutine)">
                <ng-container *ngFor="let formulaRoutine of routineFormulas[cf.id]; let index = index">
                  <option *ngIf="formulaRoutine.name" [ngValue]="formulaRoutine.id">
                    {{ isRoutines ? ("ROUTINE" | translate) : ("FORMULA" | translate) }} {{ index+1 }}:
                    {{ formulaRoutine.label ? formulaRoutine.label : formulaRoutine.name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="form-group" *ngIf="analyseType === 'CAMPAIGNS_CONCATENATION'">
        <div class="row bold">
          {{ isRoutines ? ("BENCH_MULTI_CONCATENATION_ROUTINES" | translate) : ("BENCH_MULTI_CONCATENATION_FORMULAS" | translate) }}
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-12 col-md-3 my-auto form-control-label required">
              {{ isRoutines ? ("MULTI_CAMPAIGNS_NB_ROUTINES" | translate) : ("MULTI_CAMPAIGNS_NB_FORMULAS" | translate) }}
              :</label>
            <div class="col-12 col-md-9">
              <select class="form-control" required [(ngModel)]="nbRoutines"
                (ngModelChange)="isChange = true" name="nbRoutines">
                <option *ngFor="let item of nbRoutinesTab" [ngValue]="item" translate>{{ item }}</option>
              </select>
            </div>
          </div>
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th></th>
              <ng-container *ngIf="isRoutines; else isFormulas">
                <th *ngFor="let head of [].constructor(nbRoutines); let index = index;"
                  (click)="onclickHead(index)" [ngClass]="{'head-success' : isActive(index), 'pointer-cursor': true }">
                  {{ "ROUTINE" | translate}} {{ index + 1 }}</th>
              </ng-container>
              <ng-template #isFormulas>
                <th *ngFor="let head of [].constructor(nbRoutines); let index = index;">
                  {{ "FORMULA" | translate}} {{ index + 1 }}</th>
              </ng-template>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cf of campaignFormulas">
              <th title="{{ cf.name }}">{{ cf.name |  slice:0:30 }}</th>
              <td *ngFor="let head of [].constructor(nbRoutines); let index = index;">
                <select id="listBench" class="form-control" name="listBench_{{cf.id}}"
                  [(ngModel)]="chosenRoutines[cf.id]['R'+(index+1)]"
                  (ngModelChange)="onChangeValue(cf, chosenRoutines[cf.id]['R'+(index+1)], index)">
                  <ng-container *ngFor="let formulaRoutine2 of routineFormulas[cf.id]; let index = index">
                    <option *ngIf="formulaRoutine2.name" [ngValue]="formulaRoutine2.id">
                      {{ isRoutines ? ("ROUTINE" | translate) : ("FORMULA" | translate) }} {{ index+1 }}:
                      {{ formulaRoutine2.label ? formulaRoutine2.label : formulaRoutine2.name }}
                    </option>
                  </ng-container>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="isRoutines">
      <div *ngIf="activesRoutinesTable.length > 0; else noRoutinesToDisplay" class="table-routines">
        <table class="table table-bordered table_fixed" [ngStyle]="{'width': widthTable + 'px'}">
          <thead>
            <tr>
              <th class="first-column">{{ "CAMPAIGNS" | translate }}</th>
              <th *ngFor="let routine of activesRoutinesTable" style="width:200px">{{ routine.campaignName }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="first-column">{{ "ROUTINE" | translate }}</th>
              <td *ngFor="let routine of activesRoutinesTable">{{routine.name}}</td>
            </tr>
            <ng-container *ngFor="let visit of routinesComparisonTable.visits">
              <tr *ngIf="isShown(visit.visitName)">
                <th class="first-column">{{ "VISITS" | translate }}</th>
                <td *ngFor="let routine of activesRoutinesTable">{{ visit.visitName[routine.campaignId] }}</td>
              </tr>
              <ng-container *ngFor="let order of visit.orders">
                <tr class="routine-step" *ngIf="isShown(order.orderName)">
                  <th class="first-column">{{ "ORDER" | translate }}</th>
                  <td *ngFor="let routine of activesRoutinesTable">{{ order.orderName[routine.campaignId] }}</td>
                </tr>
                <ng-container *ngFor="let step of order.steps">
                  <tr *ngIf="isShown(step.stepName)">
                    <th class="first-column inside-step">{{ "STEP" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">{{ step.stepName[routine.campaignId] }}</td>
                  </tr>
                  <tr *ngIf="isShown(step.productName)">
                    <th class="first-column inside-step">{{ "PRODUCT_NAME" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">{{ step.productName[routine.campaignId] }}</td>
                  </tr>
                  <tr *ngIf="isShown(step.formulaName)">
                    <th class="first-column inside-step">{{ "FORMULA" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">{{ step.formulaName[routine.campaignId] }}</td>
                  </tr>
                  <tr *ngIf="isShown(step.quantity)">
                    <th class="first-column inside-step">{{ "ROUTINE_QUANTITY" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">{{ step.quantity[routine.campaignId] }}</td>
                  </tr>
                  <tr *ngIf="isShown(step.lot)">
                    <th class="first-column inside-step">{{ "ROUTINE_BATCH" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">{{ step.lot[routine.campaignId] }}</td>
                  </tr>
                  <tr *ngIf="isShown(step.pauseTime)">
                    <th class="first-column inside-step">{{ "ROUTINE_PAUSE_TIME" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">
                      <ng-container
                        *ngIf="!step.pauseTime[routine.campaignId].isOldFormat; else pauseTimeNumber">
                        {{ step.pauseTime[routine.campaignId]?.hour || 0 }} :
                        {{ step.pauseTime[routine.campaignId]?.minute || 0 }} :
                        {{ step.pauseTime[routine.campaignId]?.second || 0 }}
                      </ng-container>
                      <ng-template #pauseTimeNumber>
                        {{ step.pauseTime[routine.campaignId].oldValue }}
                      </ng-template>
                    </td>
                  </tr>
                  <tr *ngIf="isShown(step.applicators)">
                    <th class="first-column">{{ "APPLICATION_MODE" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">
                      {{ step.applicators[routine.campaignId] }}
                    </td>
                  </tr>
                  <tr *ngIf="isShown(step.dryingTypes)">
                    <th class="first-column">{{ "DRYING_TYPE" | translate }}</th>
                    <td *ngFor="let routine of activesRoutinesTable">
                      {{ step.dryingTypes[routine.campaignId] }}
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
      <ng-template #noRoutinesToDisplay>
        <div class="row">
          <label class="col-12">{{ "NO_ROUTINES_TO_DISPLAY" | translate }}</label>
        </div>
      </ng-template>
    </div>
    </div>
    <dna-error-page *ngIf="error && !showSpinner" (onClickReload)="init()"></dna-error-page>
  </div>
