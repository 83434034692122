<dna-layout>
  <h1 class="mb-4" id="banner-title" translate>VOLUNTEERS</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item active" translate>LIBRARY</li>
      <li class="breadcrumb-item active" aria-current="page" translate>VOLUNTEERS</li>
    </ol>
  </nav>
  <div class="block">
    <div>
      <h3 translate>ARCS_VOLUNTEER</h3>
      <div class="ibox float-e-margins">
        <div class="ibox-content">
          <div class="row">
            <div class="form-group col-9">
              <div class="row">
                <label class="col-12 col-md-4 my-auto col-form-label">
                  {{ "PROJECTS.PROJECT_ARCS_VOLUNTEER" | translate }}
                </label>
                <div class="col-12 col-md-8 input-group">
                  <div class="input-group-prepend" ngbDropdown>
                    <button class="form-control btn btn-outline-secondary" type="button" ngbDropdownToggle>
                      {{ country }}
                    </button>
                    <div ngbDropdownMenu>
                      <ng-container *ngFor="let country of countries">
                        <a ngbDropdownItem (click)="setArcsSystemFromCountry(country)" translate>{{ country }}</a>
                      </ng-container>
                    </div>
                  </div>
                  <input class="form-control" [(ngModel)]="idVolunteer" type="text" name="arcs">
                  <div class="input-group-append">
                    <button class="form-control btn btn-outline-secondary" type="button"
                      (click)="getArcsVolunteer(idVolunteer, hub)" translate>EDIT_VOLUNTEER</button>
                  </div>
                  <ngx-loading [show]="arcsLoading" [config]="{ fullScreenBackdrop: false }">
                  </ngx-loading>
                </div>
                <div *ngIf="arcsIncorrect" class="offset-1 text-center invalid-feedback" style="display: block">
                  <p translate> PROJECTS.INCORRECT_PROJECT_ARCS </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ibox float-e-margins">
        <div class="ibox-title cursor-pointer row noMarginSide"
          (click)="isCollapsedSearchFilters = !isCollapsedSearchFilters">
          <h5 class="col-md-6" translate>SEARCH_VOLUNTEER</h5>
          <div class="ibox-tools col-md-6">
            <a>
              <i *ngIf="isCollapsedSearchFilters; else isNotCollapsedSearchFilters" class="fa fa-chevron-down"></i>
              <ng-template #isNotCollapsedSearchFilters><i class="fa fa-chevron-up"></i></ng-template>
            </a>
          </div>
        </div>
        <div class="ibox-content" id="serachFilters" [ngbCollapse]="isCollapsedSearchFilters">
          <div>
            <h5 class="fTitle" translate> FILTERS </h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="form-group col-12">
                <div class="d-flex flex-row gap-4 mb-4">
                  <div class="col-8 mt-2">
                    <dna-filter-list [array]="countries" [horizontalAlignment]="true" [filterValue]="filter.hub">
                    </dna-filter-list>
                  </div>
                  <div ngbDropdown>
                    <button class="btn btn-outline-secondary dropdown-toggle buttonFiche" type="button" ngbDropdownToggle
                      name="fiche" aria-haspopup="true" aria-expanded="false">{{ filter.fiche | translate }}</button>
                    <div ngbDropdownMenu class="dropdownFiche">
                      <ng-container *ngFor="let timelinetype of timelineTypes">
                        <a ngbDropdownItem (click)="filter.fiche = timelinetype" translate>{{ timelinetype }}</a>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group input-pad">

                    <div class="input-group-volunteer">

                      <input class="form-control input-group-volunteer-filtres" placeholder="N°Synergy" [(ngModel)]="filter.requestNumber" type="text"name="bridge">
                      <div class="input-group-volunteer-arcs">
                        <input class="form-control input-group-volunteer-arcs-filtres" placeholder="N° Volontaire ARCS" [(ngModel)]="filter.panelistNumber" type="text" name="arcs">
                        <input class="form-control input-group-volunteer-arcs-filtres" placeholder="N° Etudes ARCS" [(ngModel)]="filter.studyNumber" type="text" name="arcs">
                      </div>
                      <input class="form-control input-group-volunteer-filtres" placeholder="Orchestra" [(ngModel)]="filter.orchestra" type="text" name="orchestra">

                      <div class="mb-3 my-lg-auto input-group-volunteer-date">
                        <dna-filter-date class="filter-date" [dateFilterStart]="filter.startDate" [dateFilterEnd]="filter.endDate"
                          (updateDateStart)="updateDate($event, 'startDate')"
                          (updateDateEnd)="updateDate($event, 'endDate')">
                        </dna-filter-date>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="input-group input-pad">
                    <div class="center-button mt-4">
                      <button class="btn btn-primary mb-4" type="button" (click)="searchPanelist()"
                        translate>SEARCH</button>
                      <button class="btn btn-primary mb-4" type="button" (click)="initFilter()" translate>RESET</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="panelistsData.length > 0">
            <dna-table2 [data]="panelistsData" [headers]="panelistTableHeaders$ | async"
              [numberOfObjectsPerPage]="filter.limit" [pageIndex]="filter.page" [reverse]="true"
              [sortFunction]="sortPanelist" [sortable]="true" [title]="'PANELISTS'" [totalItems]="totalPanelists"
              (parametersChanged)="onPanelistsParametersChanged($event)">
              <ng-template #row let-data="data">
                <tr dna-panelist-row [panelist]="data" (onClickPanelist)="goToPanelist(data)"
                  (onClickType)="goToFiche(data)"></tr>
              </ng-template>
            </dna-table2>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="user.currentWorkspace.preferences?.displayNonArcsVolunteers">
      <h3 translate>VOLUNTEER_HORS_ARCS</h3>
      <div *ngIf="user.currentWorkspace.preferences?.displayNonArcsVolunteers">
        <a class="btn btn-primary mb-4 text-white" (click)="openModalGroupeVolunteer()">
          <span translate>CREATE_GROUP_VOLUNTEERS</span>
        </a>
      </div>

      <div class="ibox float-e-margins">
        <div class="ibox-content" *ngIf="currentWorkspace.preferences?.displayNonArcsVolunteers">
          <div class="row">
            <div class="form-group col-9">
              <div class="row">
                <label class="col-12 col-md-4 my-auto col-form-label">
                  {{ "VOLUNTEER_HORS_ARCS" | translate }}
                </label>
                <div class="col-12 col-md-8 input-group">
                  <div class="input-group-prepend" ngbDropdown>
                    <button class="form-control btn btn-outline-secondary" type="button" ngbDropdownToggle>
                      {{ countryHA }}
                    </button>
                    <div ngbDropdownMenu>
                      <ng-container *ngFor="let country of countries">
                        <a ngbDropdownItem (click)="setArcsSystemFromCountryHA(country, hubHA, groupVolunteerHA, formHorsArcsGroupHA, groupVolunteersHA)" translate>{{ country }}</a>
                      </ng-container>
                    </div>
                  </div>
                  <tag-input inputClass="" class="form-control form-control-input"
                    displayBy="groupName" identifyBy="groupName" name="groupVolunteerToAdd" [onlyFromAutocomplete]="false"
                    [(ngModel)]="groupVolunteerHA" maxItems=1 [(inputText)]="formHorsArcsGroupHA" secondaryPlaceholder="{{'GROUP_NAME_LABEL' | translate}}">
                  <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="groupVolunteersHA" displayBy="groupName" identifyBy="groupName" [minimumTextLength]="1"
                    [showDropdownIfEmpty]="false" limitItemsTo=5>
                  </tag-input-dropdown>
                </tag-input>
                  <input class="form-control" [(ngModel)]="numberVolunteerHorsArcs" type="text" name="horsArcs" placeholder="{{'NUMBER' | translate}}">
                  <div class="input-group-append">
                    <button class="form-control btn btn-outline-secondary" type="button"
                      (click)="getHorsArcsVolunteer(numberVolunteerHorsArcs, hubHA, groupVolunteerHA, formHorsArcsGroupHA)" translate>EDIT_VOLUNTEER</button>
                  </div>
                  <ngx-loading [show]="horsArcsLoading" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
                </div>
                <div *ngIf="horsArcsIncorrect" class="offset-1 text-center invalid-feedback" style="display: block">
                  <p translate> INCORRECT_VOLUNTEER_HORS_ARCS_NUMBER </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Group Hors Arcs -->
      <div class="ibox float-e-margins" *ngIf="currentWorkspace.preferences?.displayNonArcsVolunteers">
          <div class="ibox-content">
            <dna-edit-group-horsarcs-row [countriesIn]="countries" [hubIn]="hub" [user]="user"></dna-edit-group-horsarcs-row>
          </div>
        </div>
      </div>

      <div class="ibox float-e-margins" *ngIf="currentWorkspace.preferences?.displayNonArcsVolunteers">
        <div class="ibox-title cursor-pointer row noMarginSide"
          (click)="isCollapsedSearchFiltersHA = !isCollapsedSearchFiltersHA">
          <h5 class="col-md-6" translate>SEARCH_GROUP_VOLUNTEER_HORS_ARCS</h5>
          <div class="ibox-tools col-md-6">
            <a>
              <i *ngIf="isCollapsedSearchFiltersHA; else isNotCollapsedSearchFiltersHA" class="fa fa-chevron-down"></i>
              <ng-template #isNotCollapsedSearchFiltersHA><i class="fa fa-chevron-up"></i></ng-template>
            </a>
          </div>
        </div>
        <div class="ibox-content" id="serachFilters" [ngbCollapse]="isCollapsedSearchFiltersHA">
          <div>
            <h5 class="fTitle" translate> FILTERS </h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="form-group col-12">
                <div class="row mb-4">
                  <div class="col-8 mt-2">
                    <dna-filter-list [array]="countries" [horizontalAlignment]="true" [filterValue]="filter.hub">
                    </dna-filter-list>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group input-pad">
                    <div class="input-group-prepend">
                      <input class="form-control filtres" placeholder="Nom du groupe" [(ngModel)]="filterHA.groupName" type="text"
                        name="horsarcs name">
                    </div>
                    <div class="mb-3 my-lg-auto">
                      <dna-filter-date [dateFilterStart]="filterHA.startDate" [dateFilterEnd]="filterHA.endDate"
                        (updateDateStart)="updateDateHA($event, 'startDate')"
                        (updateDateEnd)="updateDateHA($event, 'endDate')">
                      </dna-filter-date>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group input-pad">
                    <div class="center-button mt-4">
                      <button class="btn btn-primary mb-4" type="button" (click)="searchGroupPanelistHA()" translate>SEARCH</button>
                      <button class="btn btn-primary mb-4" type="button" (click)="initFilterHA()" translate>RESET</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="groupPanelistsHAData.length > 0">
            <dna-table2 [data]="groupPanelistsHAData" [headers]="groupPanelistHATableHeaders$ | async"
              [numberOfObjectsPerPage]="filterHA.limit" [pageIndex]="filterHA.page" [reverse]="true"
              [sortFunction]="sortGroupPanelistHA" [sortable]="true" [title]="'GROUP_VOLUNTEER_HORS_ARCS'" [totalItems]="totalPanelistsHA"
              (parametersChanged)="onGroupPanelistsHAParametersChanged($event)">
              <ng-template #row let-data="data">
                <tr dna-group-panelist-horsarcs-row [panelist]="data" (onClickGroupPanelistHA)="goToGroupPanelistHA(data)"></tr>
              </ng-template>
            </dna-table2>
          </ng-container>
        </div>
      </div>

    <div class="ibox float-e-margins">
      <ngx-loading [show]="showSpinner"></ngx-loading>
    </div>
  </div>
</dna-layout>
