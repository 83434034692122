<div class="container">
  <div class="ibox float-e-margins">
    <div class="ibox-content">
      <form [formGroup]="contextForm">
        <div class="pull-right d-flex flex-row gap-1">
          <button *ngIf="!isChanged" id="init" class="btn btn-primary mb-4" type="button"
          (click)="reinitForm()" translate>RESET</button>
          <button id="save" class="btn btn-primary mb-4" type="button" (click)="save(contextForm, false)" translate>SAVE</button>
          <button *ngIf="isCancelAvailable" id="cancel" class="btn btn-default mb-4" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
        <div class="pt-3 form-group mb-3">
          <h3 translate>ONE_PAGER_CONTEXT</h3>
            <rich-text-wrapper spellcheck="false" formControlName="context" placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></rich-text-wrapper>
        </div>
        <div class="pt-3 form-group">
          <h3 translate>OBJECTIVES</h3>
            <rich-text-wrapper spellcheck="false" formControlName="objectives"placeholder="{{ 'ENTER_TEXTAREA' | translate }}" ></rich-text-wrapper>
        </div>
        <div class="pt-3 form-group">
          <h3 translate>ONE_PAGER_KPA</h3>
          <div class="row">
            <div class="col-12" formGroupName="kpa">
              <table class="table table-borderless">
                <tr>
                  <th scope="row" class="align-middle table-kpa">KPA</th>
                  <td>
                    <textarea class="form-control" formControlName="attributes" name="textarea-kpa-attributes" rows="5"
                      placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></textarea>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="align-middle table-categories">Category attributes</th>
                  <td>
                    <textarea class="form-control" formControlName="categories" name="textarea-kpa-categories" rows="5"
                      placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></textarea>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="align-middle table-musthave">Must haves</th>
                  <td><textarea class="form-control" formControlName="mustHaves" name="textarea-kpa-must-haves" rows="5"
                      placeholder="{{ 'ENTER_TEXTAREA' | translate }}"></textarea>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
