<div class="ibox float-e-margins ibox-position">
  <ngx-loading [show]="showSpinner" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
  <div class="ibox-title">
    <h5>{{ "CAMPAIGNS" | translate }} - {{ (user.currentWorkspace.name ? user.currentWorkspace.name : "ALL") | translate }}</h5>
  </div>
  <div class="ibox-content">
    <div class="row">
      <div class="col-12 col-md-6 my-auto">
        <dna-checkbox [isChecked]="filter.areMyStudies" sideLabel="left" text="MY_CAMPAIGNS" (click)="filter.areMyStudies = !filter.areMyStudies; onFilterChanged()"></dna-checkbox>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <tag-input [inputClass]="'tagInputDashboard'" [(ngModel)]="filter.users" (ngModelChange)="onFilterChanged()"
          displayBy="name" identifyBy="key" [disable]="filter.areMyStudies || usersToDisplay.length === 0" [maxItems]="usersToDisplay.length"
          [onlyFromAutocomplete]="true" placeholder="+ {{ 'USER' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { 'object': 'USER' | translate | lowercase } }}">
          <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="usersToDisplay" displayBy="name" identifyBy="key" [keepOpen]="false"
            [showDropdownIfEmpty]="true"></tag-input-dropdown>
        </tag-input>
      </div>
      <div class="col-12">
        <dna-filter-list [array]="hubs" [filterValue]="filter.hubs" [horizontalAlignment]="true" title="HUBS" (updateFilter)="onFilterChanged()"></dna-filter-list>
      </div>
      <div class="col-12">
        <dna-checkbox [isChecked]="filter.displayDemandsNotLinked" sideLabel="left" text="DISPLAY_DEMANDS_NOT_LINKED" (click)="filter.displayDemandsNotLinked = !filter.displayDemandsNotLinked; onFilterChanged()"></dna-checkbox>
      </div>
    </div>
  </div>
  <div id="ibox-Campaigns" class="ibox-content text-center">
    <div class="row">
      <div class="col-12 col-md-6">
        <svg viewBox="-1 -1 2 2" height="250px" width="250px" style="transform: rotate(-90deg);">
          <path class="green1" [ngClass]="{ 'pathhover': hover1 }" [attr.d]="utilService.pathProgress(campaignsToPrepare.length / campaignNumbers)"></path>
          <path class="green2" [ngClass]="{ 'pathhover': hover2 }" [attr.d]="utilService.pathProgress((campaignsToPrepare.length + campaignsReady.length) / campaignNumbers, campaignsToPrepare.length / campaignNumbers)"></path>
          <path class="green3" [ngClass]="{ 'pathhover': hover3 }" [attr.d]="utilService.pathProgress((campaignsToPrepare.length + campaignsReady.length + campaignsInProgress.length) / campaignNumbers, (campaignsToPrepare.length + campaignsReady.length) / campaignNumbers)"></path>
          <path class="green4" [ngClass]="{ 'pathhover': hover4 }" [attr.d]="utilService.pathProgress((campaignsToPrepare.length + campaignsReady.length + campaignsInProgress.length + campaignsFinished.length) / campaignNumbers, (campaignsToPrepare.length + campaignsReady.length + campaignsInProgress.length) / campaignNumbers)"></path>
          <path class="green5" [ngClass]="{ 'pathhover': hover5 }" [attr.d]="utilService.pathProgress(1, (campaignsToPrepare.length + campaignsReady.length + campaignsInProgress.length + campaignsFinished.length) / campaignNumbers)"></path>
          <path class="green6" [ngClass]="{ 'pathhover': hover6 }" [attr.d]="utilService.pathProgress(1, (campaignsToPrepare.length + campaignsReady.length + campaignsInProgress.length + campaignsAbandoned.length) / campaignNumbers)"></path>
        </svg>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group">
          <li class="list-group-item green1" (mouseenter)="hover1 = true" (mouseleave)="hover1 = false" (click)="goTo(filter, [campaignStates.Draft, campaignStates.Suspended])">
            {{ campaignsToPrepare.length }}
            <span translate>PREPARED</span>
          </li>
          <li class="list-group-item green2" (mouseenter)="hover2 = true" (mouseleave)="hover2 = false" (click)="goTo(filter, [campaignStates.Published])">
            {{ campaignsReady.length }}
            <span translate>READY</span>
          </li>
          <li class="list-group-item green3" (mouseenter)="hover3 = true" (mouseleave)="hover3 = false" (click)="goTo(filter, [campaignStates.Published])">
            {{ campaignsInProgress.length }}
            <span translate>PROGRESS</span>
          </li>
          <li class="list-group-item green4" (mouseenter)="hover4 = true" (mouseleave)="hover4 = false" (click)="goTo(filter, [campaignStates.Finished])">
            {{ campaignsFinished.length }}
            <span translate>FINISHED_FIELD</span>
          </li>
          <li class="list-group-item green5" (mouseenter)="hover5 = true" (mouseleave)="hover5 = false" (click)="goTo(filter, [campaignStates.Archived])">
            {{ campaignsArchived.length }}
            <span translate>ARCHIVED</span>
          </li>
          <li class="list-group-item green6" (mouseenter)="hover6 = true" (mouseleave)="hover6 = false" (click)="goTo(filter, [campaignStates.Abandoned])">
            {{ campaignsAbandoned.length }}
            <span translate>ABANDONED</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- <div class="ibox float-e-margins ibox-position">
  <dna-table [data]="synergyDemands" [headers]="tableHeaders$ | async" [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage"
    orderedBy="ID" [pageIndex]="filter.pageIndex" [reverse]="false" [sortFunction]="sort" [title]="'BRIDGE_DEMANDS'" (parametersChanged)="onParametersChanged($event)">
    <ng-template #row let-data="data">
      <tr dna-campaign-view-row [synergyDemand]="data" [users]="campaignMetadata.users" (onClick)="onClickActionButton($event, data, campaignsAll, usersToDisplay)"></tr>
    </ng-template>
  </dna-table>
</div> -->
