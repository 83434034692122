
import {finalize} from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { CampaignStates, Training, ViewGroups, ViewType, ViewTypes } from '../../../../types';
import { ConfigureCustomizeModalComponent } from '../../../../campaigns/detail/edit-campaign/parameters/configure-customize/configure-customize-modal.component';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { TrainingService } from '../../../training.service';
import { UtilService } from '../../../../shared/services/util.service';

@Component({
  selector: 'dna-training-parameters',
  templateUrl: './training-parameter.component.html',
  styleUrls: ['./training-parameter.component.less']
})
export class TrainingParameterComponent implements OnInit {

  description: string;
  isPublished: boolean;
  showSpinner: boolean = false;
  training: Training = new Training();
  trainingOriginal: Training = new Training();
  trainingStates: typeof CampaignStates = CampaignStates;
  viewGroups: typeof ViewGroups = ViewGroups;
  viewTypes: typeof ViewTypes = ViewTypes;

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  constructor(
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private trainingService: TrainingService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.training = this.trainingService.getTrainingFromLocalStorage(params.get("idTraining"));
      this.updateDescription();
      this.isPublished = this.training.state === this.trainingStates.Published;
      this.trainingOriginal = _.cloneDeep(this.training);
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.training, this.trainingOriginal);
  }

  changeViewGroup(view: ViewGroups) {
    this.training.parameters.viewGroup = view;
  }

  clickTypeview(mode: ViewType, description: string) {
    if (!this.training.parameters.viewGroup)
      this.training.parameters.viewGroup = ViewGroups.FormulaGroup;

    this.training.parameters.viewType = mode;
    this.description = description;
    this.training.workflows = _.cloneDeep(this.trainingOriginal.workflows);
  }

  configureCustomizeMode() {
    const modal = this.modalService.open(ConfigureCustomizeModalComponent, this.modalOption);
    modal.componentInstance.object = this.training;
    modal.result.then(training => {
      this.training = training;
    }, (reason) => {
    });
  }

  onCancel() {
    this.training = _.cloneDeep(this.trainingOriginal);
    this.trainingService.setTrainingFromLocalStorage(this.training);
  }

  onChangeData(value: any, object: any, path: string) {
    object[path] = value;
  }

  save() {
    this.showSpinner = true;
    if (this.training.parameters.viewType !== ViewTypes.ModeList)
      delete this.training.parameters.viewGroup;

    this.trainingService.putTraining(this.training).pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(training => {
        this.errorManager.displayMessage("ON_SUCCESS_UPDATE");
        this.trainingService.setTrainingFromLocalStorage(training);
        this.training = _.cloneDeep(training);
        this.trainingOriginal = _.cloneDeep(training);
        this.router.navigate(['trainings', training.id, 'edit', 'publish']);
      }, error => this.errorManager.catchError(error));
  }

  updateDescription() {
    if (this.training.parameters.viewType === ViewTypes.ModeList) {
      this.description = "LABORATOIRE_DESCRIPTION";
    } else if (this.training.parameters.viewType === ViewTypes.ModeCard) {
      this.description = "CARD_DESCRIPTION";
    } else if (this.training.parameters.viewType === ViewTypes.ModeDualCard) {
      this.description = "DUAL_DESCRIPTION";
    } else {
      this.description = "CUSTOMIZE_DESCRIPTION";
    }
  }

}
