
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, finalize, mergeMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import * as _ from 'lodash';

import { CampaignStates, Training } from '../../../../types';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { TrainingService } from '../../../training.service';
import { UtilService } from '../../../../shared/services/util.service';

@Component({
  selector: 'dna-training-publication',
  templateUrl: './training-publication.component.html',
  styleUrls: ['./training-publication.component.less']
})
export class TrainingPublicationComponent implements OnInit {

  training: Training = new Training();
  trainingStates: typeof CampaignStates = CampaignStates;
  showSpinner: boolean = false;
  date: any;
  
  constructor(
    private trainingService: TrainingService,
    private errorManager: ErrorManagerService,
    private route: ActivatedRoute,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.date = new Date().getTime();
    this.route.parent.paramMap
      .subscribe((params: ParamMap) => this.training = this.trainingService.getTrainingFromLocalStorage(params.get("idTraining")));
  }

  isValid(training: Training) {
    return training && training.users && training.users.accountables && training.users.accountables.length > 0
      && training.workflows && training.workflows.length > 0 && training.formula.listFormulas
      && training.formula.listFormulas.length > 0 ? true : false;
  }

  publish(training: Training) {
    if (this.isValid(training)) {
      this.showSpinner = true;
      let dataToSend: Training = _.cloneDeep(this.training);
      dataToSend.state = this.trainingStates.Published;

      this.trainingService.putTraining(dataToSend).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(trainingWithAssessments => {
          this.training.state = this.trainingStates.Published;
          this.training.assessments = trainingWithAssessments.assessments;
          this.trainingService.setTrainingFromLocalStorage(trainingWithAssessments);
          this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        },
        error => {
          this.errorManager.displayMessage('UNKNOW_ERROR', 'danger');
        });
    }
    else {
      this.errorManager.displayMessage('PUBLISH_ERROR_TRAINING', 'danger');
    }
  }

  suspend() {
    this.utilService.translateMessageModal('SUSPEND_TRAINING', '', '').pipe(
      mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.suspendTraining(this.training)),)
      .subscribe(() => this.onSuccessUpdateState(this.training, CampaignStates.Suspended));
  }

  suspendTraining(training: Training) {
    this.showSpinner = true;
    let trainingToSend: Training = _.cloneDeep(this.training);
    trainingToSend.state = this.trainingStates.Suspended;
    return this.trainingService.putTraining(trainingToSend).pipe(
    finalize(() => this.showSpinner = false),
    catchError(error => {
      this.errorManager.catchError(error);
      return observableThrowError(error);
    }),)
  }

  onSuccessUpdateState(training: Training, state: CampaignStates) {
    this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
    training.state = state;
    this.trainingService.setTrainingFromLocalStorage(training);
  }

}
