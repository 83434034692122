export class TreeNode {

  rowspan: number;
  parent: TreeNode;
  children: Array<TreeNode>;
  data: any;
  directLine: number;
  id: string;
  branchId: string;

  constructor(data: any, parent: any, index: string, branchId) {
    this.rowspan = 0;
    this.parent = parent;
    this.children = [];
    this.data = data;
    this.directLine = 0;
    this.id = `${index}-${this.data.label}`;
    const childrenLength = data.size || data.length;
    if (data.children || childrenLength > 0) {
      data.children.forEach((child: TreeNode, index) => {
        const newChild = new TreeNode(child, this, `${this.id}-${index}`, `${branchId}-${index}`);
        this.children.push(newChild);
      })
    } else {
      this.branchId = `${branchId}-${index}`;
    };
  }

  count() {
    if (this.children.length > 0) {
      this.children.forEach(child => {
        child.count();
      })
    } else {
      this.rowspan = 1;
      this.parent.up(1);
    }
  }

  up(directLine) {
    this.rowspan++;
    directLine = this.children.length === 1 ? directLine : 0;
    this.directLine = directLine;
    if (this.parent) this.parent.up(directLine + 1);
  }

  createTable(table: any, headerSet) {
    // CREATE CURRENT 
    const current = {
      value: this.data,
      rowspan: this.rowspan,
      id: this.id,
      branchId: this.branchId,
      hidden: null,
      class: this.getClasses(),
      color: this.data.props && this.data.props.color
    };
    try {
      if( headerSet && headerSet.length ) {
        for (const headerRow of headerSet) {
          for (const cel of headerRow) {
            if ([current.value.parentLabel.label.id, current.value.parentLabel.label.label].join('-') == cel.id) {
              current.hidden = current.hidden ? current.hidden : cel.hidden;
            }
          }
        }
      }
    } catch (e) { console.error(e) }
    // ADD CURRENT TO TABLE BODY
    table.tbody[table.tbody.length - 1].push(current);
    // SET THE HEADER IF NOT DONE YET
    if (!headerSet) {
      if (!table.thead.has(current.value.parentLabel)) {
        // VARIABLES FOR NESTED LOOP
        let temp = current.value.parentLabel;
        let tempHeaderTree = table.thead;
        do {
          // ADD HEADER IN MAP
          if (!tempHeaderTree.has(temp.label.id)) tempHeaderTree.set(temp.label.id, {
            id: temp.label.id,
            label: temp.label.label,
            children: new Map(),
          });
          // CHANGE VARIABLES FOR NEXT ITERATION
          tempHeaderTree = tempHeaderTree = temp.children ? (temp.children.label.id === temp.label.id ? tempHeaderTree : tempHeaderTree.get(temp.label.id).children) : null;
          temp = temp.children;
        } while (temp);

      }
    }
    // CREATE CHILDREN TABLE
    if (this.children.length > 0) {
      // TEMP VARIABLE TO DETERMINE IF CREATED BEFORE (TO AVOID MULTIPLE CELLS)
      let created = false;
      this.children.forEach(child => {
        // IF ALREADY CREATED ADD ANOTHER ARRAY TO THE BODY
        if (created) table.tbody.push([]);
        // SET CREATED TO TRUE (OBVIOUSLY)
        created = true;
        // SEND TABLE TO CHILD TO CONTINUE TABLE CREATION
        child.createTable(table, headerSet);
      })
    }
  }

  order(index, asc) {
    // IF INDEX 0 THEN SORT DIRECT CHILDREN
    if (index === 0) {
      this.children = this.children.sort((a, b) => {
        if (typeof a.data.value === "string" || typeof b.data.value === "string") {
          if (a.data.value === null) return asc ? 1 : -1;
          if (b.data.value === null) return asc ? -1 : 1;
          return asc ? a.data.value.localeCompare(b.data.value) : b.data.value.localeCompare(a.data.value);
        } else {
          return asc ? a.data.value - b.data.value : b.data.value - a.data.value;
        }
      });
      return;
    }

    // OTHERWISE NEED TO DETERMINE THE MIN DIRECTLINE AMONG ALL LINES (SUCCESSIVE COLUNM WITH ONLY ONE CHILD)
    const directLine = this.children.reduce((minLine: null | number, child) => {
      if (minLine === null) return child.directLine;
      if (minLine > child.directLine) return child.directLine;
      return minLine;
    }, null);

    // IF NO DIRECTLINE FOUND THEN CALL CHILDREN ORDER METHOD
    if (directLine === 0) {
      this.children.forEach(child => child.order(index - 1, asc));
      return;
    }

    // IF IN SCOPE THEN ORDER CURRENT COLUMN DEPENDING ON CHILDREN VALUES
    if (directLine > index - 1) {
      this.children = this.children.sort((a, b) => {
        const aValue = a.searchDirectChildValue(index);
        const bValue = b.searchDirectChildValue(index);
        if (typeof aValue === "string" || typeof bValue === "string") {
          if (aValue === null) return asc ? 1 : -1;
          if (bValue === null) return asc ? -1 : 1;
          return asc ? aValue.toString().localeCompare(bValue) : bValue.toString().localeCompare(aValue);
        } else {
          return asc ? aValue - bValue : bValue - aValue;
        }
      });
      return;
    }

    // IF NOT IN SCOPE JUMP TO END OF DIRECTLINE AND REDO PROCESS
    const childrenNode = this.getChildrenFrom(directLine);
    childrenNode.forEach(child => child.order(index - directLine, asc));

  }

  checkLabel(label, directLineOffset) {
    if (directLineOffset < 0) return -1;
    if (this.data.label === label) return directLineOffset;
    else return this.children[0].checkLabel(label, directLineOffset - 1);
  }

  getValue() {
    return this.data.value;
  }

  getChildrenFrom(directLineOffset) {
    if (directLineOffset === 0) return this.children;
    return this.children.reduce((array, child) => {
      return [...array, ...child.getChildrenFrom(directLineOffset - 1)]
    }, []);
  }


  searchDirectChildValue(directLineOffset) {
    if (directLineOffset === 0) return this.getValue();
    return this.children[0].searchDirectChildValue(directLineOffset - 1);
  }

  public calculateDepth(depth) {
    if (!this.children || this.children.length === 0) {
      return depth;
    }
    let maxDepth = depth;
    this.children.forEach(child => {
      let childDepth = child.calculateDepth(depth + 1);
      if (childDepth > maxDepth) maxDepth = childDepth;
    });

    return maxDepth;
  }

  public createHeader(header, orderHeader, currentDepth, maxDepth, headerIndexTrack) {
    // CREATE HEADER
    if (!header[currentDepth - 1]) header[currentDepth - 1] = [];
    const newHeader = {
      id: this.id,
      label: this.data.label,
      colspan: this.rowspan,
      rowspan: 1,
      order: null,
      headerIndex: null,
    };
    header[currentDepth - 1].push(newHeader);
    // GIVE SORT FUNCTIONNALITY IF LAST CHILD
    if (!this.children || this.children.length === 0) {
      newHeader.order = 0;
      // PUSH IN ORDERHEADER ARRAY FOR TRACK PURPOSE
      orderHeader.push(newHeader);
      newHeader.headerIndex = headerIndexTrack.index;
      headerIndexTrack.index++;
      // CHECK FOR ROWSPAN
      if (maxDepth > currentDepth) {
        const rowspan = maxDepth - currentDepth + 1;
        newHeader.rowspan = rowspan;
      }
    } else {
      this.children.forEach(child => {
        child.createHeader(header, orderHeader, currentDepth + 1, maxDepth, headerIndexTrack);
      })
    }
  }

  /**
   * get cell classes
   */
  private getClasses(): string {
    const set: Set<string> = new Set();
    this.getClass(this.data.parentLabel, set);
    return [...set].join(" ");
  }

  /**
   * retriver single classe from headers data
   * @param data data to get class
   * @param set set of classnames
   */
  private getClass(data: any, set: Set<string>): void {
    const currentClass = data.label.other.class;
    if(currentClass) set.add(currentClass);
    if(data.children) {
      this.getClass(data.children, set);
    };
  }
}

