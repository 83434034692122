<div class="modal-header">
  <h4 class="modal-title" translate>IMPORT_EXCEL</h4>
</div>
<div class="modal-body">
  <ngx-loading [show]="showSpinner" [config]="{fullScreenBackdrop: false}">
    <span translate>LOADING</span>
  </ngx-loading>
  <form>
    <div class="form-group">
      <label for="excelInput" translate>IMPORT</label>
      <input type="file" (change)="onFileChange($event)" class="form-control-file" name="excelInput" accept=".xlsx">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="save()" translate>VALIDATE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
