import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'dna-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.less']
})
export class AuthComponent implements OnInit {

  constructor(
    private router: Router,
    private msalService: MsalService
  ) {}

  ngOnInit(): void {
    console.log('je check active account');
    if (this.msalService.instance.getActiveAccount()) {
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, 1000);
    } else {
      console.log('pas dactive account');
      this.msalService.logoutRedirect().subscribe();
    }
  }
}

