<div class="modal-header">
  <h4 class="modal-title">
    <span translate>DEMAND </span>{{ synergyDemand.id }}
  </h4>
  <button class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only">Close</span>
  </button>
</div>
<div class="modal-body">
  <div class="ibox-title">
    <h5 translate>PRIORITY</h5>
  </div>
  <div class="ibox-content">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div *ngFor="let priority of priorities">
      <div class="input-group my-2">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input [ngModel]="prioritySynergy" (ngModelChange)="addPriorityToSynergyDemand($event)" type="radio" [value]="priority" aria-label="Radio buttons for choosing priority">
          </div>
        </div>
        <label class="form-control" aria-label="Label with radio button">{{ priority }}</label>
      </div>
    </div>
  </div>
  <div class="ibox-title">
    <h5 translate>CAMPAIGN_REFERENT</h5>
  </div>
  <div class="ibox-content">
    <tag-input inputClass="tagInputEditSynergyUser" [(ngModel)]="synergyDemand.referent" [displayBy]="'name'" [identifyBy]="'key'"
      (onAdd)="onAddReferent($event)" [onAdding]="pickReferentProperties" [onRemoving]="onRemovingReferent" [onlyFromAutocomplete]="true"
      name="editUser" placeholder="" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { 'object': 'CAMPAIGN_REFERENT' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="users" [displayBy]="'name'" [identifyBy]="'key'" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
  <div class="ibox-title">
    <h5 translate>CAMPAIGN_CONTRIBUTORS</h5>
  </div>
  <div class="ibox-content">
    <tag-input inputClass="tagInputEditSynergyUser" [(ngModel)]="synergyDemand.contributors" [displayBy]="'name'" [identifyBy]="'key'"
      [onAdding]="pickContributorProperties" [onRemoving]="onRemovingContributor" [onlyFromAutocomplete]="true" name="editUser"
      placeholder="+ {{ 'CONTRIBUTOR' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { 'object': 'CONTRIBUTOR' | translate | lowercase } }}">
      <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="users" [displayBy]="'name'" [identifyBy]="'key'" [keepOpen]="false"
        [showDropdownIfEmpty]="true"></tag-input-dropdown>
    </tag-input>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="save(synergyDemand)" translate>SAVE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>
