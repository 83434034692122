import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DNATranslateService } from '../../../../../shared/services/translate.service';

@Component({
    selector: 'dna-errors-import-excel-modal',
    templateUrl: './errors-import-excel-modal.component.html',
    styleUrls: ['./errors-import-excel-modal.component.less']
})

export class ErrorsImportExcelModalComponent implements OnInit {

    @Input() title: string;
    @Input() errorsTab: any[];

    currentLanguage: string;

    constructor(
        private activeModal: NgbActiveModal,
        private DNATranslate: DNATranslateService
    ) { }

    ngOnInit() {
        this.currentLanguage = this.DNATranslate.getLanguage();
     }

    cancel() {
        this.activeModal.dismiss();
    }

    validate() {
        this.activeModal.close();
    }

}
