<ngx-loading [show]="showSpinner"></ngx-loading>
<div *ngIf="!showSpinner">
  <h1 class="mb-4" id="banner-title" translate>
    CONFIGURE_BLOCK</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/workflows" translate>LIBRARY_OF_WORKFLOWS</a>
      </li>
      <li class="breadcrumb-item" [ngClass]="{'active': workflow.id == undefined}">
        <a [routerLink]="['/workflows', workflow.id]">
          <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
        </a>
      </li>
      <li class="breadcrumb-item active">
        <dna-translate [fieldToTranslate]="block.name"> </dna-translate>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>CONFIGURE</li>
    </ol>
  </nav>
  <div class="actions" id="banner-actions">
    <button class="btn btn-primary mb-4" type="button" (click)="validate(parameterForm)" translate>VALIDATE</button>
    <button class="btn btn-default mb-4" type="button" (click)="cancel()" translate>CANCEL</button>
  </div>
  <div class="block">
    <div *ngIf="inactive" style="text-align:center; color:red;" translate> WORKFLOW_IS_PUBLISHED </div>
    <label class="col-sm-12"></label>
    <div class="ibox float-e-margins">
      <div class="ibox-title row noMarginSide" aria-controls="blockName" (click)="isCollapsedBlockName = !isCollapsedBlockName">
        <h5 class="col-md-6">
          <span translate>TRANSLATE_BLOCK </span>
        </h5>
        <div class="ibox-tools col-md-6">
          <a>
            <i *ngIf="isCollapsedBlockName; else isNotCollapsedBlockName" class="fa fa-pencil"></i>
            <ng-template #isNotCollapsedBlockName><i class="fa fa-times"></i></ng-template>
          </a>
        </div>
      </div>
      <div class="ibox-content" [ngbCollapse]="isCollapsedBlockName" id="blockName">
        <form role="form" [formGroup]="blockForm">
          <div class="table-responsive">
            <table class="table table-borderless-th">
              <thead>
                <tr>
                  <th translate>LANGUAGE</th>
                  <th translate>NAME</th>
                  <th translate>DESCRIPTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let language of languages">
                  <td>
                    <label translate>{{language}}</label>
                  </td>
                  <td formGroupName="name">
                    <input class="form-control" type="text" formControlName="{{language}}">
                  </td>
                  <td formGroupName="description">
                    <input class="form-control" type="text" formControlName="{{language}}">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>

    <form #parameterForm="ngForm">
      <div class="ibox float-e-margins">
        <div class="ibox-title row noMarginSide">
          <h5 class="col-md-6">
            <span translate>PARAMETERS </span>
          </h5>
          <div class="ibox-tools col-md-6">
            <a aria-controls="blockParameters" (click)="isCollapsedBlockParameter = !isCollapsedBlockParameter">
              <i *ngIf="isCollapsedBlockParameter; else isNotCollapsedBlockParameter" class="fa fa-pencil"></i>
              <ng-template #isNotCollapsedBlockParameter><i class="fa fa-times"></i></ng-template>
            </a>
          </div>
        </div>
        <div class="ibox-content" id="blockParameters" [ngbCollapse]="isCollapsedBlockParameter">
          <div class="form-group row" style="padding-top: 10px">
            <label class="control-label col-sm-2" style="text-align:left;" translate>DELAY_START_WK</label>
            <div class="col-sm-4">
              <input class="form-control" [disabled]="indexBlock == 0 || inactive" type="number" name="delayStart"
                step="1" min="0" [(ngModel)]="block.parameters.delayStart">
            </div>
            <label class="control-label col-sm-2" style="text-align:left;" translate>DELAY_END_WK</label>
            <div class="col-sm-4">
              <input class="form-control" [disabled]="inactive" type="number" name="delayEnd" step="1" min="0"
                [(ngModel)]="block.parameters.delayEnd">
            </div>
          </div>
          <dna-podometer-auto-config [block]="block" [blocks]="workflow.blocks" podometerType="dna-podometer"
            [isActive]="!inactive"></dna-podometer-auto-config>
          <div class="row form-group">
            <div class='col-sm-2'>
              <label>{{'MANDATORY' | translate }}:</label>
            </div>
            <div class='col-sm-2'>
              <dna-switch [disabled]="inactive" [isSelected]="block.mandatory"
                (onBooleanChanged)="block.mandatory = !block.mandatory"></dna-switch>
            </div>
          </div>
          <div class="row form-group">
            <div class='col-sm-2'>
              <label>{{'IS_CONTEXTUAL' | translate }}:</label>
            </div>
            <div class='col-sm-2'>
              <dna-switch [disabled]="inactive" [isSelected]="block.parameters.isContextual"
                (onBooleanChanged)="setContextualBlock($event, block)"></dna-switch>
            </div>
          </div>
          <div *ngIf="block.parameters.isContextual" class="row form-group">
            <div class='col-sm-2'>
              <label>{{'IS_CONTEXTUAL_PER_FORMULA' | translate }}:</label>
            </div>
            <div class='col-sm-2'>
              <dna-switch [disabled]="inactive" [isSelected]="block.parameters.isContextualPerFormula"
                (onBooleanChanged)="setContextualFormula(block)"></dna-switch>
            </div>
          </div>
          <div *ngIf="block.parameters.isContextual" class="row form-group">
            <div class='col-sm-2'>
              <label>{{'IS_CONTEXTUAL_PER_ALL_EVAL' | translate }}:</label>
            </div>
            <div class='col-sm-2'>
              <dna-switch [disabled]="inactive" [isSelected]="block.parameters.isContextualPerEval"
                (onBooleanChanged)="setContextualEval(block)"></dna-switch>
            </div>
          </div>
          <form role="form">
            <div class="table-responsive">
              <table class="table table-borderless-th">
                <thead>
                  <tr>
                    <th translate>LANGUAGE</th>
                    <th translate>CHARTS.CHART_NAME</th>
                    <th translate>DIMENSION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let language of languages">
                    <td>
                      <label translate>{{language}}</label>
                    </td>
                    <td>
                      <input class="form-control" [disabled]="inactive" type="text" name="{{'chartName_' + language}}"
                        [(ngModel)]="block.parameters.report.chartName[language]">
                    </td>
                    <td>
                      <input class="form-control" [disabled]="inactive" type="text" name="{{'dimension_' + language}}"
                        [(ngModel)]="block.parameters.report.dimension[language]">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5 translate>COMPONENTS</h5>
        </div>
        <div class="ibox-content" id="ibox-Block">
          <div class="table-responsive">
            <table class="table table-hover table-borderless-th">
              <thead>
                <tr>
                  <th>#</th>
                  <th translate>LABEL</th>
                  <th translate>TYPE</th>
                  <th translate>DESCRIPTION</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor-pointer" *ngFor="let component of block.components; let indexComponent = index">
                  <td (click)="editComponentConfiguration(component)">{{indexComponent + 1}}</td>
                  <td (click)="editComponentConfiguration(component)">
                    <span>{{ component.args.label[currentLanguage] || component.args.label.english || '-'}}</span>
                    <span *ngIf="!component.args.label[currentLanguage] && component.args.label.english">
                      <i>(english version)</i>
                    </span>
                  </td>
                  <td (click)="editComponentConfiguration(component)">
                    <span class="label">{{component.type}}</span>
                  </td>
                  <td (click)="editComponentConfiguration(component)">
                    {{'tooltip_'+ component.type | translate }}</td>
                  <td>
                    <dna-radio-range-auto-config [component]="component" [workflow]="workflow">
                    </dna-radio-range-auto-config>
                    <dna-multiple-choice-link-config [component]="component" [workflow]="workflow">
                    </dna-multiple-choice-link-config>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
