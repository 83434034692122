<div class="container">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div class="ibox float-e-margins">
      <form novalidate #formulaForm="ngForm">
        <div class="ibox-title row">
          <div class="col-6 my-auto">
            <h5 translate>FORMULAS</h5>
          </div>
          <ng-container *ngIf="training.state !== trainingStates.Finished">
            <div class="col-6">
              <div class="float-end">
                <button id="save" class="btn btn-primary" type="button" (click)="save(formulaForm)" translate>SAVE</button>
                <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="ibox-content">
          <div class="form-group">
            <div class="row">
              <label class="col-3 col-form-label required" translate>FORMULAS</label>
              <div [ngClass]="{ 'col-7': !isDisabled, 'col-9': isDisabled }">
                <tag-input [inputClass]="'tagInputCpgFormula'" [ngClass]="{ 'ng-invalid': submitted && training.formula.listFormulas.length == 0 }"
                  [(ngModel)]="training.formula.formulas" identifyBy="name" displayBy="name" [disable]="training.state === trainingStates.Finished"
                  [onAdding]="onAdding" [onRemoving]="onRemoving(training, 'PUBLISHED', _listFormulaUntouched)" (onAdd)="training = addFormulaToList(training, $event)"
                  (onRemove)="training = removeFormulaToList(training, $event)" name="formulas" placeholder="+ {{ 'FORMULA' | translate }}"
                  secondaryPlaceholder="{{ 'ENTER_FEM' | translate: { element: 'FORMULA' | translate | lowercase } }}">
                </tag-input>
                <div *ngIf="submitted && training.formula.listFormulas.length === 0" class="error text-center" translate>
                  REQUIRED
                </div>
                <div *ngIf="submitted && training.formula.simultaneousComparison.isActive && training.formula.formulas.length < 2" class="error text-center"
                  translate>
                  TWO_FORMULA_SIM_COMPARISON
                </div>
              </div>
              <div *ngIf="!isDisabled" class="col-2">
                <button class="btn btn-primary" type="button" (click)="openModal(training)">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
                <button class="btn btn-danger" type="button" (click)="reset()">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group pt-3">
            <div class="row">
              <label class="col-3 control-label">{{ "SIMULTANEOUS_COMPARISON" | translate }} </label>
              <div class="col-9 my-auto">
                <dna-switch [disabled]="isDisabled" [isSelected]="training.formula.simultaneousComparison.isActive" (onBooleanChanged)="training = onChangeSimultaneous(training, $event)"></dna-switch>
              </div>
              <div *ngIf="training.formula.simultaneousComparison.isActive" class="col-12 pt-3">
                <div *ngIf="training.formula.listFormulas.length > 1; else isEmpty" class="offset-3 pt-3">
                  <dna-simultaneous-comparison [couples]="training.formula.simultaneousComparison.couples" (onCoupleChange)="training = onSimCompChange(training, $event)"></dna-simultaneous-comparison>
                </div>
                <ng-template #isEmpty>
                  <div class="text-center">
                    <label class="col-form-label" translate>NO_COMPARISON_TO_DISPLAY</label>
                  </div>
                </ng-template>
              </div>
              <label *ngIf="!simpCompActive" class="error text-center" translate>SIM_COMP_ACTIVE_ERROR</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
