
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {finalize} from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { DragulaService, DragulaOptions } from 'ng2-dragula';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CampaignStates, Training, User, ViewType, Workflow } from '../../../../types';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { StateService } from '../../../../shared/services/state.service';
import { TrainingService } from '../../../training.service';
import { UserService } from '../../../../shared/services/user.service';
import { UtilService } from '../../../../shared/services/util.service';
import { WorkflowService } from '../../../../workflows/workflows.service';
import { WorkflowEditModalComponent } from '../../../../campaigns/detail/edit-campaign/workflows/workflow-edit/workflow-edit-modal.component';

@Component({
  selector: 'dna-training-workflows',
  templateUrl: './training-workflows.component.html',
  styleUrls: ['./training-workflows.component.less']
})
export class TrainingWorkflowsComponent implements OnInit {

  private _wksArrayReduced: Workflow[];
  private trainingOriginal: Training = new Training();

  currentLanguage: string;
  displayFavoriteOnly: boolean = false;
  idDragula: string;
  isPublished: boolean;
  searchOn: boolean = false;
  searchText: string = "";
  showSpinner: boolean = false;
  training: Training = new Training();
  trainingStates: typeof CampaignStates = CampaignStates;
  user: User;
  workflowType: string;
  workflows: Workflow[] = [];
  workflowsFiltered: Workflow[] = [];

  CUSTOMIZE_CARD_MODE: ViewType;

  constructor(
    private dragulaService: DragulaService,
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private trainingService: TrainingService,
    private translateService: DNATranslateService,
    private userService: UserService,
    private utilService: UtilService,
    private workflowService: WorkflowService
  ) {

    this.idDragula = this.utilService.generateRandomID();
    const dragulaOptions: DragulaOptions = {
      accepts: (el: Element, target: Element, source: Element, sibling: Element): boolean => {
        return target.id !== "source1" && target.id !== "source2";
      }
    }
    dragulaService.createGroup(this.idDragula, dragulaOptions);
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.workflowType = this.stateService.WORKFLOWS;
    this.currentLanguage = this.translateService.getLanguage();
    this.CUSTOMIZE_CARD_MODE = this.trainingService.CUSTOMIZE_CARD_MODE;

    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.training = this.trainingService.getTrainingFromLocalStorage(params.get("idTraining"));
      if (!this.training.workflows) this.training.workflows = [];
      this.trainingOriginal = _.cloneDeep(this.training);

      this.workflows = this.trainingService.workflows ? _.cloneDeep(this.trainingService.workflows) : [];
      this.removeWorkflow(_.cloneDeep(this.workflows));
      this.isPublished = this.training.state === this.trainingStates.Published;
    });

    this.userService.onUserChanged().subscribe(() => {
      this.currentLanguage = this.translateService.getLanguage();
      this.user = this.userService.getUser();
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.training, this.trainingOriginal);
  }

  canIRemoveThisElement(element: Workflow) {
    if (this.training.state == this.trainingStates.Draft || this.training.state == this.trainingStates.Suspended)
      return true;

    return !this.isInOriginalTraining(element);
  }

  isInOriginalTraining(element: Workflow) {
    return _.find(this.trainingOriginal.workflows, o => o.id == element.id);
  }

  private nextSave(training: Training) {
    // if (training.parameters.viewType === this.CUSTOMIZE_CARD_MODE)
    //   training.workflows = training.workflows.map((workflow: Workflow) => this.utilService.addDefaultPagesInWorkflow(workflow, false));

    training.workflows = training.workflows.map(wk => wk = <Workflow>_.omitBy(wk, (value, key) => key[0] === "_" ||
      ["createdBy", "created_on", "notificationMode", "state", "workspaces"].find(a => a === key)));

    if (training.workflows.length > 0) {
      this.trainingService.putTraining(training).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(() => {
          this.trainingService.setTrainingFromLocalStorage(training);
          this.trainingOriginal = _.cloneDeep(training);
          this.router.navigate(['trainings', training.id, 'edit', 'formulas'])
        },
        error => this.errorManager.catchError(error));
    }
    else {
      this.showSpinner = false;
      this.errorManager.displayMessage("ON_ERROR_EMPTY_WORKFLOW", "danger");
    }
  }

  onCancel() {
    this.training = _.cloneDeep(this.trainingOriginal);
    this.removeWorkflow(_.cloneDeep(this.trainingService.workflows));
    this.trainingService.setTrainingFromLocalStorage(this.training);
  }

  onRemoveWorkflow(index: number) {
    this.training.workflows.splice(index, 1);
    this.removeWorkflow(_.cloneDeep(this.workflows));
  }

  private openModal(workflow: Workflow) {
    const modal = this.modalService.open(WorkflowEditModalComponent, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
      windowClass: "dna-modal-campaign"
    });
    modal.componentInstance.objectSend = this.training;
    modal.componentInstance.workflow = workflow;
    modal.result.then(
      (training) => {
        this.training = training;
      },
      (reason) => { }
    );
  }

  openModalWorkflow(workflow: Workflow) {
    if (!workflow.blocks) {
      this.showSpinner = true;
      this.workflowService.getWorkflow(workflow.id).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(wk => {
          let index = this.training.workflows.findIndex(workflowTraining => workflowTraining.id === wk.id);
          this.training.workflows[index] = wk;
          this.openModal(wk);
        });
    }
    else {
      this.openModal(workflow);
    }
  }

  removeWorkflow(workflows: Workflow[]) {
    this._wksArrayReduced = this.utilService.removeWorkflowsInList(workflows, this.training.workflows);
    this.workflowsFiltered = this.utilService.filterObjects(_.cloneDeep(this._wksArrayReduced), this.displayFavoriteOnly, this.searchText, this.currentLanguage);
  }

  save(training: Training) {
    this.showSpinner = true;
    let promises = training.workflows.reduce((arr, curr) => {
      if (!curr.blocks) {
        arr.push(this.workflowService.getWorkflow(curr.id))
      }
      return arr;
    }, []);

    if (promises.length > 0) {
      observableForkJoin(promises).subscribe((wks: Workflow[]) => {
        wks.map(wk => {
          let index = training.workflows.findIndex(workflowTraining => workflowTraining.id === wk.id);
          training.workflows[index] = wk;
        });
        this.nextSave(training);
      });
    } else {
      this.nextSave(training);
    }
  }

  updateList(searchText: string, displayFavorites: boolean) {
    this.displayFavoriteOnly = displayFavorites;
    this.searchText = searchText;
    this.workflowsFiltered = this.utilService.filterObjects(_.cloneDeep(this._wksArrayReduced), displayFavorites, searchText, this.currentLanguage);
  }

}
