<dna-layout>
  <h1 class="mb-4" id="banner-title">
    <span translate>DASHBOARD</span>
  </h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page" translate>DASHBOARD</li>
    </ol>
  </nav>
  <div class="block">
    <input type="hidden" id="tourSign" value="dashboard">
    <dna-campaign-view *ngIf="displayCampaignView"> </dna-campaign-view>
    <dna-workflow-view *ngIf="displayWorkflowView"> </dna-workflow-view>
    <dna-block-view *ngIf="displayBlockView"> </dna-block-view>
  </div>
</dna-layout>
