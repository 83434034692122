<ngx-loading [show]="showSpinner">
</ngx-loading>
<div *ngIf="!showSpinner" id="image-one-pager">
  <editor class="col-12" style="display: none" [inline]="true" [init]="initEditor">
  </editor>
  <div class="ibox float-e-margins">
    <div class="ibox-content item-center">
      <h5 class="mb-4">{{ "IMAGES" | translate | uppercase }}</h5>
      <h5 *ngIf="getLargeImagesCount() > 0" class="item-center-warning">
        {{ "IMAGES_TOO_HIGH" | translate }}
      </h5>
      <div class="preview-button-container">
        <button class="btn btn-secondary" (click)="openPreview()">{{'PREVIEW_PAGE' | translate}}</button>
      </div>
      <dna-upload-images (onUploadImages)="onUploadImages($event)"></dna-upload-images>
      <div ngbDropdown>
        <button class="btn btn-secondary" id="dropdownPage" ngbDropdownToggle translate="ADD_PAGE"></button>
        <div ngbDropdownMenu aria-labelledby="dropdownPage">
          <button ngbDropdownItem (click)="addPage(orientation)"
                  *ngFor="let orientation of orientations">{{ orientation | translate }}</button>
        </div>
      </div>
    </div>
    <div class="row" style="margin: 0">
      <div *ngFor="let image of sortByPageAdd(images); let index = index" [title]="image.title"
          class="col-xl-3 col-lg-4 col-md-6"
          [ngClass]="{'large-image': isImageLarge(image.id)}"
          (mouseenter)="applyHoverStyle($event, image.id)"
          (mouseleave)="removeHoverStyle($event, image.id)"
          [style.border]="isImageLarge(image.id) ? '2px solid orange' : 'none'"
          style="padding: 15px">
        <div class="row" style="margin: 0">
          <div class="col-10 img-parent">
            <img *ngIf="image.data | async as imgData; else loading" [src]="imgData"
                (click)="openModalImage(image, imgData)" class="img-fluid img-reduced img-thumbnail"
                style="width:auto; cursor: zoom-in;">
            <ng-template #loading>Loading...</ng-template>
          </div>
          <div class="col-2" style="padding:0">
            <button type="button" (click)="deleteImage(image, index)" class="btn btn-link btn-remove-img"
                    style="float: right; margin:auto;"><i class='fa fa-remove'></i></button>
          </div>
        </div>
        <div class="row m-0">
          <label class="label-image">{{ image.name }}</label>
        </div>
        <div class="row">
          <div *ngIf="image.pagesAdded > 0" style="margin-left: 1em;">{{ 'IMAGES_ADDED_TO_PAGES' | translate }} {{image.pagesAdded}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col float-end">
        <button *ngIf="!isFilling" class="btn btn-secondary float-end m-1"
                (click)="automaticFill()">{{ 'AUTOMATIC_FILL' | translate }}</button>
        <ng-template #progressBar>
          <div class="progress-container">
            <h2>{{'PAGES_LOADING'|translate}}...</h2>
            <ngb-progressbar type="success" [value]="progress"> </ngb-progressbar>
          </div>
        </ng-template>
        <ngx-loading  [show]="isFilling" [config]="{ backdropBackgroundColour: 'rgba(0, 0, 0, 0.7)', animationType: ngxLoadingAnimationTypes.none }" [template]="progressBar"></ngx-loading>
      </div>
    </div>
    <div ngbAccordion dragula="DRAGULA_EVENTS" [(dragulaModel)]="pages" #acc="ngbAccordion">
      <div ngbAccordionItem *ngFor="let page of pages; let indexPage = index" id="ngb-panel-{{indexPage}}">
        <div ngbAccordionHeader>
          <div class="d-flex align-items-center row justify-content-between">
            <div class='col-2'>
              <i class="fa fa-arrows-v page-drag"></i>
              <span class="page-drag">{{'PAGE' | translate}} {{indexPage + 1}}</span>
            </div>
            <div class="col-5">
              <button style="width:100%" class="row btn btn-link p-0" (click)="openCustomPage(page)">
                <span>{{'EDIT' | translate}}</span>
              </button>
            </div>
            <div class='col-2'>
              <button style='float:right' type="button" class="btn btn-sm btn-outline-danger ml-2"
                      (click)="removePage(page)" translate="REMOVE">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="preview" [ngClass]="{'hide': !previewMode}">
      <dna-image-layout
        *ngFor="let page of pages; let indexPage = index" [style.height]="getHeight(page.orientation)" [style.width]="portraitFormat.WIDTH"
        [style.transform]="'scale(0.7)'" [style.margin-top]="getMargin(indexPage,page.orientation)"
        [style.margin-bottom]="page.orientation === 'LANDSCAPE' && indexPage ===  0 ? '7%' : '0'"
        [_captureMode]="true"
        [_format]="portraitFormat"
        [_page]="page"
        [_landscapeFormat]="landscapeFormat">
      </dna-image-layout>
      <button type="button" class="btn btn-lg ml-2"
              (click)="closePreview()">
        <i class='fa fa-remove'></i>
      </button>
    </div>
  </div>
</div>

