<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins">
    <form novalidate name="trainingForm">
      <div class="ibox-title">
        <h5 class="center" translate> PARAMETERS </h5>
        <p *ngIf="isPublished; else notPublished" translate> PUBLISHED_TRAININGS_MESSAGE </p>
        <ng-template #notPublished>
          <button id="save" class="btn btn-primary" type="button" (click)="save()" translate>SAVE</button>
          <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </ng-template>
      </div>

      <div class="ibox-content">
        <div class="form-group">
          <div class="row">
            <label class="col-3 control-label" translate>DISPLAY_MODE</label>
            <div class="col-9">
              <button class="btn btn-primary btn-sm" [ngClass]="{'btn-selected': training.parameters.viewType === viewTypes.ModeList}"
                [disabled]="isPublished" type="button" (click)="clickTypeview(viewTypes.ModeList, 'LABORATOIRE_DESCRIPTION')"
                translate>LIST_MODE</button>
              <button class="btn btn-primary  btn-sm" [ngClass]="{'btn-selected': training.parameters.viewType === viewTypes.ModeCard}"
                [disabled]="isPublished" type="button" (click)="clickTypeview(viewTypes.ModeCard, 'CARD_DESCRIPTION')" translate>CARD_MODE</button>
              <button class="btn btn-primary  btn-sm" [ngClass]="{'btn-selected': training.parameters.viewType === viewTypes.ModeDualCard}"
                [disabled]="isPublished" type="button" (click)="clickTypeview(viewTypes.ModeDualCard, 'DUAL_DESCRIPTION')"
                translate>DUAL_MODE</button>
              <button class="btn btn-primary  btn-sm" [ngClass]="{'btn-selected': training.parameters.viewType === viewTypes.ModeCustomizeCard}"
                [disabled]="isPublished" type="button" (click)="clickTypeview(viewTypes.ModeCustomizeCard, 'CUSTOMIZE_DESCRIPTION')"
                translate>CUSTOMIZE_MODE</button>
            </div>
            <div class="offset-3 col-9 control-label description">
              <span translate> {{description}} </span>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="training.parameters.viewType === viewTypes.ModeList">
          <div class="row">
            <label class="col-3 control-label" translate>PARAMETER_SELECT_VIEW</label>
            <div class="col-9">
              <button class="btn btn-primary  btn-sm" [ngClass]="{'btn-selected': training.parameters.viewGroup === viewGroups.FormulaGroup}"
                [disabled]="isPublished" type="button" (click)="changeViewGroup(viewGroups.FormulaGroup)" translate>FORMULA</button>
              <button class="btn btn-primary btn-sm" [ngClass]="{'btn-selected': training.parameters.viewGroup === viewGroups.QuestionGroup}"
                [disabled]="isPublished" type="button" (click)="changeViewGroup(viewGroups.QuestionGroup)" translate>QUESTION</button>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="training.parameters.viewType === viewTypes.ModeCustomizeCard">
          <div class="row">
            <label class="col-3 control-label" translate> MESSAGE_CUSTOMIZE </label>
            <div class="col-9">
              <button class="btn btn-primary  btn-sm" [disabled]="isPublished" type="button" (click)="configureCustomizeMode()" translate>CUSTOMIZE_VIEW</button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 control-label">{{ "RETURN_BUTTON" | translate }}</label>
            <div class="col-9">
              <dna-switch [disabled]="isPublished" [isSelected]="training.parameters.previousAvailable" (onBooleanChanged)="onChangeData($event, training.parameters, 'previousAvailable')"></dna-switch>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 control-label">{{ "DISPLAY_BLOCK_NAME" | translate }}</label>
            <div class="col-9">
              <dna-switch [disabled]="isPublished" [isSelected]="training.parameters.displayBlockName" (onBooleanChanged)="onChangeData($event, training.parameters, 'displayBlockName')"></dna-switch>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
