
import { takeUntil, catchError } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Campaign, Workflow, Metier, Graph, Descriptor } from '@app/types';
import { FilterService, ChartService } from '../chart-module/services';
import { CampaignService } from '../../../../../campaigns.service';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Subject } from 'rxjs';
import { chartsConfigs } from '../chart-module/configs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { UserService } from '@app/shared/services/user.service';

@Component({
  selector: 'dna-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [FilterService, CampaignService, ChartService]
})
export class ReportGraphDashboardComponent implements OnInit, OnDestroy {
  @Input() campaign: Campaign;
  @Input() workflow: Workflow;
  @Input() lang: string;
  @Input() onImageLoad: any;
  @Output() _onMultipleCaptures = new EventEmitter<any>();

  private unsubsriber$ = new Subject<void>();

  public filters: any;
  public filtersLoading = true;
  public report: Array<any> = new Array(2);
  public reportError: any;
  public showSpinner = false;
  public filterCollapsed = true;
  public menuCollapsed = true;
  graphs: Graph[];
  multiplesCapturesElements: any[] = [];

  constructor(
    private filterService: FilterService,
    private campaignService: CampaignService,
    private translate: TranslateService,
    private chartService: ChartService,
    private userService: UserService
  ) { }

  filterChart = report => report.filter(chart => chart.toCapture);

  async ngOnInit() {
    ChartService.takeCaptures.subscribe(() => {
      if (this.filterChart(this.report).length === 0) {
        this._onMultipleCaptures.emit(null);
      }
    });

    await this.loadReport(
      this.campaign,
      this.workflow.id,
      this.campaign.metier,
      this.filters,
    );
    this.filters = this.filterService.filters;
    this.filtersLoading = false;
  }

  ngOnDestroy() {
    this.unsubsriber$.next();
    this.unsubsriber$.complete();
  }

  /**
   * loadReport
   * Main method in charge of getting data and parameters for the current campaign.
   * It gathers those informations into proper properties for each graph of the report.
   * @param campaign
   * @param workflowId  : string
   * @param metier      : string
   * @param filters     : any
   */
  private loadReport = async (campaign: Campaign, workflowId: string, metier: Metier, filters: any) => new Promise<void>((resolution) => {
    this.getReport(campaign.id, workflowId)
      .then((report: Array<Graph>) => {
        this.report = report
          .map((chart: any, index: number) => {
            chart.identifier = index + 1;
            return chart;
          });
        return Promise.resolve(this.report);
      })
      // LEGACY get pdescriptors : categories
      // .then((charts: Array<Graph>) => charts.length ? this.getChartsDescriptors(charts, campaign, workflowId) : Promise.resolve([]))
      .then((charts: Graph[]) => charts.length ? this.getChartsData(charts, campaign.id, workflowId, metier, filters) : Promise.resolve([]))
      .then(() => resolution())
      .catch((err: any) => {
        console.error(err);
        switch (Object.getPrototypeOf(err).name) {
          case 'Error':
            this.reportError = { error: { message: err } };
            break;
          default:
            this.reportError = err;
            break;
        }
        this.filtersLoading = false;
        this.report = [];
      });
  })

  /**
 * getReport
 * Method to get report (all chart to come up with) for a given campaign and workflow
 * @param campaignId : string
 * @param workflowId : string (deprecated)
 */
  private getReport = (campaignId: string, workflowId: string) => new Promise((resolve, reject) => {
    this.campaignService.getChartsFromCampaign(campaignId, workflowId).pipe(
      takeUntil(this.unsubsriber$))
      .subscribe(
        (data: any) => {
          resolve(data); },
        (err: HttpErrorResponse) => reject(err)
      );
  })

  /**
  * getChartsDescriptors
  * Method to retreive descriptors from campaign object
  * @param charts : Array<Graph>
  * @param campaing : Campaign
  * @param workflowId : string
  */

  private getChartsDescriptors = (charts: Array<Graph>, campaign: Campaign, workflowId: string) => Promise.all(charts.map((chart: Graph, index: number) => {
    try {
      const descriptors = chart.descriptors
        .filter(x => x.isActive && !x.isGroup)
        .map((descriptor: Descriptor) => {
          const workflow = campaign.workflows.find(w => w.id === workflowId);
          const block = workflow.blocks.find(w => w.idInQuestionnaire === descriptor.idInQuestionnaire);
          return { ...block.components.find(c => c.idInBlock === descriptor.idInBlock), blockName: block.name };
        });
      const payload = descriptors.reduce((reducer: any, desc: any) => {
        const { values, keysLeft, valuesLeft, hasCentralValue, centralValue, centralKey, keys, addOtherOption, label } = desc.args;
        if (addOtherOption) {
          const keyName = `${desc.blockName.english}_${label.english}_other`;
          reducer[keyName] = { label: 'other', key: 'other', attribute: label, blockName: desc.blockName };
        }
        if (hasCentralValue) {
          const keyName = `${desc.blockName.english}_${label.english}_${centralKey.id}`;
          reducer[keyName] = (reducer[keyName] || { label: {}, key: Number(centralKey.id), attribute: label, blockName: desc.blockName });
          Object.keys(centralValue).reduce((mapper: any, key: string) => {
            if (values[key] instanceof Array && values[key].length) {
              mapper[key] = (mapper[key] || values[key][index].value);
            }
            return mapper;
          }, reducer[keyName].label);
        } else if ((keys && keys.length) && (!keysLeft || !keysLeft.length)) {
          keys.reduce((accumulator: any, k: any, index: number) => {
            const keyName = `${desc.blockName.english}_${label.english}_${k.id}`;
            accumulator[keyName] = (accumulator[keyName] || { label: {}, key: Number(k.id), attribute: label, blockName: desc.blockName });
            Object.keys(values).reduce((mapper: any, key: string) => {
              if (values[key] instanceof Array && values[key].length) {
                mapper[key] = (mapper[key] || values[key][index].value);
              }
              return mapper;
            }, accumulator[keyName].label);
            return accumulator;
          }, reducer);
        } else {
          keysLeft.reduce((accumulator: any, keyLeft: any, index: number) => {
            const keyName = `${desc.blockName.english}_${label.english}_${keyLeft.id}`;
            accumulator[keyName] = (accumulator[keyName] || { label: {}, key: Number(keyLeft.id), attribute: label, blockName: desc.blockName });
            Object.keys(valuesLeft).reduce((mapper: any, key: string) => {
              if (valuesLeft[key] instanceof Array && valuesLeft[key].length) {
                mapper[key] = (mapper[key] || valuesLeft[key][index].value);
              }
              return mapper;
            }, accumulator[keyName].label);
            return accumulator;
          }, reducer);
        }
        return reducer;
      }, {});
      chart['pDescriptors'] = Object.values(payload);
    } catch (e) { }
    finally {
      return chart;
    }
  }))

  /**
     * getChartsData
     * Method to get data and parameters (for all chart to come up with) for a given campaign and workflow
     * @param chartArray : Array<any>
     * @param campaignId : string
     * @param workflowId : string (deprecated)
    */
  private getChartsData = (chartArray: Graph[], campaignId: string, workflowId: string, metier: Metier, filters: any) => new Promise(resolve => {
    const batchSize = 5; // Set the batch size to any desired number
    const totalCharts = chartArray.length;

    const processBatch = (startIndex: number) => {
    const endIndex = Math.min(startIndex + batchSize, totalCharts);
    const batchCharts = chartArray.slice(startIndex, endIndex);

    forkJoin(batchCharts.map((chart: any) => {
    return this.getRawData(campaignId, workflowId, filters, chart)
        .then((chart: any) => {
          this.filterService.addFilters(chart.filters);
          this.getParameters(chart, metier)
              .then((chart: any) => {
                try {
                  const element = this.report.find(x => x.identifier == chart.identifier);
                  element.chart = ChartService.instanciateChart(element.parameters.configs, element.settings, { data: chart.data, pDescriptors: chart.pDescriptors, routines: chart.routines }, element.parameters.type, chart.filters, this.lang, this.translate);
                  return Promise.resolve(element);
                } catch (e) {
                  const element = this.report.find(x => x.identifier == chart.identifier);
                  element.error = true;
                  Promise.reject(e);
                }
              })
              .catch((error: any) => {
                console.error(error);
                chart.error = true;
                return;
              });
        })
        .catch((err: any) => {
          console.log(err);
          chart.error = true;
          return;
        });
  })).subscribe(() => {
    const nextBatchStartIndex = endIndex;
    if (nextBatchStartIndex < totalCharts) {
      processBatch(nextBatchStartIndex);
    } else {
      resolve('All Charts loaded successfully!');
    }
  });
};

processBatch(0);
});

  /**
   * getRawData
   * Method to get data for a given chart
   * @param campaignId  : string
   * @param workflowId  : string
   * @param filters     : any
   * @param chart       : any
   */
  private getRawData = (campaignId: string, workflowId: string, filters: any, chart: any) => new Promise((resolve, reject) => {
    this.campaignService.getChartsData(campaignId, workflowId, chart, filters).pipe(
        takeUntil(this.unsubsriber$))
        .subscribe(
            (res: any) => {
                chart.data = res.data;
                chart.filters = filters !== undefined ? filters: res.filters;
                // Categories are now retrieve from back
                if (res.title) {
                  // chart.type.translations = res.title;
                  chart.name = res.title;
                }
                if (chart.graphParameters) {
                  chart.parameters = chart.graphParameters;
                }
                if (res.categories) { chart.pDescriptors = res.categories; }
                resolve(chart);
            },
            (err: HttpErrorResponse) => {
              chart.error = true;
              reject(err);
            });
  })


  /**
   * getParameters
   * Method to get configurations parameters for a given chart
   * @param chart : any
   * @param metier : Metier
  */
  private getParameters = (chart: any, metier: Metier) => {
    if (chart.graphParameters) {
      chart.parameters = chart.graphParameters;
    }
    try {
      const chartType = chart.type.id;
      chart.parameters = _.cloneDeep(chartsConfigs[_.get(metier, 'id', 'hair')][chartType]);
      Object.assign(chart.parameters.configs.title, { translations: chart.name });
      Object.assign(chart.parameters.configs.subtitle, { translations: chart.type.translations });
      return Promise.resolve(chart);
    } catch (e) {
      chart.error = true;
      return Promise.reject(chart);
    }
  }

  /**
     * _onFiltersUpdate
     * Method called when filters are updated.
     * Handling this task when EventEmitter is fired from the children.
     * This method is in charge to relaunch report getting workflow to get
     * updated data filtered by user's entries.
     * @param filters : Updated filters
    */
  public onFiltersUpdate = async (filters: any): Promise<any> => {
    this.filtersLoading = true;
    this.filterService.resetFilters();
    await this.loadReport(
      this.campaign,
      this.workflow.id,
      this.campaign.metier,
      filters
    );
    delete this.filters;
    this.filters = this.filterService.filters;
    this.filtersLoading = false;
  }


  /**
   * _onCollapse
   * Method to collapse/expand filters panel
   * @param status {boolean}
   */
  public _onCollapse = (status: boolean) => this.filterCollapsed = status;

  public _onCollapseMenu = (status: boolean) => this.menuCollapsed = status;

  /**
   * trackByFunction
   * Method to track elements loop by id or index (fallback option)
   * @param index {number}
   * @param elt {any}
   */
  public trackByFunction = (index: number, elt: any) => {
    if (!elt) { return null; }
    return elt.id || index;
  }

  public _onExporting = (): void => { this.showSpinner = true; };
  public _onExcelDownload = (obj: any) => {
    this.showSpinner = true;
    try {
      this.chartService.exportToExcel(obj.el, obj.name);
    } catch (e) { }
    finally {
      this.showSpinner = false;
    }
  }

  saveChartOptions(event) {
    const language = _.get(this.userService.getUser(), 'language', 'english');
    const idGraph = this.report.find(r => r.identifier === event.identifier).id;
    this.campaignService.putCampaignGraphOptions(this.campaign.id, {
      workflowId: this.workflow.id,
      idGraph: idGraph,
      chartTitle: event.title,
      language: language,
      comment: event.comment,
      parameters: event.parameters
    }).pipe(
      catchError(err => {
        console.log(err);
        return undefined;
      })
    ).subscribe();
  }

  onChangeGraphToCapture(graph) {
    graph.toCapture = !_.get(graph, 'toCapture', false);
  }

  /**
   * 20765 Emit event for all captures when each event by capture is done
   * @param elements table of [Observable<any>, any]
   */
  onMultipleCaptures(elements) {
    this.multiplesCapturesElements.push(elements);
    const chartsChecked = this.filterChart(this.report);
    if (chartsChecked.length === this.multiplesCapturesElements.length) {
      this._onMultipleCaptures.emit(this.multiplesCapturesElements);
      this.multiplesCapturesElements = [];
    }
  }

}
