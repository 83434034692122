
import {finalize, mergeMap, tap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { Observable ,  Subscription } from 'rxjs';

import { Block, States, User } from '../../../types';
import { BlockService } from '../../../blocks/blocks.service';
import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { UserService } from '../../../shared/services/user.service';
import { UtilService } from '../../../shared/services/util.service';

@Component({
    selector: 'dna-block-view',
    templateUrl: './block-view.component.html',
    styleUrls: ['../dashboard.component.less']
})

export class BlockViewComponent implements OnInit {

    blocks: Block[] = [];
    blocksInProgress: Block[] = [];
    blocksSuspended: Block[] = [];
    blocksToPrepare: Block[] = [];
    blockStates: typeof States = States;
    hover1: boolean = false;
    hover3: boolean = false;
    hover5: boolean = false;
    showSpinner: boolean = true;
    subscribeInit: Subscription;
    subscribeUser: Subscription;
    user: User;

    constructor(
        private blockService: BlockService,
        private errorManagerService: ErrorManagerService,
        private userService: UserService,
        public utilService: UtilService
    ) { }

    ngOnInit() {
        this.user = this.userService.getUser();
        this.subscribeInit = this.init().pipe(
            finalize(() => this.showSpinner = false))
            .subscribe(
            () => { },
            error => this.errorManagerService.catchError(error)
            );

        this.subscribeUser = this.userService.onUserChanged().pipe(
            tap(() => this.showSpinner = true),
            tap((user: User) => this.user = user),
            mergeMap(() => this.init()),)
            .subscribe(
            () => this.showSpinner = false,
            error => {
                this.showSpinner = false;
                this.errorManagerService.catchError(error);
            });
    }

    ngOnDestroy() {
        this.subscribeInit.unsubscribe();
        this.subscribeUser.unsubscribe();
    }

    filterBlocksByState(blocks: Block[]) {
        this.blocks = blocks;
        this.blocksToPrepare = blocks.filter(block => block.state === States.Draft);
        this.blocksInProgress = blocks.filter(block => block.state === States.Published);
        this.blocksSuspended = blocks.filter(block => block.state === States.Suspended);
    }

    init(): Observable<Block[]> {
        return this.blockService.getBlocks().pipe(
            tap((blocks) => this.filterBlocksByState(blocks.list)));
    }

}
