import {
  throwError as observableThrowError, of as observableOf,
  Observable,
  Subject,
  of,
  throwError,
  forkJoin
} from 'rxjs';

import { finalize, map, catchError, takeUntil, merge, mergeMap, tap, filter, flatMap } from 'rxjs/operators';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';

import * as _ from 'lodash';
import {
  NgbDateStruct, NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import {
  ActionTypes,
  Campaign,
  CampaignStates,
  Workspace,
  Hub,
  HubById,
  HubsById,
  OrchestraDemand,
  SynergyDemand,
  Type,
  User,
  ArcsParticipation, CampaignInfosGen, Actiview, UserWorkspace, ActiviewLang
} from '../../../../types';
import {
  CampaignService
} from '../../../campaigns.service';
import {
  DNATranslateService
} from '../../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  HttpRestService
} from '../../../../shared/services/httpRest.service';
import {
  ReferenceTypeService
} from '../../../../shared/services/reference-type.service';
import {
  SynergyDemandService
} from '../../../../shared/services/synergy-demand.service';
import {
  UserService
} from '../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../shared/services/util.service';
import { AddArcsModalComponent } from './add-arcs-modal/add-arcs-modal.component';
import { environment } from '../../../../../environments/environment';
import { ModalGroupVolunteersComponent } from '../../../../shared/modalGroupVolunteers/modalGroupVolunteers.component';
import { AssociateHorsArcsModalComponent } from './associate-hors-arcs-modal/associate-hors-arcs-modal.component';
import { WorkspaceService } from '../../../../general/my-profile/workspaces/workspaces.service';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';

export enum UnlinkType {
  Synergy = 'SYNERGY',
  Arcs = 'ARCS',
  Actiview = 'ACTIVIEW',
  HorsArcs = 'HORSARCS'
}

@Component({
  selector: 'dna-campaign-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.less']
})

export class GeneralInformationComponent implements OnInit, OnDestroy {

  activiewIncorrect: boolean = false;
  activiewLoading: boolean = false;
  activiewLocked: boolean = false;
  activityNumber: string = '';
  allCountries: any;
  arcsIncorrect: boolean[] = [];
  arcsLoading: boolean[] = [];
  arcsData: number[] = [];
  arcsLocked: boolean[] = [];
  arcsNumber: string[] = [];
  arcsSystem: HubById[] = [];
  horsArcsIncorrect: boolean[] = [];
  horsArcsLoading: boolean[] = [];
  horsArcsData: number[] = [];
  horsArcsLocked: boolean[] = [];
  horsArcsNumber: string[] = [];
  horsArcsSystem: HubById[] = [];
  campaign: CampaignInfosGen = new CampaignInfosGen();
  campaignOriginal: CampaignInfosGen = new CampaignInfosGen();
  campaignStates: typeof CampaignStates = CampaignStates;
  countries: HubsById[];
  currentWorkspace: Workspace = new Workspace();
  currentLanguage: string;
  dateFormat: string;
  hubs: Hub[];
  error: boolean = false;
  showSpinner: boolean = false;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  submitted: boolean = false;
  synergyIncorrect: boolean = false;
  synergyLoading: boolean = false;
  synergyLocked: boolean = false;
  requestNumber: string = '';
  typeCampaigns: Type[];
  typeEvaluations: Type[];
  opticalCodes: Type[];
  publishedTemplate: boolean = false;
  addActiviewAfterPublication: boolean =false;
  user: User;

  tooltipUnlink$: Observable<string>;

  destroy$: Subject<boolean> = new Subject<boolean>();
  groupsNameHorsArcs: string[] = [];
  framePersonalData: any[] = [];
  link: string = '';
  personalDataManagementAndFrameId: any = '';
  personalDataComment: string = '';
  entityId: string;

  languageMap = {
    english: ActiviewLang.english,
    french: ActiviewLang.french,
    japanese: ActiviewLang.japanese,
    chinese: ActiviewLang.chinese,
    portuguese: ActiviewLang.portuguese,
    spanish: ActiviewLang.spanish
  };

  constructor(
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private httpRestService: HttpRestService,
    private referenceType: ReferenceTypeService,
    private worspaceService: WorkspaceService,
    private route: ActivatedRoute,
    private router: Router,
    private synergyDemandService: SynergyDemandService,
    private translationService: DNATranslateService,
    private userService: UserService,
    private utilService: UtilService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService
  ) { }

  initVariableCampaign = (campaign: CampaignInfosGen) => {
    this.tooltipUnlink$ = this.translationService.translateMessage(ActionTypes.UnlinkSynergyDemand);
    this.initializeFrame(campaign);

    if (campaign.actiview) {
      this.activityNumber = campaign.actiview.activityNumber;
      this.activiewLocked = true;
    } else {
      this.activityNumber = '';
      this.activiewLocked = false;
    }

    if (_.has(campaign, 'synergy.requestNumber')) {
      this.requestNumber = campaign.synergy.requestNumber;
      this.synergyLocked = true;
    } else {
      this.requestNumber = '';
      this.synergyLocked = false;
    }

    let arcs = _.get(campaign, 'arcs', []);
    if (!_.isNil(arcs)) {
      for (let arc of arcs) {
        this.arcsNumber.push(arc.arcsStudy);
        this.arcsSystem.push(arc.arcsSystem);
        this.arcsLocked.push(true);
        this.arcsLoading.push(false);
        this.arcsIncorrect.push(false);
        this.arcsData.push(this.arcsNumber.length);
      }
    }

    let horsarcs = _.get(campaign, 'groupsPanelistsHorsArcs', []);
    if (!_.isNil(horsarcs)) {

      for (let obj of horsarcs) {
        this.groupsNameHorsArcs.push(obj.groupName);
        this.horsArcsSystem.push(obj.hub);
        this.horsArcsLocked.push(true);
        this.horsArcsLoading.push(false);
        this.horsArcsIncorrect.push(false);
        this.horsArcsData.push(this.groupsNameHorsArcs.length);
      }
    }
  }

  ngOnInit() {
    this.init();
  }

  getActiviewFrame(actiview) {
    if (actiview.activityNumber) {
      this.showSpinner = true;
      return this.httpRestService.getActiview(actiview.activityNumber, true).pipe(
        catchError(err => {
          this.showSpinner = false;
          return observableThrowError(err);
        }),
        tap(actiview => {
          this.campaign.frame.link == actiview.output.x_lnk_frame_link ? '' : this.campaign.frame.link = actiview.output.x_lnk_frame_link
        }),
        tap(() => this.showSpinner = false)
      );
    }
  }

  init() {
    this.error = false;
    this.currentLanguage = this.translationService.getLanguage();
    this.currentWorkspace = this.userService.getUser().currentWorkspace;
    this.allCountries = this.utilService.setAllCountries(this.currentLanguage);
    this.countries = this.referenceType.getHubsById();
    this.dateFormat = this.translationService.getDateFormat();
    this.hubs = this.referenceType.getHubs();
    this.typeCampaigns = this.referenceType.getTypeCampaign();
    this.typeEvaluations = this.referenceType.getTypeEvaluation();
    const idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');
    this.showSpinner = true;
    this.user = this.userService.getUser();
    this.entityId = this.localStorageService.getItem('DNA_User').entityId;

    const loadCampaign$ = this.campaignService.getCampaignInfoGen(idCampaign).pipe(
      map((campaignInfoGen: CampaignInfosGen) => {
        const targetWorkspace = this.user.workspaces.find(workspace => workspace.id === campaignInfoGen.workspaces[0]);
        if (campaignInfoGen.workspaces[0] !== this.currentWorkspace.id) {
          if (targetWorkspace) {
            this.switchWorkspace(targetWorkspace);
          } else {
            this.errorManager.displayMessage('You do not have access to this workspace', 'danger');
            this.router.navigate(['campaigns']);
          }
        }
        this.campaign = campaignInfoGen;
        this.campaignOriginal = _.cloneDeep(campaignInfoGen);
        this.campaignService.updateBreadCrumb(campaignInfoGen.name);
        this.publishedTemplate = this.campaignService.isTemplatePublishedCampaign(campaignInfoGen.isTemplate, campaignInfoGen.state);
      }),
      catchError(err => {
        this.error = true;
        throwError(err);
        return of(undefined);
      })
    );

    const loadFramePersonalData$ = this.httpRestService.getFramePersonalData(this.entityId).pipe(
      tap((data: any) => {
        this.framePersonalData = data.values;
      }),
      catchError(err => {
        this.framePersonalData = [];
        console.error('Error fetching frame personal data', err);
        return of([]);
      })
    );

    forkJoin([loadCampaign$, loadFramePersonalData$])
      .pipe(
        tap(() => {
          this.initDate(this.campaign);
          if (this.campaign.actiview) {
            this.getActiviewFrame(this.campaign.actiview).subscribe();
          }
          this.initVariableCampaign(this.campaign);
        }),
        finalize(() => this.showSpinner = false),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Pre-load helpers.
    this.campaignService.getActiviewRefs().subscribe();

    this.opticalCodes = this.referenceType.getOpticalTypes();

    this.translationService.onLangChange().subscribe((translation: any) => {
      this.allCountries = this.utilService.setAllCountries(translation.lang);
      this.currentLanguage = translation.lang;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  switchWorkspace(targetWorkspace: UserWorkspace) {
    this.showSpinner = true;
    //this.httpRestService.setFavoriteWorkspace(targetWorkspace, this.user.track).subscribe();
    this.worspaceService.getWorkspace(targetWorkspace.id, this.user.track).pipe(
      catchError(err => {
        return of(undefined);
      }),
      tap(workspace => {
        const updatedUsed = {...this.user, currentWorkspace: workspace};
        this.user = updatedUsed;
        this.currentWorkspace = workspace;
        this.userService.setUser(updatedUsed);
        this.userService.onUserChanged().subscribe();
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  byId(a: Type, b: Type): boolean {
    if (!b) return false;
    return a.id === b.id;
  }

  canDeactivate(): Observable<boolean> | boolean {
    this.submitted = true;
  
    if (this.isEditDisabled(this.campaign)) {
      return true;
    }
  
    const originalCampaignCopy = _.cloneDeep(this.campaignOriginal);
    const currentCampaignCopy = _.cloneDeep(this.campaign);
  
    if (originalCampaignCopy.actiview && currentCampaignCopy.actiview) {
      originalCampaignCopy.actiview.frame ? delete originalCampaignCopy.actiview.frame.link : '';
      currentCampaignCopy.actiview.frame ? delete currentCampaignCopy.actiview.frame.link : '';
    }
  
    return this.utilService.canDeactivate(currentCampaignCopy, originalCampaignCopy) && this.isValidCampaignInfosGen(this.campaign);
  }

  private isValidCampaignInfosGen(campaign: CampaignInfosGen) {
    const formValid = !_.isEmpty(campaign.name)
      && _.has(campaign, 'studyType.id')
      && _.has(campaign, 'evaluationType.id')
      //  && (_.has(campaign, 'focus') || campaign.isTemplate)
      && (_.has(campaign, 'startDate') || campaign.isTemplate)
      && (_.has(campaign, 'hub') || campaign.isTemplate)
    // && (_.has(campaign, 'fieldWork') || campaign.isTemplate);
    if (!formValid) {
      this.errorManager.displayMessage('INFORMATION_GENERAL_ERROR', "danger");
      return false;
    }
    return true;
  }

  clickCancel() {
    this.campaign = _.cloneDeep(this.campaignOriginal);
    this.initDate(this.campaign);
    this.initVariableCampaign(this.campaign);
  }

  getDateObject(date: Date): any {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  getSynergyData(bridgeNumber: string): Observable<SynergyDemand> {
    this.synergyIncorrect = false;
    this.synergyLoading = true;
    return this.synergyDemandService.getSynergyDemandById(bridgeNumber, true).pipe(
      catchError(err => {
        this.synergyLoading = false;
        this.synergyIncorrect = true;
        return observableThrowError(err);
      }),
      mergeMap((synergy: SynergyDemand) => {
        this.synergyLoading = false;
        if (synergy.studyId && synergy.studyId !== this.campaign.id) {
          this.errorManager.displayMessage("BRIDGE_ALREADY_LINKED", "danger");
          return observableThrowError("BRIDGE_ALREADY_LINKED");
        } else {
          this.errorManager.displayMessage("ON_SUCCESS_UPDATE_ACTIVIEW_ARCS_BRIDGE");
          this.synergyLocked = true;
          this.campaign.synergy = synergy;
          this.campaign.synergy.studyId = this.campaign.id;
          //24341 Ajout des objectifs dans la description de l'étude
          this.campaign.description = synergy.objectives;
          this.campaignService.updateCampaignWithSynergy(this.campaign.id, this.campaign.synergy).subscribe();
          return of(synergy);
        }
      }));
  }

  goTo(campaign: CampaignInfosGen) {
    if (_.has(campaign, 'metier.id')) {
      this.router.navigate(['campaigns', campaign.id, 'edit', 'protocol'], { state: { campaign: campaign } });
    } else {
      this.router.navigate(['campaigns', campaign.id, 'edit', 'workflows']);
    }
  }

  initArcsSystem = (campaign: CampaignInfosGen): HubsById => {
    const hub = this.userService.getUser().hub;
    return hub ? hub : HubsById.France;
  }
  initHorsArcsSystem = (campaign: CampaignInfosGen): HubsById => {
    const hub = this.referenceType.getHubByIdFromHub(this.campaign.hub);
    return hub ? hub : HubsById.France;
  }
  private initDate(campaign: CampaignInfosGen) {
    if (campaign.startDate) {
      this.startDate = this.getDateObject(new Date(campaign.startDate));
    }
    if (campaign.endDate) {
      this.endDate = this.getDateObject(new Date(campaign.endDate));
    }
  }

  isChangedCampaign() {
    const originalCampaignCopy = _.cloneDeep(this.campaignOriginal);
    const currentCampaignCopy = _.cloneDeep(this.campaign);
  
    if (originalCampaignCopy?.actiview && currentCampaignCopy?.actiview?.frame) {
      delete originalCampaignCopy.actiview.frame.link;
      delete currentCampaignCopy.actiview.frame.link;
    }
  
    return this.utilService.isDifferent(originalCampaignCopy, currentCampaignCopy);
  }

  isEditDisabled(campaign: CampaignInfosGen) {
    return campaign.state !== CampaignStates.Draft && campaign.state !== CampaignStates.Suspended;
  }

  isEditDisabledActiviewSynergy(campaign: CampaignInfosGen) {
    return campaign.state === CampaignStates.Published || campaign.state === CampaignStates.Finished || campaign.state === CampaignStates.Archived;
  }

  isValidSynergyArcsActiview = (): boolean => {
    let idSynergy = _.get(this.campaign, 'synergy.requestNumber', '');
    let idActiview = _.get(this.campaign, 'actiview.activityNumber', '');
    let arcsOK = true;
    for (let arcs of _.get(this.campaign, "arcs", [])) {
      if (!this.arcsNumber.find(a => a === arcs.arcsStudy)) {
        arcsOK = false;
      }
    }
    if (this.requestNumber == idSynergy && this.activityNumber == idActiview && arcsOK) {
      return true;
    } else {
      this.errorManager.displayMessage("ON_NOT_REFRESHED_ACTIVIEW_ARCS_BRIDGE", "warning", {
        timeOut: 8000
      });
      return false;
    }
  }

  linkSynergyDemandWithCampaign(synergyDemand: SynergyDemand, campaign: Campaign): Observable<SynergyDemand> {
    return this.synergyDemandService.patchSynergyDemand(synergyDemand, campaign.id);
  }

  addArcs = () => {
    this.translationService.translateMessage('ADD_ARCS_NUMBER').pipe(
      mergeMap(title => this.openModalAddArcsNumber(title).then((result: { arcsNumber: string, users: ArcsParticipation[] }) => {
        if (_.get(result, 'arcsNumber', '')) {
          this.arcsNumber.push(result.arcsNumber);
          this.arcsSystem.push(this.initArcsSystem(this.campaign));
          this.arcsLocked.push(true);
          this.arcsLoading.push(false);
          this.arcsIncorrect.push(false);
          this.arcsData.push(this.arcsNumber.length);
          if (_.get(result, 'users', '')) {
            this.campaign.arcs.push(this.utilService.getVolunteersForProject(result.users, this.initArcsSystem(this.campaign), result.arcsNumber));
          }
        }
      }))
    ).subscribe();
  }
  associateGroupHorsArcs =()=>{
    this.translationService.translateMessage('ASSOCIATE_GROUP').pipe(
      mergeMap(title => this.openModalAssociateHorsArcsGroup(title).then((result: { groupNameHorsArcs: string })=>
      {
        if (_.get(result, 'groupNameHorsArcs', '')) {
          this.groupsNameHorsArcs.push(result.groupNameHorsArcs);
          this.horsArcsSystem.push(this.initHorsArcsSystem(this.campaign));
          this.horsArcsLocked.push(true);
          this.horsArcsLoading.push(false);
          this.horsArcsIncorrect.push(false);
          this.horsArcsData.push(this.groupsNameHorsArcs.length);
      }}))
    ).subscribe();


  }
  private openModalAssociateHorsArcsGroup(title: string) {
    const modal = this.modalService.open(AssociateHorsArcsModalComponent, {
      keyboard: false,
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.title = title;
    modal.componentInstance.studyId = this.campaign.id;
    modal.componentInstance.studyHub = this.campaign.hub;
    modal.componentInstance.groupsNameHorsArcs = this.groupsNameHorsArcs;
    modal.componentInstance.horsArcsSystem = this.referenceType.getHubByIdFromHub(this.campaign.hub);
    return modal.result.then(
      (result: { groupNameHorsArcs: string }) => { return result },
      (reason) => { }
    );
  }
  private openModalAddArcsNumber(title: string) {
    const modal = this.modalService.open(AddArcsModalComponent, {
      keyboard: false,
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.title = title;
    modal.componentInstance.arcsNumbers = this.arcsNumber;
    return modal.result.then(
      (result: { arcsNumber: string, users: ArcsParticipation[] }) => { return result },
      (reason) => { }
    );
  }

  private onUpdateSuccess() {
    this.campaignOriginal = this.campaign;
    this.utilService.updateMenuTabs();
    this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
    this.goTo(this.campaign);
  }

  onDateChanged(date: NgbDateStruct, input: string) {
    if (date) {
      this.campaign[input] = new Date(Date.UTC(date.year, date.month - 1, date.day)).getTime();
    } else {
      delete this.campaign[input];
    }
  }

  onErrorRequest = (err: Response) => {
    this.showSpinner = false;
    this.errorManager.catchError(err);
    return observableThrowError(err);
  }


  /**
   * Si un ou plusieurs numéros arcs ont été ajoutés à l'étude, alors les volontaires arcs récupérés
   * en amont seront ajoutés aux volontaires existants.
   * @param campaign
   */
  private addArcsVolunteersToCampaign(campaign: CampaignInfosGen) {
    let volunteers = [];
    for (let arcs of campaign.arcs) {
      volunteers = volunteers.concat(this.utilService.getVolunteersForCampaign(arcs));
    }
    campaign.users.volunteers = _.unionBy(campaign.users.volunteers, volunteers, 'name');
  }


  private save() {
    if (this.isValidSynergyArcsActiview()) {
      this.showSpinner = true;
      this.campaignService.onChangeTemplateEmit(this.campaign.isTemplate);
      this.addArcsVolunteersToCampaign(this.campaign);
      this.updateSynergy(this.campaign, this.campaignOriginal).pipe(
        flatMap(campaign => this.campaignService.putCampaignInfoGen(campaign)),
        catchError(err => {
          this.showSpinner = false;
          this.errorManager.displayMessage('ON_ERROR_UPDATE', 'danger');
          throwError(err);
          return of();
        }),
        tap(() => this.onUpdateSuccess()),
        takeUntil(this.destroy$),
        finalize(() => this.showSpinner = false)
      ).subscribe();
    }
  }

  // A METTRE DANS PUT CAMPAIGN INFO GEN
  updateSynergy(campaign: CampaignInfosGen, campaignOriginal: CampaignInfosGen): Observable<CampaignInfosGen> {
    if (!campaignOriginal.synergy && campaign.synergy && campaign.synergy.requestNumber) {
      console.log("SYNERGY ADDED");
      campaign.synergy.studyId = campaign.id;
      return this.synergyDemandService.patchSynergyDemand(campaign.synergy, campaign.id).pipe(map(() => campaign));
    } else if (campaignOriginal.synergy && !campaign.synergy) {
      console.log("SYNERGY REMOVED");
      return this.campaignService.unlinkSynergyDemandWithCampaign(campaignOriginal.synergy).pipe(map(() => campaign))
    } else if (campaignOriginal.synergy && campaign.synergy && campaign.synergy.requestNumber !== campaignOriginal.synergy.requestNumber) {
      console.log("SYNERGY CHANGED");
      return this.campaignService.unlinkSynergyDemandWithCampaign(campaignOriginal.synergy).pipe(
        mergeMap(() => this.synergyDemandService.patchSynergyDemand(campaign.synergy, campaign.id)),
        map(() => campaign))
    } else {
      return observableOf(campaign);
    }
  }

  saveAndUpdate(form: NgForm) {
    if (this.activiewLoading || this.synergyLoading) {
      this.errorManager.displayMessage("WAIT_FOR_ACTIVIEW_SYNERGY_BRIDGE", "warning");
    } else if (this.campaign.actiview && this.addActiviewAfterPublication) {
      this.campaign.actiview.frame = this.campaign.frame;
      this.showSpinner = true;
      console.log("MAJ Actiview")
      this.campaignService.putCampaignInfoGen(this.campaign).pipe(
        catchError(err => {
      console.log("catch error actiview")

          this.showSpinner = false;
          this.errorManager.displayMessage('ON_ERROR_UPDATE', 'danger');
          throwError(err);
          return of();
        }),
        tap(() => this.addActiviewAfterPublication = false),
        tap(() => this.onUpdateSuccess()),
        takeUntil(this.destroy$),
        finalize(() => this.showSpinner = false)
      ).subscribe()
    } else if (this.campaign.endDate < this.campaign.startDate) {
      this.errorManager.displayMessage("END_DATE_ERROR", "danger");
    }
    else {
      this.submitted = true;
      if (!form.invalid) {
        this.save();
      } else {
        this.errorManager.displayMessage("ON_ERROR_FORM", "danger");
      }
    }
  }

  unlinkSynergy() {
    this.unlink(UnlinkType.Synergy);
  }

  unlinkActiview() {
    this.unlink(UnlinkType.Actiview);
  }

  unlinkArcs(index) {
    this.unlink(UnlinkType.Arcs, index);
  }

  unlinkHorsArcs(index) {
    if (this.campaign.groupsWithStartedEvaluations && this.campaign.groupsWithStartedEvaluations.includes(this.groupsNameHorsArcs[index]))
    {
      this.errorManager.displayMessage('CANT_UNLINK_GROUP_HORSARCS_EVALUATIONS_STARTED', "danger");
    }
    else
      this.openModalUnlink(UnlinkType.HorsArcs, index).pipe(tap(() => this.unlinkHorsArcsStudy(index))).subscribe(next => { }, error => { this.errorManager.displayMessage("ERROR_DELETE_HORSARCS", "danger", {
        timeOut: 8000
      }, true) });
  }

  private unlink(type: string, index?: number) {
    const isNotPublished$ = observableOf(this.campaign).pipe(
      filter(c => !this.isEditDisabledActiviewSynergy(c)))

    const isPublished$ = observableOf(this.campaign).pipe(
      filter(c => this.isEditDisabledActiviewSynergy(c)),
      mergeMap(() => this.openModalUnlink(type, index)))

    isNotPublished$.pipe(merge(isPublished$),
      tap(() => {
        switch (type) {
          case UnlinkType.Actiview:
            this.unlinkActiviewProject();
            break;
          case UnlinkType.Synergy:
            this.unlinkSynergyDemand();
            break;
          case UnlinkType.Arcs:
            this.unlinkArcsStudy(index);
            break;
        }
      }),
      filter(() => this.isEditDisabledActiviewSynergy(this.campaign)),
      tap(() => this.save()),
      takeUntil(this.destroy$))
      .subscribe(error => { });
  }

  private openModalUnlink(type: string, index: number = 0) {
    switch (type) {
      case UnlinkType.Actiview:
        return this.utilService.translateMessageModal('CONFIRM_UNLINK_ACTIVIEW', this.campaign.name, this.activityNumber).pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)));
      case UnlinkType.Synergy:
        return this.utilService.translateMessageModal('CONFIRM_UNLINK_SYNERGY', this.campaign.name, this.requestNumber).pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)));
      case UnlinkType.Arcs:
        return this.utilService.translateMessageModal('CONFIRM_UNLINK_ARCS', this.campaign.name, this.arcsNumber[index]).pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)));
      case UnlinkType.HorsArcs:
        return this.utilService.translateMessageModal('CONFIRM_UNLINK_HORSARCS', this.campaign.name, this.groupsNameHorsArcs[index]).pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)));
    }
  }

  private unlinkActiviewProject() {
    this.activiewLocked = false;
    this.activityNumber = "";
    delete this.campaign.actiview;
  }

  private unlinkArcsStudy(index: number = 0) {
    if (index !== 0) {
      this.arcsLocked.splice(index, 1);
      this.arcsNumber.splice(index, 1);
      this.arcsIncorrect.splice(index, 1);
      this.arcsLoading.splice(index, 1);
      this.arcsSystem.splice(index, 1);
      this.arcsData.splice(index, 1);
    } else {
      this.arcsLocked[index] = false;
      this.arcsNumber[index] = "";
      this.arcsIncorrect[index] = false;
      this.arcsLoading[index] = false;
    }
    this.campaign.arcs.splice(index, 1);
  }

  private unlinkHorsArcsStudy(index: number = 0) {
    this.campaignService.deleteNonArcVolunteers(this.horsArcsSystem[index], this.groupsNameHorsArcs[index], this.campaign.id).pipe(
      catchError(err => {
        return observableThrowError(err);
      }),
      tap(() => {
        if (this.horsArcsData.length !== 0) {
          this.horsArcsLocked.splice(index, 1);
          this.groupsNameHorsArcs.splice(index, 1);
          this.horsArcsIncorrect.splice(index, 1);
          this.horsArcsLoading.splice(index, 1);
          this.horsArcsSystem.splice(index, 1);
          this.horsArcsData.splice(index, 1);
        } else {
          this.horsArcsLocked = [];
          this.groupsNameHorsArcs = [];
          this.horsArcsIncorrect = [];
          this.horsArcsLoading = [];
          this.horsArcsData = [];
          this.horsArcsSystem = [];
        }

        this.errorManager.displayMessage("SUCCES_DELETE_HORSARCS", "success", {
          timeOut: 8000
        }, true);
      })
    ).subscribe();


  }

  private unlinkSynergyDemand() {
    this.synergyLocked = false;
    this.requestNumber = "";
    delete this.campaign.synergy;
  }

  getActiviewData(activityNumber: string, requestNumber: string): Observable<Actiview> {
    this.activiewLoading = true;
    if (activityNumber) {
      this.activiewIncorrect = false;
      return this.httpRestService.getActiview(activityNumber, true).pipe(
        catchError(err => {
          this.activiewIncorrect = true;
          this.activiewLoading = false;
          return observableThrowError(err);
        }),
        tap(actiview => this.campaign.actiview = {...actiview.input,
          frame: {
            personalDataComment: actiview.output.x_fst_personal_com ? actiview.output.x_fst_personal_com : this.campaign.frame.personalDataComment,
            personalDataManagementAndFrameId: actiview.output.x_fsl_personal_frame ? actiview.output.x_fsl_personal_frame.code : this.campaign.frame.personalDataManagementAndFrameId,
            link : actiview.output.x_lnk_frame_link ? actiview.output.x_lnk_frame_link : this.campaign.frame.link
        }, ...actiview.output}),
        tap(actiview => this.requestNumber = actiview.input.requestNumber || ""),
        tap(() => this.campaign.actiview.activityNumber = activityNumber),
        tap(() => this.activityNumber = activityNumber),
        tap(() => this.activiewLoading = false),
        tap(() => this.activiewLocked = true)
      );
    } else {
      return this.httpRestService.getActiviewBySynergy(requestNumber, true).pipe(
        catchError(err => {
          this.activiewLoading = false;
          this.errorManager.displayMessage('ERROR_LOAD_ACTIVIEW_FROM_SYNERGY', "danger");
          return observableThrowError(err);
        }),
        tap(actiview => {
          if (actiview && actiview.activityNumber) {
            this.campaign.actiview = {...actiview.input,
              frame: {
                personalDataComment: actiview.output.x_fst_personal_com ? actiview.output.x_fst_personal_com : this.campaign.frame.personalDataComment,
                personalDataManagementAndFrameId: actiview.output.x_fsl_personal_frame ? actiview.output.x_fsl_personal_frame.code : this.campaign.frame.personalDataManagementAndFrameId,
                link : actiview.output.x_lnk_frame_link ? actiview.output.x_lnk_frame_link : this.campaign.frame.link
            }, ...actiview.output};
            this.campaign.actiview.activityNumber = actiview.activityNumber;
            this.activityNumber = actiview.activityNumber;
            this.activiewLocked = true;
          }
        }),
        tap(() => this.activiewLoading = false)
      );
    }
  }

  updateActiviewInProject(activityNumber: string) {

    if (activityNumber) {
      this.getActiviewData(activityNumber, null).pipe(
        mergeMap(actiview => this.getSynergyData(actiview.input.requestNumber)),
        mergeMap(synergy => this.getOrchestraData(synergy.projectNumber)),
        tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE_ACTIVIEW_ARCS_BRIDGE')),
        takeUntil(this.destroy$))
        .subscribe();
    } else {
      this.activiewIncorrect = true;
    }
  }

  updateArcsInProject(arcsNumber: string, arcsSystem: HubById, index: number) {
    if (arcsNumber) {
      this.arcsIncorrect[index] = false;
      this.arcsLoading[index] = true;

      this.httpRestService.getArcsInformation(arcsSystem, arcsNumber).pipe(
        catchError(err => {
          this.arcsIncorrect[index] = true;
          this.arcsLoading[index] = false;
          return observableThrowError(err);
        }),
        tap(users => {
          this.campaign.arcs[index] = this.utilService.getVolunteersForProject(users.list, arcsSystem, arcsNumber);
          this.arcsLoading[index] = false;
          this.arcsLocked[index] = true;
        }),
        tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE_ACTIVIEW_ARCS_BRIDGE')),
        takeUntil(this.destroy$))
        .subscribe();
    } else {
      this.arcsIncorrect[index] = true;
    }
  }

  updateHorsArcsInProject(horsArcsNumber: string, horsArcsSystem: HubById, index: number) {
    if (horsArcsNumber) {
      this.horsArcsIncorrect[index] = false;
      this.horsArcsLoading[index] = true;

      this.httpRestService.getArcsInformation(horsArcsSystem, horsArcsNumber).pipe(
        catchError(err => {
          this.horsArcsIncorrect[index] = true;
          this.horsArcsLoading[index] = false;
          return observableThrowError(err);
        }),
        tap(users => {
          this.campaign.arcs[index] = this.utilService.getVolunteersForProject(users.list, horsArcsSystem, horsArcsNumber);
          this.horsArcsLoading[index] = false;
          this.horsArcsLocked[index] = true;
        }),
        tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE_ACTIVIEW_ARCS_BRIDGE')),
        takeUntil(this.destroy$))
        .subscribe();
    } else {
      this.horsArcsIncorrect[index] = true;
    }
  }

  updateSynergyInProject(bridgeNumber: string) {
    if (bridgeNumber) {
      return this.getSynergyData(bridgeNumber).pipe(
        mergeMap(synergy => this.getActiviewData(null, synergy.requestNumber)),
        mergeMap(() => this.getOrchestraData(_.get(this.campaign, 'synergy.projectNumber', ''))),
        takeUntil(this.destroy$))
        .subscribe();
    } else {
      this.synergyIncorrect = true;
    }
  }

  getOrchestraData(orchestraID: string): Observable<OrchestraDemand> {
    if (_.isEmpty(orchestraID)) {
      return observableOf();
    }
    return this.synergyDemandService.getOrchestraById(orchestraID).pipe(
      tap((orchestra) => _.set(this.campaign, 'orchestra.projectName', orchestra.name)),
      tap(() => _.set(this.campaign, 'orchestra.orchestraNumber', orchestraID)))
  }

  goToActiview(id) {
    window.open(environment.actiview_url() + id)
  }

  goToSynergy(id) {
    window.open(environment.synergy_url() + id)
  }

  updateActiviewInProjectWithSynergy() {
    if (this.campaign.synergy && this.campaign.synergy.requestNumber) {
      console.log("mise à jour avec ", this.campaign.synergy.requestNumber)
      this.getActiviewData(null, this.campaign.synergy.requestNumber).pipe(
        tap(() => this.addActiviewAfterPublication = true),
      ).subscribe();
    } else {
      this.errorManager.displayMessage('Pas de numéro synergy')
    }
  }

  openModalGroupeVolunteer() {
    const modal = this.modalService.open(ModalGroupVolunteersComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
    });
    modal.componentInstance.studyId = this.campaign.id;
    modal.componentInstance.studyName = this.campaign.name;
    modal.componentInstance.studyHub = this.campaign.hub;
    modal.componentInstance.horsArcsSystem = this.initHorsArcsSystem(this.campaign);
    modal.componentInstance.isUserAdmin = this.user.roles.includes('DNA_ADMIN');
    modal.result.then(
      (data) => {
        if (data !== "")
          this.saveNewHorsArcsGroup(data);
      },
      (reason) => { }
    );
  }

  saveNewHorsArcsGroup(horsArcsGroup) {
    this.groupsNameHorsArcs.push(horsArcsGroup);
    this.horsArcsSystem.push(this.initHorsArcsSystem(this.campaign));
    this.horsArcsLocked.push(true);
    this.horsArcsLoading.push(false);
    this.horsArcsIncorrect.push(false);
    this.horsArcsData.push(this.groupsNameHorsArcs.length);
  }

  goToPerformanceCenter(activiewNumber) {
    window.open(environment.pfc_base_url()+ "studies/" + activiewNumber)
  }

  onPersonalDataFrameChange(code: string) {
    if (!this.framePersonalData || !Array.isArray(this.framePersonalData)) {
      return '';
    }
    const selectedOption = this.framePersonalData.find(option => option.code == code);
    if (selectedOption) {
      return selectedOption.name[this.languageMap[this.currentLanguage]] || selectedOption.name[ActiviewLang.english];
    }

    return ''
  }

  loadFramePersonalData(entityId) {
    this.httpRestService.getFramePersonalData(entityId).pipe(
      catchError(err => {
        console.error('Error fetching frame personal data', err);
        return of([]);
      })
    ).subscribe((data: any) => {
      this.framePersonalData = data.values;
    });
  }
  private initializeFrame(campaign) {
    if ( !campaign.frame || campaign.frame == '' ) {
      this.campaign.frame = {
        link: '',
        personalDataManagementAndFrameId: '',
        personalDataComment: ''
      };

      this.campaignOriginal.frame = {
        link: '',
        personalDataManagementAndFrameId: '',
        personalDataComment: ''
      };
    }
  }
}
