<div class="container">
  <div class="ibox float-e-margins">
    <ngx-loading [show]="showSpinner"></ngx-loading>
    <div class="ibox-title">
      <div class="row">
        <div class="col-8">
          <h5>{{ "DESCRIPTOR" | translate }}</h5>
        </div>
        <div class="col-4" *ngIf="!isUpdated">
          <button class="btn btn-primary pull-right" type="button" (click)="back()" translate>BACK</button>
        </div>
        <div class="col-4" *ngIf="isUpdated">
          <button class="btn btn-primary" type="button" (click)="save()" translate>SAVE</button>
          <button class="btn btn-outline-secondary" type="button" (click)="cancel()" translate>CANCEL</button>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div class="form-group">
        <div class="py-3 row">
          <label class="col-md-3 form-control-label" translate>DESCRIPTOR_GROUP_NAME</label>
          <div class="col-md-6">
            <span *ngIf="descriptorGroupName?.english; else createName" class="cursor-pointer"
              (click)="openModalEditComponentName()"
              ngbTooltip="{{ 'EDIT_DESCRIPTOR_GROUP_NAME' | translate }}">
              <dna-translate [fieldToTranslate]="descriptorGroupName"> </dna-translate>
            </span>
            <ng-template #createName>
              <span class="cursor-pointer" (click)="openModalEditComponentName()" translate>
                EDIT_DESCRIPTOR_GROUP_NAME
              </span>
            </ng-template>
          </div>
        </div>
        <div class="row">
          <h4 class="col-12" translate>ADDED_DESCRIPTORS</h4>
          <p class="col-12" *ngIf="descriptorGroup.descriptors.length === 0" translate>NO_DESCRIPTOR_ADDED</p>
          <div class="table-responsive" *ngIf="descriptorGroup.descriptors.length > 0">
            <table class="table table-hover table-borderless-th dataTable">
              <thead>
                <tr>
                  <th translate>STUDY</th>
                  <th translate>WORKFLOW</th>
                  <th translate>BLOCK</th>
                  <th translate>DESCRIPTOR</th>
                  <th translate>SCALE</th>
                  <th translate>PERTINANCE</th>
                  <th translate>ACTIONS</th>
                </tr>
                <tr *ngFor='let descriptor of descriptorGroup.descriptors; let index = index'>
                  <td>
                    <span>{{ descriptor.campaignName }}</span>
                  </td>
                  <td>
                    <span>
                      <dna-translate [fieldToTranslate]="descriptor.workflowName"></dna-translate>
                    </span>
                  </td>
                  <td>
                    <span>
                      <dna-translate [fieldToTranslate]="descriptor.blockName"></dna-translate>
                    </span>
                  </td>
                  <td>
                    <span>
                      <dna-translate [fieldToTranslate]="descriptor.name"></dna-translate>{{descriptor.blockSuffix}}
                    </span>
                  </td>
                  <td>
                    <span [ngbTooltip]="descriptor.scaleDetail">{{ descriptor.scale }}</span>
                  </td>
                  <td>
                    <span>{{ descriptor.pertinance }}%</span>
                  </td>
                  <td>
                    <span style="cursor: pointer" class="mr-1" class="fa fa-times" placement="top"
                      (click)="removeDescriptor(index)" ngbTooltip="{{ 'REMOVE' | translate }}"></span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let dataRow of pagedData">
                  <ng-template [ngTemplateOutlet]="templateVariable" [ngTemplateOutletContext]="{ data: dataRow }">
                  </ng-template>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <dna-table class="col-12" [data]="othersDescriptors" [headers]="tableHeaders$ | async" orderedBy="PERTINENCE"
            [pagination]="false" [reverse]="false" [sortFunction]="sort" [title]="'ADD_OTHER_DESCRIPTORS'">
            <ng-template #row let-data="data">
              <tr (click)="addDescriptor(data)" dna-descriptors-row [descriptor]="data"></tr>
            </ng-template>
          </dna-table>
        </div>
      </div>
    </div>
  </div>
</div>
