<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins">
    <form #userForm="ngForm" novalidate name="trainingForm">
      <div class="ibox-title">
        <h5 class="text-center"> {{ 'USERS' | translate }} </h5>
        <div *ngIf="!isDisabled">
          <button id="save" class="btn btn-primary" type="button" (click)="save(userForm)" translate>SAVE</button>
          <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
      <div class="ibox-content">
        <div class="form-group">
          <div class="row">
            <label class="col-3 required form-control-label">{{"TRAINING_MANAGER" | translate}}</label>
            <div class="col-7">
              <tag-input inputClass="tagInputTrainingWf" [disable]='isDisabled' [(ngModel)]="training.users.accountables" displayBy="name"
                identifyBy="key" name="managerToAdd" [onlyFromAutocomplete]="true" (onRemove)="canIRemoveThisElement($event, 'users.accountables','key')"
                placeholder="+ {{ 'USER' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'USER' | translate | lowercase} }}">
                <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="users" displayBy="name" identifyBy="key" [minimumTextLength]="3"
                  [showDropdownIfEmpty]="false">
                </tag-input-dropdown>
              </tag-input>
              <div *ngIf="submitted && training.users.accountables.length == 0" class="error" translate>REQUIRED</div>
            </div>
          </div>
        </div>
        <div class="form-group paddingTop">
          <div class="row">
            <label class="col-3 required form-control-label">{{"PARTICIPANTS" | translate}}</label>
            <div class="col-9">
              <tag-input inputClass="tagInputTrainingWf" [disable]='isDisabled' [(ngModel)]="training.users.participants" displayBy="name"
                identifyBy="name" (onRemove)="canIRemoveThisElement($event, 'users.participants','name')" name="participantsToAdd"
                placeholder="+ {{ 'PARTICIPANT' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: {'object': 'PARTICIPANT' | translate | lowercase} }}">
              </tag-input>
              <div *ngIf="submitted && training.users.participants.length == 0" class="error" translate>
                REQUIRED
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
