import { Orientation } from '../../../types';
import { tap, take, filter, mergeMap, catchError, map, finalize, toArray } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import * as _ from 'lodash';
import { Observable, of, empty, merge, Subject, forkJoin, from } from 'rxjs';

import {
  OnePager,
  Image,
  CapturesUrl,
  Page
} from '../../../types';
import { OnePagerService } from '../../services/one-pager.service';
import { ErrorManagerService } from '../../services/errorManager.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'dna-one-pager-images',
  templateUrl: './one-pager-images.component.html',
  styleUrls: ['./one-pager-images.component.less']
})
export class OnePagerImagesComponent implements OnInit {
  images: Image[] = [];
  imagesUntouched: Image[] = [];
  imagesUpdated: Image[] = [];

  pages: Page[] = [];
  pagesUpdated: Page[] = [];
  pagesUntouched: Page[] = [];

  onePager: OnePager;
  initialOnePager: OnePager;
  showSpinner: boolean;
  isChanged: boolean;
  envName: string;

  takeCapture: Subject<void> = new Subject<void>();
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    private onePagerService: OnePagerService,
    private errorManagerService: ErrorManagerService,
    private utilService: UtilService,
    private ref: ChangeDetectorRef
  ) {
    this.envName = environment.namehttp();
    utilService.isOnOnePagerImage.emit(true);
  }

  ngOnInit() {
    this.showSpinner = true;
    this.onePager = this.onePagerService.getOnePager();
    if (this.onePager.pages && this.onePager.capturesUrls) {
      // Filtrez les pages pour ne garder que celles dont l'id est dans capturesUrls
      this.onePager.pages = this.onePager.pages.filter(page =>
        this.onePager.capturesUrls.some(capture => capture.id === page.id)
      );
    }
    this.initialOnePager = _.cloneDeep(this.onePager);
    this.putIdInCapture(this.onePager);
    this.getImagesFromOnePagerAndCache().pipe(
      tap(images => {
        this.images =
          this.onePagerService.reorderOnePagerImages(_.uniq(_.orderBy(this.onePager.images, 'indexImage', 'asc').map(i => i.id)), images);
        this.imagesUntouched = _.cloneDeep(this.images);
        this.imagesUpdated = _.cloneDeep(this.images);
        this.initPage(this.onePager);
        this.pagesUntouched = _.cloneDeep(this.pages);
        this.pagesUpdated = _.cloneDeep(this.pages);
        setTimeout(() => {
          this.showSpinner = false;
          this.ref.detectChanges();
        }, 200);
      })
    ).subscribe();
  }

  putIdInCapture(onePager: OnePager) {
    _.get(onePager, 'capturesUrls', []).map((c, index) => {
      if (!c.id) {
        const page = _.get(onePager, `pages[${index}]`, false);
        if (page) {
          c.id = page.id;
        }
      }
    });
  }

  getImagesFromOnePagerAndCache(): Observable<Image[]> {
    const images = this.onePagerService.getImages(this.onePager.id);
    return from(_.get(this.onePager, 'images', [])).pipe(
      mergeMap((image: Image) => {
        if (!_.has(image, 'title') || image.title === '') { image.title = image.name; }
        const imageCache = images.find(i => i.url === image.url);
        return imageCache ? of(imageCache) : this.getImageData(image);
      }),
      toArray()
    );
  }

  initPage(onePager: OnePager): void {
    onePager.pages = _.get(onePager, 'pages', []);
    onePager.pages.map(p => {
      p.content.map(c => {
        if (c.url) {
          c.data = _.get(this.images.find(i => i.url === c.url), 'data', of(''));
        }
      });
    });
    this.pages = _.cloneDeep(onePager.pages);
  }

  private getImageData(image: Image): Observable<Image> {
    const env = this.envName === 'production' ? 'prd' : this.envName;
    const imageUrl$ = of(image.url);
    const largeData$ = imageUrl$.pipe(
      filter((imageUrl: string) => imageUrl.split('/')[2] === `largedatarecords${env}.azureedge.net`),
      mergeMap((imageUrl: string) => this.imageFromLargeDataRecord(imageUrl))
    );
    const media$ = imageUrl$.pipe(
      filter((imageUrl: string) => imageUrl.split('/')[2] !== `largedatarecords${env}.azureedge.net` && image.url.split('/')[2] !== `powerbicaptures${env}.azureedge.net`),
      mergeMap((imageUrl: string) => this.imageFromMedia(imageUrl)),
    );
    const powerBI$ = imageUrl$.pipe(
      filter((imageUrl: string) => imageUrl.split('/')[2] === `powerbicaptures${env}.azureedge.net`),
      mergeMap((imageUrl: string) => this.imageFromPowerBI(imageUrl)),
    );

    return merge(largeData$, media$, powerBI$).pipe(
      tap((imgData) => {
        const imageCache = _.cloneDeep(image);
        imageCache.data = of(imgData);
        this.onePagerService.addImage(this.onePager.id, imageCache);
      }),
      tap(data => {
        image.data = of(data);
      }),
      map(() => image)
    );
  }

  catchError = (error) => {
    this.errorManagerService.catchError(error);
    console.log(error);
    return empty();
  }

  imageFromLargeDataRecord(imageUrl: string): Observable<string> {
    return this.onePagerService.getFromLargeDataRecord(imageUrl).pipe(
      catchError(this.catchError),
      map((data: string) => {
        const dataUri = 'data:image/png;base64,';
        return dataUri.concat(data);
      }));
  }

  imageFromMedia(imageUrl: string): Observable<string> {
    return this.onePagerService.getFromMedia(imageUrl).pipe(
      catchError(this.catchError));
  }

  imageFromPowerBI(imageUrl: string): Observable<string> {
    return this.onePagerService.getFromPowerBI(imageUrl).pipe(
      catchError(this.catchError));
  }

  onUpdatedGrid(pages: Page[]) {
    this.isChanged = true;
    this.pagesUpdated = pages;
  }

  onImagesChanged(images: Image[]) {
    this.isChanged = true;
    this.imagesUpdated = images;
  }

  putOnePager(initialOnePager, onePagerToSend, displaySuccess = true): Observable<any> {
    return this.onePagerService.updateOnePager
      (onePagerToSend.id, this.onePagerService.getElementToUpdate(initialOnePager, onePagerToSend)).pipe(
        take(1),
        tap(() => this.onePagerService.setOnePager(onePagerToSend)),
        tap((onePager: OnePager) => this.initialOnePager = _.cloneDeep(onePager)),
        tap(() => {
          this.isChanged = false;
          if (displaySuccess) {
            this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
          }
        }),
        catchError(err => {
          this.isChanged = true;
          this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
          return empty();
        }));
  }

  save() {
    this.takeCapture.next();
  }

  updateIndexesInCapture(onePager: OnePager) {
    _.get(onePager, 'pages', []).map((page, index) => {
      const capture = _.get(onePager, 'capturesUrls', []).find(c => c.id === page.id);
      if (capture) {
        capture.index = index;
      }
    });
  }

  onCapturesDone(result: { data: string[], pages: Page[], displaySuccess?: boolean }) {
    this.pages = _.cloneDeep(result.pages);
    const promises = result.data.length > 0 ? result.data.map((base64, index) =>
      this.onImageLoaded(base64, this.pages[index], index, this.onePager.capturesUrls)) : of([]);
    forkJoin(promises).pipe(
      tap((data: any[]) => {
        this.onePager.pages = this.pagesUpdated;
        this.onePager.images = this.imagesUpdated;
        this.onePager.capturesUrls = data.filter(d => !_.isNil(d));
        this.updateIndexesInCapture(this.onePager);
      }),
      mergeMap(() => this.putOnePager(this.initialOnePager, this.onePager, result.displaySuccess)),
      tap(() => {
        this.pagesUntouched = _.cloneDeep(this.pagesUpdated);
        this.imagesUntouched = _.cloneDeep(this.imagesUpdated);
      }),
      finalize(() => {
        setTimeout(() => {
          this.ref.detectChanges();
        }, 200);
      })
    ).subscribe(() => {
      this.eventsSubject.next();
    });
  }

  onImageLoaded(base64: string, page: Page, index: number, captureURLs: CapturesUrl[] = []): Observable<CapturesUrl> {
    if (base64 === '') {
      // Pas de nouvelle capture, on renvoie l'url de la capture déjà existante
      const capture = captureURLs.find(c => c.id === page.id);
      if (capture) {
        return of(capture);
      } else {
        return of(undefined);
      }
    }
    const [type, media] = base64.split(',');
    return this.utilService.uploadMediaFromComputer(undefined, media, type).pipe(
      catchError(this.catchError),
      map((url: string) => {
        return {
          index: index,
          id: page.id,
          url: url,
          orientation: page.orientation === Orientation.Landscape ? { id: 0, type: 'paysage' } : { id: 1, type: 'portrait' }
        };
      }),
      catchError(this.catchError),
    );
  }

  onCapturesError(err) {
    this.showSpinner = false;
    console.log(err);
    this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
  }

  onCancel() {
    this.pages = _.cloneDeep(this.pagesUntouched);
    this.images = _.cloneDeep(this.imagesUntouched);
    this.isChanged = false;
  }


}
