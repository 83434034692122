import { UtilService } from './../../../../../../shared/services/util.service';
import { Assessment, Training } from './../../../../../../types';
import { Component, OnInit, Input } from '@angular/core';
import { TrainingService } from '../../../../../training.service';

@Component({
  selector: '[dna-assessment-row]',
  templateUrl: './assessment-row.component.html',
  styleUrls: ['./assessment-row.component.less']
})
export class AssessmentRowComponent implements OnInit {

  @Input() assessment: Assessment;
  @Input() training: Training;

  constructor(
    public utilService: UtilService,
    public trainingService: TrainingService
  ) { }

  ngOnInit() {
    this.assessment.users = this.utilService.addNamesToUsers(this.assessment.users, this.trainingService.users);
  }

}
