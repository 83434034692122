<td (click)="goToBridgePlatform()">
  <span class="cursor-pointer">{{ synergyDemand.id }}</span>
</td>
<td (click)="goToBridgePlatform()">
  <span class="cursor-pointer">{{ synergyDemand.state.StateCode }}</span>
</td>
<td (click)="goToBridgePlatform()">
  <span class="cursor-pointer">{{ synergyDemand.priority }}</span>
</td>
<td (click)="goToBridgePlatform()">
  <span class="cursor-pointer">{{ managerName }}</span>
</td>
<td (click)="goToBridgePlatform()">
  <span class="cursor-pointer">{{ referentName }}</span>
</td>
<td (click)="goToBridgePlatform()">
  <span class="cursor-pointer">{{ synergyDemand.requester }}</span>
</td>
<td class="width-action-bar">
  <dna-action-bar class="action" [actionsButtons]="actionsButtons | async" (onClick)="onClickButton($event)">
  </dna-action-bar>
</td>
