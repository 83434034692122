import {
  of as observableOf, throwError as observableThrowError, from as observableFrom,
  Observable,
  of,
  forkJoin,
  zip
} from 'rxjs';

import { merge, mergeMap, take, catchError, tap, map, share, filter } from 'rxjs/operators';
import {
  HttpClient
} from '@angular/common/http';
import {
  EventEmitter,
  Injectable
} from '@angular/core';

import * as _ from 'lodash';

import {
  Campaign,
  FormulaBridgeHair,
  GroupedFormula,
  CampaignsMulti,
  CampaignMultiReferenceTested,
  AnalyseTypes,
  Image,
  ImageBlob,
  Metiers,
  OnePager,
  OnePagerIdentification,
  Order,
  Routine2,
  RoutineStep,
  SextiFormula,
  SimComp,
  Target,
  TypeApplicationMode,
  TypeDryingType,
  TypeMetier,
  User,
  UserInCampaign,
  Visit,
  TargetKeys,
  CapturesUrl,
  CapturesData,
  FormulaBridgeSkin,
  Evaluation,
  RoutineTabFromCampaign,
  RoutineTabForReport,
  RoutineTabFromEvaluations,
  CampaignOnePager,
  Volunteers,
  FicheCaracterisation,
  OnePagerPreview,
  OnePagerPreviewImages,
  Workflow,
  RoutinesTab,
  OnePagerHeaderInfo,
  ReferencePhoto
} from '../../types';
import { CampaignService } from '../../campaigns/campaigns.service';
import { DNATranslateService } from './translate.service';
import { environment } from '../../../environments/environment';
import { MultiCampaignsService } from '../../multi-campaigns/multi-campaigns.service';
import { VolunteerService } from '../../volunteers/volunteers.service';

interface Orchestra {
  name: string;
}

@Injectable()
export class OnePagerService {
  campaignMulti: CampaignsMulti;
  campaign: CampaignOnePager;
  metiers: typeof Metiers = Metiers;
  onePager: OnePager = new OnePager();
  images: {
    [idOnePager: string]: Image[]
  } = {};
  public stopSpinner: EventEmitter<any> = new EventEmitter();
  onePagerHeaderInfo: OnePagerHeaderInfo;

  constructor(
    private campaignService: CampaignService,
    private campaignMultiService: MultiCampaignsService,
    private http: HttpClient,
    private translateService: DNATranslateService,
    private volunteerService: VolunteerService

  ) { }

  /********** REQUESTS **********/

  createOnePager(onePager: OnePager): Observable<OnePager> {
    return this.http.post<OnePager>(`${environment.apiStudiesUrl}/v1/onePagers`, onePager);
  }

  deleteOnePager(idOnePager: string): Observable<OnePager> {
    return this.http.delete<OnePager>(`${environment.apiStudiesUrl}/v1/onePagers/${idOnePager}`);
  }

  getOnePagerById(idOnePager: string): Observable<any> {
    return this.http.get<any>(`${environment.apiStudiesUrl}/v1/onePagers/${idOnePager}`);
  }

  getOnePagerMultiById(idOnePager: string): Observable<any> {
    return this.http.get<any>(`${environment.apiStudiesUrl}/v1/campaignsMulti/onePagers/${idOnePager}/multi`);
  }

  getOnePagers(): Observable<OnePager[]> {
    return this.http.get<OnePager[]>(`${environment.apiStudiesUrl}/v1/onePagers`);
  }

  getOrchestraName(id: string): Observable<Orchestra> {
    return _.isString(id) ? this.http.post<Orchestra>(`${environment.apiStudiesUrl}/orchestra`, {
      id: id
    }) : observableThrowError('please provide an correct id');
  }

  updateOnePager(onePagerId: string, elementsUpdated: any, campaignId?: string): Observable<OnePager> {
    return this.http.put<OnePager>(`${environment.apiStudiesUrl}/v1/onePagers/${onePagerId}`, { elementsUpdated: elementsUpdated, campaignId: campaignId });
  }

  putActiview(idActiview: string, actiview: any) {
    return this.http.put(`${environment.apiStudiesUrl}/v1/actiview/${idActiview}`, actiview);
  }

  getOnePagerPreview(idCampaign: string, idOnePager: string, requestNumber: string): Observable<OnePagerPreview> {
    return this.http.get<OnePagerPreview>(`${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/onePager/${idOnePager}/synergy/${requestNumber}`);
  }

  /********** FUNCTIONS **********/

  buildOnePagerContext(newOnePager: OnePager, campaign: CampaignOnePager) {
    // Récupération et nettoyage des objectifs
    let objectives = _.get(campaign, 'synergy.objectives', newOnePager.objectives);
    // Utilise une expression régulière pour rechercher et supprimer toutes les balises HTML
    // (tout ce qui est entre "<" et ">") dans la chaîne de caractères `objectives`,
    // afin de nettoyer le texte et éviter l'injection de code HTML indésirable.
    if(objectives){
      newOnePager.objectives = objectives.replace(/<\/?[^>]+(>|$)/g, "");
    }
    // Mise à jour du serveur
    this.updateOnePager(newOnePager.id, {objectives: newOnePager.objectives})
      .pipe(
        // Gestion des erreurs
        catchError(error => {
          return observableThrowError(error); // Utilisation de observableThrowError pour propager l'erreur
        }),
        // Gestion de la réponse du serveur
        map(updatedOnePager => {
          return updatedOnePager; // Retourne le OnePager mis à jour pour une utilisation ultérieure si nécessaire
        })
      )
      .subscribe();
  }

  buildOnePagerFromCampaign(initialOnePager: OnePager, newOnePager: OnePager, campaign: CampaignOnePager): OnePager {
    if (!newOnePager.isBuild) {
      this.buildOnePagerHead(newOnePager.identification, campaign);
      this.buildOnePagerContext(newOnePager, campaign);
      this.buildOnePagerTargetProtocol(newOnePager, campaign);
      newOnePager.isBuild = true;
    }
    this.addExperts(newOnePager, campaign);
    this.buildOnePagerProductsTable(newOnePager, campaign);
    this.updateOnePager(newOnePager.id, this.getElementToUpdate(initialOnePager, _.omit(newOnePager, 'volunteersNumber'))).pipe(take(1)).subscribe();
    return newOnePager;
  }

  addExperts(newOnePager: OnePager, campaign: Campaign | CampaignOnePager) {
    newOnePager.identification.expert = _.uniqBy(newOnePager.identification.expert.concat(_.get(campaign, 'experts', [])), 'key');
  }

  buildOnePagerHead(identification: OnePagerIdentification, campaign: Campaign | CampaignOnePager) {
    if (!identification) {
      identification = new OnePagerIdentification();
    }
    identification.projectName = _.get(campaign, 'orchestra.projectName', _.get(identification, 'projectName', ''));
    identification.actiview = _.get(campaign, 'actiview.activityNumber', _.get(identification, 'actiview', ''));
    identification.bridge = _.get(campaign, 'synergy.requestNumber', _.get(identification, 'synergy', ''));
    identification.orchestra = _.get(campaign, 'actiview.projectNumber', _.get(identification, 'orchestra', ''));
    identification.fieldwork = _.get(campaign, 'fieldWork.value', _.get(identification, 'fieldwork', ''));
    identification.estimatedContribution = _.get(campaign, 'protocol.estimatedContribution', _.get(identification, 'estimatedContribution', ''));

    const referentUsers = [];
    _.get(campaign, 'synergy.referent', []).forEach(referent => this.findUserAndAddToArray(referent, referentUsers));
    if (!_.isEmpty(referentUsers)) {
      identification.expert = referentUsers;
    } else {
      this.findUserAndAddToArray(_.head(_.get(campaign, 'users.accountables')), referentUsers);
      identification.expert = _.isEmpty(_.get(identification, 'expert'))
        ? referentUsers
        : _.get(identification, 'expert');
    }


    const manager = _.get(this.campaignService, 'users', []).find(u => u && u.key === _.get(campaign, 'synergy.manager', ''));
    identification.manager = _.isNil(manager) ? [] : [manager.name];

    identification.laboratory = _.get(campaign, 'synergy.requester', _.get(identification, 'laboratory', ''));

    identification.hub = _.get(campaign, 'hub', _.get(identification, 'hub', ''));

    identification.fieldwork = _.get(campaign, 'fieldwork', _.get(identification, 'fieldwork', ''));

    const date = campaign.endDate ? `${new Date(campaign.startDate).toLocaleDateString()} - ${new Date(campaign.endDate).toLocaleDateString()}` : new Date(campaign.startDate).toLocaleDateString();
    identification.studyDate = date;
  }

  buildOnePagerProductsTable(newOnePager: OnePager, campaign: CampaignOnePager) {
    const productsTableFromCampaign: SextiFormula[] = (_.get(campaign, 'parameters.isRoutine') || _.get(campaign, 'isRoutine'))
      ? this.setOnePagerSexti(_.get(campaign, 'formula.routines', []), campaign.name)
      : [];
    this.generateProductsTables(newOnePager, campaign, productsTableFromCampaign);
  }

  buildOnePagerTargetProtocol(newOnePager: OnePager, campaign: CampaignOnePager) {
    newOnePager.protocol = _.get(campaign, 'protocol.description', _.get(newOnePager, 'protocol', ''));

    let target = '';
    if (campaign.target) {
      target = this.stringifyCampaignTarget(campaign.target);
    }

    newOnePager.targetAssignment = [{
      target: target,
      volunteersNumber: !_.isUndefined(newOnePager.volunteersNumber) ? newOnePager.volunteersNumber : campaign.users.volunteersNumber
    }];
  }

  private findUserAndAddToArray(user: User, array: User[]): void {
    const userFound = _.get(this.campaignService, 'users', []).find(u => u.key === _.get(user, 'key', ''));
    if (userFound) { array.push(userFound); }
  }

  updateImagesAndPutOnePager(images: Image[], onePager: OnePager): Observable<OnePager> {
    const onePagerToSend = _.cloneDeep(onePager);
    onePagerToSend.images = images.map((i: Image) => _.pick(i, ['name', 'url']));
    return this.updateOnePager(onePagerToSend.id, _.pick(onePagerToSend, ['images']));
  }

  addCaptureInOnePagerImages(onePager: OnePager, image: Image) {
    onePager.images.push(image);
    return onePager;
  }

  generateProductsTables(onePager: OnePager, campaign: Campaign | CampaignOnePager, productsTableFromCampaign) {
    if (_.get(campaign, 'formula.simultaneousComparison.isActive')) {
      const coupleArray = _.get(campaign, 'formula.simultaneousComparison.couples', []).filter(couple => couple.isActive);
      const coupleTested = this.getTestedCouples(productsTableFromCampaign, coupleArray, _.get(campaign, 'formula.listFormulas', []));
      const coupleReference = this.getReferenceCouples(productsTableFromCampaign, coupleArray, _.get(campaign, 'formula.listFormulas', []));
      onePager.formulas.tested = coupleTested;
      onePager.formulas.reference = coupleReference;
    } else {
      onePager.formulas.tested = productsTableFromCampaign;
    }
    return onePager;
  }

  // Getter & Setter: Campaign
  getCampaign() {
    return this.campaign;
  }

  setCampaign(campaign) {
    this.campaign = campaign;
  }

  getCampaignMulti() {
    return this.campaignMulti;
  }

  setCampaignMulti(campaignMulti) {
    this.campaignMulti = campaignMulti;
  }

  getOnePagerHeaderInfo() {
    return this.onePagerHeaderInfo;
  }

  setOnePagerHeaderInfo(onePagerHeaderInfo: OnePagerHeaderInfo) {
    this.onePagerHeaderInfo = onePagerHeaderInfo;
  }

  private getCampaignDate(campaign: Campaign) {
    return campaign.endDate
      ? `${new Date(campaign.startDate).toLocaleDateString()} - ${new Date(campaign.endDate).toLocaleDateString()}`
      : new Date(campaign.startDate).toLocaleDateString();
  }

  getCampaignNameAndDate(onePager: OnePager, campaigns: Campaign[]): { name: string, dateLastModif: number } {
    return {
      name: campaigns.find((c: Campaign) => c.idOnePager === onePager.id).name,
      dateLastModif: campaigns.find((c: Campaign) => c.idOnePager === onePager.id).updated_on
    };
  }

  getDataURI(images: Image[]): Observable<Image[]> {
    const tabImages = [];
    images.forEach(image => {
      if (image.isSelected !== false) {
        const imageNameSplit = image.name.split('.');
        const dataURI = `data:image/${imageNameSplit[imageNameSplit.length - 1]};base64`;
        const mediaObject$ = this.http.get<any>(image.url).pipe(share());

        const media$ = mediaObject$.pipe(filter(object => object.media),
          map((object: ImageBlob) => `${object.type},${object.media}`));
        const largeDataRecords$ = mediaObject$.pipe(filter(object => !object.media),
          map(parameter => _.isArray(parameter) ? `${dataURI},${parameter[0]}` : `${dataURI},${parameter}`));

        tabImages.push(media$.pipe(merge(largeDataRecords$),
          map((data: string) => {
            return {
              data: data,
              url: image.url,
              name: image.name
            };
          })));
      }
    });
    return !_.isEmpty(tabImages) ? <Observable<Image[]>>forkJoin(tabImages) : of([]);
  }

  getDataURI2(urlTab: CapturesUrl[]): Observable<CapturesData[]> {
    const tabImages = [];
    if (urlTab) {
      urlTab.forEach(element => {
        if (element.url) {
          const mediaObject$ = this.http.get<any>(element.url).pipe(share(),
            catchError(err => {
              console.log('error 123', err);
              return observableThrowError(err);
            }));
          const media$ = mediaObject$.pipe(filter(object => object.media),
            map((object: ImageBlob) => `${object.type},${object.media}`));
          tabImages.push(media$.pipe(
            map((data: string) => {
              return {
                index: element.index,
                data: data,
                orientation: element.orientation
              };
            })));
        }

      });
    }
    return !_.isEmpty(tabImages) ? <Observable<Image[]>>forkJoin(tabImages) : of([]);
  }

  getDataFromImages(urlTab: CapturesUrl[], images: OnePagerPreviewImages[]): CapturesData[] {
    const tabImages = [];
    if (!_.isEmpty(urlTab)) {
      urlTab.forEach(element => {
        const img = images.find(img => img.id === element.id);
        if (img) {
          tabImages.push({
            index: element.index,
            data: img.data,
            orientation: element.orientation
          });
        }
      });
    }
    return tabImages;
  }

  getNames(usersTab: string[] = []) {
    if (usersTab && usersTab.length > 0) {
      let user: User;
      usersTab = usersTab.reduce((acc: string[], curr: string) => {
        user = _.get(this.campaignService, 'users', []).find(u => u.key === curr);
        return user !== undefined ? acc.concat(` ${_.get(this.campaignService, 'users', []).find(u => u.key === curr).name}`) : acc;
      }, []);
    }
    return usersTab;
  }

  // Getter & Setter: OnePager
  getOnePager = (): OnePager => {
    return this.onePager;
  }

  setOnePager(onePager: OnePager) {
    this.onePager = onePager;
  }

  getAndBuildOnePager(campaign: Campaign | CampaignOnePager): Observable<OnePager> {
    const hasOnePager$ = observableOf(campaign).pipe(
      filter(campaign => _.has(campaign, 'idOnePager')),
      mergeMap(() => this.getOnePagerById(campaign.idOnePager)),
      map(resp => {
        const imgs = this.reorderOnePagerImages(resp.graphIds, resp.onePager.images);
        resp.onePager.images = imgs;
        return resp.onePager;
      })
    );

    const cmp = _.cloneDeep(campaign);
    let onePagerTmp: OnePager;
    const newOnePager$ = observableOf(campaign).pipe(
      filter(campaign => !_.has(campaign, 'idOnePager')),
      mergeMap(() => this.createOnePager(new OnePager())),
      tap((onePager: OnePager) => cmp.idOnePager = onePager.id),
      tap(onePager => onePagerTmp = onePager),
      mergeMap(onePager => this.campaignService.putCampaignOnePager(cmp.id, onePager.id)),
      map(() => onePagerTmp));

    return hasOnePager$.pipe(merge(newOnePager$),
      tap((onePager: OnePager) => {
        const initialOnePager = _.cloneDeep(onePager);
        onePager.gridstackElements = _.get(onePager, 'gridstackElements', []);
        onePager.images = _.get(onePager, 'images', []);
        this.buildOnePagerFromCampaign(initialOnePager, onePager, cmp);
        this.setOnePager(onePager);
      }));
  }

  getAndBuildOnePagerMulti(idOnePager: string, idCampaignMulti: string): Observable<OnePager> {
    let onePager$: Observable<OnePager>;
    if (!_.isEmpty(idOnePager)) {
      onePager$ = this.getOnePagerMultiById(idOnePager);
    } else {
      let onePagerTmp;
      onePager$ = this.createOnePager(new OnePager()).pipe(
        tap(onePager => onePagerTmp = onePager),
        mergeMap((onePager: OnePager) => this.campaignMultiService.patchCampaignMultiOnePager(idCampaignMulti, onePager.id)),
        map(() => onePagerTmp));
    }

    return onePager$.pipe(
      tap((resp: any) => {
        resp.onePager.gridstackElements = _.get(resp.onePager, 'gridstackElements', []);
        resp.onePager.images = _.get(resp.onePager, 'images', []);
        this.setOnePager(resp.onePager);
      }),
      map((resp: any) => resp.onePager)
      );
  }

  getReferenceCouples(productsTableFromCampaign, coupleArray: SimComp[], listFormula: GroupedFormula[]) {
    let coupleReference = [];
    for (const data of coupleArray) {
      const formula = listFormula.find(f => f.name === data.bench.name);
      formula.formula.forEach((f) => {
        let reference = productsTableFromCampaign.filter(product =>
          product.routineName === _.get(data, 'bench.name', '')
        );
        if (!reference) {
          reference = {
            routineName: _.get(data, 'bench.name', ''),
            formulaName: f.name
          };
        }
        coupleReference = coupleReference.concat(reference);
      });
    }
    coupleReference = _.uniq(coupleReference);
    return coupleReference;
  }

  getTestedCouples(productsTableFromCampaign, coupleArray: SimComp[], listFormula: GroupedFormula[]) {
    let coupleTested = [];
    for (const data of coupleArray) {
      data.lab.forEach(lab => {
        const foundedLab = listFormula.find(f => f.name === lab.name);
        foundedLab.formula.forEach((f) => {
          let tested = productsTableFromCampaign.filter(product =>
            product.routineName === lab.name
          );
          if (!tested) {
            tested = {
              routineName: lab.name,
              formulaName: f.name
            };
          }
          coupleTested = coupleTested.concat(tested);
        });
      });
    }
    coupleTested = _.uniq(coupleTested);
    return coupleTested;
  }

  setOnePagerSexti(routines: Routine2[], campaignName: string): SextiFormula[] {
    return routines.reduce(
      (acc1, curr1: Routine2) => {
        return acc1.concat(
          curr1.visits.reduce(
            (acc2, curr2: Visit) => {
              return acc2.concat(
                curr2.orders.reduce(
                  (acc3, curr3: Order) => {
                    return acc3.concat(
                      curr3.steps.reduce(
                        (acc4, curr4: RoutineStep) => {
                          return acc4.concat({
                            campaignName: campaignName,
                            routineName: curr1.name,
                            routineLabel: _.get(curr1, 'label', ''),
                            visitName: curr2.name,
                            formulaName: curr4.formula.formulaName,
                            productName: curr4.formula.productName,
                            stepName: curr4.name,
                            lot: curr4.formula.lot,
                            socle: curr4.formula.socle
                          });
                        }, []
                      )
                    );
                  }, []
                )
              );
            }, []
          )
        );
      }, []
    );
  }

  /**
   * 22147
   * @param graphIds
   * @param onePagerImages
   * @returns
   */
  reorderOnePagerImages(graphIds, onePagerImages) {
    const imagesOrdered = [];
    graphIds.forEach(idGraph => {
        const graphsInImages = onePagerImages.filter(i => i.id === idGraph);
        graphsInImages.forEach(gii => imagesOrdered.push(gii));
    });
    onePagerImages.filter(img => _.isUndefined(imagesOrdered.find(io => io.id === img.id))).forEach(imageNotAdded => imagesOrdered.push(imageNotAdded));
    imagesOrdered.map((io, index) => _.set(io, 'indexImage', index));
    return imagesOrdered;
}

  private setOnePagerUsersFromCampaign(expertsOrManagers: UserInCampaign[]) {
    const headerUsers: UserInCampaign[] = [];
    if (expertsOrManagers && expertsOrManagers.length > 0) {
      expertsOrManagers.forEach(expertOrManager => {
        const userFound = _.get(this.campaignService, 'users', []).find((u: User) => u.key === expertOrManager.key);
        if (userFound) {
          headerUsers.push({
            name: userFound.name,
            key: userFound.key,
            pzone: null,
            isActive: true
          });
        }
      });
    }
    return headerUsers;
  }

  stringifyCampaignTarget(target: Target): string {
    return Object.keys(target).reduce((arr, cur) => {
      if (Array.isArray(target[cur])) {
        const values = target[cur].reduce((arr2, cur2) => {
          if (cur2['Text'] && cur2['IsValue']) {
            this.translateService.translateMessage(cur2.Text).pipe(
              tap((text) => arr2.push(text)))
              .subscribe();
          }
          return arr2;
        }, []).join(', ');

        if (values) {
          this.translateService.translateMessage(TargetKeys[cur])
            .subscribe((trad: string) => arr.push(`${trad}: ${values}`));
        }
      }
      return arr;
    }, []).join('\n');
  }

  /********** OnePager preview **********/

  translateKeysFromRoutines(routines: Routine2[], metierName: TypeMetier): Observable<object[]> {
    const observables: Observable<{ key: any, value: string }>[] = [];

    if (routines) {
      routines.forEach((routine: Routine2) => {
        if (routine.visits) {
          routine.visits.forEach((visit: Visit) => {
            if (visit.orders) {
              visit.orders.forEach((order: Order) => {
                order.steps.forEach((step: RoutineStep) => {
                  observables.push(
                    this.translateService.translateMessage(step.class).pipe(
                      map((translation: string) => {
                        return {
                          key: step.class,
                          value: translation
                        };
                      }))
                  );

                  const applicators = _.get(step, 'formula.applicators', []);
                  if (!_.isEmpty(applicators)) {
                    applicators.forEach((applicator: TypeApplicationMode) => {
                      observables.push(
                        this.translateService.translateMessage(applicator.key).pipe(
                          map((translation: string) => {
                            return {
                              key: applicator.key,
                              value: translation
                            };
                          }))
                      );
                    });
                  }

                  switch (metierName) {
                    case this.metiers.Hair:
                      const formulaHair = <FormulaBridgeHair>step.formula;

                      if (formulaHair.applyTo) {
                        observables.push(
                          this.translateService.translateMessage(formulaHair.applyTo).pipe(
                            map((translation: string) => {
                              return {
                                key: formulaHair.applyTo,
                                value: translation
                              };
                            }))
                        );
                      }

                      _.get(formulaHair, 'dryingTypes', []).forEach((dryingType: TypeDryingType) => {
                        observables.push(
                          this.translateService.translateMessage(dryingType.key).pipe(
                            map((translation: string) => {
                              return {
                                key: dryingType.key,
                                value: translation
                              };
                            }))
                        );
                      });
                      break;
                  }
                });
              });
            }
          });
        }
      });
    }
    return observables.length === 0 ? observableOf([]) : forkJoin(observables);
  }

  /******** Get Images **************/

  getFromLargeDataRecord(url: string): Observable<string> {
    const dataURI = 'data:image/jpeg;base64';
    return this.http.get<any>(url).pipe(map(parameter => _.isArray(parameter) ? `${dataURI},${parameter[0]}` : `${dataURI},${parameter}`));
  }

  getFromMedia(url: string): Observable<string> {
    return this.http.get<any>(url).pipe(map(object => `${object.type},${object.media}`));
  }

  getFromPowerBI(url: string): Observable<string> {
    return this.http.get<any>(url).pipe(map(object => `${object.type},${object.media}`));
  }

  /******** Setter and Getter Images **************/

  addImage(idOnePager: string, image: Image) {
    if (!this.images[idOnePager]) {
      this.images[idOnePager] = [];
    }
    if (!this.images[idOnePager].find(i => i.url === image.url)) {
      this.images[idOnePager].push(image);
    }
  }

  setImages(idOnePager: string, images: Image[]) {
    this.images[idOnePager] = images;
  }

  getImages(idOnePager: string) {
    return _.get(this.images, idOnePager, []);
  }

  /******** Build datas for report **************/

  getQuantitiesFromCampaign(workflows: Workflow[], routinesFromCampaign: Routine2[], metierName: TypeMetier, campaignVolunteers: any[]): RoutineTabForReport[] {
    const routinesTabByWorkflow: RoutineTabForReport[] = [];
    workflows.forEach(wk => {
      const routinesTab: RoutinesTab[] = [];
      routinesFromCampaign.forEach(routine => {
        routinesTab.push({
          name: routine.name,
          label: routine.label,
          routineDatas: this.buildRoutinesTab(routine, metierName, campaignVolunteers)
        });
      });
      routinesTabByWorkflow.push({
          workflow: {
              id: wk.id,
              name: wk.name
          },
          routinesTab: routinesTab
      });
    });
    return routinesTabByWorkflow;
  }

  updateQuantitiesWithEvals(evaluations: Evaluation[], routinesTabByWorkflow: RoutineTabForReport[], evalsFromCampaign: Evaluation[]): RoutineTabForReport[] {
    const routinesInEvals = !_.isEmpty(evaluations)
      ? evaluations.filter(e => _.has(e, 'routines'))
        .reduce(
          (acc1, curr1: Evaluation) => {
            return acc1.concat(
              curr1.routines.reduce(
                (acc2, curr2: Routine2) => {
                  return acc2.concat({
                    ..._.omit(curr1, 'routines'),
                    routine: curr2,
                    isStarted: curr1.progress !== 0
                  });
                }, []
              )
            );
          }, []
        )
      : [];
    const productQuantities: any[] = [];
    routinesInEvals.forEach(routineEval => {
      productQuantities.push(this.buildProductsQuantitiesObject(routineEval));
    });
    routinesTabByWorkflow.forEach(byWorkflow => {
      byWorkflow.routinesTab.forEach(routine => {
        routine.routineDatas.forEach(data => {
          let productQuantitiesFiltered = [];
          productQuantitiesFiltered = _.flatten(productQuantities).filter(pq =>
            pq.workflow === byWorkflow.workflow.id &&
            pq.routineName === routine.name &&
            pq.visit === data.visit &&
            pq.orderName === data.orderName &&
            pq.formulaName === data.formulaName &&
            pq.stepName === data.stepName
          );

          data.pauseTime.forEach(pt => {
            const foundedPauseTime = productQuantitiesFiltered.find(p =>
              p.routineName === routine.name &&
              p.volunteerName === pt.volunteerName
            );
            if (!_.isUndefined(foundedPauseTime)) {
              _.set(pt, 'volunteerName', foundedPauseTime.volunteerName);
              _.set(pt, 'pauseTime', foundedPauseTime.pauseTime);
              _.set(pt, 'isStarted', foundedPauseTime.isStarted);
            }
          });
          data.quantitiesByVolunteer.forEach(dataQuantity => {
            const foundedQuantity = productQuantitiesFiltered.find(p =>
              p.routineName === routine.name &&
              p.volunteerName === dataQuantity.volunteerName
            );
            if (!_.isUndefined(foundedQuantity)) {
              _.set(dataQuantity, 'quantity', foundedQuantity.quantitiesByVolunteer);
              _.set(dataQuantity, 'isStarted', foundedQuantity.isStarted);
            }
          });
        });
      });
    });
    return routinesTabByWorkflow;
  }

  buildRoutinesTab(routine: Routine2, metierName: TypeMetier, campaignVolunteers: any[]): RoutineTabFromCampaign[] {
    return routine.visits.reduce(
      (acc2, curr2: Visit) => {
        switch (metierName) {
          case Metiers.Hair: {
            return acc2.concat(
              curr2.orders.reduce(
                (acc3, curr3: Order) => {
                  return acc3.concat(
                    curr3.steps.reduce(
                      (acc4, curr4: RoutineStep) => {
                        const quantitiesByVolunteer = campaignVolunteers.map((volunteer) => {
                          return {
                            volunteerName: volunteer.name,
                            quantity: curr4.formula.quantity,
                            isStarted: false
                          };
                        });
                        const allPauseTimes = campaignVolunteers.map((volunteer) => {
                          return {
                            volunteerName: volunteer.name,
                            pauseTime: curr4.formula.pauseTime
                          };
                        });
                        const formula = <FormulaBridgeHair>curr4.formula;
                        return acc4.concat({
                          visit: curr2.name,
                          orderName: curr3.name,
                          shades: curr3.shade,
                          evaluation: curr3.evaluation,
                          stepName: curr4.name,
                          productName: curr4.formula.productName,
                          categorie: curr4.class,
                          formulaName: curr4.formula.formulaName,
                          lot: curr4.formula.lot,
                          quantity: curr4.formula.quantity,
                          quantitiesByVolunteer: quantitiesByVolunteer,
                          applicationMode: curr4.formula.applicators,
                          pauseTime: allPauseTimes,
                          applyTo: formula.applyTo,
                          dryingTypes: formula.dryingTypes
                        });
                      }, []
                    )
                  );
                }, []
              )
            );
          }
          case Metiers.Skin: {
            return acc2.concat(
              curr2.orders.reduce(
                (acc3, curr3: Order) => {
                  return acc3.concat(
                    curr3.steps.reduce(
                      (acc4, curr4: RoutineStep) => {
                        const formula = <FormulaBridgeSkin>curr4.formula;
                        const quantitiesByVolunteer = campaignVolunteers.map((volunteer) => {
                          return {
                            volunteerName: volunteer.name,
                            quantity: curr4.formula.quantity,
                            isStarted: false
                          };
                        });
                        const allPauseTimes = campaignVolunteers.map((volunteer) => {
                          return {
                            volunteerName: volunteer.name,
                            pauseTime: curr4.formula.pauseTime
                          };
                        });
                        return acc4.concat({
                          visit: curr2.name,
                          orderName: curr3.name,
                          shades: curr3.shade,
                          evaluation: curr3.evaluation,
                          stepName: curr4.name,
                          productName: curr4.formula.productName,
                          categorie: curr4.class,
                          formulaName: curr4.formula.formulaName,
                          lot: curr4.formula.lot,
                          quantity: curr4.formula.quantity,
                          quantitiesByVolunteer: quantitiesByVolunteer,
                          applicationMode: curr4.formula.applicators,
                          pauseTime: allPauseTimes,
                          timeDrying: formula.timeDrying
                        });
                      }, []
                    )
                  );
                }, []
              )
            );
          }
          default: {
            return acc2.concat(
              curr2.orders.reduce(
                (acc3, curr3: Order) => {
                  return acc3.concat(
                    curr3.steps.reduce(
                      (acc4, curr4: RoutineStep) => {
                        const quantitiesByVolunteer = campaignVolunteers.map((volunteer) => {
                          return {
                            volunteerName: volunteer.name,
                            quantity: curr4.formula.quantity,
                            isStarted: false
                          };
                        });
                        const allPauseTimes = campaignVolunteers.map((volunteer) => {
                          return {
                            volunteerName: volunteer.name,
                            pauseTime: curr4.formula.pauseTime
                          };
                        });
                        return acc4.concat({
                          visit: curr2.name,
                          orderName: curr3.name,
                          shades: curr3.shade,
                          evaluation: curr3.evaluation,
                          stepName: curr4.name,
                          productName: curr4.formula.productName,
                          categorie: curr4.class,
                          formulaName: curr4.formula.formulaName,
                          lot: curr4.formula.lot,
                          quantity: curr4.formula.quantity,
                          quantitiesByVolunteer: quantitiesByVolunteer,
                          applicationMode: curr4.formula.applicators,
                          pauseTime: allPauseTimes
                        });
                      }, []
                    )
                  );
                }, []
              )
            );
          }
        }
      }, []
    );
  }

  buildProductsQuantitiesObject(routineEval): RoutineTabFromEvaluations[] {
    return routineEval.routine.visits.reduce(
      (acc1, curr1: Visit) => {
        return acc1.concat(
          curr1.orders.reduce(
            (acc2, curr2: Order) => {
              return acc2.concat(
                curr2.steps.reduce(
                  (acc3, curr3: RoutineStep) => {
                    return acc3.concat({
                      workflow: routineEval.questionnaireId,
                      volunteerName: this.getUserFromEval(routineEval),
                      routineName: routineEval.routine.name,
                      visit: curr1.name,
                      orderName: curr2.name,
                      formulaName: curr3.formula.formulaName,
                      stepName: curr3.name,
                      quantitiesByVolunteer: curr3.formula.quantity,
                      isStarted: routineEval.isStarted,
                      pauseTime: curr3.formula.pauseTime
                    });
                  }, []
                )
              );
            }, []
          )
        );
      }, []
    );
  }

  getTestedReferenceTab(campaignsMulti: CampaignsMulti, campaigns: Campaign[]): CampaignMultiReferenceTested {
    let reference = [];
    let tested = [];
    const couples = [];
    if (_.get(campaignsMulti, 'analyseType', '') === AnalyseTypes.comparison) {
      if (campaignsMulti.chosenBench && !_.isEmpty(campaigns)) {
        campaigns.forEach((campaign: Campaign) => {
          const benchCampaign = campaignsMulti.chosenBench[campaign.id];
          if (benchCampaign) {
            const routines = this.setOnePagerSexti(_.get(campaign, 'formula.routines', []), campaign.name);
            const formulasReference = campaign.formula.listFormulas.filter(formula => formula.id === _.get(benchCampaign, 'bench', ''));
            reference = reference.concat(this.fillTab(formulasReference, routines));
            const formulasTested = campaign.formula.listFormulas.filter(formula => formula.id !== _.get(benchCampaign, 'bench', ''));
            tested = tested.concat(this.fillTab(formulasTested, routines));
            couples.push(this.fillCouplesTab(formulasReference, formulasTested, campaign.name));
          }
        });
      }
    } else {
      if (campaignsMulti.chosenRoutines && !_.isEmpty(campaigns)) {
        campaigns.forEach((campaign: Campaign) => {
          const routinesCampaign = campaignsMulti.chosenRoutines[campaign.id];
          if (routinesCampaign) {
            const routines = this.setOnePagerSexti(_.get(campaign, 'formula.routines', []), campaign.name);
            const formulasReference = campaign.formula.listFormulas.filter(formula => formula.id === _.get(routinesCampaign, 'R2', ''));
            reference = reference.concat(this.fillTab(formulasReference, routines));
            const formulasTested = campaign.formula.listFormulas.filter(formula => formula.id !== _.get(routinesCampaign, 'R2', '')
              && _.includes(_.values(routinesCampaign), formula.id));
            tested = tested.concat(this.fillTab(formulasTested, routines));
            couples.push(this.fillCouplesTab(formulasReference, formulasTested, campaign.name));
          }
        });
      }
    }
    return {
      referenceTab: reference,
      testedTab: tested,
      couples: couples
    };
  }

  getRoutinesTab(campaigns: Campaign[]): SextiFormula[] {
    let routinesTab: SextiFormula[] = [];
    campaigns.forEach((campaign: Campaign) => {
      routinesTab = routinesTab.concat(this.setOnePagerSexti(_.get(campaign, 'formula.routines', []), campaign.name));
    });
    return routinesTab;
  }

  fillTab(formulasReferenceTested: GroupedFormula[], routines: SextiFormula[]): SextiFormula[] {
    const tab = [];
    formulasReferenceTested.forEach(formulaReferenceTested => {
      const referenceTestedRoutine = routines.filter(routine => routine.routineName === _.get(formulaReferenceTested, 'name', ''));
      if (referenceTestedRoutine) {
        tab.push(referenceTestedRoutine);
      }
    });
    return _.flatten(tab);
  }

  fillCouplesTab(formulasReference: GroupedFormula[], formulasTested: GroupedFormula[], campaignName: string) {
    return {
      reference: this.reduceList(formulasReference),
      tested: this.reduceList(formulasTested),
      campaignName: campaignName
    };
  }

  reduceList(list: GroupedFormula[]): string {
    return list.reduce(
      (acc: string, curr: GroupedFormula) => curr.name ? (acc.length > 0 ? `${acc}, ${curr.name}` : curr.name) : acc,
      ''
    );
  }

  getUserFromEval(routineEval) {
    if (_.get(routineEval, 'volunteer.name', undefined)) {
      return routineEval.volunteer.name;
    } else {
      const users = _.get(this.campaignService, 'users', []);
      return routineEval.users.reduce((acc: string, curr: User) => {
        const user = users.find(u => u.key === curr.key);
        const next = _.isEmpty(acc) ? '' : ', ';
        return user !== undefined ? acc + next + `${user.name}` : acc;
      }, '');
    }

  }

  buildVisiteByVolunteerTable(evaluations: Evaluation[], campaign: CampaignOnePager, lang: string) {
    if (!_.isEmpty(_.get(campaign, 'visits', []))) {
      const allVisits = _.get(campaign, 'visits', []).map(visit => {
        visit = _.pick(visit, ['id', 'name']);
        _.set(visit, 'startDate', 'NOT_STARTED');
        return visit;
      });
      const visitByVolunteerTable: any[] = [];
      const evalsByWorkflow = _.groupBy(_.get(campaign, 'evaluations', []), 'questionnaireId');
      const byWorkflowAndVolunteer = _.values(evalsByWorkflow).map(wk => {
        return _.groupBy(wk, 'volunteer.name');
      });
      const byVolunteer = byWorkflowAndVolunteer.map(bwav => {
        return _.values(bwav).map(bv => {
          return !_.isUndefined(bv.find(v => v.progress > 0)) ? bv.find(v => v.progress > 0) : bv[0];
        });
      });
      _.flatten(byVolunteer).forEach(ce => {
        const evaluation = evaluations.find(e => e.id === ce.id);
        const visitsWithDate = _.cloneDeep(allVisits).map(av => {
          const visit = _.get(evaluation, 'visits', []).find(v => v.id === av.id);
          if (visit) { av.startDate = visit.startDate === 'NOT_STARTED' ? 'NOT_STARTED' : new Date(visit.startDate).toLocaleDateString(); }
          return av;
        });
        if (_.keys(evalsByWorkflow).length > 1) {
          const workflowInCampaign = campaign.workflows.find(w => w.id === ce.questionnaireId).name;
          const workflowName = !_.isEmpty(_.get(workflowInCampaign, [lang], '')) ? _.get(workflowInCampaign, [lang], '') : _.get(workflowInCampaign, 'english', '');
          visitByVolunteerTable.push({
            workflowName: workflowName,
            volunteerName: _.get(evaluation, 'volunteer.name', _.get(ce, 'volunteer.name', '')),
            visits: visitsWithDate
          });
        } else {
          visitByVolunteerTable.push({
            volunteerName: _.get(evaluation, 'volunteer.name', _.get(ce, 'volunteer.name', '')),
            visits: visitsWithDate
          });
        }
      });
      return _.orderBy(visitByVolunteerTable, 'volunteer.name', 'asc').filter(element => element.visits.some(v => v.startDate !== 'NOT_STARTED'));
    } return [];
  }

  initVolunteers(campaign: Campaign): Observable<FicheCaracterisation[]> {
    const data = [];
    campaign.users.volunteers.forEach((volunteer: Volunteers) => {
      data.push(
        this.volunteerService.getFicheCaracByPanelist(this.campaignService.getArcsSystem(campaign, volunteer.name) + '_' + volunteer.name, _.get(campaign, 'synergy.requestNumber', '')).pipe(
          catchError(error => {
            return observableOf(undefined);
          })
        )
      );
    });
    return zip(...data).pipe(
      map((formsCaracterisation: FicheCaracterisation[]) => formsCaracterisation.filter((f: FicheCaracterisation) => !_.isNil(f))));
  }

  stopOnePagerSpinner() {
    this.stopSpinner.emit(true);
  }

  getHeadInfoFromDatabase(campaignsHeadInfo) {
    const laboratory: string[] = [];
    const expert: string[] = [];
    const fieldwork: string[] = [];
    const dates: string[] = [];
    campaignsHeadInfo.forEach(head => {
      if (!_.isEmpty(_.get(head, '[0].identification.laboratory', ''))) {
        laboratory.push(head[0].identification.laboratory);
      }
      if (!_.isEmpty(_.get(head, '[0].identification.expert', []))) {
        head[0].identification.expert.forEach(exp => expert.push(exp.name));
      }
      if (!_.isEmpty(_.get(head, '[0].identification.fieldwork', []))) {
        fieldwork.push(head[0].identification.fieldwork);
      }
      if (!_.isEmpty(_.get(head, '[0].identification.studyDate', []))) {
        dates.push(head[0].identification.studyDate);
      }
    });
    return {
      laboratory: this.formatValues(_.uniq(laboratory)),
      experts: this.formatValues(_.uniq(expert)),
      fieldwork: this.formatValues(_.uniq(fieldwork)),
      studyDates: dates.join(', ')
    };
  }

  setHeaderInfoExperts(campaignsHeadInfo, users) {
    campaignsHeadInfo = campaignsHeadInfo.map(campaignHeadInfo => {
      campaignHeadInfo = campaignHeadInfo.resources.map(chi => {
        chi.identification.expert = _.get(chi, 'identification.expert', []).filter(expert => !_.isEmpty(expert)).map(expert => {
          if (!_.has(expert, 'name')) {
            const user = users.find(u => u.key === expert.key);
            if (user) {
              _.set(expert, 'name', user.name);
            }
          }
          return expert;
        });
        return chi;
      });
      return campaignHeadInfo;
    });
    return campaignsHeadInfo;
  }

  formatValues(values: string[]) {
    return values.reduce((name, b, index) => {
      if (index > 0) { name += ' , '; }
      name += b;
      return name;
    }, '');
  }


  getRoutinePrime(index: number): string {
    let prime = 'R2';
    for (let i = 0; i < index + 1; i++) {
      prime = prime.concat('\'');
    }
    return prime;
  }

  getRoutineMultiName(index: number): string {
    const routine = 'RM';
    if (index < 1) {
      return routine + (index + 1);
    } else {
      return routine + (index + 2);
    }
  }

  getRoutineName(routine: string, campaignName: string, tabReferenceTested: CampaignMultiReferenceTested): string {
    const couple = tabReferenceTested.couples.find(cp => cp.campaignName === campaignName && (cp.reference === routine || cp.tested === routine));
    if (couple && couple.reference === routine) {
      return this.getRoutinePrime(tabReferenceTested.couples.indexOf(couple));
    } else if (couple && couple.tested === routine) {
      return this.getRoutineMultiName(tabReferenceTested.couples.indexOf(couple));
    }
    return routine;
  }

  getElementToUpdate(initialOnePager, onePager) {
    const elementsToUpdate = {};
    Object.keys(onePager).forEach(key => {
      if (!_.isEqual(onePager[key], _.get(initialOnePager, key, undefined))) {
        elementsToUpdate[key] = onePager[key];
      }
    });
    return elementsToUpdate;
  }

  // DNA PHOTOS
  updateOnePagerWithPhotos(onePagerId: string, dnaphotosToUpdate: ReferencePhoto[]): Observable<string> {
    const photosToUpdate = dnaphotosToUpdate.map((photo:ReferencePhoto)=>{
      return {
        id:photo.photoId,
        data: {
            "_isScalar": false
        },
        name: photo.photoName,
        url: `${environment.apiStudiesUrl}/v1/medialoaderdnaphotos/${photo.photoId}`,
        title: photo.photoName,
        pagesAdded: 0
      }
    })
    return this.http.put<string>(`${environment.apiStudiesUrl}/v1/onePagers/${onePagerId}`, { dnaphotosToUpdate: photosToUpdate });
  }

  checkPhotosInOnePager(idOnePager:string, photosSelected:ReferencePhoto[]): Observable<any>{
    return this.http.post<any>(`${environment.apiStudiesUrl}/v1/onePagers/${idOnePager}/checkExistPhotos`,{ photosSelected: photosSelected });
  }

  deleteReferencePhotosFromOnePager(onePagerId:string, photosReferencesToDelete:string[]): Observable<any>{
    return this.http.patch<any>(`${environment.apiStudiesUrl}/v1/onepagers/${onePagerId}/deletePhotoReferences`, {photosIdToDelete:photosReferencesToDelete});
  }
}
