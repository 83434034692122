import { OnePagerService } from '../../../shared/services/one-pager.service';
import { mergeMap, map, takeUntil, tap } from 'rxjs/operators';
import {
  ActivatedRoute,
} from '@angular/router';
import {
  AfterViewInit,
  Component, OnDestroy,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';

import {
  ApplicationInsightsService
} from '../../../shared/services/applicationInsights.service';
import {
  Campaign,
  CampaignMetadata,
  DNATypes,
  Menu
} from '../../../types';
import {
  CampaignService
} from '../../campaigns.service';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'dna-campaign-root',
  templateUrl: './campaign-root.component.html',
  styleUrls: ['./campaign-root.component.less']
})
export class CampaignRootComponent implements OnInit, AfterViewInit, OnDestroy {

  campaign: Campaign = new Campaign();
  showSpinner: boolean = false;
  sideMenu: Menu[];
  isOnOnePagerImage = false;
  initTime = performance.now();

  idCampaign: string;
  isTemplate: boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private  aiService: ApplicationInsightsService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private translateService: DNATranslateService,
    private utilService: UtilService,
    private onePagerService: OnePagerService
  ) {}

  ngOnInit() {
    this.utilService.isOnOnePagerImage.subscribe(isOnOnePagerImage => this.isOnOnePagerImage = isOnOnePagerImage);
    window.scrollTo(0, 0);

    this.campaignService.getCampaignMetadata().pipe(
      map((metadata: CampaignMetadata) => _.cloneDeep(metadata)),
      tap((cloneMetadata: CampaignMetadata) => this.campaignService.users = cloneMetadata.users),
      tap((cloneMetadata: CampaignMetadata) => this.campaignService.workflows = cloneMetadata.workflows),
      mergeMap(() => this.route.paramMap),
      tap(paramMap => this.idCampaign = paramMap.get("idCampaign")),
      mergeMap(() => this.campaignService.CampaignTemplate(this.idCampaign)),
      tap(data => this.isTemplate = data.isTemplate),
      tap(() => this.updateMenu(this.idCampaign, this.isTemplate)),
      takeUntil(this.destroy$)
    )
      .subscribe(() => this.showSpinner = false);

    this.translateService.onLangChange().pipe(
      tap(() => this.updateMenu(this.idCampaign, this.isTemplate)),
      takeUntil(this.destroy$),)
      .subscribe();

    this.campaignService.onUpdateBreadCrumb().subscribe((campaignName) => {
      this.campaign.name = campaignName;
    });

    this.onePagerService.stopSpinner.subscribe((res) => {
      if (res) this.showSpinner = false;
    });

    this.campaignService.updateTemplate.subscribe(isTemplate => {
      this.isTemplate = isTemplate;
      this.updateMenu(this.idCampaign, isTemplate);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onActivate(data) {
    this.showSpinner = false;
  }

  ngAfterViewInit() {
    const templateUrl = this.route.snapshot.routeConfig.path;
    this.aiService.logPageView('Campaign', '', performance.now() - this.initTime, templateUrl);
  }

  updateMenu(idCampaign: string, isTemplate: boolean) {
    let menuLink = '/campaigns';
    let menuType = DNATypes.Campaign;
    let menuTemplate = DNATypes.Template;

    if (isTemplate) {
      this.utilService.createMenu(menuTemplate)
        .pipe(takeUntil(this.destroy$))
        .subscribe((menus: Menu[]) => {
          this.sideMenu = menus.map((menu: Menu) => {
            menu.link = [menuLink, idCampaign].concat(menu.link);
            return menu;
          });
        });
    } else {
      this.utilService.createMenu(menuType)
        .pipe(takeUntil(this.destroy$))
        .subscribe((menus: Menu[]) => {
          this.sideMenu = menus.map((menu: Menu) => {
            menu.link = [menuLink, idCampaign].concat(menu.link);
            return menu;
          });
        });
    }
  }


  onOnePagerClick() {
    this.showSpinner = true;
  }
  calculateMenuClasses() {
    return {
      'col-md-1': this.isOnOnePagerImage,
      'col-md-3': !this.isOnOnePagerImage
    };
  }

  calculateClasses() {
    return {
      'col-md-11': this.isOnOnePagerImage,
      'col-md-9': !this.isOnOnePagerImage
    };
  }
}
