
import {finalize} from 'rxjs/operators';
import {
  Router
} from '@angular/router';
import {
  Component,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';

import {
  Block,
  DNAComponent
} from '../../../../types';
import {
  BlockService
} from '../../../blocks.service';
import {
  DNATranslateService
} from '../../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  UtilService
} from '../../../../shared/services/util.service';

@Component({
  selector: 'dna-component-selection',
  templateUrl: './component-selection.component.html',
  styleUrls: ['./component-selection.component.less']
})
export class ComponentSelectionComponent implements OnInit {

  block: Block;
  components: DNAComponent[] = [];
  componentsFiltered: DNAComponent[] = [];
  currentLanguage: string;
  displayFavoriteOnly: boolean = false;
  orderedBy: string;
  reverse: boolean;
  searchText: string = "";
  showSpinner: boolean = true;

  constructor(
    private blockService: BlockService,
    private DNATranslate: DNATranslateService,
    private errorManager: ErrorManagerService,
    private router: Router,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.block = this.blockService.block;

    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });

    this.blockService.getComponents().pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(components => {
        this.components = components.list;
        this.componentsFiltered = _.cloneDeep(this.components);
      },
      error => this.errorManager.catchError(error)
      );
  }

  filterComponent(text: string, components: DNAComponent[]) {
    return _.filter(components,
      (c: DNAComponent) => {
        return c.type.toLowerCase().indexOf(text.toLowerCase()) !== -1;
      }
    );
  }

  onSortByChanged(sort: any) {
    if (_.isString(sort)) {
      this.reverse = !this.reverse;
      this.orderedBy = sort;
    } else {
      this.reverse = sort.reverse;
      this.orderedBy = sort.orderedBy;
    }
    this.componentsFiltered = this.utilService.sortListByType(this.componentsFiltered, this.orderedBy, this.reverse);
  }

  returnBack() {
    this.router.navigate(['blocks', this.block.id]);
  }

  selectComponent(component: DNAComponent) {
    this.blockService.component = component;
    this.router.navigate(['blocks', this.block.id, 'editComponent']);
  }

  updateSearchText(searchText: string) {
    this.searchText = searchText;
    this.componentsFiltered = this.filterComponent(this.searchText, this.components);
  }

}
