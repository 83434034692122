
import {mergeMap} from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AnalyseService } from '../../../../../campaigns/detail/edit-campaign/analyse/analyse.service';
import { Assessment, Buttons } from './../../../../../types';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import { HttpRestService } from '../../../../../shared/services/httpRest.service';
import { Languages, TableHeader, Training } from '../../../../../types';
import { TrainingService } from '../../../../training.service';
import { UtilService } from '../../../../../shared/services/util.service';

@Component({
  selector: 'dna-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.less']
})
export class AssessmentsComponent implements OnInit {

  currentTraining: Training;
  currentLanguage: Languages;
  showSpinner: boolean = false;

  listButtons$: Observable<Buttons[]>;
  tableHeaders$: Observable<TableHeader[]>;

  constructor(
    public analyseService: AnalyseService,
    private DNATranslate: DNATranslateService,
    private errorManager: ErrorManagerService,
    private httpRestService: HttpRestService,
    private route: ActivatedRoute,
    private router: Router,
    private trainingService: TrainingService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.tableHeaders$ = this.utilService.createHeadersAssessments(this.currentTraining);
      this.listButtons$ = this.utilService.createButtonsAssessments([true, true]);
    });

    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.currentTraining = this.trainingService.getTrainingFromLocalStorage(params.get("idTraining"));
      this.tableHeaders$ = this.utilService.createHeadersAssessments(this.currentTraining);
      this.listButtons$ = this.utilService.createButtonsAssessments([true, true]);
    });
  }

  buttonAction(buttonId: string, training: Training) {
    switch (buttonId) {
      case "EXPORT_EXCEL":
        this.getExportExcel(training);
        break;
      case "DELETE":
        this.remove(training);
        break;
    }
  }

  getExportExcel(training: Training) {
    this.showSpinner = true;
    this.httpRestService.exportVisualizationToExcel(training.id, "trainings").then(() => this.showSpinner = false)
      .catch(() => this.showSpinner = false);
  }

  onSuccessDelete() {
    this.errorManager.displayMessage("ON_SUCCESS_DELETE");
    this.router.navigate(['trainings']);
  }

  remove(training: Training) {
    this.utilService.translateMessageModal("REMOVE_TRAINING", "", "").pipe(
      mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.trainingService.deleteTraining(training.id)),)
      .subscribe(() => this.onSuccessDelete());
  }

  sort(assessments: Assessment[], headerId: string, reverse: boolean) {
    switch (headerId) {
      case "ID":
        return this.utilService.sortListByType(assessments, "id", reverse);
      case "WORKFLOW":
        return this.utilService.sortListByType(assessments, "idWorkflow", reverse);
      case "FORMULA":
        return this.utilService.sortListByType(assessments, "formula.0.name", reverse);
      case "USERS":
        return this.utilService.sortListByType(assessments, "users.0.key", reverse);
      case "VOLUNTEER":
        return this.utilService.sortListByType(assessments, "participant.name", reverse);
      case "PROGRESSION":
        return this.utilService.sortListByType(assessments, "progress", reverse);
    }
  }

}
