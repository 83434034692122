<div class="modal-header">
  <h4 class="modal-title"> {{ title }}</h4>
  <button class="close" type="button" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true" class="fa fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <form>
    <form class="form-horizontal">
      <div class="form-group">
        {{ message }}
      </div>
    </form>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="submit" (click)="validate()">{{ "VALIDATE" | translate }}</button>
  <button class="btn btn-default" type="button" (click)="cancel()">{{ "CANCEL" | translate }}</button>
</div>
