<td (click)="routeSelection(training)">{{ training.name }}</td>
<td (click)="routeSelection(training)" translate>{{ training.state }}</td>
<td (click)="routeSelection(training)">
  <svg viewBox="-1 -1 2 2" height="16px" width="16px" style="transform: rotate(-90deg);">
    <path [attr.d]="utilService.pathProgress(training.progress)" fill="#1ab394"></path>
    <path [attr.d]="utilService.pathProgress(1, training.progress)" fill="#d7d7d7"></path>
  </svg>
  <span>
    <small style="vertical-align: top">{{ training.progress | percent:'1.0-2' }}</small>
  </span>
</td>
<td (click)="routeSelection(training)">{{ training.created_on | date:'yyyy-MM-dd' }}</td>
<td (click)="routeSelection(training)">{{ training.updated_on | date:'yyyy-MM-dd' }}</td>
<td>
  <dna-favorites [id]="training.id" type="training"></dna-favorites>
  <dna-action-bar [actionsButtons]="actionsButtons | async" (onClick)="onClickButton($event)">
  </dna-action-bar>
</td>
