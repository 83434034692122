import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ErrorManagerService } from '../../../../../../shared/services/errorManager.service';
import { DNATranslateService } from '../../../../../../shared/services/translate.service';
import { BlocksSelectedInVisit, CampaignVisit } from '../../../../../../types';

@Component({
  selector: 'dna-visits-blocks-modal',
  templateUrl: './visits-blocks-modal.component.html',
  styleUrls: ['./visits-blocks-modal.component.less']
})
export class VisitsBlocksModalComponent implements OnInit {

  @Input() blocksSelected: BlocksSelectedInVisit[];
  @Input() indexCurrentVisit: number;
  @Input() visit: CampaignVisit;

  blocksByWorkflow: {
    idWorkflow: string,
    blocks: BlocksSelectedInVisit[]
  }[];
  collapsedWorkflowsTab = {};
  originalBlocksSelected: BlocksSelectedInVisit[];
  currentLanguage: string;
  
  constructor(
    private activeModal: NgbActiveModal,
    private errorManager: ErrorManagerService,
    private DNATranslate: DNATranslateService
    ) { }

  ngOnInit() {
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.originalBlocksSelected = _.cloneDeep(this.blocksSelected);
    this.blocksByWorkflow = _.chain(this.blocksSelected).groupBy('idWorkflow').map((value, key) => ({ idWorkflow: key, blocks: value })).value();
    this.collapseAllVisitsWorkflows();
  }

  private collapseAllVisitsWorkflows() {
    this.blocksByWorkflow.forEach((wk, indexWorkflow) => {
      this.collapsedWorkflowsTab[indexWorkflow] = false;
    });
  }

  isBlockInThisVisit(idBlock: string, blockidInQuestionnaire: string) {
    const block = this.blocksSelected.find(bs => bs.id === idBlock && bs.idInQuestionnaire === blockidInQuestionnaire && bs.index === this.indexCurrentVisit);
    return !_.isUndefined(block) && block.selected;
  }

  isBlockInOtherVisit(idBlock: string, blockidInQuestionnaire: string) {
    const block = this.blocksSelected.find(bs => bs.id === idBlock && bs.idInQuestionnaire === blockidInQuestionnaire && bs.index !== this.indexCurrentVisit);
    return !_.isUndefined(block) && block.selected;
  }

  addOrRemoveBlock(block: BlocksSelectedInVisit) {
    if(!this.isBlockInOtherVisit(block.id, block.idInQuestionnaire)) {
      if(!block.selected) {
        block.selected = !block.selected;
      } else {
        if(block.index !== this.indexCurrentVisit) {
          block.index = this.indexCurrentVisit;
        } else {
          block.selected = !block.selected;
        }
      }
      block.selected ? block.index = this.indexCurrentVisit : delete block.index;
      this.setBlockNameToDisplay(this.visit, block);
    } else {
      this.DNATranslate.translateMessage("ON_SELECT_BLOCK_IN_VISIT_ERROR", {indexVisit: block.index+1}).subscribe((translated) => {
        this.errorManager.displayMessage(translated, 'warning', {timeOut:10000}, false);
      });
    }
  }

  onCancel() {
    this.blocksSelected = this.originalBlocksSelected
    this.activeModal.dismiss();
  }

  onValidate() {
    this.activeModal.close(this.blocksSelected);
  }

  private setBlockNameToDisplay(visit, block) {
    const blockName = !_.isEmpty(block.name[this.currentLanguage]) ? block.name[this.currentLanguage] : block.name['english'];
    const visitName = !_.isEmpty(_.get(visit, 'name', [])) ? visit.name : visit.value.toString();
    block.nameToDisplay = block.selected ? `${blockName}_${visitName}` : blockName;
  }

}
