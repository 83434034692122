<form class="form-horizontal d-flex flex-column gap-3" #dnaMultipleChoiceForm="ngForm">
  <div class="form-group row">
    <div class="col-sm-2">
      <label class="control-label" translate>LABEL</label>
      <span class="fa fa-info" container="body" placement="bottom" ngbTooltip="{{ 'tooltip_label' | translate }}"></span>
    </div>
    <div class="col-sm-10">
      <input class="form-control" [disabled]="inactive" [(ngModel)]="component.args.label.english" type="text" name="label">
    </div>
  </div>
  <div class="form-group row">
    <label class="control-label col-sm-3" translate>LEFT_RIGHT_MODE</label>
    <div class="col-sm-1">
      <button class="noButton" type="button" [disabled]="inactive" (click)="updateLeftRightMode(); sendError()">
        <i class="fa icon-resize" [ngClass]="{'fa-square-o': !component.args.leftRightMode, 'fa-check-square-o': component.args.leftRightMode}"></i>
      </button>
    </div>
    <p class="col-sm-8" translate>LEFT_RIGHT_MODE_DESC</p>
  </div>
  <div>
    <div class="form-group row" *ngIf="component.args.leftRightMode">
      <div class="col-sm-3">
        <label class="control-label" translate>ADD_CENTRAL_VALUE</label>
        <span class="fa fa-info" container="body" placement="bottom" ngbTooltip="{{ 'BECOMES_CBA_CENTRAL_ABC' | translate}}"></span>
      </div>
      <div class="col-sm-1">
        <button class="noButton" type="button" [disabled]="inactive" (click)="toggleCentralValue(); sendError()">
          <i class="fa icon-resize" [ngClass]="{'fa-square-o': !component.args.hasCentralValue, 'fa-check-square-o': component.args.hasCentralValue}"></i>
        </button>
      </div>
      <div class="col-sm-5 row" *ngIf="component.args.hasCentralValue">
        <label class="control-label col-sm-4" translate>CENTRAL_MAIN</label>
        <div class="col-sm-4">
          <input class="form-control" [disabled]="inactive" #centralValue="ngModel" [(ngModel)]="component.args.centralValue.english[0].value" (ngModelChange)="buildTabPreview(); sendError()" type="text" name="centralValue">
          <div *ngIf="(!component.args.centralValue.english[0].value || (component.args.centralValue.english[0].value && component.args.centralValue.english[0].value.length == 0)) && (submitted || centralValue.dirty)"
            class="alert alert-danger" translate>
            REQUIRED
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row mt-3 mb-3" *ngIf="component.args.leftRightMode">
      <div class="col-sm-2">
        <label class="control-label" translate>LABEL_LEFT</label>
        <span class="fa fa-info" container="body" placement="bottom" ngbTooltip="{{ 'tooltip_label' | translate }}"></span>
      </div>
      <div class="col-sm-4">
        <input class="form-control" [disabled]="inactive" #labelLeft="ngModel" [(ngModel)]="component.args.labelLeft.english" (ngModelChange)="sendError()"
          type="text" name="labelLeft">
      </div>
      <div class="col-sm-2">
        <label class="control-label" translate>LABEL_RIGHT</label>
        <span class="fa fa-info" container="body" placement="bottom" ngbTooltip="{{ 'tooltip_label' | translate }}"></span>
      </div>
      <div class="col-sm-4">
        <input class="form-control" [disabled]="inactive" #labelRight="ngModel" [(ngModel)]="component.args.labelRight.english" (ngModelChange)="sendError()"
          type="text" name="labelRight">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-2">
        <label for="size" class="control-label" *ngIf="component.args.leftRightMode">{{ "LEFT_MODALITIES" | translate}}
          <dna-mandatory></dna-mandatory>
        </label>
        <label for="size" class="control-label" *ngIf="!component.args.leftRightMode">{{"MODALITIES" | translate}}
          <dna-mandatory></dna-mandatory>
        </label>
        <span class="fa fa-info" placement="bottom" ngbTooltip="{{ 'tooltip_value' | translate }}"></span>
      </div>
      <div class="col-sm-4">
        <tag-input [disable]="inactive" #values="ngModel" [(ngModel)]="component.args.values.english" (ngModelChange)="updateValues(); sendError()"
          name="values" identifyBy="value" displayBy="value" [maxItems]="50" placeholder="+ {{ 'VALUE' | translate }}"
          secondaryPlaceholder="{{ 'ENTER_FEM' | translate: { element: 'VALUE' | translate | lowercase } }}">
        </tag-input>
        <div *ngIf="(!component.args.values.english || (component.args.values.english && component.args.values.english.length == 0)) && (submitted || values.dirty)"
          class="alert alert-danger" translate>
          REQUIRED
        </div>
      </div>
      <div *ngIf="!component.args.hasCentralValue && component.args.leftRightMode" class="col-sm-2">
        <label for="size" class="control-label" translate>DESCRIPTOR_TYPE</label>
      </div>
      <div *ngIf="!component.args.hasCentralValue && component.args.leftRightMode" class="col-sm-4 form-group">
        <select class="form-control" [(ngModel)]="component.args.descriptorKeyType" (ngModelChange)="buildTabPreview(); sendError()"
          name="descriptorKeyType" #descriptorKeyType="ngModel" [compareWith]="compareDescriptor" [disabled]="inactive">
          <option *ngFor="let descriptorType of descriptorKeyTypes"
            [ngValue]="descriptorType"
            translate>{{descriptorType.name}}
          </option>
        </select>
        <div *ngIf="(!component.args.descriptorKeyType && submitted)"
          class="alert alert-danger" translate>
          REQUIRED
        </div>
      </div>
    </div>
      <div *ngIf="component.args.values.english.length > 0 && component.args.descriptorKeyType" class="form-group row mt-3">
        <div class="col-sm-2">
          <label class="control-label margintop" translate>MODALITIES_PREVIEW</label>
          <label class="control-label margintop" translate>ASSOCIATED_VALUES</label>
        </div>
        <div class="col-sm-10 tabPreview">
          <table>
            <ng-container *ngIf="component.args.leftRightMode; else noLeftRightMode">
              <tr>
                <td *ngFor="let val of tabPreviewValues">
                  <div class="btn-group btn-group-toggle">
                    <button type="button" class="btn btn-primary" disabled>{{val.value}}</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td *ngFor="let key of tabPreviewKeys" class="center">{{key.id}}</td>
              </tr>
            </ng-container>
            <ng-template #noLeftRightMode>
              <tr>
                <td *ngFor="let val of component.args.values.english">
                  <div class="btn-group btn-group-toggle">
                    <button type="button" class="btn btn-primary" disabled>{{val.value}}</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td *ngFor="let key of component.args.keys; let indexKey = index" class="center input-key">
                  <input name="key_{{indexKey}}" [ngClass]="{'is-invalid': isInvalidIndex(key.id)}" class="form-control" [disabled]="inactive" [(ngModel)]="key.id" type="number" (ngModelChange)="sendError()">
                </td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
      <div *ngIf="component.args.leftRightMode">
      <div class="form-group d-flex flex-row align-items-center mt-3 mb-3">
        <label class="control-label col-sm-3" translate>ANSWERS</label>
        <div class="btn-group btn-group-toggle" role="group" name="radioBasic">
            <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [(ngModel)]="component.args.answersLeftRight">
                <mat-button-toggle [checked]="component.args.answersLeftRight === AnswersLeftRight.OneAnswerTotal" [value]="AnswersLeftRight.OneAnswerTotal">{{'ONE_ANSWER_TOTAL' | translate}}</mat-button-toggle>
                <mat-button-toggle [checked]="component.args.answersLeftRight === AnswersLeftRight.OneAnswerPerSide" [value]="AnswersLeftRight.OneAnswerPerSide">{{'ONE_ANSWER_PER_SIDE' | translate}}</mat-button-toggle>
                <mat-button-toggle [checked]="component.args.answersLeftRight === AnswersLeftRight.MultipleAnswer" [value]="AnswersLeftRight.MultipleAnswer">{{'MULTIPLE_ANSWER_PER_SIDE' | translate}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 pointer" translate>CALCULATE_INDEX</label>
        <div class="col-md-2">
          <button class="noButton" type="button" [disabled]="inactive" (click)="component.args.isIndexCalculation = !component.args.isIndexCalculation">
            <i class="fa icon-resize" [ngClass]="{'fa-square-o' : !component.args.isIndexCalculation, 'fa-check-square-o' : component.args.isIndexCalculation}"></i>
          </button>
        </div>
        <ng-container *ngIf="component.args.isIndexCalculation">
          <label class="col-md-2" translate>OPERATOR</label>
          <select class="col-sm-4 form-control" [(ngModel)]="component.args.operator" size="1" name="operator" style="width:90px">
            <option *ngFor="let operator of indexOperators" [value]="operator" translate>{{operator}}</option>
          </select>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="!component.args.leftRightMode" class="form-group row">
    <label class="control-label col-sm-3" translate>MULTIPLE_ANSWER</label>
    <div class="col-sm-1">
      <button class="noButton" type="button" [disabled]="inactive" (click)="component.args.multipleSelection = !component.args.multipleSelection">
        <i class="fa icon-resize" [ngClass]="{'fa-square-o': !component.args.multipleSelection, 'fa-check-square-o': component.args.multipleSelection}"></i>
      </button>
    </div>
  </div>
  <div class="form-group row">
    <label class="control-label col-sm-3" translate>ADD_OTHER_OPTION</label>
    <div class="col-sm-1">
      <button class="noButton" type="button" [disabled]="inactive" (click)="component.args.addOtherOption = !component.args.addOtherOption">
        <i class="fa icon-resize" [ngClass]="{'fa-square-o': !component.args.addOtherOption, 'fa-check-square-o': component.args.addOtherOption}"></i>
      </button>
    </div>
  </div>
  <div class="form-group row">
    <label class="control-label col-sm-3" translate>VERTICAL_ALIGNMENT</label>
    <div class="col-sm-1">
      <button class="noButton" type="button" [disabled]="inactive" (click)="component.args.verticalAlignment = !component.args.verticalAlignment">
        <i class="fa icon-resize" [ngClass]="{'fa-square-o': !component.args.verticalAlignment, 'fa-check-square-o': component.args.verticalAlignment}"></i>
      </button>
    </div>
  </div>
  <!-- <div class="form-group row">
    <label class="control-label col-sm-3" translate>ADD_COMMENTARY</label>
    <div class="col-sm-1">
      <button class="noButton" [disabled]="inactive" (click)="component.args.commentary = !component.args.commentary">
        <i class="fa icon-resize" [ngClass]="{'fa-square-o': !component.args.commentary, 'fa-check-square-o': component.args.commentary}"></i>
      </button>
    </div>
  </div> -->
</form>
