<div class="container">
   <!--
  <ngx-loading [show]="showSpinner">
</ngx-loading>
-->
    <div class="ibox float-e-margins">
        <div class="ibox-title">
            <div class="row">
                <div class="col-12 col-md-4 my-auto">
                    <h5>{{ "GRAPH" | translate }}</h5>
                </div>
                <div class="col-12 col-md-8">
                    <div *ngIf="isChanged; else isNotChanged" class="float-end">
                        <ng-container>
                            <dna-save-cancel (onClickSave)="save()" (onClickCancel)="onCancel()"></dna-save-cancel>
                        </ng-container>
                    </div>
                    <ng-template #isNotChanged>
                        <div class="float-end">
                            <button id="back" class="btn btn-primary no-margin-bottom" type="button"
                                (click)="goToReport()" translate>DISPLAY_VIEW_REPORT</button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <dna-graphs-page [graphs]="campaignMulti.graphs" [descriptorGroup]="campaignMulti.descriptorsGroups" [isMulti]="true" [editMode]="true"
             (isWorkflowChanged)="onWkChanged($event)"></dna-graphs-page>
    </div>
</div>
