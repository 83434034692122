import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

import * as _ from 'lodash';
import {
  Observable
} from 'rxjs';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  Block,
  BlockParameter,
  Workflow
} from '../../../types';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import {
  WorkflowService
} from '../../workflows.service';
import { BlockService } from '../../../blocks/blocks.service';

@Component({
  selector: 'dna-edit-all-blocks',
  templateUrl: './edit-all-blocks.component.html',
  styleUrls: ['./edit-all-blocks.component.css']
})
export class EditAllBlocksComponent implements OnInit {
  blocks: Block[] = [];
  editBlockForm: FormGroup;
  currentLanguage: string;
  isChanged: boolean = false;
  modalOption: NgbModalOptions = {};
  showSpinner: boolean = true;
  workflow: Workflow = new Workflow();
  workflowUntouched: Workflow;

  constructor(
    private DNATranslate: DNATranslateService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private utilService: UtilService,
    private workflowService: WorkflowService,
    private blockService: BlockService
  ) { }

  ngOnInit() {
    this.currentLanguage = this.DNATranslate.getLanguage();

    this.workflow = _.cloneDeep(this.workflowService.workflow);
    this.workflowUntouched = _.cloneDeep(this.workflowService.workflow);
    this.initialiseBlocks(this.workflow.blocks).subscribe(() => this.showSpinner = false);
  }

  cancel() {
    this.router.navigate(['workflows', this.workflow.id]);
  }

  /**
   * initialiseBlocks allows to initialise the form of this component
   * This function is observable in order to set to true the showSpinner variable at the right time
   * @param blocks
   */
  initialiseBlocks(blocks: Block[]): Observable<any> {
    return new Observable(observer => {
      let blocksArray = blocks.map(block => {
        if (!block.parameters) {
          block.parameters = new BlockParameter();
        }
        return this.formBuilder.group({
          id: block.idInQuestionnaire,
          name: block.name[this.currentLanguage] || block.name['english'] || '-',
          isMandatory: block.mandatory,
          delayStart: block.parameters.delayStart || 0,
          delayEnd: block.parameters.delayEnd || 0,
          contextual: block.parameters.isContextual || false,
          contextualEval: block.parameters.isContextualPerEval || false,
          contextualFormula: block.parameters.isContextualPerFormula || false,
          display: block.parameters.report.display || true,
          order: [block.parameters.report.order || 1, [Validators.min(1), Validators.max(blocks.length)]],
          chartType: block.parameters.report.chartType,
          chartName: this.formBuilder.group(block.parameters.report.chartName)
        });
      });

      this.editBlockForm = this.formBuilder.group({
        blocks: this.formBuilder.array(blocksArray)
      });

      observer.next();
    });
  }

  onSubmit(form: FormGroup) {
    this.showSpinner = false;
    this.workflow.blocks.map((block, indexBlock) => {
      for (let formValues of form.value.blocks) {
        if (block.idInQuestionnaire === formValues.id) {
          block.mandatory = formValues.isMandatory;
          if (!block.parameters) {
            block.parameters = new BlockParameter();
          }
          block.parameters.delayStart = formValues.delayStart;
          block.parameters.delayEnd = formValues.delayEnd;
          block.parameters.isContextual = formValues.contextual;
          block.parameters.isContextualPerEval = formValues.contextualEval;
          block.parameters.isContextualPerFormula = formValues.contextualFormula;
          block.parameters.report.order = formValues.order;
          block.parameters.report.chartType = formValues.chartType;
          block.parameters.report.chartName = formValues.chartName;
        }
      }
      if (!_.isEqual(this.workflowUntouched.blocks[indexBlock].parameters, block.parameters)) {
        this.workflowService.updateBlockProperty(block.index, 'parameters', block.parameters);
      }
      if (!_.isEqual(this.workflowUntouched.blocks[indexBlock].mandatory, block.mandatory)) {
        this.workflowService.updateBlockProperty(block.index, 'mandatory', block.mandatory);
      }
    });
    this.router.navigate(['workflows', this.workflow.id]);
  }

  /**
   * setContextual is used to set isContextual property according to isContextualPerEval and isContextualPerFormula states
   * @param contextual
   * @param name
   * @param indexBlock
   */
  setContextual(contextual: any, name: string[], indexBlock: number) {
    let edit = this.editBlockForm.get(`blocks.${indexBlock}`);
    contextual.valueChanges.subscribe(value => {
      if (value === true) {
        edit.get(`${name[1]}`).setValue(!value);
        edit.get('contextual').setValue(true);
      } else {
        edit.get(`${name[1]}`).value ? edit.get('contextual').setValue(true) : edit.get('contextual').setValue(false);
      }
    });
  }

}
