import { Chart } from "../../chart.model";

const doWork = (data: Array<any>, baseKey: string, lang: string, payload: any, drilledMethod: string): any => {
    let usedColors = [...Chart.drilledColors];
    const flattenedData = [...new Set(
        data.flatMap((item) => 
            item.values.flatMap((valueItem) => 
                valueItem.values.map((innerValue) => innerValue.label)
            )
        )
    )];
    const sortedVolunteers = flattenedData.sort((a, b) => a.localeCompare(b));
    return data.reduce((accumulator: any, item: any, index: number) => {
        if (item.hasOwnProperty('values') && Array.isArray(item.values)) {
            item.values.reduce((reducer: { categories: object, series: object }, object: any, fIndex : number) => {
                let keyLabel : string;
                if( item.values.length > 1 ) keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang), Chart.getObjectValueTranslation(object.attribute.label, lang)].join('_');
                else keyLabel = [Chart.getObjectValueTranslation(item.attribute.blockName, lang), Chart.getObjectValueTranslation(item.attribute.label, lang)].join('_');
                accumulator.categories[keyLabel] = { ...item.attribute, drilled: object.attribute };

                object.values.reduce(( acc : any, drilledObject : any, volIndex : number ) => {
                    const keySerie = Chart.getObjectValueTranslation(drilledObject.label, lang);
                    const volSortedIndex = sortedVolunteers.indexOf(keySerie);

                    acc[keySerie] = (acc[keySerie] || { name: keySerie, data: [], color: usedColors[volSortedIndex] });
                    (acc[keySerie].data = ( acc[keySerie].data || []) ).push({
                        y : Math.abs(Number(drilledObject.value[baseKey])),
                        x : Object.keys(accumulator.categories).indexOf(keyLabel),
                    });
                    return acc;
                }, accumulator.series)

                // Add scatters for medians
                switch ( drilledMethod ) {
                    case null:
                        break;
                    case 'median':
                        reducer.series['median'] = (accumulator.series['median'] || { name: 'Median', data: [], type: 'scatter', zIndex: 4, color: Chart.colorScale[fIndex] });
                        (reducer.series['median'].data = ( reducer.series['median'].data || []) ).push({
                            y  : median(Chart.castValuesToNumber(object.values, baseKey, true), baseKey),
                            x  : Object.keys(accumulator.categories).indexOf(keyLabel),
                            details : object.values
                        });
                }
                return reducer;
            }, accumulator);
        }
        return accumulator;
    }, payload);
};



const median = (array: Array<any>, key: string) => {
    array = array.filter(x => x.hasOwnProperty(key) && x[key] !== null);
    if (!array.length) return null;
    const mid = Math.floor(array.length / 2),
        nums = [...array].sort((a, b) => a[key] - b[key]);
    return array.length % 2 !== 0 ? nums[mid][key] : (nums[mid - 1][key] + nums[mid][key]) / 2;
};

export {
    doWork
}