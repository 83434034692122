
import {
  from as observableFrom, of as observableOf,
  Observable
} from 'rxjs';

import { map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import {
  HttpClient
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';

import * as _ from 'lodash';

import {
  environment
} from '../../../environments/environment';
import {
  Campaign,
  CampaignInfosGen,
  CampaignStates,
  Formula,
  FormulaCampaign,
  GroupedFormula,
  OrchestraDemand,
  Order,
  Routine2,
  RoutineSynergy,
  SynergyDemand,
  UserInCampaign,
  Workspace
} from '../../types';
import {
  UtilService
} from './util.service';
import {
  WorkspaceService
} from '../../general/my-profile/workspaces/workspaces.service';
import { UserService } from './user.service';

@Injectable()
export class SynergyDemandService {

  serverUrlStudio: string;
  synergyDemands: SynergyDemand[] = [];

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private workspaceService: WorkspaceService
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  /********** REQUESTS **********/

  createSynergyDemands(synergyDemands: SynergyDemand[]): Observable<SynergyDemand[]> {
    return this.http.post<SynergyDemand[]>(`${this.serverUrlStudio}/synergy`, synergyDemands);
  }

  getSynergyDemandById(id: string, refresh: boolean = false): Observable<SynergyDemand> {
    return this.http.get<SynergyDemand>(`${environment.apiApptalkUrl}/v1/requests/${id}?refresh=${refresh}`);
  }

  resetLocalSynergy() {
    this.synergyDemands = [];
  }

  getOrchestraById(projectNumber): Observable<OrchestraDemand> {
    return this.http.post<OrchestraDemand>(`${environment.apiApptalkUrl}/v1/orchestra`, {projectNumber: projectNumber});
  }

  getSynergyDemands(): Observable<SynergyDemand[]> {
    if (this.synergyDemands && this.synergyDemands.length > 0) {
      this.getSynergyDemandsHttp().pipe(take(1)).subscribe();
      return observableOf(this.synergyDemands);
    }
    return this.getSynergyDemandsHttp();
  }

  getSynergyDemandsHttp(): Observable<SynergyDemand[]> {
    return this.http.get<any>(`${environment.apiApptalkUrl}/v1/requests`).pipe(tap(demands => this.synergyDemands = demands.list));
  }

  patchSynergyDemand(synergyDemand: SynergyDemand, campaignId: string = ""): Observable<SynergyDemand> {
    let index = this.synergyDemands.findIndex(s => s.id === synergyDemand.id);
    if (index != -1) {
      this.synergyDemands[index] = synergyDemand;
    }
    return this.http.patch<SynergyDemand>(`${environment.apiApptalkUrl}/v1/requests/${synergyDemand.requestNumber}`, {
      'studyId': campaignId,
      // 'studyWorkspaceId': ,
      'referents': synergyDemand.referent || [],
      'contributors': synergyDemand.contributors || [],
      'priority': synergyDemand.priority
    });
  }

  /********** FUNCTIONS **********/

  private addFormulaInCampaign(campaign: Campaign, synergyDemand: SynergyDemand): FormulaCampaign {
    const listF: GroupedFormula[] = synergyDemand.listFormulas.map((f: Formula) => new GroupedFormula(this.utilService.generateRandomID(), f.name, [f]));
    if (!campaign.parameters.isRoutine) {
      campaign.formula.listFormulas = _.unionBy(campaign.formula.listFormulas, listF, "name");
      campaign.formula.formulas = campaign.formula.listFormulas.map(f => _.pick(f, ["name"]));
    } else {
      campaign.formula.formulas = _.unionBy(campaign.formula.formulas, listF.map(f => _.pick(f, ["name"])), 'name');
    }
    return campaign.formula;
  }

  private addRoutineInCampaign(routines: RoutineSynergy[], campaign: Campaign): Campaign {
    if (campaign.state !== CampaignStates.Published) {
      campaign.formula.simultaneousComparison.isActive = true;
      campaign.parameters.isRoutine = true;

      let bench: Routine2 = new Routine2(1);
      let lab: Routine2 = new Routine2(2);
      bench.visits[0].orders = [];
      lab.visits[0].orders = [];

      routines = _.sortBy(routines, ['IDNumberField']);

      routines.forEach(
        (routine: RoutineSynergy, index: number) => {
          let orderBench: Order = new Order(`${index}`);
          let orderLab: Order = new Order(`${index}`);
          orderLab.steps[0].formula.formulaName = routine.NumberReferenceField;
          orderLab.steps[0].formula.lot = routine.BatchReferenceField;
          orderLab.steps[0].formula.quantity = parseInt(routine.QuantityReferenceField);
          orderLab.steps[0].formula.productName = routine.NameReferenceField;
          orderBench.steps[0].formula.formulaName = routine.NumberTestField;
          orderBench.steps[0].formula.lot = routine.BatchTestField;
          orderBench.steps[0].formula.quantity = parseInt(routine.QuantityTestField);
          orderBench.steps[0].formula.productName = routine.NameTestField;
          bench.visits[0].orders.push(orderBench);
          lab.visits[0].orders.push(orderLab);
        }
      );

      const campaignRoutines: [Routine2, Routine2] = [bench, lab];
      campaign.formula.routines = campaignRoutines;
      campaign.formula.listFormulas = this.utilService.getListFormulasFromRoutine(campaign);
      campaign.formula.simultaneousComparison.couples = this.utilService.calculateCouple(
        campaign.state,
        campaign.formula.listFormulas,
        campaign.formula.simultaneousComparison.couples,
        _.get(campaign, 'formula.applicationZone'),
        _.get(campaign, 'formula.chosenBench', _.get(campaign, 'formula.listFormulas[0]'))
      );
    }

    return campaign;
  }

  private addRoutineInCampaignFromSynergy(routines: Routine2[], campaign: Campaign): Campaign {
    if (campaign.state !== CampaignStates.Published) {
      campaign.formula.simultaneousComparison.isActive = true;
      campaign.parameters.isRoutine = true;

      if (!_.isEmpty(_.get(campaign, 'visits', []))) {
        const visitNames = campaign.visits.reduce((arr, val) => {
          arr.push(val.name);
          return arr;
        }, []);
  
        const routinesWithVisits = [];
        routines.forEach((routine) => {
          for (let index = 0; index < visitNames.length; index++) {
            if (!routine.visits[index]) {
              routine.visits.push(routine.visits[0]);
            }
            routine.visits[index].name = visitNames[index]; 
          }
          routinesWithVisits.push(routine);
        });
        routines = routinesWithVisits;
      }
      campaign.formula.routines = routines;
      campaign.formula.simultaneousComparison.couples = this.utilService.calculateCouple(
        campaign.state,
        campaign.formula.listFormulas,
        campaign.formula.simultaneousComparison.couples,
        _.get(campaign, 'formula.applicationZone'),
        _.get(campaign, 'formula.chosenBench', _.get(campaign, 'formula.listFormulas[0]'))
      );
    }

    return campaign;
  }

  private addUsersInCampaign(campaign: Campaign, synergyDemand: SynergyDemand): UserInCampaign[] {
    return _.unionBy(campaign.users.accountables, synergyDemand.contributors, "key");
  }

  addWorkspacesToSynergyDemand(synergyDemand: SynergyDemand, workspaces: Workspace[]): SynergyDemand {
    synergyDemand.workspaces = _.union(synergyDemand.workspaces, workspaces.map((workspace: Workspace) => workspace.id));
    return synergyDemand;
  }

  getWorkspaces(key: string): Observable<Workspace[]> {
    return this.workspaceService.getWorkspacesByUser(key).pipe(
      map((workspaces: Workspace[]) => workspaces.length > 0 ? workspaces : []));
  }

  updateCampaignOnSynergyChanged(synergyDemand: SynergyDemand, campaign: Campaign): Campaign {
    if (synergyDemand) {
      campaign.formula = this.addFormulaInCampaign(_.cloneDeep(campaign), _.cloneDeep(synergyDemand));
      let users = this.addUsersInCampaign(campaign, synergyDemand)
      campaign.users.accountables = users;
      synergyDemand.contributors = users;
      if (_.isEmpty(synergyDemand.referent) && !_.isEmpty(campaign.users.accountables)) {
        synergyDemand.referent = [];
        synergyDemand.referent.push(campaign.users.accountables[0]);
      }
      if (_.isArray(synergyDemand.routines) && synergyDemand.routines.length > 0) {
        if (synergyDemand.origin && 'Synergy' === synergyDemand.origin) {
          campaign = this.addRoutineInCampaignFromSynergy(_.cloneDeep(synergyDemand.routines), _.cloneDeep(campaign));
        } else {
          campaign = this.addRoutineInCampaign(_.cloneDeep(synergyDemand.routines), _.cloneDeep(campaign));
        }
      }
    }
    return campaign;
  }

  updateSynergyDemandWorkspaces(synergyDemand: SynergyDemand): Observable<SynergyDemand> {
    if (synergyDemand.studyId) {
      return observableOf(synergyDemand);
    }
    synergyDemand.workspaces = _.get(synergyDemand, 'oldWorkspaces', []);
    if (!synergyDemand.referent) synergyDemand.referent = [];
    if (!synergyDemand.contributors) synergyDemand.contributors = [];
    let array = _.uniqBy(synergyDemand.contributors.concat(synergyDemand.referent), 'key');
    return observableFrom(array).pipe(
      mergeMap((user: UserInCampaign) => this.getWorkspaces(user.key)),
      toArray(),
      map((workspaces: Workspace[][]) => _.flatten(workspaces)),
      map((workspaces: Workspace[]) => this.addWorkspacesToSynergyDemand(_.cloneDeep(synergyDemand), workspaces)))
  }

}
