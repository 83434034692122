
import {of as observableOf,
  Observable,
  Subject,
} from 'rxjs';

import {merge, mergeMap, take, takeUntil, finalize, map, tap} from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Router
} from '@angular/router';

import * as _ from 'lodash';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  ActionBarButton,
  ActionTypes,
  Campaign,
  CampaignMetadata,
  CampaignStates,
  DNATypes,
  Hub,
  LightCampaign,
  ModalContent,
  SynergyDemand,
  TableHeader,
  User,
  UserInCampaign,
} from '../../../types';
import {
  CampaignService
} from '../../../campaigns/campaigns.service';
import {
  CreateCampaignModalComponent
} from '../../../campaigns/list/create-campaign/create-campaign-modal.component';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  EditDemandModalComponent
} from './edit-demand/edit-demand-modal.component';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  FilterService
} from '../../../shared/services/filter.service';
import {
  LinkToCampaignModalComponent
} from './link-to-campaign/link-to-campaign-modal.component';
import {
  ReferenceTypeService
} from '../../../shared/services/reference-type.service';
import {
  SynergyDemandService
} from '../../../shared/services/synergy-demand.service';
import {
  UserService
} from '../../../shared/services/user.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dna-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['../dashboard.component.less', 'campaign-view.component.less']
})
export class CampaignViewComponent implements OnInit, OnDestroy {

  campaignNumbers: number = 0;
  campaigns: LightCampaign[] = [];
  campaignsAll: LightCampaign[] = [];
  campaignsArchived: LightCampaign[] = [];
  campaignsFinished: LightCampaign[] = [];
  campaignsAbandoned: LightCampaign[] = [];
  campaignsInProgress: LightCampaign[] = [];
  campaignsReady: LightCampaign[] = [];
  campaignsToPrepare: LightCampaign[] = [];
  campaignStates: typeof CampaignStates = CampaignStates;
  filter: any;
  hover1: boolean = false;
  hover2: boolean = false;
  hover3: boolean = false;
  hover4: boolean = false;
  hover5: boolean = false;
  hover6: boolean = false;
  hubs: Hub[] = [];
  campaignMetadata: CampaignMetadata;
  showSpinner: boolean = true;
  synergyDemands: SynergyDemand[] = [];
  synergyDemandsAll: SynergyDemand[] = [];
  today: number = new Date().setUTCHours(0, 0, 0, 0);
  user: User;
  usersToDisplay: User[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();
  tableHeaders$: Observable<TableHeader[]>;

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: 'lg',
  };

  constructor(
    public utilService: UtilService,
    private campaignService: CampaignService,
    private dnaTranslateService: DNATranslateService,
    private errorManagerService: ErrorManagerService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private referenceType: ReferenceTypeService,
    private router: Router,
    private synergyDemandService: SynergyDemandService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.hubs = this.referenceType.getHubs();
    this.user = this.userService.getUser();
    this.filter = this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).dashboard;

    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.SynergyDemand);

    this.init(_.cloneDeep(this.user)).pipe(
      takeUntil(this.destroy$))
      .subscribe(() => { }, error => this.errorManagerService.catchError(error));

    this.dnaTranslateService.onLangChange().pipe(
      tap(() => this.tableHeaders$ = this.utilService.createHeaders(DNATypes.SynergyDemand)),
      takeUntil(this.destroy$))
      .subscribe();

    this.userService.onUserChanged().pipe(
      tap(() => this.showSpinner = true),
      tap(() => this.destroy$.next(true)),
      tap(() => this.ngOnInit()),
        takeUntil(this.destroy$))
        .subscribe(() => { }, error => this.errorManagerService.catchError(error));

    this.campaignService.getHttpCampaignsWithFilter(this.filter, false, false);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addNamesToReferentOrContributors(synergyDemands: SynergyDemand[]): Observable<SynergyDemand[]> {
    synergyDemands = synergyDemands.map((synergyDemand: SynergyDemand) => {
      synergyDemand.contributors = (synergyDemand.contributors && synergyDemand.contributors.length > 0) ?
        synergyDemand.contributors.map((contributor: UserInCampaign) => this.mapReferentOrContributorWithUser(contributor, this.campaignMetadata.users)) : [];
      synergyDemand.referent = (synergyDemand.referent && synergyDemand.referent.length > 0) ?
        synergyDemand.referent.map((referent: UserInCampaign) => this.mapReferentOrContributorWithUser(referent, this.campaignMetadata.users)) : [];
      return synergyDemand;
    })
    return observableOf(synergyDemands);
  }

  deleteProjectFromCampaign = (campaign: Campaign): Campaign => {
    delete campaign.synergy;
    delete campaign.actiview;
    return campaign;
  };

  getUsersFromWorkspace(user: User): Observable<User[]> {
    return observableOf(this.campaignService.displayUsersName(this.campaignMetadata)).pipe(
      map(users => {
        const currentWKUser = _.get(user, 'currentWorkspace.users', []);
        if (currentWKUser.length === 0) return users;
        return currentWKUser.map((idUser: string) => users.find((user: User) => _.get(user, 'key', '') === idUser));
      }));
  }

  goTo(filter: any, statesSelected: CampaignStates[]) {
    const typeCampaigns = JSON.stringify(filter.studyType)
    const hubs: string = filter.hubs.reduce(
      (acc: string, curr: Hub, index: number) => index === 0 ? `${curr}` : `${acc},${curr}`,
      ''
    );

    const states: string = statesSelected.reduce(
      (acc: string, curr: CampaignStates, index: number) => index === 0 ? `${curr}` : `${acc},${curr}`,
      ''
    );

    const users: string = filter.areMyStudies
      ? this.user.track
      : filter.users.reduce((acc: string, curr: User, index: number) => index === 0 ? `${curr.key}` : `${acc},${curr.key}`, '');

    const params: any = {
      hubs: hubs,
      states: states,
      typeCampaigns:typeCampaigns
    }

    if (users) {
      params.users = users;
    }

    this.router.navigate(['campaigns', params]);
  }

  goToCampaign(id: string) {
    this.router.navigate(['campaigns', id, 'edit']);
  }

  init(user: User) {
    return this.campaignService.getCampaignMetadata().pipe(
      take(1),
      tap(meta => this.campaignMetadata = meta),
      mergeMap(() => this.initCampaigns(user))
    );
      // mergeMap(() => this.initCampaigns(user, filter).pipe(merge(this.initSynergyDemands(user, filter)))),);
  }

  initCampaigns(user: User) {
    this.showSpinner = true;
    const users$ = this.getUsersFromWorkspace(user);
    return users$.pipe(tap((users: User[]) => this.usersToDisplay = users),
      mergeMap(() => this.campaignService.getHttpCampaignsWithFilter(this.filter, false, false)),
      tap((campaigns) => this.campaigns = _.cloneDeep(campaigns.list)),
      tap((campaigns) => this.updateCampaignNumbers(campaigns.list)),
      tap((campaigns) => this.updateCampaignsFilteredByState(campaigns.list)),
      tap((campaigns) => this.showSpinner = false),)
  }

  initSynergyDemands(user: User, filter: any): Observable<SynergyDemand[]> {
    return this.synergyDemandService.getSynergyDemands().pipe(
      mergeMap((demands: SynergyDemand[]) => this.addNamesToReferentOrContributors(demands)),
      tap((demands: SynergyDemand[]) => this.synergyDemandsAll = demands),
      map((demands: SynergyDemand[]) => this.utilService.filterListSynergyDemandsInDashboard(demands, filter)),
      tap((demands: SynergyDemand[]) => this.synergyDemands = _.cloneDeep(demands)),);
  }

  mapReferentOrContributorWithUser(userRC: UserInCampaign, usersInDatabase: User[]): UserInCampaign {
    const userDB = usersInDatabase.find((user: User) => userRC.key == user.key);

    if (userDB) {
      userRC.name = this.campaignService.decodeUserName(userDB.name);
    }

    return _.pick(userRC, ['name', 'key', 'formula', 'pzone', 'isActive']);
  }

  onClickActionButton(actionButton: ActionBarButton, synergyDemand: SynergyDemand, campaigns: LightCampaign[], users: User[]) {
    switch (actionButton.id) {
      case ActionTypes.EditSynergyDemand:
        this.openModalEditDemand(_.cloneDeep(synergyDemand), _.cloneDeep(users));
        break;
      case ActionTypes.CreateCampaign:
        this.openModalCreateCampaign(_.cloneDeep(synergyDemand));
        break;
      case ActionTypes.LinkCampaign:
        this.openModalLinkToCampaign(_.cloneDeep(synergyDemand), _.cloneDeep(campaigns));
        break;
      case ActionTypes.GoToCampaign:
        try {
          this.goToCampaign(synergyDemand.studyId);
        } catch (error) {
          this.errorManagerService.catchError(error);
        }
        break;
      case ActionTypes.UnlinkSynergyDemand:
        const campaign = campaigns.find((c: LightCampaign) => c.id === synergyDemand.studyId);
        try {
          this.utilService.translateMessageModal('CONFIRM_UNLINK_SYNERGY', campaign.name, synergyDemand.id).pipe(
            mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)))
            .subscribe(
            () => this.unlinkSynergyDemand(synergyDemand, campaign),
            error => { }
            );
        } catch (error) {
          this.errorManagerService.catchError(error);
        }
        break;
    }
  }

  onFilterChanged() {
    this.showSpinner = true;
    this.campaignService.getHttpCampaignsWithFilter(this.filter, false, false).pipe(
      tap((campaigns) => this.campaigns = _.cloneDeep(campaigns.list)),
      tap((campaigns) => this.updateCampaignNumbers(campaigns.list)),
      tap((campaigns) => this.updateCampaignsFilteredByState(campaigns.list)),
      tap(() => this.showSpinner = false),
    ).subscribe();

    //this.synergyDemands = this.utilService.filterListSynergyDemandsInDashboard(this.synergyDemandsAll, this.filter);
  }

  onParametersChanged(object: any) {
    this.filter.pageIndex = object.pageIndex;
    this.filter.numberOfObjectsPerPage = object.numberOfObjectsPerPage;
  }

  openModalCreateCampaign(synergyDemand: SynergyDemand) {
    const modal = this.modalService.open(CreateCampaignModalComponent, this.modalOption);
    modal.componentInstance.synergyDemand = synergyDemand;
    modal.result.then(
      () => {
        this.router.navigate(['/campaigns', 'new', 'edit']);
      },
      (reason) => { }
    ).catch(error => {
      this.errorManagerService.catchError(error);
      this.router.navigate(['dashboard']);
    });
  }

  openModalEditDemand(synergyDemand: SynergyDemand, users: User[]) {
    const modal = this.modalService.open(EditDemandModalComponent, this.modalOption);
    modal.componentInstance.synergyDemand = synergyDemand;
    modal.componentInstance.users = users;
    modal.result.then(
      (demand: SynergyDemand) => {
        this.showSpinner = true;
        this.synergyDemands = this.updateSynergyDemands(_.cloneDeep(this.synergyDemands), _.cloneDeep(demand));
        this.synergyDemandService.patchSynergyDemand(demand, demand.studyId).pipe(
          takeUntil(this.destroy$),
          finalize(() => this.showSpinner = false),)
          .subscribe(
          () => this.errorManagerService.displayMessage("BRIDGE_EDIT_SUCCESS"),
          (error) => this.errorManagerService.catchError(error)
          );
      },
      (reason) => { }
    ).catch((error) => this.errorManagerService.catchError(error));
  }

  openModalLinkToCampaign(synergyDemand: SynergyDemand, campaigns: LightCampaign[]) {
    const modal = this.modalService.open(LinkToCampaignModalComponent, this.modalOption);
    modal.componentInstance.synergyDemand = synergyDemand;
    modal.componentInstance.campaigns = campaigns;
    modal.result.then(
      (campaign: LightCampaign) => {
        this.errorManagerService.displayMessage("BRIDGE_LINK_SUCCESS");
        this.campaignService.goTo(campaign);
      },
      (reason) => { }
    ).catch((error) => {
      this.errorManagerService.catchError(error);
    });
  }

  sort(synergyDemands: SynergyDemand[], idHeader: string, reverse: boolean) {
    switch (idHeader) {
      case "ID":
        return this.utilService.sortListByType(synergyDemands, "id", reverse);
      case "BRIDGE_STATE":
        return this.utilService.sortListByType(synergyDemands, "status", reverse);
      case "PRIORITY":
        return this.utilService.sortListByType(synergyDemands, "priority", reverse);
      case "CAMPAIGN_MANAGER":
        return this.utilService.sortListByType(synergyDemands, "name", reverse, false, null, atob);
      case "CAMPAIGN_REFERENT":
        return this.utilService.sortListByType(synergyDemands, "referent.0.name", reverse);
      case "BRIDGE_REQUESTER":
        return this.utilService.sortListByType(synergyDemands, "requester.name", reverse);
    }
  }

  unlinkSynergyDemand(synergyDemand: SynergyDemand, campaign: LightCampaign) {
    this.showSpinner = true;
    this.campaignService.getHttpCampaign(campaign.id).pipe(
      map(this.deleteProjectFromCampaign),
      mergeMap(this.campaignService.putCampaign),
      tap((c: Campaign) => this.campaignService.setCampaignFromLocalStorage(c)),
      mergeMap(() => this.campaignService.unlinkSynergyDemandWithCampaign(synergyDemand)),
      map((s: SynergyDemand) => this.updateReferentsInSynergyDemand(s, synergyDemand.referent)),
      map((s: SynergyDemand) => this.updateSynergyDemands(_.cloneDeep(this.synergyDemands), _.cloneDeep(s))),
      tap((synergyDemands: SynergyDemand[]) => this.synergyDemands = synergyDemands),
      takeUntil(this.destroy$),
      finalize(() => this.showSpinner = false),)
      .subscribe(() => { }, error => this.errorManagerService.catchError(error));
  }

  updateCampaignNumbers(campaigns: LightCampaign[]) {
    this.campaignNumbers = campaigns.filter((campaign: LightCampaign) => campaign.state !== CampaignStates.Removed).length;
  }

  updateCampaignsFilteredByState(campaigns: LightCampaign[]) {
    this.campaignsToPrepare = campaigns.filter((campaign: LightCampaign) => campaign.state === CampaignStates.Draft || campaign.state === CampaignStates.Suspended);
    this.campaignsReady = campaigns.filter(
      (campaign: LightCampaign) => campaign.state === CampaignStates.Published && campaign.startDate > this.today
    );
    this.campaignsInProgress = campaigns.filter(
      (campaign: LightCampaign) => campaign.state === CampaignStates.Published && campaign.startDate <= this.today
    );
    this.campaignsFinished = campaigns.filter((campaign: LightCampaign) => campaign.state === CampaignStates.Finished);
    this.campaignsAbandoned = campaigns.filter((campaign: LightCampaign) => campaign.state === CampaignStates.Abandoned);
    this.campaignsArchived = campaigns.filter((campaign: LightCampaign) => campaign.state === CampaignStates.Archived);
  }

  updateReferentsInSynergyDemand(demand: SynergyDemand, referents: UserInCampaign[]): SynergyDemand {
    demand.referent = _.cloneDeep(referents);
    return demand;
  }

  updateSynergyDemands(synergyDemands: SynergyDemand[], synergyDemand: SynergyDemand): SynergyDemand[] {
    const index = synergyDemands.findIndex((d: SynergyDemand) => d.id === synergyDemand.id);
    synergyDemands[index] = synergyDemand;
    return synergyDemands;
  }

}
