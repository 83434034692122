
import {zip as observableZip, 
  Observable,
  Subscription
} from 'rxjs';

import {mergeMap, merge, map, filter, tap} from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import { LangChangeEvent } from '@ngx-translate/core';

import { CampaignService } from '../../../campaigns/campaigns.service';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  DNATypes,
  Menu,
  Training,
  TrainingMetadata
} from '../../../types';
import {
  TrainingService
} from '../../training.service';
import {
  UtilService
} from '../../../shared/services/util.service';

@Component({
  selector: 'dna-training-root',
  templateUrl: './training-root.component.html',
  styleUrls: ['./training-root.component.less']
})
export class TrainingRootComponent implements OnInit, OnDestroy {

  training: Training = new Training();
  trainingId: string;
  showSpinner: boolean = false;
  sideMenu: Menu[];
  subscriptions: Subscription[] = [];

  constructor(
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private translateService: DNATranslateService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    const idTraining$ = this.route.paramMap.pipe(map((params: ParamMap) => params.get('idTraining')));

    let cloneMetadata;
    const metadataTraining$ = this.trainingService.getTrainingMetadata().pipe(
      tap((metadata: TrainingMetadata) => {
        cloneMetadata = _.cloneDeep(metadata);
        cloneMetadata.users = this.campaignService.setUsersName(cloneMetadata.users);
        this.trainingService.users = cloneMetadata.users.concat(cloneMetadata.volunteers);
        this.trainingService.workflows = cloneMetadata.workflows;
      }));

    const newTraining$ = idTraining$.pipe(filter(idTraining => idTraining === 'new'),
      tap((idTraining: string) => this.trainingId = idTraining),
      tap(() => this.updateMenu()),
      mergeMap(() => metadataTraining$),);

    const existingTraining$ = idTraining$.pipe(filter(idTraining => idTraining !== 'new'),
      tap(() => this.showSpinner = true),
      tap((idTraining: string) => this.trainingId = idTraining),
      tap(() => this.updateMenu()),
      mergeMap(() => this.trainingService.getCurrentTraining(this.trainingId).pipe(tap(training => this.training = training))),);

    this.subscriptions.push(
      newTraining$.pipe(
        merge(
          observableZip(
            existingTraining$,
            metadataTraining$
          )
        ))
        .subscribe(() => this.showSpinner = false)
    );

    this.subscriptions.push(
      this.translateService.onLangChange().subscribe((translation: LangChangeEvent) => this.updateMenu())
    );

    this.subscriptions.push(
      this.utilService.onUpdateMenuTabs().subscribe(() => this.updateMenu())
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  updateMenu() {
    this.subscriptions.push(
      this.utilService.createMenu(DNATypes.Training).subscribe(
        (menus: Menu[]) => {
          let menusToDisplay = this.trainingId === 'new' ? menus.slice(0, 1) : menus;
          this.sideMenu = menusToDisplay.map(
            (menu: Menu) => {
              menu.link = ['/trainings', this.trainingId].concat(menu.link);
              return menu;
            }
          );
        }
      )
    );
  }

}
