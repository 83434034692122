<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 class="text-center">{{ "DETAIL" | translate: { object: "ASSESSMENT" | translate | lowercase } }}</h5>
      <div>
        <button class="btn btn-primary" [routerLink]="['/trainings', trainingId, 'edit', 'rawdata']" translate>BACK_LIST</button>
      </div>
    </div>
    <div class="ibox-content">
      <ng-container *ngIf="assessment && !showSpinner">
        <div class="form-group">
          <div class="row">
            <label class="col-3 form-control-label">{{ "WORKFLOW" | translate }}</label>
            <p>
              <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
            </p>
          </div>
          <div class="row">
            <label class="col-3 form-control-label">{{ "FORMULA" | translate }}</label>
            <p>{{ assessment.formula.name }}</p>
          </div>
          <div class="row">
            <label class="col-3 form-control-label">{{ "USERS" | translate }}</label>
            <p>
              <ng-container *ngFor="let user of assessment.users; let isLast = last">
                {{ user.name }}{{ isLast ? "" : ", " }}
              </ng-container>
            </p>
          </div>
          <div>
            <div class="row">
              <label class="col-3 form-control-label">{{ "PARTICIPANTS" | translate }}</label>
              <p>{{ assessment.participant ? assessment.participant.name : '-' }}</p>
            </div>
          </div>
        </div>
        <dna-table [data]="assessment.answers" [headers]="tableHeaders$ | async" orderedBy="INDEX" [reverse]="false" [sortFunction]="sort"
          [title]="'ANSWERS'" (downloadData)="download($event)">
          <ng-template #row let-data="data">
            <tr dna-detail-assessment-row class="cursor-pointer" [answer]="data" [assessment]="assessment" [currentLanguage]="currentLanguage"
              [trainingId]="trainingId"></tr>
          </ng-template>
        </dna-table>
      </ng-container>
    </div>
  </div>
</div>
