<div class="container">
    <!--
  <ngx-loading [show]="showSpinner">
  </ngx-loading>
  -->
  <div class="ibox float-e-margins">
    <form #generalInfoForm="ngForm" novalidate name="trainingForm">
    <div class="ibox-title">
      <h5 class="text-center" *ngIf="!training.id; else trainingId"> {{ 'GENERAL' | translate }} - {{ 'DATE_CREATION' | translate }} </h5>
      <ng-template #trainingId>
        <h5 class="text-center"> {{ 'GENERAL' | translate }} - {{ 'MODIFICATION' | translate }} </h5>
      </ng-template>
      <p *ngIf="training.state === trainingStates.Published" translate> PUBLISHED_TRAININGS_MESSAGE </p>
      <div *ngIf="!isEditDisabled(training)">
        <button id="save" class="btn btn-primary" type="button" (click)="saveAndUpdate(generalInfoForm)" translate>SAVE</button>
        <button id="cancel" class="btn btn-default" type="button" (click)="clickCancel()" translate>CANCEL</button>
      </div>
    </div>
    <div class="ibox-content">
        <div class="form-group">
          <div class="row">
            <label class="col-3 required form-control-label" [ngClass]="{'is-invalid' : name.invalid && (name.touched || submitted)}">
            {{'NAME' |translate}} </label>
            <input class="col-8 form-control" [ngClass]="{'is-invalid' : name.invalid && (name.touched || submitted)}" [disabled]="isEditDisabled(training)"
              #name="ngModel" [(ngModel)]="training.name" type="text" name="name" required>
            <div *ngIf="name.dirty || name.touched || submitted" class="offset-1 text-center invalid-feedback">
              <p *ngIf="name.errors && name.errors.required" translate> REQUIRED </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 form-control-label">{{"DESCRIPTION" | translate}}</label>
            <textarea class="col-8 form-control" [disabled]="isEditDisabled(training)" [(ngModel)]="training.description" style="overflow:hidden"
              type="text" name="description"></textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 required form-control-label">
            {{'SESSIONS' |translate}} 
            </label>
            <input class="col-8 form-control" #minInput="ngModel" min="1" name="sessions" type='number' [(ngModel)]="training.sessions" [disabled]="isEditDisabled(training)" required>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 required form-control-label" [ngClass]="{'is-invalid' : dateStart.invalid && (dateStart.touched || submitted)}">{{'DATE_START' |translate}}</label>
            <div class=" col-8 input-group">
              <input class="col-11 margin-date form-control" [ngClass]="{'is-invalid' : dateStart.invalid && (dateStart.touched || submitted)}"
                [disabled]="isEditDisabled(training)" [ngModel]="startDate" (ngModelChange)="onDateChanged($event, 'startDate')"
                #dateStart="ngModel" [placeholder]="dateFormat" name="d" ngbDatepicker #d="ngbDatepicker" required>
              <button class="input-group-addon" [disabled]="isEditDisabled(training)" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
              <div *ngIf="dateStart.dirty || dateStart.touched || submitted" class="offset-1 text-center invalid-feedback">
                <p *ngIf="dateStart.errors && dateStart.errors.required" translate>REQUIRED</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  </form>
  </div>
</div>

