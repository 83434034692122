<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5> {{ 'WORKFLOWS' | translate }} </h5>
      <div>
        <button id="save" class="btn btn-primary" type="button" (click)="save(training)" translate>SAVE</button>
        <button id="cancel" class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
      </div>
    </div>
    <div class="ibox-content">
      <div *ngIf="training && workflowsFiltered" class="row">
        <div class="filter-group col-md-12">
          <div class="display-parent row">
            <div *ngIf="!searchOn" class="col-md-1">
              <dna-button-display-only class="fav-button" logo="fa fa-star" [value]="displayFavoriteOnly" (onClick)="updateList(searchText, !displayFavoriteOnly)">
              </dna-button-display-only>
            </div>
            <div class="display-child col-md-5">
              <dna-search-logo *ngIf="!searchOn" class="fav-button" [data]="searchText" [isDisplayed]="searchOn" (onClickDisplay)="searchOn =! searchOn"></dna-search-logo>
              <dna-search-text *ngIf="searchOn" [data]="searchText" [isDisplayed]="searchOn" (onDisplay)="searchOn = false;" (onSearch)="updateList($event, displayFavoriteOnly)">
              </dna-search-text>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="workflowsList scroll-bar">
            <div *ngIf="searchText.length > 0" class="search-text">{{ 'SEARCH' | translate }} : {{ searchText }}</div>
            <ul id="source2" [dragula]="idDragula" [(dragulaModel)]="workflowsFiltered">
              <li *ngFor="let item of workflowsFiltered" class="workflow copy-allowed">
                <div class="box-item display-parent row">
                  <div class="col-md-10">
                    <dna-translate [fieldToTranslate]="item.name"> </dna-translate>
                  </div>
                  <div class="display-child col-md-2">
                    <span class="icon">
                <dna-favorites [id]="item.id" [type]="workflowType"></dna-favorites>
              </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id="dropzone" class="dropzoneWorkflows scroll-bar offset-md-1 col-md-6">
          <ul id="target" [dragula]="idDragula" [(dragulaModel)]="training.workflows">
            <li *ngFor="let workflow of training.workflows, let index = index">
              <div class="container_block panel-published col-md-12">
                <div class="title block-padding">
                  <div class="display-parent row">
                    <div class="col-md-9">
                      <div>
                        <dna-translate [fieldToTranslate]="workflow.name"> </dna-translate>
                      </div>
                      <div>
                        <dna-translate [fieldToTranslate]="workflow.description"> </dna-translate>
                      </div>
                    </div>
                    <div class="display-child col-md-3">
                      <span *ngIf="canIRemoveThisElement(workflow)" class="pull-right fa fa-trash" (click)="onRemoveWorkflow(index)"> </span>
                      <span class="pull-right fa fa-cog" (click)="openModalWorkflow(workflow)"> </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
