import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  DNATranslateService
} from '../../../../shared/services/translate.service';
import * as _ from 'lodash';
import {
  BlockService
} from '../../../blocks.service';
import {
  Block
} from '../../../../types';
import { UserService } from '../../../../shared/services/user.service';

@Component({
  selector: 'dna-preview-components',
  templateUrl: './preview-components.component.html',
  styleUrls: ['./preview-components.component.less']
})

export class PreviewComponents implements OnInit {
  block: Block = new Block();
  showSpinner: boolean;
  url: SafeResourceUrl;
  constructor(
    private DNATranslate: DNATranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private blockService: BlockService,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.block = this.blockService.block;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.mobile_url()}/#/blockPreview/${this.block.id}/${this.userService.getUser().currentWorkspace.id}`);
  }

  back() {
    this.router.navigate(['blocks', this.block.id]);
  }

}
