
 <div class="modal-scroll">
  <div class="modal-header">
    <div class="row col-12">
       <div class="col-4">
          <h3 class="modal-title" translate>
              DESCRIPTORS
            </h3>
       </div> 
       <div class="col-4 open-close">
          <button class="btn btn-primary"  (click)="openCloseDescriptors(false)" translate>ALL_OPEN</button>
          <button class="ml-2 btn btn-primary" type="submit" (click)="openCloseDescriptors(true)" translate>ALL_CLOSE</button>
       </div> 
       <div class="col-4">
          <button class="close" type="button" (click)="cancel()">
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>
       </div>  
         
    </div>
    
  </div>
  <div class="modal-body">
    <div class="modal-scroll">
      <ng-container *ngFor="let block of blockList; let indexBlock = index">
        <button type="button" class="m-1 btn btn-block btn-light" attr.aria-controls="{{'collapseBlock_' + indexBlock}}"
          (click)="collapsedBlocksTab[indexBlock] = !collapsedBlocksTab[indexBlock]">
          <div class="row">
            <span class="col-md-4">
              <dna-translate [fieldToTranslate]="descriptorsGrouped[block][0].descriptors[0].blockName"> </dna-translate>
            </span>
            <span class="col-md-4">
              {{ "NUMBER_OBJECTS_SELECTED" | translate: { objects: "DESCRIPTORS" | translate | lowercase } }}:
              {{getNumberSelected(descriptorsGrouped[block])}}
            </span>
            <div class="col-md-4" (click)="selectAllDescriptors(descriptorsGrouped[block], indexBlock, $event); false">
                <span class="fa"
                  [ngClass]="{ 'fa-square-o': !selectAll[indexBlock], 'fa-check-square-o': selectAll[indexBlock] }"></span>
                <span translate>SELECT_ALL_DESCRIPTOR</span>
              </div>
            </div>
          </button>
        <div class="row m-1" id="{{'collapseBlock_' + indexBlock}}" [ngbCollapse]="collapsedBlocksTab[indexBlock]">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width: 30%">{{ "NAME" | translate }}</th>
                <th style="width: 35%">{{ "DESCRIPTORS" | translate }}</th>
                <th style="width: 35%">{{ "SCALE" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let descriptorsGroup of descriptorsGrouped[block]; let index = index"
                class="line-descriptor cursor-pointer" [ngClass]="{'selected': isDescriptorSelected(descriptorsGroup)}"
                (click)="addOrRemoveDescriptors(descriptorsGroup)">
                <td>
                  <dna-translate [fieldToTranslate]="descriptorsGroup.name"> </dna-translate>
                </td>
                <td>
                  {{getName(descriptorsGroup.descriptors, 'name')}}
                </td>
                <td>
                  {{descriptorsGroup.descriptors[0].scaleDetail}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="isGroup">
      <div>
        <table class="table table-hover table-borderless-th dataTable">
          <thead>
            <tr>
              <th style="width: 30%">{{ "NAME" | translate }}</th>
              <th style="width: 35%">{{ "DESCRIPTORS" | translate }}</th>
              <th style="width: 35%">{{ "SCALE" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let block of blockList; let indexBlock = index">
              <ng-container *ngFor="let descriptorsGroup of descriptorsGrouped[block]; let index = index">
                <tr *ngIf="isDescriptorSelected(descriptorsGroup)">
                  <td>
                    <dna-translate [fieldToTranslate]="descriptorsGroup.name"> </dna-translate>
                  </td>
                  <td>
                    {{getName(descriptorsGroup.descriptors, 'name')}}
                  </td>
                  <td>
                    {{descriptorsGroup.descriptors[0].scaleDetail}}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="py-3 row">
        <label class="col-md-3 my-auto form-control-label required" translate>GROUP_DESCRIPTORS_NAME</label>
        <div class="col-md-6">
          <input type="text" class="form-control" [(ngModel)]="groupName" required>
        </div>
      </div>
      <div class="py-3 row">
        <label class="col-md-3 my-auto form-control-label required" translate>CALCUL_METHOD</label>
        <div class="col-md-6">
          <select class="form-control" [(ngModel)]="calculationMethodSelected">
            <option *ngFor="let calculMethod of calculationMethods" [ngValue]="calculMethod" translate>{{ calculMethod }}
            </option>
          </select>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="submit" (click)="submit(descriptorGroupInGraph)" translate>VALIDATE</button>
  <button class="btn btn-default" type="button" (click)="cancel()" translate>CANCEL</button>
</div>