<div class="container">
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 class="center" translate> ACTIONS </h5>
    </div>
    <div class="ibox-content">
      <div class="form-group" *ngIf="training.state == trainingStates.Draft || training.state == trainingStates.Suspended">
        <div class="row">
          <div class="col-3">
            <button class="btn btn-primary" (click)="publish(training)" translate>PUBLISH</button>
          </div>
          <label class="col-9 control-label" translate> PUBLICATION_TRAINING </label>
        </div>
      </div>
      <div class="form-group" *ngIf="training.state == trainingStates.Published">
        <div class="row">
          <div class="col-3">
            <button class="btn btn-danger" [disabled]="training.startDate < date" (click)="suspend(training)" translate>SUSPEND</button>
          </div>
          <label *ngIf="training.startDate < date" class="col-9 control-label" translate> SUSPEND_TRAINING_NOT_ALLOW </label>
        </div>
      </div>
    </div>
  </div>
</div>
