import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '../../../campaigns.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { BlocksSelectedInVisit, CampaignVisit, CampaignVisits } from '../../../../types';
import * as _ from 'lodash';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RemoveVisitsModalComponent } from './remove-visits-modal/remove-visits-modal.component';

@Component({
  selector: 'dna-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.less']
})
export class VisitsComponent implements OnInit {

  showSpinner: boolean = true;
  campaignVisits: CampaignVisits;
  originalCampaignVisits: CampaignVisits;
  error: boolean = false;
  errorWorkflow: boolean = false;
  currentLanguage: string;
  idCampaign: string;
  visitsChanged: boolean = false;
  blocksSelected: BlocksSelectedInVisit[];
  initSelectedBlocks: Subject<void> = new Subject<void>();
  onErrorVisitsValues: boolean = false;
  publishedTemplate: boolean = false;

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "sm"
  };

  constructor(
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private translationService: DNATranslateService,
    private errorManager: ErrorManagerService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.currentLanguage = this.translationService.getLanguage();
    this.idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');
    this.campaignService.getCampaignVisits(this.idCampaign).pipe(
      catchError(err => {
        console.log(err);
        this.showSpinner = false;
        if(_.get(err, 'error.error', false) === "ERROR_WORKFLOW") {
          this.errorWorkflow = true;
        } else {
          this.error = true;
        }
        return of();
      }),
      tap((campaignVisits: CampaignVisits) => this.campaignVisits = campaignVisits),
      tap((campaignVisits: CampaignVisits) => this.publishedTemplate = this.campaignService.isTemplatePublishedCampaign(campaignVisits.isTemplate, campaignVisits.state)),
      tap((campaignVisits: CampaignVisits) => this.originalCampaignVisits = _.cloneDeep(campaignVisits)),
      tap(() => this.campaignService.updateBreadCrumb(this.campaignVisits.name)),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.visitsChanged) {
      this.errorManager.displayMessage('SAVE_MODIFICATION_BEFORE_CONTINUING', "default");
      return false;
    }
    return true;
  }

  visitsUpdated(blocksSelected) {
    this.updateNbVisitsByVolunteer(this.campaignVisits);
    this.onErrorVisitsValues = false;
    this.visitsChanged = true;
    this.blocksSelected = blocksSelected;
  }

  save() {
    if(this.areVisitsInOrder(this.campaignVisits)) {
      this.showSpinner = true;
      this.areAllBlocksInVisits(this.campaignVisits)
      this.campaignService.putCampaignVisits(this.idCampaign, this.campaignVisits).pipe(
        catchError(err => {
          console.log(err);
          this.showSpinner = false;
          this.errorManager.displayMessage("ON_ERROR_UPDATE", "danger");
          return of();
        }),
        tap((res: { unableDeleteVisitTab: any[], visits:CampaignVisit[], visitPageUpdated:Boolean}) => this.campaignVisits.visits = res.visits),
        tap((res: { unableDeleteVisitTab: any[], visits:CampaignVisit[], visitPageUpdated:Boolean}) => this.openModalVisitsNotRemoved(res.unableDeleteVisitTab)),
        tap((res: { unableDeleteVisitTab: any[], visits:CampaignVisit[], visitPageUpdated:Boolean}) => this.displayMessageCustomizedMode(res.visitPageUpdated)),
        tap(() => this.visitsChanged = false),
        tap(() => this.errorManager.displayMessage("ON_SUCCESS_UPDATE")),
        finalize(() => this.showSpinner = false)
      ).subscribe();
    }
  }

  onCancel() {
    this.campaignVisits = _.cloneDeep(this.originalCampaignVisits);
    this.visitsChanged = false;
    this.onErrorVisitsValues = false;
    this.initSelectedBlocks.next();
  }

  areAllBlocksInVisits(campaignVisits: CampaignVisits) {
    let missingBlocks = false;
    const blocksInVisits = _.flatten(campaignVisits.visits.map(visit => { return visit.blocks })).filter(b => b.isActive);
    _.get(campaignVisits, 'blocks', []).filter(block => block.isActive).forEach(b => {
      if(!blocksInVisits.find(block => block.id === b.id && block.idInQuestionnaire === b.idInQuestionnaire)) {
        missingBlocks = true;
      }
    });
    if(missingBlocks) this.errorManager.displayMessage("ON_VALIDATE_VISITS_MISSING_BLOCKS", 'warning', {timeOut:10000});
  }

  openModalVisitsNotRemoved(unableDeleteVisitTab) {
    if(!_.isEmpty(unableDeleteVisitTab)) {
      const modal = this.modalService.open(RemoveVisitsModalComponent, this.modalOption);
      modal.componentInstance.unableDeleteVisitTab = unableDeleteVisitTab;
      modal.result.then(() => {
      }, (reason) => { });
    };
  }

  setSelection() {
    this.campaignVisits.visitSelection = !this.campaignVisits.visitSelection;
    this.updateNbVisitsByVolunteer(this.campaignVisits);
    this.visitsChanged = true;
  }

  private displayMessageCustomizedMode(visitPageUpdated) {
    if(visitPageUpdated) {
      this.errorManager.displayMessage("CUSTOMIZED_MODE_RESET", 'warning', { timeOut: 10000 }, true);
    }
  }

  private areVisitsInOrder(campaignVisits): boolean {
    let value = 0;
    for (let visit of campaignVisits.visits) {
      
      if (visit.unit.name === "NONE") {
        this.errorManager.displayMessage("ERROR_UNIT_NONE", 'danger', { timeOut: 10000 }, true);
        return false;
      }

      if(visit.value <= value && visit.index !==0) {
        this.errorManager.displayMessage("ERROR_INVALID_VISITS_VALUES", 'danger', { timeOut: 10000 }, true);
        this.onErrorVisitsValues = true;
        return false;
      } else {
        value = visit.value;
      }
    };
    return true;
  }

  private updateNbVisitsByVolunteer(campaignVisits: CampaignVisits) {
    this.campaignVisits.nbVisitsByVolunteer = campaignVisits.visitSelection ? campaignVisits.visits.length + 1 : campaignVisits.visits.length;
  }

}
