<div class="container">
  <ngx-loading [show]="showSpinner">
  </ngx-loading>
  <div *ngIf="!showSpinner && !error" class="ibox float-e-margins">
    <div class="ibox-title">
      <h5 translate> ACTIONS </h5>
    </div>
    <div *ngIf="campaignPublication.hasSynergy && !campaignPublication.isTemplate" class="ibox-content row">
      <div class="col-12 col-md-3 my-auto text-center">
        <dna-checkbox [isChecked]="campaignPublication.toxAgreement"
          [disabled]="campaignPublication.state !== campaignStates.Draft && campaignPublication.state !== campaignStates.Suspended"
          (onBooleanChanged)="onToxAgreementChange()">
        </dna-checkbox>
      </div>
      <label class="col-12 col-md-9 col-form-label" translate> TOX_AGREEMENT </label>
    </div>
    <div class="ibox-content actions-gap">
      <div *ngIf="campaignPublication.state !== campaignStates.Published" class="form-group row">
        <div class="col-12 col-md-3">
          <button class="btn btn-primary" (click)="publish(campaignPublication)"
            [disabled]="checkPublishStatus()"
            translate>PUBLISH</button>
        </div>
        <label *ngIf="!campaignPublication.isTemplate" class="col-12 col-md-9 col-form-label" translate>
          PUBLICATION_CAMPAIGN </label>
      </div>
      <div *ngIf="campaignPublication.state === campaignStates.Published" class="form-group row">
        <div class="col-12 col-md-3">
          <button class="btn btn-warning"
            [disabled]=" !campaignPublication.isTemplate && campaignPublication.progress != 0"
            (click)="suspend(campaignPublication)" translate>SUSPEND</button>
        </div>
        <label *ngIf="!campaignPublication.isTemplate && campaignPublication.progress != 0"
          class="col-12 col-md-9 col-form-label" translate> SUSPEND_NOT_ALLOW </label>
      </div>
      <div *ngIf="campaignPublication.state === campaignStates.Published && !campaignPublication.isTemplate"
        class="form-group row">
        <div class="col-12 col-md-3">
          <button class="btn btn-primary" (click)="finish(campaignPublication)" translate>FINISH</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate>FINISH_DESCRIPTION</label>
      </div>
      <div
        *ngIf="!campaignPublication.isTemplate && campaignPublication.state !== campaignStates.Abandoned && campaignPublication.state !== campaignStates.Finished"
        class="form-group row">
        <div class="col-12 col-md-3">
          <button class="btn btn-dark" (click)="abandon(campaignPublication)" translate>ABANDON</button>
        </div>
      </div>
      <div class="form-group row"
        *ngIf="!campaignPublication.isTemplate && campaignPublication.actiview !== undefined && campaignPublication.actiview !== null">
        <div class="col-12 col-md-3">
          <button id="milor" class="btn btn-primary" type="button" (click)="openModalActiviewData()"
            translate>SAVE_SEND_ACTIVIEW</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> SEND_ACTIVIEW_DESC </label>
      </div>
      <ng-container
        *ngIf="!campaignPublication.isTemplate && hasEvaluations && campaignPublication.state === campaignStates.Published">
        <div *ngIf="displayRandomisation" class="form-group row">
          <div class="col-12 col-md-3">
            <button class="btn btn-primary" (click)="openModalRandomisation()" translate>RANDOMIZATION</button>
          </div>
          <label class="col-12 col-md-9 col-form-label" translate>MANAGE_RANDOMISATION_FORMULA</label>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-3">
            <button class="btn btn-primary" (click)="export(campaignPublication)" translate>EXPORT</button>
          </div>
          <label class="col-12 col-md-9 col-form-label" translate>EXPORT_DESC</label>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-3">
            <button class="btn btn-primary" (click)="import()" translate>IMPORT</button>
          </div>
          <label class="col-12 col-md-9 col-form-label" translate>IMPORT_DESC</label>
        </div>
      </ng-container>
      <div
        *ngIf="!hasEvaluations && campaignPublication.state === campaignStates.Published && !campaignPublication.isTemplate"
        class="form-group row">{{ "ERROR_NO_EVALUATIONS" | translate }}</div>
      <div *ngIf="campaignPublication.state === campaignStates.Published && linkAuthorized" class="form-group row">
        <div class="col-12 col-md-3">
          <a target="_blank" [href]="url">
            <button class="btn btn-primary" translate>LINK_MOBILE</button>
          </a>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> GO_TO_MOBILE </label>
      </div>
      <div class="form-group row">
        <div class="col-12 col-md-3">
          <button id="charac" class="btn btn-primary" type="button" (click)="chooseCaracterisation()"
            translate>SELECT_CARAC</button>
        </div>
        <label class="col-12 col-md-9 col-form-label" translate> SELECT_CARAC_DESC </label>
      </div>
      <div *ngIf="isConfort" class="form-group row">
          <div class="col-12 col-md-3">
            <button id="timeconfort" class="btn btn-primary" type="button" (click)="chooseConfortTime()"
              translate>SELECT_CONFORT_TIME</button>
          </div>
          <label class="col-12 col-md-9 col-form-label" translate> SELECT_CONFORT_TIME_DESC </label>
        </div>
    </div>
  </div>
  <div *ngIf="!showSpinner && error" class="ibox float-e-margins">
    <div class="ibox-title">
      <div class="row">
        <div class="col-12 col-md-4 my-auto">
          <h5>{{ "ERROR" | translate }}</h5>
        </div>
      </div>
    </div>
    <dna-error-page (onClickReload)="init()"></dna-error-page>
  </div>
</div>
