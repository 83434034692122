
import { throwError as observableThrowError, empty as observableEmpty, Observable, Subject, of, throwError, from } from 'rxjs';

import { tap, takeUntil, zip, catchError, mergeMap, finalize } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import * as pdfFontsCJ from '../../../../../../inlineScripts/vfs_fonts_cj.js';
import * as pdfMake from 'pdfmake/build/pdfmake.js';

import {
  CampaignStates,
  FicheCaracterisation,
  Image,
  OnePager,
  Panelist,
  PDFTranslation,
  States,
  TypeMetier,
  SimComp,
  CampaignOnePager,
  Languages,
  UserInCampaign,
  OnePagerPreview,
  CapturesData,
  User,
  ConfortData,
  Workspace,
} from '../../../../../types';
import { CampaignService } from '../../../../campaigns.service';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import { GeneratePDFService } from '../../../../../shared/services/generate-pdf.service';
import { OnePagerService } from '../../../../../shared/services/one-pager.service';
import { UserService } from '../../../../../shared/services/user.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MilorAdditionalInfosComponent } from '../../../../../shared/modals/milorAdditionalInfos/milorAdditionalInfos.component';
import { environment } from '../../../../../../environments/environment';
import { UtilService } from '../../../../../shared/services/util.service';
import { ActiviewDataComponent } from '../../../../../shared/modals/actiview-data/actiview-data.component';
import { WorkspaceService } from '../../../../../general/my-profile/workspaces/workspaces.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-one-pager-preview',
  templateUrl: './one-pager-preview.component.html',
  styleUrls: ['./one-pager-preview.component.less']
})

export class OnePagerPreviewComponent implements OnInit {

  campaign: CampaignOnePager;
  campaignStates: typeof CampaignStates = CampaignStates;
  displayProductsTable = false;
  formsCaracterisation: FicheCaracterisation[] = [];
  images: Image[] = [];
  screens: string[];
  imagesAndComments: { data: string }[] = [];
  metierName: TypeMetier;
  onePager: OnePager;
  onePagerPDF: OnePager;
  panelists: Panelist[] = [];
  showSpinner = true;
  valuesFromAttributeDetails: string[][];
  routinesTabForReport: any[] = [];
  pdf: any;
  isPDFLoading: boolean = true;
  simComp = false;
  currentLanguage: string;
  isMilorAvailable = true;
  title = '';
  keysFromRoutines: object[] = [];
  pdfTranslations: PDFTranslation;
  onePagerPreview: OnePagerPreview;
  user: User;
  formulasName: string[];
  translationsFromRoutines: () => Observable<object[]>;
  translationsFromPDF: () => Observable<PDFTranslation>;
  imagesCaptures$: Observable<CapturesData[]>;

  confortTabArr

  productsInfoByVolunteer: any[];

  destroy$: Subject<boolean> = new Subject<boolean>();

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };


  constructor(
    private campaignService: CampaignService,
    private dnaTranslateService: DNATranslateService,
    private errorManagerService: ErrorManagerService,
    private generatePDFService: GeneratePDFService,
    private onePagerService: OnePagerService,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal,
    private ref: ChangeDetectorRef,
    private utilService: UtilService,
    private workspace: WorkspaceService
  ) { }


  ngOnInit() {
    this.setPdfMakeFonts();
    this.campaign = this.onePagerService.getCampaign();
    this.displayProductsTable = _.get(this.campaign, 'formula.simultaneousComparison.isActive');
    this.metierName = _.get(this.campaign, 'metier.name', undefined);
    this.formulasName = _.get(this.campaign, 'formula.listFormulas', []).map(formula => formula.name);
    this.onePager = this.onePagerService.getOnePager();
    this.isMilorAvailable = this.isMilorAvailableFunc(this.onePager);
    this.onePagerPDF = _.cloneDeep(this.onePager);
    this.title = this.generateTitle();
    this.user = this.userService.getUser();
    this.translationsFromRoutines = () => this.onePagerService.translateKeysFromRoutines(this.campaign.formula.routines, this.metierName);
    this.translationsFromPDF = () => this.generatePDFService.getTranslations(this.getMessages(this.onePager));
    this.imagesCaptures$ = this.onePagerService.getDataURI2(this.onePager.capturesUrls).pipe(
      catchError((err) => {
        this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
        setTimeout(() => {
          this.showSpinner = false;
          this.ref.detectChanges();
        });
        return of([]);
      }));
    this.getOnePagerPreview(this.translationsFromRoutines, this.translationsFromPDF, this.imagesCaptures$).pipe(
      finalize(() => {
        setTimeout(() => {
          this.showSpinner = false;
          this.ref.detectChanges();
        });
      })
    ).subscribe();

  }

  isMilorAvailableFunc(onePager: OnePager) {
    return _.get(onePager, 'identification.actiview.length', 0) > 0;
  }


  private containsJPorCNInObject(obj: any): boolean {
    const regex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;

    // Si l'objet est une chaîne de caractères, vérifiez-la directement
    if (typeof obj === 'string') {
      return regex.test(obj);
    }

    // Si l'objet est un tableau, parcourez chaque élément
    if (Array.isArray(obj)) {
      for (const item of obj) {
        if (this.containsJPorCNInObject(item)) {
          return true;
        }
      }
    }

    // Si l'objet est un objet (et non un tableau ou une chaîne), parcourez chaque propriété
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === 'dryingTypes' && Array.isArray(obj[key]) && obj[key].length > 0) {
            const text = obj[key][0].value;
            if (typeof text === 'string' && regex.test(text)) {
              return true;
            }
          } else if (this.containsJPorCNInObject(obj[key])) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private addQuantites(onePagerPreview: any) {
    const routinesTab = onePagerPreview.routinesTab;
    const datasPerVolunteer = onePagerPreview.datasPerVolunteer;

    // Inverser le tableau pour correspondre a datasPerVolunteer
    const reversedData = routinesTab.reverse();

    // Parcourir chaque objet et ajouter le nom à chaque élément de routineDatas aussi pour cloner en un seul tableau
    const mergedRoutineDatas = reversedData.flatMap(item =>
      item.routineDatas.map(routineData => ({ ...routineData, name: item.name }))
    );

    mergedRoutineDatas.forEach((routineData, routineIndex) => {
      datasPerVolunteer.forEach(volunteerDataArray => {
        volunteerDataArray.forEach((volunteerDataObj, volunteerIndex) => {
          if (volunteerDataObj.routineName === routineData.name &&
            volunteerDataObj.orderName === routineData.orderName &&
            volunteerDataObj.stepName === routineData.stepName &&
            volunteerDataObj.visitName === routineData.visit &&
            routineIndex === volunteerIndex) {

            routineData.quantitiesByVolunteer.push({
              volunteerName: volunteerDataObj.volunteerName,
              quantity: volunteerDataObj.quantitiesByVolunteer,
              pauseTime: volunteerDataObj.pauseTime,
            });
          }
        });
      });
    });

    return routinesTab.reverse();
  }


  getOnePagerPreview(translationsFromRoutines, translationsFromPDF, imagesCaptures$) {
    const onePagerInfo$ = this.onePagerService.getOnePagerPreview(this.campaign.id, this.onePager.id, _.get(this.campaign, 'synergy.requestNumber', 'NONE'));


    return translationsFromRoutines().pipe(
      zip(translationsFromPDF(), imagesCaptures$, onePagerInfo$),
      tap(([keysFromRoutines, translations, capturedData, result]) => {
        this.onePagerPreview = result;
        const quantities = this.addQuantites(this.onePagerPreview);
        this.setPdfMakeFonts();

        this.keysFromRoutines = keysFromRoutines;
        this.pdfTranslations = translations;
        this.imagesAndComments = _.orderBy(capturedData, 'index', 'asc');


        this.generatePDF(
          this.metierName,
          this.onePager,
          this.onePagerPreview.routinesTab,
          this.keysFromRoutines,
          this.pdfTranslations,
          this.imagesAndComments,
          this.displayProductsTable,
          this.onePagerPreview.caracterisations,
          this.displayProductsTable ? this.campaign.formula.simultaneousComparison.couples : [],
          this.onePagerPreview.visitsByVolunteer,
          this.onePagerPreview.confortTab,
          this.onePagerPreview.numberVolunteers,
          this.onePagerPreview.pdfReportUserByCol || false,
          this.formulasName,
        );

        this.dnaTranslateService.onLangChange().pipe(
          tap(() => this.setPdfMakeFonts(this.onePagerPreview.routinesTab.toString())),
          mergeMap(() => translationsFromRoutines().pipe(zip(translationsFromPDF()))),
          takeUntil(this.destroy$))
          .subscribe((args: [object[], PDFTranslation]) => {
            this.generatePDF(
              this.metierName,
              this.onePager,
              this.onePagerPreview.routinesTab,
              args[0],
              args[1],
              this.imagesAndComments,
              this.displayProductsTable,
              this.onePagerPreview.caracterisations,
              this.displayProductsTable ? this.campaign.formula.simultaneousComparison.couples : [],
              this.onePagerPreview.visitsByVolunteer,
              this.onePagerPreview.confortTab,
              this.onePagerPreview.numberVolunteers,
              this.onePagerPreview.pdfReportUserByCol || false,
              this.formulasName,
            );
          });
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  archiveCampaign() {
    this.showSpinner = true;
    this.campaignService.putCampaignState(this.campaign.id, { state: States.Archived, oldState: this.campaign.state }).pipe(
      catchError(error => {
        this.showSpinner = false;
        this.catchError(error);
        return observableThrowError(error);
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe(() => this.router.navigate(['campaigns']));
  }

  catchError = (error) => {
    setTimeout(() => {
      this.showSpinner = false;
      this.ref.detectChanges();
    });
    this.errorManagerService.catchError(error);
    return observableEmpty();
  }


  private generatePDF(
    metierName: TypeMetier,
    onePager: OnePager,
    routinesTabForReport: any[],
    valuesFromRoutinesTranslations: object[],
    translations: PDFTranslation,
    images,
    isRoutine: boolean,
    formsCaracterisation: FicheCaracterisation[],
    couples: SimComp[],
    visitsByVolunteer: any[],
    confortTab: ConfortData[],
    numberVolunteers: number,
    pdfReportUserByCol: boolean,
    formulasName: string[],
  ) {
    const docDefinition = {
      info: {
        title: this.title
      },
      // a string or { width: number, height: number }
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [15, 15],
      content: [],
      styles: {},
      defaultStyle: {
        columnGap: 5
      }
    };

    // remove empty name item
    const formattedOnePager = {
      ...onePager,
      identification: {
        ...onePager.identification,
        expert: onePager.identification.expert.filter(exp => exp.name)
      },
    };

    this.confortTabArr = confortTab;

    this.confortTabArr.forEach((confortTabItem) => {
      if (Array.isArray(confortTabItem.values)) {
        confortTabItem.values.forEach((confortData) => {
          if (confortData.pvalue === null) {
            confortData.pvalue = '';
          }
          if (confortData.pvalue < 1e-5) {
            confortData.pvalue = '<0.0001';
          }
        });
      }
    });

    docDefinition.content = this.generatePDFService.getContent(
      pdfReportUserByCol,
      metierName,
      formattedOnePager,
      routinesTabForReport,
      valuesFromRoutinesTranslations,
      translations,
      images,
      isRoutine,
      formsCaracterisation,
      couples,
      visitsByVolunteer,
      this.confortTabArr,
      numberVolunteers,
      formulasName,
    );
    docDefinition.styles = this.generatePDFService.getStyles();

    // Open the PDF
    this.pdf = pdfMake.createPdf(docDefinition);
    this.isPDFLoading = false;
  }


  downloadPdf() {
    this.pdf.download(this.title + '.pdf');
  }

  openPdf() {
    this.pdf.open();
  }

  sendToMilor() {
    const modal = this.modalService.open(MilorAdditionalInfosComponent);
    modal.componentInstance.campaign = this.campaign;
    modal.componentInstance.metier = this.user.currentWorkspace.name;
    modal.componentInstance.lang = this.user.language;
    modal.componentInstance.title = this.title;
    //  modal.componentInstance.recipients = this.onePager.archiveRecipients ? this.onePager.archiveRecipients.map(v => { return { 'display': v, 'value': v } }) : [];
    this.showSpinner = true;
    from(modal.result).pipe(
      tap(data => {
        if (data.title) {
          this.onePager.archiveProjectTitle = data.title;
          this.title = data.title;
          //     this.onePager.archiveRecipients = data.recipients;
        }
      }),
      mergeMap(() => {
        return from(new Promise((resolve, reject) => {
          this.pdf.getBase64(base64 => {
            resolve(base64);
          });
        }));
      }),
      mergeMap((base64: string) => {
        const contributors = this.campaign.users.accountables.map((ex: UserInCampaign) => `${ex.key} `);
        return this.postOnePagerToMilor(this.campaign.id, this.onePager.id, base64, this.onePager.archiveProjectTitle, this.onePager.archiveRecipients, this.userService.getUser().track, contributors);
      }),
      finalize(() => {
        this.showSpinner = false;
        this.ref.detectChanges();
      })
    ).subscribe();
  }

  postOnePagerToMilor(idCampaign: string, idOnePager: string, base64: string, projectTitle: string, recipients: string[], peopleKey: string, contributors: string[]): Observable<any> {
    this.errorManagerService.displayMessage('Envoi en cours', 'warn');
    return this.campaignService.postOnePagerToMilor(idCampaign, idOnePager, base64, projectTitle, recipients, peopleKey, contributors).pipe(
      catchError((err: HttpErrorResponse) => {
        this.showSpinner = false;
        this.onePager.sendToMilor = false;
        if (err && err.error && err.error.reason && err.error.reason.startsWith("Milor")) {
          this.errorManagerService.displayMessage(err.error.reason, 'danger');
        } else {
          this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
        }

        return throwError(err);
      }),
      tap((milorData: any) => {
        console.log('archiveId before', this.onePager.archiveId);
        if (milorData && milorData.documentCurrentId) {
          this.onePager.archiveId = milorData.documentCurrentId;
          this.onePager.sendToMilor = true;
          this.goToMilorPlatform();
        }
      }),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS'))
    );
  }

  goToMilorPlatform() {
    console.log('archiveId after', this.onePager.archiveId);
    window.open(environment.milor_url() + this.onePager.archiveId);
  }

  openModalActiviewData() {
    const modalActiviewData = this.modalService.open(ActiviewDataComponent, this.modalOption);
    modalActiviewData.componentInstance.campaignId = this.campaign.id;
    modalActiviewData.componentInstance.isVolunteer = _.get(this.campaign, 'users.isVolunteer', false);
    modalActiviewData.result.then(() => {
      this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
      this.ref.detectChanges();
    }, (reason) => { });
  }

  getActiviewDatas() {
    this.campaignService.getCampaignAnalyse;
  }

  replaceAll(object, regexp, replacement) {
    const regExp = new RegExp(regexp, 'g');
    for (const property in object) {
      if (object.hasOwnProperty(property)) {
        let data = object[property];
        if (typeof data === 'object') {
          data = this.replaceAll(data, regexp, replacement);
        } else if (typeof data === 'string') {
          data = data.replace(regExp, replacement);
        }
        object[property] = data;
      }
    }
    return object;
  }


  private setPdfMakeFonts(content?: string) {
    if (!content) {
      content = this.dnaTranslateService.getLanguage();
    }

    this.currentLanguage = this.dnaTranslateService.getLanguage();
    const hasJPorCNCharacters = this.containsJPorCNInObject(this.onePagerPreview);

    if (hasJPorCNCharacters || this.currentLanguage === Languages.Chinese || this.currentLanguage === Languages.Japanese) {
      pdfMake.vfs = pdfFontsCJ.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: '方正黑体简体.TTF',
          bold: '方正黑体简体.TTF',
          italics: '方正黑体简体.TTF',
          bolditalics: '方正黑体简体.TTF',
        },
        NotoSansJP: {
          normal: 'NotoSansJP-Regular.ttf',
          bold: 'NotoSansJP-Bold.ttf',
          italics: 'NotoSansJP-Italic.ttf',
          bolditalics: 'NotoSansJP-BoldItalic.ttf'
        },
        方正黑体简体: {
          normal: '方正黑体简体.TTF',
          bold: '方正黑体简体.TTF',
          italics: '方正黑体简体.TTF',
          bolditalics: '方正黑体简体.TTF',
        },
        defaultStyle: {
          font: '方正黑体简体'
        }
      };
    } else {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf',
        },
        defaultStyle: {
          font: 'Roboto'
        }
      };
    }
  }


  private generateTitle() {
    let title = _.get(this.onePager, 'archiveProjectTitle', '');
    const titleTemplate = '{{bridge}} - SEE - {{actiview}}{{fieldwork}} [ {{studyname}} ] {{metier}} - {{orchestra}}Study report - {{lang}}';
    if (_.isEmpty(title)) {
      title = titleTemplate;
      title = title.replace('{{bridge}}', _.get(this.campaign, 'synergy.requestNumber', ''));
      title = title.replace('{{actiview}}', '');
      title = title.replace('{{fieldwork}}', _.get(this.campaign, 'fieldWork.id', ''));
      title = title.replace('{{studyname}}', this.campaign.name);
      title = title.replace('{{metier}}', _.get(this.userService.getUser(), 'currentWorkspace.name', ''));
      title = title.replace('{{orchestra}}', '');
      title = title.replace('{{lang}}', this.utilService.languageToISOCode(_.get(this.userService.getUser(), 'language', 'english')));
    }
    return title;
  }

  getMessages(onePager: OnePager): PDFTranslation {
    const messages = new PDFTranslation();
    if (_.has(onePager, 'substrate.key')) {
      messages.substrate = onePager.substrate.key;
    }
    return messages;
  }
}
