import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { Hub, States, User, Workflow } from '../../../types';
import { UserService } from '../../../shared/services/user.service';
import { UtilService } from '../../../shared/services/util.service';
import { WorkflowService } from '../../../workflows/workflows.service';

@Component({
  selector: 'dna-workflow-view',
  templateUrl: './workflow-view.component.html',
  styleUrls: ['../dashboard.component.less']
})
export class WorkflowViewComponent implements OnInit {

  hover1: boolean = false;
  hover3: boolean = false;
  hover5: boolean = false;
  showSpinner: boolean = true;
  subscribeInit: Subscription;
  subscribeUser: Subscription;
  user: User;
  userHub : Hub;
  workflows: Workflow[] = [];
  workflowsInProgress: Workflow[] = [];
  workflowsSuspended: Workflow[] = [];
  workflowsToPrepare: Workflow[] = [];
  workflowStates: typeof States = States;

  constructor(
    private errorManagerService: ErrorManagerService,
    private userService: UserService,
    public utilService: UtilService,
    private workflowService: WorkflowService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.userHub = this.userService.getUserHub(this.user.hub);
    this.subscribeInit = this.init().pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(
      () => { },
      error => this.errorManagerService.catchError(error)
      );

    this.subscribeUser = this.userService.onUserChanged().pipe(
      tap(() => this.showSpinner = true),
      tap((user: User) => this.user = user),
      mergeMap(() => this.init()),)
      .subscribe(
      () => this.showSpinner = false,
      error => {
        this.showSpinner = false;
        this.errorManagerService.catchError(error);
      });
  }

  ngOnDestroy() {
    this.subscribeInit.unsubscribe();
    this.subscribeUser.unsubscribe();
  }

  filterWorkflowsByState(workflows) {
    this.workflowsToPrepare = workflows.filter(workflow => workflow.state === States.Draft);
    this.workflowsInProgress = workflows.filter(workflow => workflow.state === States.Published);
    this.workflowsSuspended = workflows.filter(workflow => workflow.state === States.Suspended);
  }
  filterWorkflowsByHubUser(workflows:Workflow[]){
    this.workflows = workflows;

    if(!this.userHub) return workflows;
    return workflows.filter(workflow => workflow.hub === this.userHub);
  }
  init(): Observable<Workflow[]> {
    return this.workflowService.getWorkflows().pipe(
      tap((workflows) => this.filterWorkflowsByState(this.filterWorkflowsByHubUser(workflows.list))));
  }

}
